import React, { useState, useEffect } from 'react'
import Layout from 'components/Layout'
import { MainContainer } from './Main.styled.js'
import { Button, Select, Tabs, Row, Col, Empty } from 'antd'
import { StyledModal } from 'styled'
import moment from 'moment'
import ExerciseEmpty from 'assets/exercises/empty.png'
import PreviewRoutine from '../PreviewRoutine'
import CreateRoutine from '../CreateRoutine'
import ImportRoutine from '../ImportRoutine'
import { WorkoutProvider, WorkoutConsumer } from '../Uses/WorkoutStore'
import RoutineList from './RoutineList'
import { TableOutlined } from '@ant-design/icons'
import TablesSummary from '../TablesSummary'

const { Option } = Select
const { TabPane } = Tabs

export default (props) => {
  const [bootcampSelected, setBootcampSelected] = useState()
  const [routineSelected, setRoutineSelected] = useState()
  const [categorySelected, setCategorySelected] = useState('GYM')
  const [levelSelected, setLevelSelected] = useState(1)
  const [routineModalVisible, setRoutineModalVisible] = useState(false)
  const [newRoutineVisible, setNewRoutineVisible] = useState(false)
  const [importRoutineVisible, setImportRoutineVisible] = useState(false)
  const [createRoutineStep, setCreateRoutineStep] = useState(0)
  const [previewProgram, setPreviewProgram] = useState(false)

  useEffect(() => {
    if (previewProgram && previewProgram !== categorySelected) setPreviewProgram(categorySelected)
  }, [categorySelected])

  useEffect(() => {
    setPreviewProgram(false)
  }, [bootcampSelected, levelSelected])

  const handleNewBootcampModal = () => {
    setRoutineModalVisible(!routineModalVisible)
  }

  const handleUsePrevious = () => {
    setImportRoutineVisible(!importRoutineVisible)
    setRoutineModalVisible(!routineModalVisible)
  }

  const handleCreateNew = () => {
    localStorage.removeItem('ROUTINE_ID')
    setCreateRoutineStep(0)
    setRoutineSelected(null)
    setPreviewProgram(null)
    setNewRoutineVisible(true)
    setRoutineModalVisible(!routineModalVisible)
  }

  const handleBootcampSelect = (bootcampId) => {
    setRoutineSelected(null)
    setBootcampSelected(bootcampId)
  }

  const handleRoutineSelect = (routineId) => {
    setNewRoutineVisible(false)
    setRoutineSelected(routineId)
    setPreviewProgram(null)
  }

  const handleEditRoutine = (routineId, step) => {
    let stepNumber = 0
    if (step !== 'Imported') {
      stepNumber = parseInt(step.replace('InProgress_', ''))
    }
    localStorage.setItem('ROUTINE_ID', routineId)
    setCreateRoutineStep(stepNumber)
    setRoutineSelected(null)
    setNewRoutineVisible(true)
    setPreviewProgram(null)
  }

  const reset = () => {
    localStorage.removeItem('ROUTINE_ID')
    setRoutineSelected(null)
    setNewRoutineVisible(false)
    setImportRoutineVisible(false)
    setCategorySelected('GYM')
    setRoutineModalVisible(false)
    setPreviewProgram(null)
  }

  const handleSummary = (e, programType, workoutSelected) => {
    e.preventDefault()
    if (workoutSelected.routines.filter((r) => r.category === programType).length === 0) return
    setPreviewProgram(programType)
    setRoutineSelected(null)
    setNewRoutineVisible(false)
  }

  return (
    <Layout {...{ ...props }}>
      <MainContainer>
        <p className="title">Exercises Programming</p>
        <hr />
        <WorkoutProvider>
          <WorkoutConsumer>
            {({ workouts, bootcamps, refetch }) => {
              bootcamps = bootcamps.filter((b) => moment(b.close_date).isAfter(moment().local()))
              const bootcampDefault = bootcamps[0].program_id
              const workoutsToShow = workouts.filter((w) => w.program_id === (bootcampSelected || bootcampDefault)).sort((a, b) => a.level - b.level)
              const workoutSelected = workoutsToShow.find((w) => w.level === levelSelected) || { routines: [] }
              return (
                <Row gutter={[16, 16]}>
                  <Col xs={24} md={6} lg={6} className={newRoutineVisible ? 'disabled' : ''}>
                    <div className="card" style={{ marginBottom: '5px' }}>
                      <p>Select a bootcamp</p>
                      <Select value={bootcampSelected || bootcampDefault} onChange={handleBootcampSelect}>
                        {bootcamps.map((b) => {
                          return <Option key={b.program_id}>{b.description}</Option>
                        })}
                      </Select>
                      <hr />
                      <div className="levels">
                        {workoutsToShow.map((l) => {
                          return (
                            <div
                              key={l.level}
                              onClick={() => setLevelSelected(l.level)}
                              className={`${l.level === levelSelected ? 'selected' : ''} level-${l.level}`}
                            >
                              Level {l.level}
                            </div>
                          )
                        })}
                      </div>
                    </div>
                    <div className="card" style={{ marginBottom: '5px' }}>
                      <Tabs defaultActiveKey="GYM" className="routines-tab" onChange={(e) => setCategorySelected(e)}>
                        <TabPane
                          tab={
                            <span>
                              Gym
                              <TableOutlined onClick={(e) => handleSummary(e, 'GYM', workoutSelected)} />
                            </span>
                          }
                          key="GYM"
                        >
                          <RoutineList
                            workoutPlanId={workoutSelected.id}
                            routines={workoutSelected.routines}
                            category="GYM"
                            {...{ handleRoutineSelect, routineSelected, levelSelected }}
                            reload={() => {
                              reset()
                              refetch()
                            }}
                          />
                        </TabPane>
                        <TabPane
                          tab={
                            <span>
                              Bodyweight
                              <TableOutlined onClick={(e) => handleSummary(e, 'HOME', workoutSelected)} />
                            </span>
                          }
                          key="HOME"
                        >
                          <RoutineList
                            workoutPlanId={workoutSelected.id}
                            routines={workoutSelected.routines}
                            category="HOME"
                            {...{ handleRoutineSelect, routineSelected, levelSelected }}
                            reload={() => {
                              reset()
                              refetch()
                            }}
                          />
                        </TabPane>
                      </Tabs>
                    </div>
                    <Button type="primary" onClick={handleNewBootcampModal} block>
                      Add program day
                    </Button>
                    <StyledModal
                      visible={routineModalVisible}
                      title="How do you want to add a new routine?"
                      className="confirm"
                      centered={true}
                      onCancel={handleNewBootcampModal}
                      footer={[
                        <Button key="import" onClick={handleUsePrevious}>
                          Use previous
                        </Button>,
                        <Button key="create" type="primary" onClick={handleCreateNew}>
                          Create new
                        </Button>,
                      ]}
                    />
                    <ImportRoutine
                      bootcampId={bootcampSelected || bootcampDefault}
                      visible={importRoutineVisible}
                      onSuccess={() => {
                        reset()
                        refetch()
                      }}
                      onCancel={reset}
                    />
                  </Col>
                  <Col xs={24} md={18} lg={18}>
                    <div className="card" style={{ height: '100%' }}>
                      {routineSelected && (
                        <PreviewRoutine
                          id={routineSelected}
                          bootcampId={bootcampSelected || bootcampDefault}
                          editRoutine={handleEditRoutine}
                          onSave={() => {
                            reset()
                            refetch()
                          }}
                          editable
                        />
                      )}
                      {newRoutineVisible && (
                        <CreateRoutine
                          bootcampId={bootcampSelected || bootcampDefault}
                          workout={workoutSelected}
                          category={categorySelected}
                          stepToShow={createRoutineStep}
                          level={levelSelected}
                          onCancel={() => {
                            reset()
                            refetch()
                          }}
                          onFinish={reset}
                        />
                      )}
                      {previewProgram && (
                        <TablesSummary
                          key={previewProgram}
                          programRoutines={workoutSelected.routines.filter((r) => r.category === previewProgram)}
                          programName={workoutSelected.name}
                        />
                      )}
                      {!routineSelected && !newRoutineVisible && !previewProgram && (
                        <Empty image={ExerciseEmpty} description="Select a Program to see details" />
                      )}
                    </div>
                  </Col>
                </Row>
              )
            }}
          </WorkoutConsumer>
        </WorkoutProvider>
      </MainContainer>
    </Layout>
  )
}
