import React, { Fragment, useEffect, useState } from 'react'
import { Row, Col, Steps, Form, Input, DatePicker } from 'antd'
import { TimelineContainer } from './Bootcamps.styled'

import moment from 'moment'

const FormItem = Form.Item
const { TextArea } = Input

export default function Timeline({ timeline, edit, form }) {
  const [timelineS, setTimelineS] = useState(timeline)

  useEffect(() => {
    setTimelineS(timeline)
    let fields = {}

    timeline.forEach((tl, i) => {
      fields[`Timeline.Title${i}`] = tl.Title ? tl.Title : null
      fields[`Timeline.date${i}`] = tl.Date ? moment(tl.Date) : null
      fields[`Timeline.description${i}`] = tl.Description ? tl.Description : null
    })

    form.setFieldsValue(fields)
  }, [timeline])

  localStorage.setItem('CurrentTimeline', JSON.stringify(timelineS))

  const getStepTitle = (step) => {
    return (
      <>
        <h4>{moment(step.Date).format('MMMM D')}</h4>
        <h2>{step.Title}</h2>
      </>
    )
  }

  const getStepIcon = (step) => {
    if (!!step.Icon) return <img style={step.IconStyle ? step.IconStyle : null} src={step.Icon} alt="" />
    return
  }

  const handleTimelineChanges = (id, value, type) => {
    setTimelineS(
      timeline.map((t) => {
        if (t.Id === id) {
          if (type === 'title') t.Title = value
          if (type === 'date') t.Date = value
          if (type === 'description') t.Description = value
        }
        return t
      }),
    )
    localStorage.setItem('CurrentTimeline', JSON.stringify(timeline))
  }

  return (
    <div className="content-shadow">
      <h3 className="section-title"> </h3>
      <Row>
        <Col md={12} sm={24} xs={24}>
          <p>Preview Timeline</p>
          <TimelineContainer>
            <div className="content">
              <Steps direction="vertical">
                {timeline.map((step, i) => {
                  return (
                    <Steps.Step
                      key={i}
                      className={step.IsColored ? 'colored' : ''}
                      title={getStepTitle(step)}
                      description={<div dangerouslySetInnerHTML={{ __html: step.Description }} />}
                      icon={getStepIcon(step)}
                    />
                  )
                })}
              </Steps>
            </div>
          </TimelineContainer>
        </Col>
        <Col md={12} sm={24} xs={24}>
          <p>Timeline Steps</p>
          {timeline.map((step, i) => {
            return (
              <Fragment key={i}>
                <p className="title">Step title</p>
                {!edit ? (
                  <p>{step.Title}</p>
                ) : (
                  <FormItem
                    name={`Timeline.Title${i}`}
                    rules={[
                      {
                        max: 65,
                        message: 'The max length is 65 characters',
                      },
                    ]}
                  >
                    <Input onChange={(e) => handleTimelineChanges(step.Id, e.target.value, 'title')} />
                  </FormItem>
                )}

                <p className="title">Date</p>
                {!edit ? (
                  <p>{moment(step.Date).format('DD/MMM/YYYY')}</p>
                ) : (
                  <FormItem name={`Timeline.date${i}`}>
                    <DatePicker format="DD/MMM/YYYY" onChange={(date) => handleTimelineChanges(step.Id, date, 'date')} />
                  </FormItem>
                )}

                <p className="title">Description</p>
                {!edit ? (
                  <p>{step.Description}</p>
                ) : (
                  <FormItem
                    name={`Timeline.description${i}`}
                    rules={[
                      {
                        max: 255,
                        message: 'The max length is 255 characters',
                      },
                    ]}
                  >
                    <TextArea onChange={(e) => handleTimelineChanges(step.Id, e.target.value, 'description')} autoSize={{ minRows: 3, maxRows: 5 }} />
                  </FormItem>
                )}

                <hr />
              </Fragment>
            )
          })}
        </Col>
      </Row>
    </div>
  )
}
