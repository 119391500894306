import styled from '@emotion/styled'
import { Drawer } from 'antd'

export const ContentDrawer = styled(Drawer)`
  .ant-drawer-body {
    padding-bottom: 45px;
    padding-right: 0px;
    padding-top: 10px;
  }
  .ant-drawer-title {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    margin-right: 15px;
    span {
      font-size: 20px;
      margin-right: 15px;
    }
  }
  .anticon {
    color: #ff6b00 !important;
  }
  .ant-checkbox-wrapper {
    display: flex;
    margin-bottom: 10px;
    line-height: 15px;

    .ant-checkbox-inner {
      background-color: #c4c4c4;
      box-shadow: inset 0px 4px 8px #979797;
    }

    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: #ff4613;
      box-shadow: none;
    }

    .ant-checkbox + span {
      font-size: 14px;
      font-weight: 500;
      color: #5d5d5d;
    }
  }

  .step-action {
    background-color: #fff;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    box-shadow: 2px 1px 7px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    padding: 10px;
    button {
      width: 100px !important;
    }
    .btn-reset {
      border: 1px solid #ff6b00;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 12.5px;
      color: #ff6b00;
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
    }
  }
`
