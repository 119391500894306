import React, { Component, useState, useEffect } from 'react'
import { Query } from 'react-apollo'
import apolloClient from 'apolloClient.js'
import gql from 'graphql-tag'
import Loader from 'components/Loader'
import Layout from 'components/Layout'
import { apiServices } from 'services/api'
import { Card, Table, Tag, Modal, Button, Tabs, notification, Avatar, Popconfirm, Input, Select } from 'antd'
import { UserOutlined, SaveOutlined, EditOutlined, LockFilled, UnlockFilled } from '@ant-design/icons'
import './index.scss'
import moment from 'moment'
import { getCookie } from 'tiny-cookie'
import { COOKIE } from 'constants.js'

const UpdateBlock = gql`
  mutation update_block($id: uuid!, $blocked: Boolean!) {
    update_user(where: { id: { _eq: $id } }, _set: { is_active: $blocked }) {
      returning {
        id
        is_active
      }
      affected_rows
    }
  }
`

const UpdateBootcampBlock = gql`
  mutation update_bootcamp_block(
    $traineeId: uuid!
    $programId: uuid!
    $blocked: Boolean!
    $cancellationReason: String!
    $cancellationDate: timestamp!
  ) {
    update_trainee_program_coach(
      where: { trainee_id: { _eq: $traineeId }, _and: { program_id: { _eq: $programId } } }
      _set: { is_active: $blocked, cancellation_reason: $cancellationReason, cancellation_date: $cancellationDate }
    ) {
      returning {
        is_active
        coach_id
        trainee_id
        cancellation_reason
        cancellation_date
      }
    }
  }
`

const getClients = gql`
  query getClients($today: date) {
    program {
      program_id
      description
      start_date
      end_date
      access_date
      coach_letters_delivery_date
      group_delivery_date
      exercises_delivery_date
    }
    coach(where: { is_active: { _eq: true } }, order_by: { user: { first_name: asc } }) {
      id
      user_id
      user {
        first_name
        last_name
      }
      max_students
      assigned_aggregate(where: { program: { start_date: { _lte: $today }, _and: { end_date: { _gte: $today } } } }) {
        aggregate {
          count
        }
      }
    }
    trainees: trainee(order_by: { user: { last_login: desc } }) {
      user {
        id
        first_name
        last_name
        email
        contact_id
        is_active
        picture
      }
      trainee_program_coach: selected(order_by: { program: { start_date: desc } }) {
        trainee_id
        program_id
        coach_id
        is_active
        cancellation_reason
        cancellation_date
        extras
        enrollment_stage
        enrollment {
          level
          sub_level
        }
        thread_id
        welcome_letter_sent
        macro {
          status
        }
      }
    }
    configuration(where: { id: { _eq: "Levels" } }) {
      id
      value
    }
  }
`

const updateClientBootcampData = gql`
  mutation UpdateClientBootcampData($programId: uuid!, $traineeId: uuid!, $level: Int, $subLevel: String, $coachId: uuid) {
    update_trainee_details(
      where: { program_id: { _eq: $programId }, _and: { trainee_id: { _eq: $traineeId } } }
      _set: { level: $level, sub_level: $subLevel }
    ) {
      affected_rows
    }
    update_trainee_program_coach(
      where: { program_id: { _eq: $programId }, _and: { trainee_id: { _eq: $traineeId } } }
      _set: { coach_id: $coachId }
    ) {
      affected_rows
    }
  }
`

const updateClientBootcampDataWithMacro = gql`
  mutation UpdateClientBootcampDataWithMacro(
    $programId: uuid!
    $traineeId: uuid!
    $level: Int
    $subLevel: String
    $coachId: uuid
    $coachApprover: uuid
  ) {
    update_trainee_details(
      where: { program_id: { _eq: $programId }, _and: { trainee_id: { _eq: $traineeId } } }
      _set: { level: $level, sub_level: $subLevel }
    ) {
      affected_rows
    }
    update_trainee_program_coach(
      where: { program_id: { _eq: $programId }, _and: { trainee_id: { _eq: $traineeId } } }
      _set: { coach_id: $coachId }
    ) {
      affected_rows
    }
    update_macro(where: { program_id: { _eq: $programId }, _and: { trainee_id: { _eq: $traineeId } } }, _set: { coach_approver: $coachApprover }) {
      affected_rows
    }
  }
`

const sendReAssignationMessage = gql`
  mutation sendReAssignationMessage($message: message_insert_input!) {
    insert_message(objects: [$message]) {
      returning {
        id
        thread_id
      }
    }
  }
`

const { TabPane } = Tabs
const { Search } = Input
const { Option } = Select

const UserBootcampsCard = ({ bootcamps, handleBootcampBlock, refetch, coaches, visible, handleSaveChanges, levels, levelList }) => {
  const initialData = (key) => {
    let data = {
      level: !!bootcamps[key].enrollment && !!bootcamps[key].enrollment.level ? bootcamps[key].enrollment.level : null,
      sub_level: !!bootcamps[key].enrollment && !!bootcamps[key].enrollment.sub_level ? bootcamps[key].enrollment.sub_level : null,
      assigned_coach: !!bootcamps[key].coach_id ? bootcamps[key].coach_id : null,
      program_id: !!bootcamps[key].program_id ? bootcamps[key].program_id : null,
      trainee_id: !!bootcamps[key].trainee_id ? bootcamps[key].trainee_id : null,
      oldCoachId: !!bootcamps[key].coach_id ? bootcamps[key].coach_id : null,
      coachName:
        !!bootcamps[key].coach && !!bootcamps[key].coach.user
          ? `${bootcamps[key].coach.user.first_name} ${bootcamps[key].coach.user.last_name}`
          : null,
      coachUserId: !!bootcamps[0].coach ? bootcamps[0].coach.user_id : null,
      currentLevel: !!bootcamps[key].enrollment && !!bootcamps[key].enrollment.level ? bootcamps[key].enrollment.level : null,
      macroStatus: !!bootcamps[key].macro && !!bootcamps[key].macro.status ? bootcamps[key].macro.status : null,
      welcome_letter_sent: !!bootcamps[key].welcome_letter_sent ? bootcamps[key].welcome_letter_sent : null,
      thread_id: !!bootcamps[key].thread_id ? bootcamps[key].thread_id : null,
      coach_letters_delivery_date: !!bootcamps[key].program.coach_letters_delivery_date ? bootcamps[key].program.coach_letters_delivery_date : null,
      group_delivery_date: !!bootcamps[key].program.group_delivery_date ? bootcamps[key].program.group_delivery_date : null,
      exercises_delivery_date: !!bootcamps[key].program.exercises_delivery_date ? bootcamps[key].program.exercises_delivery_date : null,
    }
    return data
  }

  const [editable, setEditable] = useState(false)
  const [bootcampData, setBootcampData] = useState({
    level: !!bootcamps[0].enrollment && !!bootcamps[0].enrollment.level ? bootcamps[0].enrollment.level : null,
    sub_level: !!bootcamps[0].enrollment && !!bootcamps[0].enrollment.sub_level ? bootcamps[0].enrollment.sub_level : null,
    assigned_coach: !!bootcamps[0].coach_id ? bootcamps[0].coach_id : null,
    program_id: !!bootcamps[0].program_id ? bootcamps[0].program_id : null,
    trainee_id: !!bootcamps[0].trainee_id ? bootcamps[0].trainee_id : null,
    oldCoachId: !!bootcamps[0].coach_id ? bootcamps[0].coach_id : null,
    coachName:
      !!bootcamps[0].coach && !!bootcamps[0].coach.user ? `${bootcamps[0].coach.user.first_name} ${bootcamps[0].coach.user.last_name}` : null,
    coachUserId: !!bootcamps[0].coach ? bootcamps[0].coach.user_id : null,
    currentLevel: !!bootcamps[0].enrollment && !!bootcamps[0].enrollment.level ? bootcamps[0].enrollment.level : null,
    macroStatus: !!bootcamps[0].macro && !!bootcamps[0].macro.status ? bootcamps[0].macro.status : null,
    thread_id: !!bootcamps[0].thread_id ? bootcamps[0].thread_id : null,
    coach_letters_delivery_date: !!bootcamps[0].program.coach_letters_delivery_date ? bootcamps[0].program.coach_letters_delivery_date : null,
    group_delivery_date: !!bootcamps[0].program.group_delivery_date ? bootcamps[0].program.group_delivery_date : null,
    exercises_delivery_date: !!bootcamps[0].program.exercises_delivery_date ? bootcamps[0].program.exercises_delivery_date : null,
  })

  useEffect(() => {
    setEditable(false)
    setBootcampData(initialData(0))
  }, [visible])

  return (
    <Card title="User Bootcamps" className="user-bootcamps-card card-shadow">
      {bootcamps && bootcamps.length >= 1 ? (
        <Tabs defaultActiveKey="1" size="small" onChange={(key) => setBootcampData(initialData(parseInt(key) - 1))}>
          {bootcamps.map((bootcamp, index) => {
            let bootcampStage = 'Unassigned'
            if (bootcamp.enrollment_stage && bootcamp.enrollment_stage.length > 0) {
              if (bootcamp.enrollment_stage.every((s) => typeof s === 'string')) {
                bootcampStage = bootcamp.enrollment_stage[bootcamp.enrollment_stage.length - 1].split('-').join(' ')
              } else {
                const lastSection = bootcamp.enrollment_stage[bootcamp.enrollment_stage.length - 1]
                if (lastSection) {
                  if (lastSection.stages && lastSection.stages.length > 0) {
                    const lastStage = lastSection.stages[lastSection.stages.length - 1]
                    if (lastStage) bootcampStage = lastStage
                    else bootcampStage = 'Unknown'
                  } else {
                    bootcampStage = lastSection.id === 'bootcamp-assigned' ? 'Bootcamp Assigned' : 'Unknown'
                  }
                } else {
                  bootcampStage = 'Unknown'
                }
              }
            }
            if (bootcampStage === 'terms-and-conditions') bootcampStage = 'Finished'
            return (
              <TabPane tab={bootcamp.program.description} key={index + 1}>
                <div className="user-bootcamp-detail">
                  <div className="bootcamp-detail-upper-data">
                    <div className="enrollment-progress">
                      <p className="upper-text">Enrollment Progress</p>
                      <p className="user-data-email">{bootcampStage}</p>
                    </div>
                    <div className="meal-plan">
                      <p className="upper-text">Meal Plan</p>
                      <p>{bootcamp.extras && bootcamp.extras.meal_plan && bootcamp.extras.meal_plan !== 0 ? 'Yes' : 'None'}</p>
                    </div>
                    <div className="user-acces">
                      <p className="upper-text">User Access</p>
                      <span>
                        <Tag color={bootcamp.is_active ? 'green' : 'orange'}>{bootcamp.is_active ? 'Active' : 'Blocked Bootcamp'}</Tag>
                      </span>
                      {bootcamp.cancellation_reason && (
                        <>
                          <br />
                          <small>
                            Reason {bootcamp.cancellation_reason} at {moment.utc(bootcamp.cancellation_date).local().format('YYYY-MM-DD hh:mm:ss')}
                          </small>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="bootcamp-detail-lower-data">
                    <div className="user-level">
                      <p className="upper-text">Level</p>
                      {editable ? (
                        <Select
                          onChange={(value) =>
                            setBootcampData({
                              ...bootcampData,
                              level: value,
                            })
                          }
                          defaultValue={!!bootcampData && !!bootcampData.level ? `${bootcampData.level}` : '1'}
                        >
                          {levelList.map((el) => (
                            <Option key={el} value={el}>
                              {el}
                            </Option>
                          ))}
                        </Select>
                      ) : (
                        <p>{bootcampData.level ? bootcampData.level : 'Unassigned'}</p>
                      )}
                    </div>
                    <div className="user-sublevel">
                      <p className="upper-text">Sub-level</p>
                      {editable ? (
                        <Select
                          onChange={(value) =>
                            setBootcampData({
                              ...bootcampData,
                              sub_level: value,
                            })
                          }
                          defaultValue={!!bootcampData && !!bootcampData.sub_level ? bootcampData.sub_level : ''}
                        >
                          {bootcampData.level ? (
                            levels
                              .find((el) => el.level === parseInt(bootcampData.level))
                              .sublevels.map((el) => (
                                <Option key={el} value={el}>
                                  {el}
                                </Option>
                              ))
                          ) : (
                            <Option value="A">A</Option>
                          )}
                        </Select>
                      ) : (
                        <p>{!!bootcampData && !!bootcampData.sub_level ? `${bootcampData.sub_level}` : 'Unassigned'}</p>
                      )}
                    </div>
                    <div className="assigned-coach">
                      <p className="upper-text">Coach</p>
                      {editable ? (
                        <Select
                          onChange={(value) =>
                            setBootcampData({
                              ...bootcampData,
                              assigned_coach: value,
                              coachName:
                                coaches.find((coach) => coach.id === value).user.first_name +
                                ' ' +
                                coaches.find((coach) => coach.id === value).user.last_name,
                            })
                          }
                          defaultValue={!!bootcampData.coachName ? bootcampData.coachName : ''}
                        >
                          {coaches.map((coach, index) => {
                            let percentaje = coach.assigned_aggregate.aggregate.count / coach.max_students
                            let status = 'rgba(229, 61, 46, 0.5)'
                            switch (true) {
                              case percentaje <= 0.8:
                                status = 'rgba(38, 172, 0, 0.5)'
                                break
                              case percentaje >= 0.81 && percentaje <= 0.99:
                                status = 'rgba(247, 171, 47, 0.5)'
                                break
                              default:
                                status = 'rgba(229, 61, 46, 0.5)'
                                break
                            }
                            return (
                              <Option key={index} value={coach.id}>
                                <p>
                                  {coach.user.first_name} {coach.user.last_name}
                                </p>
                                <div className="count-container">
                                  <p>
                                    {coach.assigned_aggregate.aggregate.count} / {coach.max_students}
                                  </p>
                                  <div className="badge" style={{ backgroundColor: status }} />
                                </div>
                              </Option>
                            )
                          })}
                        </Select>
                      ) : (
                        <p>{bootcampData.coachName ? bootcampData.coachName : 'Unassigned'}</p>
                      )}
                    </div>
                  </div>
                  <div className="bootcamp-detail-footer">
                    {editable ? (
                      <>
                        <Button
                          className="cancel-edit-button"
                          onClick={() => {
                            setEditable(!editable)
                          }}
                        >
                          <span>Cancel</span>
                        </Button>
                        <Button
                          className="save-edit-button"
                          onClick={() => {
                            handleSaveChanges(bootcampData, refetch)
                            setEditable(!editable)
                          }}
                        >
                          <span>
                            <SaveOutlined />
                            Save Changes
                          </span>
                        </Button>
                      </>
                    ) : (
                      <>
                        <Button className="edit-button" onClick={() => setEditable(!editable)}>
                          <span>
                            <EditOutlined />
                            Edit Level
                          </span>
                        </Button>
                        <Popconfirm
                          className="block-confirmation"
                          title="Are you sure?"
                          onConfirm={() =>
                            handleBootcampBlock({
                              traineeId: bootcamp.trainee_id,
                              programId: bootcamp.program_id,
                              status: bootcamp.is_active,
                              index,
                              refetch,
                            })
                          }
                          okText="Change Status"
                          cancelText="Cancel"
                        >
                          {bootcamp.is_active ? (
                            <Button className="block-button">
                              <span>
                                <LockFilled />
                                Block from bootcamp
                              </span>
                            </Button>
                          ) : (
                            <Button className="unblock-button">
                              <span>
                                <UnlockFilled />
                                Unblock from bootcamp
                              </span>
                            </Button>
                          )}
                        </Popconfirm>
                      </>
                    )}
                  </div>
                </div>
              </TabPane>
            )
          })}
        </Tabs>
      ) : (
        <div>
          <h4>Seems like there's no bootcamps yet</h4>
        </div>
      )}
    </Card>
  )
}

const UserDetailCard = ({ user, picture, email, contactId, status, handleBlock, DBid, refetch }) => {
  const [loadingImpersonate, setLoadingImpersonate] = useState(false)
  const isSupportAgent = (localStorage.getItem('USER_ROLES') || '').indexOf('support') !== -1

  const handleImpersonate = () => {
    setLoadingImpersonate(true)

    var urlencoded = new URLSearchParams()
    urlencoded.append('email', email)

    fetch(`${process.env.REACT_APP_AUTH_SERVER}/wp-json/ketogains/impersonate`, {
      method: 'POST',
      headers: {
        Authorization: getCookie(COOKIE.USER_TOKEN),
      },
      body: urlencoded,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code) {
          throw new Error(`We could not impersonate you. ${data.message ? data.message : 'Error from server.'}`)
        } else {
          window.open(`${process.env.REACT_APP_STUDENT_PORTAL}/sso?jwt=${data.jwt}&admin=true`)
          setLoadingImpersonate(false)
        }
      })
      .catch((error) => {
        notification.error({ message: `${error}` })
        setLoadingImpersonate(false)
      })
  }

  return (
    <Card className="user-detail-card card-shadow">
      <div className="user-detail-body">
        <div className="user-picture">
          <Avatar src={picture} icon={<UserOutlined />} />{' '}
        </div>
        <div className="user-data">
          <p className="user-data-name">{user}</p>
          <p className="user-data-email">{email}</p>
        </div>
        <div className="user-contact-id">
          <p className="upper-text">Contact ID</p>
          <p>{contactId}</p>
        </div>
        <div className="user-status">
          <p className="upper-text">Status</p>
          <div>
            <span>
              <Tag color={status ? 'green' : 'red'}>{status ? 'Active' : 'Blocked User'}</Tag>
            </span>
          </div>
        </div>
      </div>
      <div className="user-detail-footer">
        {isSupportAgent && (
          <Button loading={loadingImpersonate} onClick={handleImpersonate}>
            Impersonate
          </Button>
        )}
        <Popconfirm
          className="block-confirmation"
          title="Are you sure?"
          onConfirm={() => handleBlock({ contactId, DBid, status, refetch })}
          okText="Change Status"
          cancelText="Cancel"
          disabled={true}
        >
          {status ? (
            //TODO IMPLEMENT BLOCK WITH WORDPRESS/MEMBERIUM
            <Button disabled={true} className="" style={{ background: 'transparent' }}>
              <span style={{ background: 'transparent', border: '0' }}>
                <LockFilled />
                Block User
              </span>
            </Button>
          ) : (
            <Button className="" style={{ background: 'transparent' }}>
              <span style={{ background: 'transparent', border: '0' }}>
                <UnlockFilled />
                Unblock User
              </span>
            </Button>
          )}
        </Popconfirm>
      </div>
    </Card>
  )
}

const UserDetailModal = ({
  visible,
  data,
  coaches,
  handleClose,
  handleBlock,
  handleBootcampBlock,
  refetch,
  handleSaveChanges,
  levels,
  levelList,
}) => {
  const [dataToShow, setDataToShow] = useState(data)

  useEffect(() => {
    setDataToShow(data)
  }, [visible, data])

  const { user, picture, email, contactId, status, bootcamps, id } = dataToShow

  return (
    <Modal
      title={
        <div>
          <UserOutlined />
          <span>User Detail</span>
        </div>
      }
      className="user-detail-modal"
      visible={visible}
      footer={null}
      onCancel={handleClose}
      width={700}
      destroyOnClose={true}
    >
      {dataToShow.user && (
        <>
          <UserDetailCard {...{ user, picture, email, contactId, status, handleBlock, DBid: id, refetch }} />
          <UserBootcampsCard {...{ bootcamps, handleBootcampBlock, refetch, coaches, visible, handleSaveChanges, levels, levelList }} />
        </>
      )}
    </Modal>
  )
}

class Clients extends Component {
  constructor(props) {
    super(props)
    this.state = {
      visible: false,
      modalData: {},
      dataSource: [],
      filtered: false,
      filterText: '',
    }
  }

  handleClose = () => {
    this.setState({
      visible: false,
    })
  }

  handleBlock = async ({ contactId, DBid, status, refetch }) => {
    let msg3 = 'Status updated'
    let descr2 = 'The status of the user has been updated correctly'
    let errorMsg = 'An error ocurred!'
    let errorDescr = 'Something happened! Please try later or contact to the support team'

    await apolloClient
      .mutate({
        mutation: UpdateBlock,
        variables: {
          id: DBid,
          blocked: !status,
        },
      })
      .then(async ({ data }) => {
        await notification.success({
          message: msg3,
          description: descr2,
        })
        await this.setState((prevState) => ({
          modalData: {
            ...prevState.modalData,
            status: data.update_user.returning[0].is_active,
          },
        }))
      })
      .catch((err) => {
        console.log(err)
        notification.error({
          message: errorMsg,
          description: errorDescr,
        })
      })
  }

  handleBootcampBlock = async ({ traineeId, programId, status, index, refetch }) => {
    let msg = 'Status updated'
    let descr = 'The status of the user has been updated correctly'
    let errorMsg = 'An error ocurred!'
    let errorDescr = 'Something happened! Please try later or contact to the support team'

    await apolloClient
      .mutate({
        mutation: UpdateBootcampBlock,
        variables: {
          traineeId,
          programId,
          blocked: !status,
          cancellationReason: status ? 'MANUAL' : 'REACTIVATED',
          cancellationDate: 'now()',
        },
      })
      .then(async ({ data }) => {
        refetch()

        await notification.success({
          message: msg,
          description: descr,
        })

        await this.setState((prevState) => ({
          modalData: {
            ...prevState.modalData,
            bootcamps: prevState.modalData.bootcamps.map((bootcamp, i) => {
              if (i === index) {
                return {
                  ...bootcamp,
                  is_active: data.update_trainee_program_coach.returning[0].is_active,
                  cancellation_reason: data.update_trainee_program_coach.returning[0].cancellation_reason,
                  cancellation_date: data.update_trainee_program_coach.returning[0].cancellation_date,
                }
              } else {
                return bootcamp
              }
            }),
          },
        }))
      })
      .catch((err) => {
        console.log(err)
        notification.error({
          message: errorMsg,
          description: errorDescr,
        })
      })
  }

  handleSaveChanges = async (bootcampData, refetch) => {
    let variables = {
      programId: bootcampData.program_id,
      traineeId: bootcampData.trainee_id,
      level: parseInt(bootcampData.level),
      subLevel: bootcampData.sub_level,
      coachId: bootcampData.assigned_coach,
    }
    let isValidCoachApprover = false

    if (!!bootcampData.macroStatus && bootcampData.macroStatus === 'PendingToApprove') {
      if (
        (!!bootcampData.currentLevel && bootcampData.currentLevel === 1 && bootcampData.level === '3') ||
        (!!bootcampData.currentLevel && bootcampData.currentLevel === 2 && bootcampData.level === '3')
      ) {
        variables = { ...variables, coachApprover: '90028abc-4af1-4b9f-bdf1-5d41383d8f15' }
        isValidCoachApprover = true
      } else if (
        (!!bootcampData.currentLevel && bootcampData.currentLevel === 3 && bootcampData.level === '2') ||
        (!!bootcampData.currentLevel && bootcampData.currentLevel === 3 && bootcampData.level === '1')
      ) {
        variables = { ...variables, coachApprover: 'a6dc0934-ce0d-4bc2-aaf0-8a24bfba2064' }
        isValidCoachApprover = true
      }
    }

    await apolloClient
      .mutate({
        mutation: isValidCoachApprover ? updateClientBootcampDataWithMacro : updateClientBootcampData,
        variables: variables,
      })
      .then(async ({ data }) => {
        apiServices('POST', `infusion-soft/update-coach-and-level`, { traineeId: bootcampData.trainee_id, programId: bootcampData.program_id })
          .then((response) => {
            return response.json()
          })
          .then((resp) => {
            if (resp.code !== 204) {
              notification.warning({
                message: "Sorry we couldn't sync your reassignment this time, we will try it again later.",
              })
            }
          })
          .catch((error) => {
            console.log(error)
          })
          .finally(() => {
            refetch()
            notification.success({
              message: 'Data updated',
              description: 'The bootcamp data was updated successfully',
            })
          })
      })
      .catch((err) => {
        notification.error({
          message: 'Something went wrong',
          description: 'There was an error trying to update the data,lease try again later. Reloading page',
        })
        setTimeout(() => {
          window.location.reload()
        }, 3000)
      })
  }

  render() {
    let { visible, modalData, filterText } = this.state
    const today = moment().format('YYYY-MM-DD')

    const columns = [
      {
        title: 'User',
        dataIndex: 'user',
        key: 'user',
        sorter: (a, b) => {
          if (a.user < b.user) {
            return -1
          }
          if (a.user > b.user) {
            return 1
          }
          return 0
        },
        render: (user, record) => {
          return (
            <span>
              <Avatar src={record.picture} icon={<UserOutlined />} /> <span>{user}</span>
            </span>
          )
        },
      },
      {
        title: 'E-mail',
        dataIndex: 'email',
        key: 'email',
        sorter: (a, b) => {
          if (a.email < b.email) {
            return -1
          }
          if (a.email > b.email) {
            return 1
          }
          return 0
        },
      },
      {
        title: 'Contact Id',
        dataIndex: 'contactId',
        key: 'contactId',
      },
      {
        title: 'Bootcamps',
        dataIndex: 'bootcamps',
        key: 'bootcamps',
        render: (bootcamps, record) => (
          <span>
            <ul className="bootcamps-list">
              {bootcamps.map((bootcamp, index) => {
                const { program } = bootcamp
                return <li key={index}>{program.description}</li>
              })}
            </ul>
          </span>
        ),
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (status, record) => (
          <span>
            <Tag color={status ? 'green' : 'red'}>{status ? 'Active' : 'Blocked User'}</Tag>
            {status && record.blockedBootcamps && record.blockedBootcamps > 0 ? (
              <div>
                <Tag color="orange">{record.blockedBootcamps} Blocked Bootcamp</Tag>
              </div>
            ) : (
              ''
            )}
          </span>
        ),
      },
    ]

    return (
      <Layout {...{ ...this.props }}>
        <div className="MainContainer">
          <p className="title">Clients</p>
          <hr />
          <Query query={getClients} fetchPolicy="network-only" variables={{ today }}>
            {({ loading, error, data, refetch }) => {
              if (error) {
                console.log(error)
                return null
              }

              if (loading) {
                return (
                  <Loader>
                    <div className="rectangle" />
                    <div className="rectangle" />
                    <div className="rectangle" />
                    <div className="rectangle" />
                  </Loader>
                )
              }

              let levels = data.configuration && data.configuration[0] && data.configuration[0].value ? JSON.parse(data.configuration[0].value) : []
              let levelList = []
              levels.forEach((el) => {
                levelList.push(`${el.level}`)
              })
              const programs = data.program || []
              const coaches = data.coach || []

              let dataSource = data.trainees.map((trainee, index) => {
                const { user, trainee_program_coach } = trainee
                let counter = trainee_program_coach.filter((b) => !b.is_active).length
                let obj = {
                  key: index,
                  id: user.id,
                  user: user.first_name + ' ' + user.last_name,
                  picture: user.picture,
                  email: user.email,
                  contactId: user.contact_id,
                  bootcamps: trainee_program_coach
                    .map((tpc) => {
                      return {
                        ...tpc,
                        program: programs.find((p) => p.program_id === tpc.program_id),
                        coach: coaches.find((c) => c.id === tpc.coach_id),
                      }
                    })
                    .sort(function (a, b) {
                      if (moment(a.program.start_date).isAfter(b.program.start_date)) {
                        return -1
                      }
                      if (moment(a.program.start_date).isBefore(b.program.start_date)) {
                        return 1
                      }
                      return 0
                    }),
                  blockedBootcamps: counter,
                  status: user.is_active,
                  data: trainee,
                }
                return obj
              })

              return (
                <div className="container">
                  <div className="filters">
                    <Search
                      placeholder="First name, last name, e-mail, or contact ID"
                      allowClear={true}
                      onChange={(e) => this.setState({ filterText: e.target.value })}
                    />
                  </div>
                  <Table
                    className="clients-table"
                    size="middle"
                    columns={columns}
                    scroll={{ x: true }}
                    dataSource={dataSource.filter(
                      (bootcamp) =>
                        bootcamp.user.toLowerCase().includes(filterText.toLowerCase()) ||
                        bootcamp.email.toLowerCase().includes(filterText.toLowerCase()) ||
                        bootcamp.contactId.includes(filterText),
                    )}
                    onRow={(record) => {
                      return {
                        onClick: (e) => {
                          this.setState({
                            visible: true,
                            modalData: record,
                          })
                        },
                      }
                    }}
                  />
                  <UserDetailModal
                    visible={visible}
                    data={modalData}
                    coaches={coaches}
                    handleClose={this.handleClose}
                    handleBlock={this.handleBlock}
                    handleBootcampBlock={this.handleBootcampBlock}
                    refetch={refetch}
                    handleSaveChanges={this.handleSaveChanges}
                    levels={levels}
                    levelList={levelList}
                  />
                </div>
              )
            }}
          </Query>
        </div>
      </Layout>
    )
  }
}

export default Clients
