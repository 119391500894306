import React, { useState } from 'react'
import { navigate } from '@reach/router'
import { Form, message } from 'antd'
import { loginCredentials } from 'auth'
import Logo from 'components/Logo'
import backgroundImage from 'images/sign-in-bg.png'
import { StyledButton, StyledInput } from 'styled'
import { AppVersion } from 'constants.js'
import { LoginContainer, H1, Main, Header, Content, Footer, StyledLink, Version } from './Login.styled'

const FormItem = Form.Item

const Login = () => {
  const [disableButton, setDisableButton] = useState(false)
  const loginWithCredentials = (credentials) => {
    setDisableButton(true)
    return new Promise((resolve, reject) => {
      loginCredentials(credentials)
        .then(() => {
          resolve()
          navigate('/')
        })
        .catch((error) => {
          console.warn(error)
          message.error(error)
          resolve()
          setDisableButton(false)
        })
    })
  }

  return (
    <LoginContainer bgUrl={backgroundImage}>
      <Content>
        <Header>
          <Logo />
        </Header>
        <Main>
          <H1>Sign In</H1>
          <Form hideRequiredMark={true} onFinish={loginWithCredentials}>
            <FormItem
              label="Email"
              labelCol={{ span: 24 }}
              name="username"
              rules={[
                {
                  type: 'email',
                  message: 'The input is not valid email!',
                },
                { required: true, message: 'Please input your email!' },
              ]}
            >
              <StyledInput type="email" />
            </FormItem>
            <FormItem label="Password" labelCol={{ span: 24 }} name="password" rules={[{ required: true, message: 'Please input your password!' }]}>
              <StyledInput type="password" />
            </FormItem>
            <FormItem>
              <StyledButton disabled={disableButton} loading={disableButton} load htmlType="submit">
                Sign In
              </StyledButton>
            </FormItem>
            <Version>{AppVersion}</Version>
          </Form>
        </Main>
      </Content>
      <Footer>
        <StyledLink href={`${process.env.REACT_APP_AUTH_SERVER}/wp-login.php?action=lostpassword`} target="_blank">
          Forgot your password?
        </StyledLink>
      </Footer>
    </LoginContainer>
  )
}

export default Login
