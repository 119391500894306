import styled from '@emotion/styled'
import { StyledModal } from 'styled'

export const ImportRoutineModal = styled(StyledModal)`
  width: calc(100vw - 100px) !important;
  top: 72px !important;

  .ant-modal-content {
    .ant-modal-body {
      padding: 0;

      .card {
        .ant-empty,
        .preview {
          height: calc(100% - 40px);
        }

        .step-action {
          text-align: right;

          .ant-btn:first-of-type {
            margin-right: 5px;
          }
        }
      }
    }

    .ant-modal-footer {
      display: none;
    }
  }

  @media (max-width: 991.9px) {
    width: calc(100vw - 20px) !important;
  }
`
