import styled from '@emotion/styled'

export const Root = styled.div`
  height: 400px;
  padding-right: 20px;
  overflow-y: auto;
  width: 380px;
  margin: 0 auto;
  border: 1px solid #d9d9d9;
`

export const MessageList = styled.ul`
  margin: 0;
  padding: 10px;
  list-style: none;
  font-size: 11px;
  color: #5d5d5d;
  width: 350px;
`

export const Message = styled.li`
  width: max-content;
  max-width: 80%;
  margin-left: auto;
  margin-bottom: 15px;
  padding: 10px;
  text-align: left;
  background-color: #f3f3f3;
  border-radius: 7px;

  &.trainee {
    margin-left: 0;
    margin-right: auto;

    .information {
      span {
        text-align: left;
      }
    }
  }

  &.coach {
    margin-left: auto;

    .information {
      display: grid;
      grid-template-columns: 30px auto;

      .delete {
        cursor: pointer;
        visibility: hidden;

        &.ant-popover-open {
          visibility: visible;
        }
      }
    }

    &:hover,
    &:active,
    &:focus,
    &:visited {
      .delete {
        visibility: visible;
      }
    }
  }

  &.unread {
    border: thin solid #ff6b00;
  }

  strong {
    font-weight: 600;
  }
`

export const Answer = styled.div`
  padding: 0;
  margin-bottom: 3px;

  p,
  li {
    overflow-wrap: break-word;
    color: #5d5d5d;
    margin-bottom: 4px;
  }

  img {
    width: 100%;
  }

  .attachments {
    .attachment-item {
      &.file {
        display: flex;
        flex-direction: row;
        align-items: center;

        .preview {
          min-width: 36px;
          max-width: 36px;
          height: 36px;
          background: #ffffff;
          border: 1px solid #979797;
          border-radius: 4px;
          flex-grow: 1;
          padding: 8px;
          text-align: center;

          img {
            width: 13px;
            height: 13px;
          }
        }

        .detail {
          margin-left: 10px;
          flex-grow: 2;

          p {
            margin-bottom: 0px;
            overflow: hidden;
            max-width: 140px;
            white-space: nowrap;
            text-overflow: ellipsis;

            a,
            a:hover,
            a:visited {
              font-weight: 600;
              color: #5d5d5d;
            }
          }
        }
      }

      &.image {
        img {
          border: 1px solid #979797;
          border-radius: 4px;
          margin-bottom: 10px;
        }

        p {
          margin-bottom: 0px;
          overflow: hidden;
          max-width: 140px;
          white-space: nowrap;
          text-overflow: ellipsis;

          a,
          a:hover,
          a:visited {
            font-weight: 600;
            color: #5d5d5d;
          }
        }
      }
    }
  }

  iframe {
    width: 100%;
  }

  .ql-align-center {
    text-align: center;
  }

  .ql-align-right {
    text-align: left;
  }
`

export const Date = styled.span`
  display: block;
  padding: 0;
  text-align: left;
  color: #959595;
  font-size: 10px;
  font-weight: 300;
  text-align: left;
`

export const Loader = styled.div`
  .lds-dual-ring {
    display: inline-block;
    width: 64px;
    height: 64px;
  }
  .lds-dual-ring:after {
    content: ' ';
    display: block;
    width: 46px;
    height: 46px;
    margin: 1px;
    border-radius: 50%;
    border: 5px solid #ff6b00;
    border-color: #ff6b00 transparent #ff6b00 transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }
  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

export const ReadBlock = styled.div`
  display: block;
  height: 1px;
  width: 100%;
`
