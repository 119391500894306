import React, { Component } from 'react'
import { Widget } from '@uploadcare/react-widget'
import uploadcare from 'uploadcare-widget'
import uploadcareTabEffects from 'uploadcare-widget-tab-effects'

import { UPLOADS } from '../../../constants'

class Uploader extends Component {
  componentDidMount() {
    if (this.props.effects) uploadcare.registerTab('preview', uploadcareTabEffects)
  }

  onChange = (fileInfo) => {
    const { onUploadComplete } = this.props
    if (typeof onUploadComplete === 'function') {
      onUploadComplete(fileInfo)
    }
  }

  render() {
    return (
      <Widget
        publicKey={UPLOADS.UPLOADCARE_PUBLIC_KEY}
        tabs={UPLOADS.UPLOADCARE_TABS}
        imageShrink={UPLOADS.UPLOADCARE_SHRINK}
        imagesOnly={UPLOADS.UPLOADCARE_IMAGES_ONLY}
        previewStep={this.props.effects ? UPLOADS.UPLOADCARE_PREVIEW : false}
        effects={['rotate', 'crop']}
        locale={UPLOADS.UPLOADCARE_LOCALE}
        localeTranslations={UPLOADS.UPLOADCARE_LOCALE_TRANSLATIONS}
        onChange={this.onChange}
      />
    )
  }
}

export default Uploader
