import styled from '@emotion/styled'

export const Container = styled.div`
  padding: 5px 22px;
  .sub-title {
    color: #5d5d5d;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
  }
  .row {
    margin-bottom: 26px;
  }
`
