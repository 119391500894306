import { useState, useMemo } from 'react'
const useSearchExercises = (exercises, parentExercise) => {
  const [query, setQuery] = useState({ sort: 'asc' })
  const [filteredExercises, setFilteredExercises] = useState(exercises)

  useMemo(() => {
    let result = exercises.filter((exercise) => {
      if (parentExercise !== null && exercise.id === parentExercise.id) {
        return false
      }
      let isMatch = true
      let isMatchOption = true

      if (query.options) {
        const { filterMuscles, filterEquipments } = query.options
        if (filterMuscles.length > 0 || filterEquipments.length > 0) {
          let isMatchMuscle = false
          let isMatchEquipment = false

          filterMuscles.length > 0 && (isMatchMuscle = exercise.muscles.some((em) => filterMuscles.includes(em)))
          filterEquipments.length > 0 && (isMatchEquipment = exercise.gym_equipments.some((ge) => filterEquipments.includes(ge.gym_equipment_id)))
          isMatchOption = !!isMatchMuscle || !!isMatchEquipment
        }
      }
      if (query.text) isMatch = exercise.name.toLowerCase().includes(query.text.toLowerCase())

      return isMatch && isMatchOption
    })

    result = result.sort((a, b) => {
      var exerciseA = (query.sort === 'asc' ? a : b).name.trim().toUpperCase()
      var exerciseB = (query.sort === 'asc' ? b : a).name.trim().toUpperCase()
      if (exerciseA < exerciseB) {
        return -1
      }
      if (exerciseA > exerciseB) {
        return 1
      }

      return 0
    })

    setFilteredExercises(result)
  }, [exercises, query])

  return { query, setQuery, filteredExercises }
}
export default useSearchExercises
