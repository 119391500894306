import styled from '@emotion/styled'

export const PreviewExerciseContainer = styled.div`
  .thumbnail-container {
    img {
      width: 200px;
      margin: 10px auto;
      display: block;
    }
  }
  .video-container {
    iframe {
      width: 300px;
      margin: 10px auto;
      display: block;
    }
  }
`
