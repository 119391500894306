import React, { useEffect, useState } from 'react'
import { Button, notification } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { WeeksContainer } from '../../Uses/ExerciseWeeks/ExerciseWeeks.styled'
import { apiServices } from 'services/api'
import ExerciseWeeks from '../../Uses/ExerciseWeeks/ExerciseWeeks'
import CloseModal from '../CloseModal'
import { isValidConfiguration } from '../../Uses/GlobalFunctions'

export default ({ onNext, onBack, showClose, onClose, setShowClose }) => {
  const routineId = localStorage.getItem('ROUTINE_ID')
  const step = 'configuration'

  const [loading, setLoading] = useState(true)
  const [routine, setRoutine] = useState()
  const [canGoNext, setCanGoNext] = useState(false)
  const [isBtnLoading, setIsBtnLoading] = useState()
  const [isBtnBackLoading, setIsBtnBackLoading] = useState(false)
  const [showCloseModal, setShowCloseModal] = useState(showClose)

  useEffect(() => {
    apiServices('GET', `routine?&id=${routineId}&step=${step}&withExercises=true&withWeeks=true&withUniqueExercises=true&withSets=true`)
      .then((resp) => resp.json())
      .then((data) => setRoutine(data))
      .catch(console.log)
      .finally(() => setLoading(false))
  }, [])

  useEffect(() => {
    validate()
  }, [routine])

  useEffect(() => setShowCloseModal(showClose), [showClose])

  const validate = () => {
    if (routine) {
      let isValid = isValidConfiguration(routine)
      if (isValid) {
        setCanGoNext(true)
      } else {
        setCanGoNext(false)
      }
    }
  }

  const save = (skipValidation) => {
    return apiServices('POST', `routine?step=routine-configuration${skipValidation ? '&skip-validation=true' : ''}`, {
      id: routineId,
      routine_weeks: routine.routine_weeks,
      routine_exercises: routine.routine_exercises.map((e) => {
        return {
          routine_id: routineId,
          exercise_id: e.exercise_id,
          is_alternative: e.is_alternative,
          parent: e.parent,
          user_notes: e.user_notes,
          order: e.order,
        }
      }),
    }).then((result) => result.json())
  }

  const handleClickNext = () => {
    setIsBtnLoading(true)
    save()
      .then((data) => {
        if (data === 204) {
          onNext()
        }
      })
      .catch((err) => {
        notification.error({
          message: `Error while trying to save the routine`,
          description: `Error from DB ${err.message}`,
        })
      })
      .finally(() => {
        setIsBtnLoading(false)
      })
  }

  const handleClickBack = () => {
    setIsBtnBackLoading(true)
    save(true)
      .catch((err) => {
        notification.error({
          message: `Error while trying to save the routine`,
          description: `Error from DB ${err.message}`,
        })
      })
      .finally(() => {
        onBack()
        setIsBtnBackLoading(false)
      })
  }

  const handleClose = async (withSave) => {
    return new Promise((resolve, reject) => {
      if (withSave) {
        save(true)
          .then((data) => {
            if (data === 204) {
              notification.success({ message: 'Saved correctly!' })
              onClose()
              resolve()
            } else {
              throw new Error('Error saving exercises!')
            }
          })
          .catch((err) => {
            notification.error({
              message: `Error while trying to save the routine`,
              description: `Error from DB ${err.message}`,
            })
            resolve()
          })
      } else if (typeof onClose === 'function') {
        onClose()
        resolve()
      }
    })
  }

  return (
    <WeeksContainer>
      <p className="section-title">Weekly Sets and Reps</p>
      {loading && (
        <div className="loader">
          <LoadingOutlined />
        </div>
      )}
      {!loading && (
        <>
          <ExerciseWeeks routine={routine} editMode={true} setRoutine={setRoutine} step={step} heightlistcard={90} heightconfig={0} />
          <CloseModal visible={showCloseModal} onCancel={setShowClose} handleClose={handleClose} />
        </>
      )}

      <div className="step-action">
        <Button className="btn-cancel" onClick={handleClickBack} disabled={isBtnBackLoading || isBtnLoading} loading={isBtnBackLoading}>
          Back
        </Button>
        <Button type="primary" onClick={handleClickNext} disabled={!canGoNext || isBtnBackLoading} loading={isBtnLoading}>
          Next
        </Button>
      </div>
    </WeeksContainer>
  )
}
