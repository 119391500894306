import styled from '@emotion/styled'
import { Card } from 'antd'

export const Root = styled.div`
  width: 90%;
  min-height: calc(100vh - 90px);
  background: #ffffff;
  margin: 10px auto;
  padding: 27px 8px 8px;
  border-radius: 3px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.5);

  h1 {
    color: #5d5d5d;
    margin: 0;
    font-size: 20px;
    font-weight: 600;
    padding-left: 10px;
  }

  hr {
    border: none;
    border-top: 1px solid #f6f6f6;
  }

  main {
    .list {
      min-height: 350px;
      max-height: 500px;
      overflow-y: auto;
      background-color: #f4f4f4;
      padding: 1px 5px 10px 0px;
      margin-bottom: 7px;
    }

    .ant-btn {
      border-radius: 12.5px;
      margin-bottom: 7px;
      margin-left: 5px;

      &.create-new {
        width: 90%;
        display: block;
        margin: 0 auto;
        background-color: #ff6b00;
        color: #ffffff;
      }

      &:hover,
      &:active,
      &:focus,
      &:visited {
        border-color: transparent;
      }
    }

    .ant-card {
      box-shadow: 1px 1px 7px 0 rgba(0, 0, 0, 0.35);
    }
  }

  .ant-form-item {
    margin: 0;

    .ant-form-item-control {
      .ant-input {
        width: 95%;
        height: auto;
        color: #6d6d6d;
        border-radius: 3px;
        border: 1px solid #b9b9b9;
        padding: 2px 4px;
        font-size: 12px;

        &.bootcamp-name {
          height: 26.4px;
        }
      }

      .ant-form-explain {
        font-size: 10px;
        margin-top: 1px;
      }

      &.has-error {
        .ant-input {
          border-color: #f5222d;
        }
      }
    }
  }
`

export const BootcampCard = styled.div`
  padding: 4px;
  background-color: #ffffff;
  opacity: 0.7;
  border-radius: 2px;
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.15);
  cursor: pointer;
  margin: 0px 1px;
  margin-bottom: 4px;

  .content {
    border-left: 3px solid transparent;
    padding: 10px 4px 4px 13px;

    &.current {
      border-left-color: #ff6b00;
    }

    &.upcoming {
      border-left-color: #2ba918;
    }

    &.stand-by {
      border-left-color: #4bc4bc;
    }

    h2 {
      margin: 0;
      font-size: 16px;
      font-weight: 600;
      color: #5d5d5d;
    }

    p {
      margin: 0;
      font-size: 10px;
      font-weight: 500;
      color: #959595;
    }

    .tags {
      .ant-tag {
        letter-spacing: 1px;
        font-size: 7px;
        height: auto;
        line-height: 14px;
      }
    }
  }

  &.active {
    box-shadow: 4px 6px 11px 4px rgba(0, 0, 0, 0.12);
    opacity: 1;
    margin-bottom: 10px;
  }
`

export const BootcampDetailsCard = styled(Card)`
  h3 {
    color: #5d5d5d;
    font-size: 14px;
    font-weight: 600;
  }

  p.title {
    color: #959595;
    font-size: 11px;
    font-weight: 500;
    margin-bottom: 2px;
  }

  hr {
    border: none;
    border-top: 1px solid #f2f2f2;
    margin-top: 13px;
  }

  .ant-card-body {
    padding: 8px 24px;
  }

  .ant-card-head-title {
    color: #5d5d5d;
    font-size: 18px;
    font-weight: 600;
    padding: 0px 10px;
    margin: 16px 0px;
    border-left: 3px solid transparent;
  }

  &.current {
    .ant-card-head-title {
      border-left-color: #ff6b00;
    }
  }

  &.upcoming {
    .ant-card-head-title {
      border-left-color: #2ba918;
    }
  }

  &.stand-by {
    .ant-card-head-title {
      border-left-color: #4bc4bc;
    }
  }

  .dates {
    p {
      margin: 0;
      color: #6d6d6d;
    }

    .anticon {
      margin-left: 3px;
      color: #ff6b00;
    }
  }

  .content-shadow {
    width: 98%;
    margin: 0 auto;
    margin-bottom: 10px;
    border: 0.5px solid #e6e6e6;
    border-radius: 2px;
    box-shadow: 1px 1px 7px 0 rgba(0, 0, 0, 0.35);

    h3.section-title {
      margin: 15px;
      padding: 10px 0px;
      border-bottom: 1px solid #f2f2f2;
    }

    p:not(.title) {
      font-weight: 600;
      color: #5d5d5d;
      font-size: 12px;
    }

    .ant-tabs-tabpane {
      padding: 0px 10px 22px;

      .ant-divider-with-text-left {
        margin-bottom: 4px;
        margin-top: 8px;

        .ant-divider-inner-text {
          color: #5d5d5d;
          font-size: 10px;
          font-weight: 600;
        }
      }

      .url-detail {
        color: #5d5d5d;
        font-size: 11px;

        p {
          white-space: nowrap;
          width: 98%;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    &.enrollment-report {
      padding: 10px;

      p {
        margin-bottom: 2px;

        &.stats {
          font-size: 10px;
        }
      }
    }
  }

  .actions {
    text-align: right;
  }
`

export const BannerContainer = styled.div`
  border: 1px solid #e7e7e7;
  padding: 14px 16px;
  width: 95%;

  .banner {
    max-width: 460px;
    margin: 0 auto;
    padding: 10px;
    background-size: cover;
    background-repeat: no-repeat;
    text-align: right;
    margin-bottom: 5px;
    border-radius: 4px;
    line-height: 20px;

    hr {
      border-color: rgba(255, 255, 255, 0.26);
      border-style: solid;
    }

    h2 {
      color: #fff;
      font-family: Montserrat;
      font-size: 20px;
      font-weight: bold;
      background-size: cover;
    }

    h3 {
      color: #fff;
      font-family: Montserrat;
      font-size: 16px;
      font-weight: bold;
    }

    .actions {
      text-align: right;
      .ant-btn {
        background: #ff4613;
        color: white;
        font-weight: bold;
        border: none;

        &:hover {
          border: none;
        }

        ${({ status }) =>
          !!status && (status === 'delayed' || status === 'overdue' ? `background: white; color: #DE4B4B;` : `background: white; color: #70ca85;`)}
      }
    }

    ${({ background }) =>
      background &&
      `
      background-image: url(${background});
      background-size: auto;
      background-position: center center;
    `}

    ${({ backgroundSize }) =>
      backgroundSize &&
      `
      background-size: ${backgroundSize};
    `}

  ${({ textAlign }) =>
      textAlign &&
      `
      text-align: ${textAlign};
    `}
  }
`

export const SublevelCard = styled.div`
  box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.25);

  .cover {
    height: 49px;
    background-color: #dedede;
    background-size: cover;
    background-position: center;
  }

  .content {
    padding: 10px 8px;

    .name-icon {
      border-bottom: 1px solid #f2f2f2;
      padding-bottom: 8px;
      margin-bottom: 8px;

      img {
        padding: 2px;
        width: 38px;
        height: 38px;
        border-radius: 50%;
        background-color: #ffffff;
        box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.25);
        vertical-align: middle;
      }

      h3 {
        display: inline-block;
        border-left: 2px solid #ff6b00;
        margin-left: 10px;
        margin-bottom: 0px;
        padding: 0px 10px;
        color: #383838;
        font-size: 16px;
        font-weight: 600;
        vertical-align: middle;
      }
    }

    p {
      font-weight: normal !important;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      &.description {
        white-space: normal;
        display: block;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
    }

    .title {
      color: #5d5d5d;
      font-size: 12px;
      font-weight: bold !important;
      margin-bottom: 12px;
    }

    .label {
      color: #959595 !important;
      font-size: 12px !important;
      font-weight: 500 !important;
      margin-bottom: 2px;
    }
  }
`
export const TimelineContainer = styled.div`
  border: 1px solid #e7e7e7;
  padding: 14px 16px;
  width: 95%;
  margin: 10px auto;
  background-color: #fbfbfb;
  border-radius: 4px;

  p,
  h2 {
    text-align: center;
    color: #000000;
    margin-bottom: 3px;
    line-height: 1.3;
  }

  h2 {
    font-size: 20px;
    font-weight: bold;
  }

  p {
    font-size: 13px;
    font-weight: 600;
    line-height: 17px;
    padding: 0px 70px;
  }

  hr {
    border: none;
    border-top: 2px solid #ececec;
    margin: 16px 0px 10px;
  }

  .banner {
    img {
      width: 100%;
    }
  }

  .content {
    padding: 20px 16px;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.18);
  }

  .ant-steps {
    .ant-steps-item-tail {
      &:after {
        width: 2px !important;
        background-color: #61a0eb;
      }
    }

    .ant-steps-icon {
      img {
        width: 20px;
        height: 20px;
      }
    }

    .ant-steps-item-title {
      h2 {
        font-weight: bold;
        font-size: 15px;
        color: #000000;
        text-align: left;
        margin: 0;
        line-height: 25px;
      }

      h4 {
        font-size: 11px;
        color: #7d9fc8;
        font-weight: bold;
        margin: 0;
        line-height: 16px;
      }
    }

    .ant-steps-item-content {
      margin-right: 30px;
    }

    .ant-steps-item-description {
      color: #000000 !important;
      font-size: 13px;
      line-height: 17px;
      font-weight: 500;
      margin-bottom: 90px;
    }

    .colored {
      .ant-steps-item-tail {
        &:after {
          background-color: #ff7e22;
        }
      }
      .ant-steps-item-title {
        h4 {
          color: #ff6b00;
        }
      }
    }
  }
`
