import styled from '@emotion/styled'
import { Table } from 'antd'

const ExerciseTable = styled(Table)`
  .thumbnails {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 10px;
    img {
      width: 100%;
    }
  }
`
export default ExerciseTable
