import React from 'react'
import { ExerciseCard as StyledExerciseCard } from './ExerciseCard.styled'
import { ReactComponent as EquipmentIcon } from 'assets/icons/icon-equipment.svg'
import { ReactComponent as MuscleIcon } from 'assets/icons/icon-muscle.svg'

function ExerciseCard({ exercise, onClick, className }) {
  const { name, thumbnail, muscles, gym_equipment, gif_url } = exercise
  return (
    <>
      <StyledExerciseCard selected={className} onClick={onClick}>
        <div className="exercise-card">
          <img src={gif_url ? gif_url : thumbnail} alt="" className="exercise-image" />
          <div className="exercise-info">
            <p className="title" title={name}>
              {name}
            </p>
            {muscles && (
              <div className="muscles">
                <MuscleIcon />
                <p title={muscles}>{muscles}</p>
              </div>
            )}
            {gym_equipment && (
              <div className="equipments">
                <EquipmentIcon />
                <p title={gym_equipment}>{gym_equipment}</p>
              </div>
            )}
          </div>
        </div>
      </StyledExerciseCard>
    </>
  )
}

export default ExerciseCard
