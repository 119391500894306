import React, { useEffect, useState, useContext } from 'react'
import { Divider, Row, Col, Select, Input, Button, notification } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { PreviewRoutineContainer } from './PreviewRoutine.styled'
import { WorkoutStore } from '../Uses/WorkoutStore'
import ExerciseWeeks from '../Uses/ExerciseWeeks/ExerciseWeeks'
import { apiServices } from 'services/api'
import EditIcon from 'assets/icons/edit.png'
import { isValidConfiguration } from '../Uses/GlobalFunctions'
import CustomSwitch from '../Uses/CustomSwitch'
const { Option } = Select

export default ({ id, bootcampId, editable, editRoutine, onSave }) => {
  const { workouts, routine_types, routine_categories } = useContext(WorkoutStore)
  const [loading, setLoading] = useState(true)
  const [edit, setEdit] = useState(false)
  const [routine, setRoutine] = useState()
  const [canGoNext, setCanGoNext] = useState(false)
  const [programType, setProgramType] = useState()
  const [programName, setProgramName] = useState()
  const [programEdition, setProgramEdition] = useState()
  const [isOptionalProgram, setIsOptionalProgram] = useState()
  const [isBtnLoading, setIsBtnLoading] = useState(false)

  const workoutSelected =
    workouts.find((w) => {
      return w.program_id === bootcampId && w.routines.find((r) => r.id === id)
    }) || {}
  const routineSelected = workoutSelected.routines.find((r) => r.id === id)
  const routineType = routine_types.find((rc) => rc.key === routineSelected.type) || { label: '-' }
  const routineCategory = routine_categories.find((rc) => rc.key === routineSelected.category)
  const step = 'configuration'

  useEffect(() => {
    setEdit(false)
    setLoading(true)
    apiServices('GET', `routine?&id=${id}&step=${step}&withExercises=true&withWeeks=true&withUniqueExercises=true&withSets=true`)
      .then((resp) => resp.json())
      .then((data) => {
        const { category, type, is_optional, name } = data
        setRoutine(data)
        setProgramType(type)
        setProgramName(name)
        setProgramEdition(category)
        setIsOptionalProgram(is_optional)
      })
      .catch((error) => console.log(error))
      .finally(() => setLoading(false))
  }, [id])

  useEffect(() => {
    validate()
  }, [routine, programName, programType, programEdition, isOptionalProgram])

  const validate = () => {
    if (routine) {
      let isValid = isValidConfiguration(routine)
      setCanGoNext(isValid)
    }
  }

  const handleSave = () => {
    setIsBtnLoading(true)
    const routineObj = {
      ...routine,
      name: programName || routine.name,
      type: programType || routine.type,
      category: programEdition || routine.category,
      is_optional: isOptionalProgram !== null ? isOptionalProgram : routine.is_optional,
    }
    apiServices('POST', `routine?id=${routine.id}&step=routine-summary`, { ...routineObj })
      .then((result) => result.json())
      .then((data) => {
        if (data === 204) {
          notification.success({
            className: 'success',
            message: 'The routine was updated successfully!',
          })
          setEdit(false)
          onSave()
        }
      })
      .catch((err) => {
        notification.error({
          message: `Error while trying to save the routine`,
          description: `Error from DB ${err.message}`,
        })
      })
      .finally(() => {
        setIsBtnLoading(false)
      })
  }

  const handleEdit = () => {
    if (routineSelected.status === 'Approved') {
      setEdit(true)
    } else {
      editRoutine(routineSelected.id, routineSelected.status)
    }
  }

  const handleCancel = () => {
    setProgramType(null)
    setProgramName(null)
    setProgramEdition(null)
    setIsOptionalProgram(null)
    setEdit(false)
  }

  return (
    <PreviewRoutineContainer edit={edit}>
      <div className="header">
        <div className="title">
          <Divider type="vertical" />
          {routineSelected.name}
        </div>
        {editable && !edit && !loading && (
          <div className="edit-link" onClick={handleEdit}>
            <img src={EditIcon} alt="" /> Edit program
          </div>
        )}
      </div>
      <hr />
      <div className="routine-details">
        <p>
          <b>Program Details </b>
        </p>
        {!edit && (
          <Row gutter={[16, 16]}>
            <Col xs={24} md={8}>
              <label>
                Program type <span>{routineType.label}</span>
              </label>
            </Col>
            <Col xs={24} md={8}>
              <label>
                Program name <span>{routineSelected.name}</span>
              </label>
            </Col>
            <Col xs={24} md={4}>
              <label>
                Program Edition <span>{routineCategory.label}</span>
              </label>
            </Col>
            <Col xs={24} md={4}>
              <label>
                Optional day <span>{routineSelected.is_optional ? 'Yes' : 'No'}</span>
              </label>
            </Col>
          </Row>
        )}
        {edit && (
          <Row gutter={[16, 16]}>
            <Col xs={24} md={12}>
              <label>Program type</label>
              <Select defaultValue={programType || routine.type} placeholder="Select an option" onChange={setProgramType}>
                {routine_types.map((type) => {
                  return <Option key={type.key}>{type.label}</Option>
                })}
              </Select>
            </Col>
            <Col xs={24} md={12}>
              <label>Program name</label>
              <Input
                type="text"
                defaultValue={programName || routine.name}
                placeholder="Type a name to your program"
                maxLength="30"
                onChange={(e) => setProgramName(e.target.value)}
              />
            </Col>
            <Col xs={24} md={12}>
              <label>Is Gym Edition?</label>
              <CustomSwitch checked={programEdition === 'GYM'} onChange={() => setProgramEdition(programEdition === 'GYM' ? 'HOME' : 'GYM')} />
            </Col>
            <Col xs={24} md={12}>
              <label>Optional day</label>
              <CustomSwitch checked={isOptionalProgram} onChange={() => setIsOptionalProgram(!isOptionalProgram)} />
            </Col>
          </Row>
        )}
      </div>
      <hr />
      <div className={`routine-weeks ${edit ? 'edit' : ''}`}>
        <p className="title">Program Overview</p>
        {loading && (
          <div className="loader">
            <LoadingOutlined />
          </div>
        )}
        {!loading && routine && (
          <ExerciseWeeks routine={routine} editMode={edit} setRoutine={setRoutine} heightlistcard={edit ? 100 : 0} heightconfig={edit ? 0 : 24} />
        )}
      </div>
      {edit && (
        <div className="step-action">
          <Button className="btn-cancel" disabled={isBtnLoading} onClick={() => handleCancel()}>
            Cancel
          </Button>
          <Button type="primary" onClick={handleSave} loading={isBtnLoading} disabled={!canGoNext}>
            Save
          </Button>
        </div>
      )}
    </PreviewRoutineContainer>
  )
}
