import gql from 'graphql-tag'

export const updateWelcomeLetter = gql`
  mutation updateWelcomeLetter($IDcoach: uuid!, $welcomeLetter: String!) {
    update_coach(where: { id: { _eq: $IDcoach } }, _set: { welcome_letter: $welcomeLetter }) {
      returning {
        id
        welcome_letter
        user_id
      }
    }
  }
`
