import gql from 'graphql-tag'

export const getTotalAmount = gql`
  query($forDate: String!, $programId: uuid!) {
    total_amount: trainee_aggregate(where: { selected: { program_id: { _eq: $programId } } }) {
      aggregate {
        count
      }
    }

    level_1: trainee_aggregate(where: { level: { _eq: 1 }, _and: [{ selected: { program_id: { _eq: $programId } } }] }) {
      aggregate {
        count
      }
      nodes {
        id
        selected_aggregate {
          aggregate {
            count
          }
        }
      }
    }

    level_2: trainee_aggregate(where: { level: { _eq: 2 }, _and: [{ selected: { program_id: { _eq: $programId } } }] }) {
      aggregate {
        count
      }
      nodes {
        id
        selected_aggregate {
          aggregate {
            count
          }
        }
      }
    }

    level_3: trainee_aggregate(where: { level: { _eq: 3 }, _and: [{ selected: { program_id: { _eq: $programId } } }] }) {
      aggregate {
        count
      }
      nodes {
        id
        selected_aggregate {
          aggregate {
            count
          }
        }
      }
    }

    unassigned: trainee_aggregate(where: { _and: { selected: { program_id: { _eq: $programId }, coach_id: { _is_null: true } } } }) {
      aggregate {
        count
      }
    }

    sent_review: trainee_aggregate(where: { selected: { program_id: { _eq: $programId } }, _and: { measurement: { for_date: { _eq: $forDate } } } }) {
      aggregate {
        count
      }
    }
  }
`
