import React from 'react'
import { Redirect } from '@reach/router'
import { Query } from 'react-apollo'
import gql from 'graphql-tag'
import AppLoading from 'components/AppLoading'

const getData = gql`
  query {
    User @client {
      id
    }
    App @client {
      loading
    }
  }
`

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Query query={getData} fetchPolicy="cache-only">
      {({ data }) => {
        const {
          User: { id },
          App: { loading },
        } = data
        if (!id) {
          return <Redirect to="/login" noThrow />
        }
        if (loading) {
          return <AppLoading type="loading" />
        }
        return <Component {...rest} />
      }}
    </Query>
  )
}

export default PrivateRoute
