import React, { useState, useEffect } from 'react'
import { Row, Col, Form, DatePicker, Tooltip, Tabs } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import moment from 'moment'

const FormItem = Form.Item
const { TabPane } = Tabs

const AutomatizationProcess = ({ bootcamp, edit, form }) => {
  const [bootcampToShow, setBootcampToShow] = useState(bootcamp)
  useEffect(() => {
    setBootcampToShow(bootcamp)
    form.setFieldsValue({
      coach_letters_delivery_date: bootcamp.coach_letters_delivery_date ? moment(bootcamp.coach_letters_delivery_date) : null,
      group_delivery_date: bootcamp.group_delivery_date ? moment(bootcamp.group_delivery_date) : null,
      exercises_delivery_date: bootcamp.exercises_delivery_date ? moment(bootcamp.exercises_delivery_date) : null,
    })
  }, [bootcamp, edit])

  return (
    <div className="content-shadow">
      <Tabs>
        <TabPane>
          <Row className="dates">
            <Col md={8} xs={12}>
              <p className="title">
                Coach Letters Delivery Date
                <Tooltip
                  title={<span style={{ fontSize: '10px' }}>The date the coaches' letters will be mailed. The date is in US Central Time </span>}
                >
                  <InfoCircleOutlined />
                </Tooltip>
              </p>
              {!edit && <p>{moment(bootcampToShow.coach_letters_delivery_date).format('DD/MMM/YYYY HH:mm:ss')}</p>}
              {edit && (
                <FormItem
                  name="coach_letters_delivery_date"
                  rules={[
                    {
                      validator: (rule, value, callback) => {
                        if (value === null) return callback()
                        const start = bootcamp.start_date
                        const access = bootcamp.access_date
                        if (!!start && !!start[0] && !!access && !!access[0]) {
                          if (!moment(value).isBetween(access, start)) callback(false)
                        }
                        callback()
                      },
                      message: 'Please choose a date between the access date and start date.',
                    },
                  ]}
                >
                  <DatePicker showTime showToday={false} showNow={false} />
                </FormItem>
              )}
            </Col>
            <Col md={8} xs={12}>
              <p className="title">
                Group Delivery Date
                <Tooltip title={<span style={{ fontSize: '10px' }}>The date the group link is displayed. The date is in US Central Time </span>}>
                  <InfoCircleOutlined />
                </Tooltip>
              </p>
              {!edit && <p>{moment(bootcampToShow.group_delivery_date).format('DD/MMM/YYYY HH:mm:ss')}</p>}
              {edit && (
                <FormItem
                  name="group_delivery_date"
                  rules={[
                    {
                      validator: (rule, value, callback) => {
                        if (value === null) return callback()
                        const start = bootcamp.start_date
                        const access = bootcamp.access_date
                        if (!!start && !!start[0] && !!access && !!access[0]) {
                          if (!moment(value).isBetween(access, start)) callback(false)
                        }
                        callback()
                      },
                      message: 'Please choose a date between the access date and start date.',
                    },
                  ]}
                >
                  <DatePicker showTime showToday={false} showNow={false} />
                </FormItem>
              )}
            </Col>
            <Col md={8} xs={12}>
              <p className="title">
                Exercise's Delivery Date
                <Tooltip title={<span style={{ fontSize: '10px' }}>The date the exercise links are displayed. The date is in US Central Time </span>}>
                  <InfoCircleOutlined />
                </Tooltip>
              </p>
              {!edit && <p>{moment(bootcampToShow.exercises_delivery_date).format('DD/MMM/YYYY HH:mm:ss')}</p>}
              {edit && (
                <FormItem
                  name="exercises_delivery_date"
                  rules={[
                    {
                      validator: (rule, value, callback) => {
                        if (value === null) return callback()
                        const start = bootcamp.start_date
                        const access = bootcamp.access_date
                        if (!!start && !!start[0] && !!access && !!access[0]) {
                          if (!moment(value).isBetween(access, start)) callback(false)
                        }
                        callback()
                      },
                      message: 'Please choose a date between the access date and start date.',
                    },
                  ]}
                >
                  <DatePicker showTime showToday={false} showNow={false} />
                </FormItem>
              )}
            </Col>
          </Row>
        </TabPane>
      </Tabs>
    </div>
  )
}

export default AutomatizationProcess
