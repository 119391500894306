import React, { useState } from 'react'
import { Steps, Divider } from 'antd'
import Step1 from './Step1/'
import Step2 from './Step2/'
import Step3 from './Step3/'
import Step4 from './Step4/'
import { Container, StyledSteps } from './styled'
import { ReactComponent as Close } from 'assets/icons/close.svg'

const { Step } = Steps

export default (props) => {
  const { bootcampId, workout, category, onCancel, stepToShow = 0, level, onFinish } = props
  const [current, setCurrent] = useState(stepToShow)
  const [showCloseModal, setShowCloseModal] = useState(false)

  const next = () => {
    setCurrent(current + 1)
  }

  const prev = () => {
    setCurrent(current - 1)
  }

  const steps = [
    {
      id: 1,
      title: 'Set Up',
      content: (
        <Step1 {...{ bootcampId, workout, category, showClose: showCloseModal, onClose: onCancel, setShowClose: setShowCloseModal }} onNext={next} />
      ),
    },
    {
      id: 2,
      title: 'Exercises Selection',
      content: <Step2 {...{ showClose: showCloseModal, onClose: onCancel, setShowClose: setShowCloseModal, level }} onNext={next} onBack={prev} />,
    },
    {
      id: 3,
      title: 'Program Configuration',
      content: <Step3 {...{ showClose: showCloseModal, onClose: onCancel, setShowClose: setShowCloseModal }} onNext={next} onBack={prev} />,
    },
    {
      id: 4,
      title: 'Summary',
      content: <Step4 {...{ showClose: showCloseModal, onClose: onCancel, setShowClose: setShowCloseModal }} onBack={prev} onFinish={onFinish} />,
    },
  ]

  return (
    <Container>
      <div className="header-stepper">
        <div className="title">
          <Divider type="vertical" />
          {steps[current].title}
        </div>
        <StyledSteps current={current}>
          {steps.map((step) => {
            return <Step key={step.id} />
          })}
        </StyledSteps>
        <div className="close">
          <Close onClick={() => setShowCloseModal(true)} />
        </div>
      </div>
      <hr />
      <div className="steps-content">{steps[current].content}</div>
    </Container>
  )
}
