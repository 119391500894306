import styled from '@emotion/styled'
import { Modal } from 'antd'

export const ExerciseFormContainer = styled.div`
  .ant-popover-title {
    display: none;
  }
  .required {
    color: rgb(255, 107, 0);
  }
  & > div {
    margin: 10px;
    & > label {
      cursor: pointer;
      display: block;
    }
    .quill > .ql-container {
      max-height: 200px;
      overflow: auto;
    }
    .image-container {
      & img {
        margin: auto;
        display: block;
        max-width: 300px;
      }
      & button {
        margin: 10px auto;
        display: block;
      }
    }
  }
`
export const AntModalExercisesSelector = styled(Modal)`
  .ant-modal-footer {
    position: sticky;
    bottom: 0px;
    right: 0px;
    background-color: white;
  }
`
