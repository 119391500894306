import React, { Component } from 'react'
import { Query } from 'react-apollo'
import { Avatar } from 'antd'
import { getData } from 'queries/bootcamp'
import { getDateReview } from 'services'
import { getCoachesData } from './query'
import { StyledTitle } from 'styled'
import { Root, CoachesData, CoachDataDetails, CoachDataDetail, CoachList, CoachCard, Value, CoachProfile, CoachName } from './CoachesInfo.styled'

class CoachesInfo extends Component {
  render() {
    return (
      <Query query={getData}>
        {({ error, data }) => {
          if (error) {
            console.error(error)
            return null
          }
          const {
            Bootcamp: { id: programId },
          } = data
          const { actualWeekStart: forDate } = getDateReview()

          return (
            <Query query={getCoachesData} fetchPolicy="cache-and-network" variables={{ forDate, programId }}>
              {({ loading, error, data }) => {
                if (error || loading) return null
                const coaches = data.coach || []

                return (
                  <Root>
                    <CoachesData>
                      <StyledTitle>Coaches data</StyledTitle>
                      <CoachDataDetails>
                        <CoachDataDetail>
                          Weeklies
                          <br />
                          submitted
                        </CoachDataDetail>
                        <CoachDataDetail>
                          Weeklies waiting
                          <br />
                          response
                        </CoachDataDetail>
                        <CoachDataDetail>
                          Requested
                          <br />
                          assistance
                        </CoachDataDetail>
                      </CoachDataDetails>
                      <CoachList>
                        {coaches.map((item) => {
                          const nickName = `${item.user.first_name} ${item.user.last_name}`
                          const picture = item.user.picture
                          const assigned = item.assigned || []
                          const weekliesReports = assigned.reduce(
                            (acc, item) => {
                              const ready_wait = acc.ready_wait + item.trainee.ready_wait.aggregate.count
                              const completed = acc.completed + item.trainee.completed.aggregate.count
                              const assistance = acc.assistance + item.trainee.assistance.aggregate.count
                              return { ...acc, ready_wait, completed, assistance }
                            },
                            { ready_wait: 0, completed: 0, assistance: 0 },
                          )

                          return (
                            <CoachCard key={item.id}>
                              <CoachProfile>
                                <Avatar size={45} src={picture} />
                                <CoachName>{nickName}</CoachName>
                              </CoachProfile>
                              <Value>{weekliesReports.completed}</Value>
                              <Value>{weekliesReports.ready_wait}</Value>
                              <Value>{weekliesReports.assistance}</Value>
                            </CoachCard>
                          )
                        })}
                      </CoachList>
                    </CoachesData>
                  </Root>
                )
              }}
            </Query>
          )
        }}
      </Query>
    )
  }
}

export default CoachesInfo
