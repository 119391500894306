import styled from '@emotion/styled'

export const Root = styled.div`
  margin: 0 auto;
  max-width: 1024px;
  width: 100%;
`
export const CoachesData = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 780px;
  margin: 0 auto;
  padding: 20px 0;
`
export const CoachDataDetails = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  color: #404040;
  font-size: 16px;
  font-family: 'Montserrat', sans-serif;
  line-height: 19px;
  text-align: center;

  div:first-of-type {
    width: 200px;
  }
`
export const CoachDataDetail = styled.div`
  width: 150px;
  &:first-of-type {
    margin-left: auto;
  }
`
export const CoachList = styled.ul`
  margin: 0;
  margin-bottom: 40px;
  padding: 0;
`
export const CoachCard = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  padding: 20px;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  border-radius: 4px;
  background-color: #f9f9f9;
  box-shadow: 0 1px 4px 0 rgba(146, 146, 146, 0.5);
`
export const Value = styled.div`
  width: 150px;
  text-align: center;
`
export const CoachProfile = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-grow: 1;
`
export const CoachName = styled.div`
  margin-left: 18px;
  font-weight: 600;
`
