import React, { useState, useContext } from 'react'
import { Popover, Empty } from 'antd'
import ContextMenu from 'assets/icons/context-menu.png'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import styled from '@emotion/styled'
import ExerciseCard from '../ExerciseCard/ExerciseCard'
import { ReactComponent as IconTrash } from 'assets/icons/icon-trash.svg'
import { ReactComponent as IconPlus } from 'assets/icons/icon-plus.svg'
import { ReactComponent as IconRepeat } from 'assets/icons/icon-repeat.svg'
import ExerciseEmpty from 'assets/exercises/empty.png'
import { WorkoutStore } from '../../Uses/WorkoutStore'
import { getGymEquipments, getMuscles } from '../../Uses/GlobalFunctions'

const ExerciseContainer = styled.div`
  display: grid;
  grid-template-columns: auto 20px;
  grid-gap: 10px;
  align-items: center;
  margin-bottom: 8px;

  .context-menu {
    text-align: center;

    img {
      cursor: pointer;
    }
  }
`

const EmptyExercises = () => {
  return <Empty image={ExerciseEmpty} description="You haven't added any exercises here yet" />
}

export default function ExerciseList({
  week,
  editMode,
  handleSelectExercise,
  routineExercises,
  selectedExercise,
  showModalDelete,
  showModalTip,
  getNote,
  weekNumber,
  showModalAltExc,
  handleSetConfiguration,
}) {
  const { exercises, muscles, gym_equipments } = useContext(WorkoutStore)
  const [visible, setVisible] = useState()

  const setExercisesDetails = (id) => {
    let details = exercises.find((el) => el.id === id)
    let muscle = getMuscles({ exerciseMuscles: details.muscles, arrayMuscles: muscles })
    let equipment = getGymEquipments({ exerciseEquipments: details.gym_equipments, gymEquipments: gym_equipments })

    let exercise = {
      id: id,
      name: details.name,
      gif_url: details.gif_url,
      thumbnail: details.thumbnails.length > 0 ? details.thumbnails[0].url : '',
      muscles: muscle,
      gym_equipment: equipment,
    }
    return exercise
  }

  const handleClickPopover = (e, option, id) => {
    e.preventDefault()
    setVisible(false)
    switch (option) {
      case 'DELETE':
        showModalDelete({ status: true, id })
        break
      case 'ADD TIP':
        showModalTip({ status: true, id })
        getNote(week, id, weekNumber)
        break
      case 'ALTERNATIVE':
        showModalAltExc(id)
        break
      default:
        console.warn(`Option not supported: ${option}`)
        break
    }
  }

  const PopoverActions = ({ id }) => {
    return (
      <>
        <p onClick={(e) => handleClickPopover(e, 'DELETE', id)}>
          <IconTrash />
          <span>Delete</span>
        </p>
        <p onClick={(e) => handleClickPopover(e, 'ADD TIP', id)}>
          <IconPlus />
          <span>Add Tip</span>
        </p>
        <p onClick={(e) => handleClickPopover(e, 'ALTERNATIVE', id)}>
          <IconRepeat />
          <span>Alternative</span>
        </p>
      </>
    )
  }

  const handleReorderedExercises = (items, week) => {
    let reorderedExercises = []
    const { week_exercises } = week

    items.forEach((item, key) => {
      const order = key + 1
      const altExercise = week_exercises.find((we) => we.parent === item.exercise_id)
      reorderedExercises = [...reorderedExercises, { ...item, order }]
      if (altExercise) {
        reorderedExercises = [...reorderedExercises, { ...altExercise, order }]
      }
    })

    return reorderedExercises
  }

  function handleOnDragEnd(result) {
    if (!result.destination) return
    const items = Array.from(routineExercises.filter((e) => !e.is_alternative))
    const [reorderItem] = items.splice(result.source.index, 1)
    items.splice(result.destination.index, 0, reorderItem)
    week.week_exercises = handleReorderedExercises(items, week)
    handleSetConfiguration(week)
  }

  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <Droppable droppableId="exercises">
        {(provided) => (
          <div className="container-overflow show-scrollbars" tabIndex="0" {...provided.droppableProps} ref={provided.innerRef}>
            {routineExercises
              .filter((e) => !e.is_alternative)
              .map((exercise, index) => (
                <Draggable key={exercise.exercise_id} draggableId={exercise.exercise_id} index={index} isDragDisabled={!editMode}>
                  {(provided) => (
                    <ExerciseContainer {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                      <ExerciseCard
                        className={`${!!selectedExercise ? (exercise.exercise_id === selectedExercise ? 'selected' : '') : null}`}
                        exercise={setExercisesDetails(exercise.exercise_id)}
                        onClick={() => handleSelectExercise(exercise.exercise_id)}
                      />
                      {editMode && (
                        <div className="context-menu">
                          <Popover
                            placement="bottomRight"
                            content={<PopoverActions id={exercise.exercise_id} />}
                            visible={visible === exercise.id}
                            onVisibleChange={(isVisible) => setVisible(isVisible ? exercise.id : null)}
                            trigger="click"
                            overlayClassName="context-menu-exercises"
                          >
                            <img src={ContextMenu} alt="..." />
                          </Popover>
                        </div>
                      )}
                    </ExerciseContainer>
                  )}
                </Draggable>
              ))}
            {routineExercises.length === 0 && <EmptyExercises />}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}
