import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react'
import { ContainerTextEditor } from './TextEditor.styled'
import ReactQuill, { Quill } from 'react-quill'
import quillEmoji from 'quill-emoji'
import { DeleteFilled, FileFilled } from '@ant-design/icons'
import { Widget } from '@uploadcare/react-widget'
import 'react-quill/dist/quill.snow.css'
import 'quill-emoji/dist/quill-emoji.css'
import { UPLOADS } from '../../../../constants.js'
import { getAttachmentsHTML } from '../uses'

Quill.register(
  {
    'formats/emoji': quillEmoji.EmojiBlot,
    'modules/emoji-toolbar': quillEmoji.ToolbarEmoji,
  },
  true,
)

const TextEditor = ({ send, programId, onChange = () => {} }, ref) => {
  const [loading, setLoading] = useState(true)
  const [editorHtml, setEditorHtml] = useState('')
  const [attachments, setAttachments] = useState([])
  const quillRef = useRef(null)

  useEffect(() => {
    if (quillRef) {
      if (quillRef.current) {
        if (quillRef.current.editingArea) {
          let editorChild = quillRef.current.editingArea.getElementsByClassName('ql-editor')
          if (editorChild && editorChild.length > 0) {
            editorChild[0].removeAttribute('data-gramm')
          }
        }
      }
    }

    const message = localStorage.getItem(`MESSAGE`)
    const attachments = localStorage.getItem(`ATTACHMENTS`)

    setEditorHtml(!!message ? message : '')
    setAttachments(!!attachments ? JSON.parse(attachments) : [])

    setLoading(false)
  }, [])

  useImperativeHandle(ref, () => ({
    handleSendMessage() {
      if ((!!!editorHtml || editorHtml.replace(/<(.|\n)*?>/g, '').trim().length === 0) && attachments.length === 0) {
        alert('Write something first!')
      } else {
        let message = editorHtml
        message += getAttachmentsHTML(attachments)
        send(message, programId, () => {
          if (quillRef) {
            if (quillRef.current) {
              if (quillRef.current.editingArea) {
                let editorChild = quillRef.current.editingArea.getElementsByClassName('ql-editor')
                if (editorChild && editorChild.length > 0) {
                  console.log('editorChild[0] :>> ', editorChild[0])
                  editorChild[0].innerText = ''
                }
              }
            }
          }
          localStorage.removeItem(`ATTACHMENTS`)
          localStorage.removeItem(`MESSAGE`)
          setEditorHtml('')
          setAttachments([])
        })
      }
    },
  }))

  const handleChange = (html) => {
    let htmlBody = html
    htmlBody += getAttachmentsHTML(attachments)
    setEditorHtml(html)
    onChange(htmlBody)
    localStorage.setItem(`MESSAGE`, html)
    if (quillRef) {
      if (quillRef.current) {
        if (quillRef.current.editingArea) {
          let editorChild = quillRef.current.editingArea.getElementsByClassName('ql-editor')
          if (editorChild && editorChild.length > 0) {
            editorChild[0].removeAttribute('data-gramm')
          }
        }
      }
    }
  }

  const handleFileSelected = (file) => {
    if (file) {
      file.done((fileInfo) => {
        const attachments = [fileInfo]
        localStorage.setItem(`ATTACHMENTS`, JSON.stringify(attachments))
        let htmlBody = editorHtml
        htmlBody += getAttachmentsHTML(attachments)
        onChange(htmlBody)
        setAttachments(attachments)
      })
    }
  }

  const fileSizeReadable = (size) => {
    const i = Math.floor(Math.log(size) / Math.log(1024))
    return (size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i]
  }

  const deleteAttachment = (uuid) => {
    const newAttachments = attachments.filter((a) => a.uuid !== uuid)
    localStorage.setItem(`ATTACHMENTS`, JSON.stringify(newAttachments))
    let htmlBody = editorHtml
    htmlBody += getAttachmentsHTML(newAttachments)
    onChange(htmlBody)
    setAttachments(newAttachments)
  }

  return (
    <ContainerTextEditor>
      {!loading && (
        <>
          <div className="wrap-editor">
            <ReactQuill
              placeholder="Write a message..."
              onChange={handleChange}
              modules={TextEditor.modules}
              formats={TextEditor.formats}
              defaultValue={editorHtml}
              ref={quillRef}
              dangerouslySetInnerHTML={{ __html: editorHtml }}
            />
            {attachments && attachments.length > 0 && (
              <div className="attachments">
                {attachments.map((item) => {
                  const sizeReadable = fileSizeReadable(item.size)
                  return (
                    <div className="attachment-item" key={item.uuid}>
                      <div className={`preview ${item.isImage ? 'image' : 'icon'}`}>
                        {item.isImage && <img src={item.cdnUrl} alt="" />}
                        {!item.isImage && <FileFilled />}
                      </div>
                      <div className="detail">
                        <p className="name">{item.name}</p>
                        <p className="size">{sizeReadable}</p>
                      </div>
                      <div className="actions">
                        <DeleteFilled onClick={() => deleteAttachment(item.uuid)} />
                      </div>
                    </div>
                  )
                })}
              </div>
            )}
          </div>

          <div id="quill-toolbar" className="buttons-container">
            <button className="ql-bold" />
            <button className="ql-italic" />
            <button className="ql-underline" />
            <button className="ql-link" />
            <button className="ql-list" value="bullet" />
            <Widget
              publicKey={UPLOADS.UPLOADCARE_PUBLIC_KEY}
              id="file"
              name="file"
              tabs={UPLOADS.UPLOADCARE_TABS}
              previewStep="true"
              onFileSelect={handleFileSelected}
            />
            <button className="ql-emoji" />
          </div>
        </>
      )}
    </ContainerTextEditor>
  )
}

TextEditor.modules = {
  toolbar: {
    container: '#quill-toolbar',
  },
  'emoji-toolbar': true,
}

TextEditor.formats = ['header', 'bold', 'italic', 'underline', 'list', 'link', 'align', 'emoji', 'color', 'video']

export default forwardRef(TextEditor)
