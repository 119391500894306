// Please set .env files to modify.
// For local testing it is required to set .env.development.local with 'http://localhost:3000'
// DO NOT COMMIT .local file to repository

export const AppVersion = `v${process.env.REACT_APP_VERSION}-${process.env.REACT_APP_BRANCH}`
export const DB = process.env.REACT_APP_DB

export const LOCAL_STORAGE = {
  USER_ID: 'USER_ID',
  CONTACT_ID: 'CONTACT_ID',
  COACH_ID: 'COACH_ID',
  USER_NICKNAME: 'USER_NICKNAME',
  USER_PICTURE: 'USER_PICTURE',
  BOOTCAMP_ID: 'BOOTCAMP_ID',
  BOOTCAMP_START_DATE: 'BOOTCAMP_START_DATE',
  BOOTCAMP_END_DATE: 'BOOTCAMP_END_DATE',
  BOOTCAMP_DESCRIPTION: 'BOOTCAMP_DESCRIPTION',
  USER_ROLES: 'USER_ROLES',
}

export const COOKIE = {
  USER_TOKEN_EXPIRE_TIME: 'USER_TOKEN_EXPIRE_TIME',
  USER_TOKEN: 'USER_TOKEN',
  API_TOKEN: 'API_TOKEN',
}

// Mask style for all Modal Frame
export const MASK_STYLE = { backgroundColor: 'rgba(0, 0, 0, 0.7' }

export const UPLOADS = {
  UPLOADCARE_PUBLIC_KEY: '131f89f26e091050eb22',
  UPLOADCARE_TABS: 'file camera',
  UPLOADCARE_SHRINK: '1024x1024 80%',
  UPLOADCARE_IMAGES_ONLY: true,
  UPLOADCARE_PREVIEW: true,
  UPLOADCARE_CROP: 'free',
  UPLOADCARE_LOCALE: 'en',
  UPLOADCARE_LOCALE_TRANSLATIONS: {
    buttons: {
      cancel: 'Remove',
      remove: 'Remove',
      choose: {
        files: {
          one: 'Upload your CV',
          other: 'Upload your documents',
        },
        images: {
          one: 'Upload',
          other: 'Upload',
        },
      },
    },
  },
}
