import styled from '@emotion/styled'

export default styled.h2`
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  text-transform: uppercase;
  color: #ff6b00;

  span {
    font-weight: 600;
  }
`
