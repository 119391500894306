const fileSizeReadable = (size) => {
  const i = Math.floor(Math.log(size) / Math.log(1024))
  return (size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i]
}

export const getAttachmentsHTML = (attachments) => {
  if (attachments.length === 0) return ''
  let htmlBody = ''
  htmlBody += '<div class="attachments">'
  attachments.forEach((attach) => {
    const sizeReadable = fileSizeReadable(attach.size)
    if (attach.isImage) {
      htmlBody += '<div class="attachment-item image">'
      htmlBody += `<img width="250" src="${attach.cdnUrl}" />`
      htmlBody += `<p><a href="${attach.cdnUrl}" target="_blank">${attach.name}</a></p>`
      htmlBody += `<p>${sizeReadable}</p>`
      htmlBody += '</div>'
    } else {
      htmlBody += '<div class="attachment-item file">'
      htmlBody += '<div class="preview">'
      htmlBody += `<img src="/icons/icon-file.png" alt="" />`
      htmlBody += '</div>'
      htmlBody += '<div class="detail">'
      htmlBody += `<p><a href="${attach.cdnUrl}" target="_blank">${attach.name}</a></p>`
      htmlBody += `<p>${sizeReadable}</p>`
      htmlBody += '</div>'
      htmlBody += '</div>'
    }
  })
  htmlBody += '</div>'
  return htmlBody
}
