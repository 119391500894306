import React, { useState } from 'react'
import { Button, Select } from 'antd'

import styled from '@emotion/styled'

const WeekImportContainer = styled.div`
  width: 100%;

  .import-selector {
    width: 100%;
    .week-selector {
      margin-top: 11px;
      width: 70%;
      border-right: 0;

      :disabled {
        background-color: #c4c4c4;
        cursor: not-allowed;
      }
    }
  }

  .ant-btn.ant-btn-primary {
    border-radius: 0px 25px 25px 0px;
    border-left: 0;
    height: 32px;
    width: 30%;

    :disabled {
      background-color: #c4c4c4;
      cursor: not-allowed;
      border-color: #c4c4c4;
    }
  }
`

const { Option } = Select

export const ExerciseConfigImport = ({ weekActual, routine, handleSetConfiguration, reloadSelectedExercise }) => {
  const [weekToImport, setWeekToImport] = useState(null)
  const selectWeekToImport = (value) => {
    setWeekToImport(parseInt(value.split('-')[1]))
  }
  const importWeek = (event) => {
    event.preventDefault()
    if (!weekToImport || weekActual === weekToImport) return
    const routineToCopy = { ...routine.routine_weeks[weekToImport - 1] }
    const actualRoutine = { ...routine.routine_weeks[weekActual - 1] }
    const newExercises = []
    routineToCopy.week_exercises.forEach((we) => {
      const week_sets = []
      const filterExercise = actualRoutine.week_exercises.filter(
        (e) => e.exercise_id === we.exercise_id && e.is_alternative === we.is_alternative && e.parent === we.parent,
      )
      if (filterExercise.length > 0) {
        we.week_sets.forEach((s) => {
          let set = { ...s }
          if (set.id) delete set.id
          week_sets.push({ ...set, routine_week_exercise_id: filterExercise[0].id })
        })
        newExercises.push({ ...we, routine_week_id: actualRoutine.id, week_sets: week_sets, id: filterExercise[0].id })
      }
    })
    newExercises.forEach((element) => {
      handleSetConfiguration(element)
    })
    reloadSelectedExercise()
  }
  return (
    // TODO: Logic for states: Disabled, Enabled, Undo Import.
    <WeekImportContainer>
      <div className="section-header">
        <p className="section-title">Select a week to import configuration from</p>
      </div>
      <div className="import-selector">
        <Select className="week-selector" onChange={selectWeekToImport}>
          {[1, 2, 3, 4, 5, 6, 7, 8].map((we) => {
            return (
              <Option key={`selector-${we}`} value={`week-${we}`} disabled={weekActual === we}>
                Week {we}
              </Option>
            )
          })}
        </Select>
        <Button type="primary" onClick={importWeek}>
          Import
        </Button>
      </div>
    </WeekImportContainer>
  )
}
