import React, { useState, useEffect, useContext } from 'react'
import { notification } from 'antd'
import { apiServices } from 'services/api'
import { WorkoutStore } from '../../Uses/WorkoutStore'
import CloseModal from '../CloseModal'
import ExerciseSelector from '../../Uses/ExerciseSelector'
import { ImportRoutineModal } from '../../ImportRoutine/ImportRoutine.styled'
import { formatExercise } from '../../../ExercisesCatalog/Uses'

export default ({ onNext, onBack, showClose, onClose, setShowClose, level }) => {
  const [exercisesSelectedList, setExercisesSelectedList] = useState([])
  const [alternativesSelectedList, setAlternativesSelectedList] = useState([])
  const [preloadExercises, setPreloadExercises] = useState([])
  const [loadingScreen, setLoadingScreen] = useState(true)
  const [canGoNext, setCanGoNext] = useState(false)
  const [isBtnLoading, setIsBtnLoading] = useState(false)
  const [isBtnBackLoading, setIsBtnBackLoading] = useState(false)
  const [showCloseModal, setShowCloseModal] = useState(showClose)
  const [selectedExercise, setSelectedExercise] = useState()
  const [selectedExerciseAlternatives, setSelectedExerciseAlternatives] = useState([])

  const { exercises, muscles, gym_equipments } = useContext(WorkoutStore)
  const step = 'routine-exercises'
  const routineId = localStorage.getItem('ROUTINE_ID')
  const maxExercises = 15

  useEffect(() => {
    const fetchData = () => {
      apiServices('GET', `routine?step=${step}&id=${routineId}&withUniqueExercises=${true}`)
        .then((resp) => resp.json())
        .then((data) => {
          if (data.routine_exercises.length > 0) {
            setPreloadExercises(data.routine_exercises.map((exercise) => exercise.exercise_id))
            const simulate = { target: { checked: true } }
            data.routine_exercises
              .filter((e) => !e.is_alternative)
              .forEach((exercise) => {
                handleSelectExercise(simulate, exercise.exercise_id)
              })
            const alternatives = data.routine_exercises
              .filter((e) => e.is_alternative)
              .map((e) => {
                return {
                  id: e.exercise_id,
                  is_alternative: true,
                  parent: e.parent,
                }
              })
            setAlternativesSelectedList(alternatives)
          }
        })
        .catch(console.warn)
        .finally(() => setLoadingScreen(false))
    }
    fetchData()
  }, [])

  useEffect(() => {
    setCanGoNext(exercisesSelectedList.length > 0 && exercisesSelectedList.length <= maxExercises)
  }, [exercisesSelectedList])

  useEffect(() => {
    if (selectedExercise) {
      const alternatives = alternativesSelectedList
        .filter((a) => a.parent === selectedExercise.id)
        .map((a) => {
          return formatExercise({ id: a.id, exercises, muscles, gym_equipments, is_alternative: true, parent: a.parent })
        })
      setSelectedExerciseAlternatives(alternatives)
    } else setSelectedExerciseAlternatives([])
  }, [selectedExercise])

  useEffect(() => setShowCloseModal(showClose), [showClose])

  const handleSelectExercise = (e, id) => {
    if (e.target.checked) {
      const exercise = formatExercise({ id, exercises, muscles, gym_equipments, is_alternative: false, parent: null })
      let details = exercises.find((el) => el.id === id)
      let alternatives = []
      if (details.alternatives && details.alternatives.length > 0) {
        alternatives = details.alternatives
          .filter((e) => e.level === level)
          .map((data) => {
            return {
              id: data.exercise_alternative_id,
              is_alternative: true,
              parent: id,
            }
          })
        setAlternativesSelectedList((prev) => [...prev, ...alternatives])
      }
      setExercisesSelectedList((prev) => [...prev, exercise])
    } else {
      setAlternativesSelectedList(alternativesSelectedList.filter((e) => e.parent !== id))
      setExercisesSelectedList(exercisesSelectedList.filter((e) => e.id !== id))
    }
  }

  const save = async (skipValidation) => {
    const parents = []
    const getExercisesWithFormat = (exercisesArr) => {
      const exercises = []
      for (let index = 0; index < exercisesArr.length; index++) {
        const exercise = exercisesArr[index]
        let order = index + 1
        if (exercise.is_alternative) {
          const parentFindIdx = parents.findIndex((p) => p.exerciseId === exercise.parent)
          if (parentFindIdx >= 0) {
            order = parents[parentFindIdx].nextOrder
            parents[parentFindIdx] = { exerciseId: exercise.parent, nextOrder: order + 1 }
          } else {
            order = 1
            parents.push({ exerciseId: exercise.parent, nextOrder: 2 })
          }
        }
        exercises.push({
          routine_id: routineId,
          exercise_id: exercise.id,
          is_alternative: exercise.is_alternative,
          parent: exercise.parent,
          user_notes: null,
          order,
        })
      }
      return exercises
    }

    const mainExercises = getExercisesWithFormat(exercisesSelectedList)
    const alternativeExercises = getExercisesWithFormat(alternativesSelectedList)
    const routine_exercises = [...mainExercises, ...alternativeExercises]

    return apiServices('POST', `routine?step=routine-exercises${skipValidation ? '&skip-validation=true' : ''}`, {
      id: routineId,
      routine_exercises,
    }).then((result) => result.json())
  }

  const handleClickNext = () => {
    setIsBtnLoading(true)
    save()
      .then((data) => {
        if (data === 204) {
          onNext()
        }
      })
      .catch((err) => {
        notification.error({
          message: `Error while trying to save the routine`,
          description: `Error from DB ${err.message}`,
        })
      })
      .finally(() => {
        setIsBtnLoading(false)
      })
  }

  const handleDeleteExercise = (id) => {
    const simulate = { target: { checked: false } }
    handleSelectExercise(simulate, id)
    setPreloadExercises((prev) => prev.filter((e) => e !== id))
  }

  const handleClose = async (withSave) => {
    return new Promise((resolve, reject) => {
      if (withSave) {
        if (exercisesSelectedList.length <= 0) {
          onClose()
          resolve()
        } else {
          save(true)
            .then((data) => {
              if (data === 204) {
                notification.success({ message: 'Saved correctly!' })
                onClose()
                resolve()
              } else {
                throw new Error('Error saving exercises!')
              }
            })
            .catch((err) => {
              notification.error({
                message: `Error while trying to save the routine`,
                description: `Error from DB ${err.message}`,
              })
              resolve()
            })
        }
      } else if (typeof onClose === 'function') {
        onClose()
        resolve()
      }
    })
  }

  const handleClickBack = () => {
    setIsBtnBackLoading(true)
    save(true)
      .catch((err) => {
        notification.error({
          message: `Error while trying to save the routine`,
          description: `Error from DB ${err.message}`,
        })
      })
      .finally(() => {
        onBack()
        setIsBtnBackLoading(false)
      })
  }

  const handleDeleteAlternative = (id) => setSelectedExerciseAlternatives(selectedExerciseAlternatives.filter((a) => a.id !== id))

  const handleSelectAlternative = (e, id) => {
    if (e.target.checked) {
      const exercise = formatExercise({ id, exercises, muscles, gym_equipments, is_alternative: true, parent: selectedExercise.id })
      setSelectedExerciseAlternatives([...selectedExerciseAlternatives, exercise])
    } else {
      setSelectedExerciseAlternatives(selectedExerciseAlternatives.filter((a) => a.id !== id))
    }
  }

  const saveAlternatives = () => {
    const alternatives = alternativesSelectedList.filter((a) => a.parent !== selectedExercise.id)
    const selectedAlternatives = selectedExerciseAlternatives.map((a) => {
      return { id: a.id, is_alternative: true, parent: selectedExercise.id }
    })
    setSelectedExercise()
    setAlternativesSelectedList([...alternatives, ...selectedAlternatives])
  }

  return (
    <>
      {!loadingScreen && (
        <>
          <ExerciseSelector
            maxExercises={maxExercises}
            exercisesSelectedList={exercisesSelectedList}
            handleClickBack={handleClickBack}
            isBtnBackLoading={isBtnBackLoading}
            isBtnLoading={isBtnLoading}
            handleClickNext={handleClickNext}
            canGoNext={canGoNext}
            preloadExercises={preloadExercises}
            handleSelectExercise={handleSelectExercise}
            level={level}
            setExercisesSelectedList={setExercisesSelectedList}
            handleDeleteExercise={handleDeleteExercise}
            isAlternativesSelector={false}
            selectedExercise={null}
            setSelectedExercise={setSelectedExercise}
          />
          <CloseModal visible={showCloseModal} onCancel={setShowClose} handleClose={handleClose} />
        </>
      )}
      {selectedExercise && (
        <ImportRoutineModal visible={true} closable={true} footer={[]} destroyOnClose={true} onCancel={() => setSelectedExercise()}>
          <ExerciseSelector
            maxExercises={maxExercises}
            exercisesSelectedList={selectedExerciseAlternatives}
            handleClickBack={() => setSelectedExercise()}
            isBtnBackLoading={false}
            isBtnLoading={false}
            handleClickNext={saveAlternatives}
            canGoNext={true}
            preloadExercises={[]}
            handleSelectExercise={handleSelectAlternative}
            level={level}
            setExercisesSelectedList={setSelectedExerciseAlternatives}
            handleDeleteExercise={handleDeleteAlternative}
            isAlternativesSelector={true}
            selectedExercise={selectedExercise}
            setSelectedExercise={null}
          />
        </ImportRoutineModal>
      )}
    </>
  )
}
