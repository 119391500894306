import React from 'react'
import { CardContainer, FirstSection, ScrollContainer, ToolsObjective } from './CardInfoExercise.styled'
import { ReactComponent as ArrowDown } from '../../../assets/exercises/arrow-down-info.svg'
import { ReactComponent as Equip } from '../../../assets/exercises/kettlebell-info.svg'
import { ReactComponent as Arm } from '../../../assets/exercises/arm-exercise.svg'

const CardInfoExercise = ({ pixeles, name, description, preparation, execution, gym_equipments, muscles }) => {
  const style = {
    marginBottom: '2px',
  }
  const equipmentArr = gym_equipments
    ? gym_equipments.map((equipment) => `${equipment.name} ${equipment && equipment.quantity !== 1 ? `(${equipment.quantity})` : ``}`)
    : []
  return (
    <CardContainer pixeles={pixeles}>
      <FirstSection>
        <div>{name}</div>
        <ArrowDown onClick={() => {}} />
      </FirstSection>
      <div className="equipment-main">
        <ToolsObjective>
          <Equip />
          <div style={{ display: 'inline-block' }}>
            <p style={style}>Equipment:</p>
            {equipmentArr.length > 0 && <p>{equipmentArr.join(',')}</p>}
            {equipmentArr.length === 0 && <p style={{ textAlign: 'center' }}>-</p>}
          </div>
        </ToolsObjective>
        <ToolsObjective style={{ width: '60%' }}>
          <Arm />
          <div style={{ display: 'inline-block', maxWidth: '75%' }}>
            <p style={style}>Main Muscles:</p>
            {muscles.length > 0 && <p>{muscles.join('/')}</p>}
            {muscles.length === 0 && <p style={{ textAlign: 'center' }}>-</p>}
          </div>
        </ToolsObjective>
      </div>
      <div className="info-exercise">
        <ScrollContainer>
          {description && description.replace(/<(.|\n)*?>/g, '').trim().length !== 0 && (
            <>
              <p>
                <b>Description</b>
              </p>
              <div dangerouslySetInnerHTML={{ __html: description }} />
            </>
          )}
          {preparation && preparation.replace(/<(.|\n)*?>/g, '').trim().length !== 0 && (
            <>
              <b>Preparation</b>
              <div dangerouslySetInnerHTML={{ __html: preparation }} />
            </>
          )}
          {execution && execution.replace(/<(.|\n)*?>/g, '').trim().length !== 0 && (
            <>
              <b>Execution</b>
              <div dangerouslySetInnerHTML={{ __html: execution }} />
            </>
          )}
        </ScrollContainer>
      </div>
    </CardContainer>
  )
}

export default CardInfoExercise
