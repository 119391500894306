import React, { useContext, useState, useEffect } from 'react'
import { Select, Button, Empty, Input } from 'antd'
import { WorkoutStore } from '../WorkoutStore'
import { AddButton } from '../../Uses/ExerciseWeeks/ExerciseWeeks.styled'
import { TechniqueCards } from '../TechniqueCards'
import { ReactComponent as IconTrashOutline } from 'assets/icons/icon-trash-outline.svg'
import ExerciseEmpty from 'assets/exercises/empty.png'
import { SetCardContainer } from './ExerciseWeeks.styled'
const { Option } = Select

export default function ExerciseConfiguration({ editMode, week, selectedExercise, handleSetConfiguration, actualWeek }) {
  const { techniques, set_repetition_units, exercises } = useContext(WorkoutStore)
  const [loading, setLoading] = useState(true)
  const [weekExercise, setWeekExercise] = useState(null)
  const [alternatives, setAlternatives] = useState()
  const [associateTechnique, setAssociateTechnique] = useState(null)
  const [associateUnit, setAssociateUnit] = useState('REPETITIONS')

  const EmptyConfig = () => {
    return <Empty image={ExerciseEmpty} description="Empty Configuration" />
  }

  let defaultSet = {
    routine_week_exercise_id: null,
    min_repetitions: null,
    max_repetitions: null,
    min_weight: null,
    max_weight: null,
    is_warmup: false,
    is_amrap: null,
    is_optional: false,
    drop_set_percent_min: null,
    drop_set_percent_max: null,
    min_rir: null,
    max_rir: null,
    order: null,
  }

  const specialTechniqueTypes = [
    {
      id: '',
      key: 'Normal Reps',
    },
    {
      id: 'AMRAP',
      key: 'AMRAP',
    },
    {
      id: 'Range Reps',
      key: 'Range Reps',
    },
    {
      id: 'Fixed Reps / RIR',
      key: 'Fixed Reps / RIR',
      option: 'DELOAD',
      hasRir: true,
    },
    {
      id: 'Range Reps / RIR',
      key: 'Range Reps / RIR',
      option: 'DELOAD',
      hasRir: true,
    },
  ]

  useEffect(() => {
    const foundWeekExercise = week.find((we) => we.exercise_id === selectedExercise && we.is_alternative === false && we.parent === null)
    if (foundWeekExercise) {
      if (foundWeekExercise.week_sets.length > 0) {
        const minRepetitions = foundWeekExercise.week_sets[0].min_repetitions
        const maxRepetitions = foundWeekExercise.week_sets[0].max_repetitions
        const minRir = foundWeekExercise.week_sets[0].min_rir
        const technique = foundWeekExercise.technique
        let techniqueSet
        switch (technique) {
          case '':
          case null:
          case 'EMOM':
          case "21's":
            techniqueSet = !!minRepetitions && !maxRepetitions ? '' : !!minRepetitions && !!maxRepetitions ? 'Range Reps' : null
            techniqueSet += !!minRir && techniqueSet === '' ? 'Fixed Reps / RIR' : !!minRir ? ' / RIR' : ''
            break
          case 'RPT':
            techniqueSet = !maxRepetitions ? 'Fixed Reps / RIR' : 'Range Reps / RIR'
            break
          case 'APT':
          case 'AMRAP':
          case 'MYO REPS':
            techniqueSet = technique
            break
          case 'BFR':
          case 'DROPSET':
          case '%1RM & X RM':
            techniqueSet = foundWeekExercise.week_sets[0].is_amrap
              ? 'AMRAP'
              : !!minRepetitions && !maxRepetitions
              ? ''
              : !!minRepetitions && !!maxRepetitions
              ? 'Range Reps'
              : null
            techniqueSet += !!minRir && techniqueSet === '' ? 'Fixed Reps / RIR' : !!minRir ? ' / RIR' : ''
            break
          case 'DELOAD':
            techniqueSet = !!minRepetitions && !maxRepetitions ? 'Fixed Reps / RIR' : 'Range Reps / RIR'
            break
          default:
            techniqueSet = technique
            break
        }
        setAssociateTechnique(techniqueSet)
        setAssociateUnit(foundWeekExercise.reps_unit)
      } else {
        if (editMode) {
          foundWeekExercise.technique = ''
          setAssociateTechnique('Range Reps')
          copyConfigAltExercises({ action: 'SET TECHNIQUE', technique: '' })
        } else {
          setAssociateTechnique(null)
        }
        setAssociateUnit('REPETITIONS')
      }
      const alternativesList = week
        .filter((we) => we.parent === selectedExercise)
        .map((we) => {
          let exercise = exercises.find((e) => e.id === we.exercise_id)
          return exercise ? exercise.name : 'unnamed'
        })

      setAlternatives(alternativesList.join(' / '))
      setWeekExercise(foundWeekExercise)
    } else {
      setWeekExercise(null)
      setAlternatives('')
    }
    setLoading(false)
  }, [selectedExercise])

  const copyConfigAltExercises = ({ action, index, param, data, technique, reps_unit }) => {
    let alternatives = []
    alternatives = week.filter((e) => e.parent === selectedExercise)
    if (alternatives.length > 0) {
      alternatives.forEach((alt) => {
        alt.is_warmup = param === 'is_warmup' ? data : false
        switch (action) {
          case 'SET TECHNIQUE':
            if (technique !== 'DELOAD') {
              alt.deload_percent_min = null
              alt.deload_percent_max = null
              alt.deload_week = null
            }
            alt.technique = !technique ? '' : technique
            alt.has_deload = technique === 'DELOAD'
            alt.week_sets = []
            break
          case 'SET UNIT REP':
            alt.reps_unit = reps_unit
            break
          case 'SET ASOC TECHNIQUE':
            alt.week_sets = []
            break
          case 'ADD SET':
            alt.week_sets.push({ ...data, routine_week_exercise_id: alt.id })
            break
          case 'DELETE SET':
            alt.week_sets = alt.week_sets.filter((ws, i) => i !== index)
            break
          case 'SET DATA':
            alt.week_sets[index][param] = data
            break
          case 'SET DELOAD':
            alt[param] = data
            break
          case 'CHANGE SET':
            alt.week_sets[index] = { ...data, routine_week_exercise_id: alt.id, id: alt.week_sets[index.id] }
            break
          default:
            break
        }
      })
    }
  }

  const handleSetTechnique = (technique) => {
    if (technique !== 'DELOAD') {
      weekExercise.deload_percent_min = null
      weekExercise.deload_percent_max = null
      weekExercise.deload_week = null
    }
    weekExercise.technique = technique
    weekExercise.has_deload = technique === 'DELOAD'
    weekExercise.week_sets = []
    copyConfigAltExercises({ action: 'SET TECHNIQUE', technique })
    handleSetConfiguration(weekExercise)
    if (
      technique !== null &&
      technique !== '' &&
      technique !== 'BFR' &&
      technique !== 'DELOAD' &&
      technique !== 'DROPSET' &&
      technique !== '%1RM & X RM' &&
      technique !== 'RPT' &&
      technique !== 'EMOM' &&
      technique !== "21's"
    ) {
      setAssociateTechnique(technique)
    } else {
      setAssociateTechnique(null)
    }
  }

  const handleUnit = (unit) => {
    weekExercise.reps_unit = unit
    setAssociateUnit(unit)
    copyConfigAltExercises({ action: 'SET UNIT REP', reps_unit: unit })
  }

  const handleAddSet = () => {
    defaultSet = {
      ...defaultSet,
      routine_week_exercise_id: weekExercise.id,
      is_amrap: associateTechnique === 'AMRAP',
      order: weekExercise.week_sets.length + 1,
    }
    if (weekExercise.week_sets.length > 0) {
      const anteriorSet = { ...weekExercise.week_sets[weekExercise.week_sets.length - 1] }
      if (anteriorSet.id) delete anteriorSet.id
      const set = { ...anteriorSet, order: weekExercise.week_sets.length + 1 }
      weekExercise.week_sets.push(set)
      copyConfigAltExercises({ action: 'ADD SET', data: set })
    } else {
      weekExercise.week_sets.push(defaultSet)
      copyConfigAltExercises({ action: 'ADD SET', data: defaultSet })
    }
    handleSetConfiguration(weekExercise)
  }

  const handleDeleteSet = (index) => {
    weekExercise.week_sets = weekExercise.week_sets.filter((ws, i) => i !== index).map((data, i) => ({ ...data, order: i + 1 }))
    copyConfigAltExercises({ action: 'DELETE SET', index })
    if (weekExercise.week_sets.length > 0 && weekExercise.week_sets[0].is_drop_set) {
      const set = { ...weekExercise.week_sets[0], is_drop_set: false, drop_set_percent_max: null, drop_set_percent_min: null }
      weekExercise.week_sets[0] = set
      copyConfigAltExercises({ action: 'CHANGE SET', data: set, index: 0 })
    }
    handleSetConfiguration(weekExercise)
  }

  const handleSetData = (index, param, data) => {
    if (
      (weekExercise.technique === null ||
        weekExercise.technique === '' ||
        weekExercise.technique === 'BFR' ||
        weekExercise.technique === 'DROPSET' ||
        weekExercise.technique === '%1RM & X RM' ||
        weekExercise.technique === 'DELOAD' ||
        weekExercise.technique === 'EMOM' ||
        weekExercise.technique === "21's") &&
      (associateTechnique === 'Range Reps' || associateTechnique === 'Range Reps / RIR') &&
      weekExercise.week_sets[index].max_repetitions <= 0
    ) {
      weekExercise.week_sets[index].max_repetitions = ''
    }
    weekExercise.week_sets[index][param] = data
    weekExercise.is_warmup = param === 'is_warmup' ? data : false

    copyConfigAltExercises({ action: 'SET DATA', index, param, data })
    handleSetConfiguration(weekExercise)
  }

  const handleSetDeload = (param, data) => {
    weekExercise[param] = data
    copyConfigAltExercises({ action: 'SET DELOAD', param, data })
    handleSetConfiguration(weekExercise)
  }

  const onlyNumbers = (e) => {
    if (!/[0-9]/.test(e.key)) {
      e.preventDefault()
    }
  }

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      handleAddSet()
    }
  }

  const changeSet = (set) => {
    const findSetIndex = weekExercise.week_sets.findIndex((s) => s.order === set.order)
    if (findSetIndex >= 0) {
      weekExercise.week_sets[findSetIndex] = set
      copyConfigAltExercises({ action: 'CHANGE SET', data: set, index: findSetIndex })
      handleSetConfiguration(weekExercise)
    }
  }

  return (
    <div className="configuration" tabIndex="0">
      {!loading && weekExercise && (
        <>
          <p className="section-title">Exercise Configuration</p>
          <hr />

          {/* TODO: Activate superset when it's available */}
          {/* {editMode && (
            <>
              <p className="section-title">Super set</p>
              <div className="super-set-config">
                <p>No</p>
                <Switch disabled />
                <p>Yes</p>
              </div>
              <p className="section-title">Select the superset exercise</p>
              <Select disabled />
              <hr />
            </>
          )} */}

          {alternatives && (
            <div>
              <p className="section-title">Alternatives</p>
              <p>{alternatives}</p>
            </div>
          )}

          {weekExercise.technique_notes && (
            <div>
              <p className="section-title">Notes</p>
              <p>{weekExercise.technique_notes}</p>
            </div>
          )}

          <p className="section-title">{editMode ? 'Select a technique' : 'Technique'}</p>
          {editMode ? (
            <Select value={weekExercise.technique} onChange={handleSetTechnique}>
              <Option key="">Normal</Option>
              {techniques.map((technique) => (
                <Option disabled={technique.key === 'SUPERSET'} key={technique.key} value={technique.key}>
                  {technique.key}
                </Option>
              ))}
            </Select>
          ) : (
            <p>{weekExercise.technique === '' || weekExercise.technique === null ? 'Normal' : weekExercise.technique}</p>
          )}
          {editMode && !!weekExercise.has_deload && (
            <div className="section-deload">
              <div>
                <p>Weight Cut</p>
                <div className="multi-input-container">
                  <Input
                    className="multi-input"
                    maxLength="3"
                    value={weekExercise.deload_percent_min || ''}
                    onKeyPress={(e) => onlyNumbers(e)}
                    onChange={(e) => handleSetDeload('deload_percent_min', e.target.value)}
                    autoFocus
                  />
                  <span>-</span>
                  <Input
                    className="multi-input"
                    maxLength="3"
                    value={weekExercise.deload_percent_max || ''}
                    onKeyPress={(e) => onlyNumbers(e)}
                    onChange={(e) => handleSetDeload('deload_percent_max', e.target.value)}
                  />
                </div>
              </div>
              <div>
                <p>Select week of reference</p>
                <Select defaultValue={weekExercise.deload_week} onChange={(value) => handleSetDeload('deload_week', value)}>
                  {['1', '2', '3', '4', '5', '6', '7', '8'].map((we) => {
                    return (
                      <Option key={`deload-${actualWeek}-${we}`} value={we} disabled={actualWeek <= parseInt(we)}>
                        Week {we}
                      </Option>
                    )
                  })}
                </Select>
              </div>
            </div>
          )}

          {editMode &&
            (weekExercise.technique === null ||
              weekExercise.technique === '' ||
              weekExercise.technique === 'BFR' ||
              weekExercise.technique === 'DELOAD' ||
              weekExercise.technique === 'DROPSET' ||
              weekExercise.technique === '%1RM & X RM' ||
              weekExercise.technique === 'RPT' ||
              weekExercise.technique === 'EMOM' ||
              weekExercise.technique === "21's") && (
              <Select
                value={associateTechnique}
                style={{ marginTop: 14 }}
                onChange={(technique) => {
                  weekExercise.week_sets = []
                  copyConfigAltExercises({ action: 'SET ASOC TECHNIQUE' })
                  setAssociateTechnique(technique)
                  handleSetConfiguration(weekExercise)
                }}
              >
                {specialTechniqueTypes.map((type) => {
                  return (
                    <Option
                      disabled={
                        (!!weekExercise.has_deload && (type.id === '' || type.id === 'AMRAP' || type.id === 'Range Reps')) ||
                        ((weekExercise.technique === '' || weekExercise.technique === null || weekExercise.technique === 'EMOM') &&
                          type.id === 'AMRAP') ||
                        (weekExercise.technique === 'RPT' && !type.hasRir) ||
                        (weekExercise.technique === "21's" && type.id !== '')
                      }
                      key={type.key}
                      value={type.id}
                    >
                      {type.key}
                    </Option>
                  )
                })}
              </Select>
            )}
          <hr />
          <div className="section-header">
            <p className="section-title">Sets and Repetitions Details</p>
            {editMode && weekExercise.technique !== null && associateTechnique !== null && <AddButton onClick={handleAddSet}>+Add set</AddButton>}
          </div>
          <hr />
          <div>
            {editMode && (
              <div style={{ marginBottom: '10px' }}>
                <p className="section-title">{editMode ? 'Select the unit' : 'Unit'}</p>
                <Select value={associateUnit} onChange={handleUnit}>
                  {set_repetition_units.map((unit) => {
                    return <Option key={unit.key}>{unit.label}</Option>
                  })}
                </Select>
              </div>
            )}
            {weekExercise.week_sets.map((set, index) => {
              let cardTechnique = associateTechnique !== null ? associateTechnique : weekExercise.technique
              let unitRepsShortName = 'Reps'
              if (associateUnit) {
                const unitObject = set_repetition_units.find((u) => u.key === associateUnit) || { shortName: 'Reps' }
                unitRepsShortName = unitObject.shortName
              }
              if (cardTechnique === 'MYO REPS' && index > 0) cardTechnique = 'Mini Sets'
              if (weekExercise.technique === 'DELOAD' && !editMode) cardTechnique = weekExercise.technique
              return (
                <SetCardContainer key={index} editMode={editMode}>
                  <TechniqueCards
                    key={index}
                    technique={cardTechnique}
                    unitReps={unitRepsShortName}
                    exercise={weekExercise}
                    config={set}
                    setWarmup={(isWarmup) => handleSetData(index, 'is_warmup', isWarmup)}
                    setMinReps={(num) => handleSetData(index, 'min_repetitions', Number(num))}
                    setMaxReps={(num) => handleSetData(index, 'max_repetitions', Number(num))}
                    setMinRIR={(num) => handleSetData(index, 'min_rir', Number(num))}
                    setMaxRIR={(num) => handleSetData(index, 'max_rir', Number(num))}
                    editMode={editMode}
                    keyDown={handleKeyDown}
                    changeSet={changeSet}
                    exerciseTechnique={weekExercise.technique}
                  />
                  {editMode && (
                    <Button className="trash-button" onClick={() => handleDeleteSet(index)}>
                      <IconTrashOutline />
                    </Button>
                  )}
                </SetCardContainer>
              )
            })}
            {weekExercise.week_sets.length === 0 && <EmptyConfig />}
          </div>
        </>
      )}
    </div>
  )
}
