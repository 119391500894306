import React, { useEffect, useState } from 'react'
import { Row, Col, Form, Input, DatePicker, Tooltip } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import moment from 'moment'

const FormItem = Form.Item
const { RangePicker } = DatePicker

const BootcampInformation = ({ bootcamp, edit, form }) => {
  const [bootcampToShow, setBootcampToShow] = useState(bootcamp)

  useEffect(() => {
    setBootcampToShow(bootcamp)
    form.setFieldsValue({
      description: bootcamp.description ? bootcamp.description : null,
      product_tag: bootcamp.product_tag ? bootcamp.product_tag : null,
      duration: bootcamp.duration ? bootcamp.duration : null,
      access_date: bootcamp.access_date ? moment(bootcamp.access_date) : null,
      start_end_date: bootcamp.start_date && bootcamp.end_date ? [moment(bootcamp.start_date), moment(bootcamp.end_date)] : null,
      close_date: bootcamp.close_date ? moment(bootcamp.close_date) : null,
    })
  }, [bootcamp, edit])

  const handleRangeSelected = (dates) => {
    if (dates && dates.length > 1) {
      const start = dates[0]
      const end = dates[1]
      const diffDays = end.diff(start, 'day')
      const weeks = diffDays > 0 ? parseInt((end.diff(start, 'day') / 7).toFixed(0)) : 0
      form.setFieldsValue({ duration: weeks })
    } else {
      form.setFieldsValue({ duration: '' })
    }

    setTimeout(() => {
      form.validateFields(['access_date', 'close_date', 'duration'])
      calculateBannerDays()
    }, 100)
  }

  const calculateBannerDays = () => {
    const startEndDate = form.getFieldValue('start_end_date')
    if (!!startEndDate) {
      setTimeout(() => {
        const accessDate = form.getFieldValue('access_date')
        if (!!accessDate) {
          const diffStartAccessDate = accessDate.diff(startEndDate[0], 'day')
          const diffEndCloseDate = startEndDate[1].diff(startEndDate[0], 'day')

          form.setFieldsValue(
            {
              'Banners.on_time.days_to_show': diffStartAccessDate,
              'Banners.overdue.days_to_show': diffEndCloseDate,
            },
            () => {
              form.validateFields(['Banners.delayed.days_to_show'])
            },
          )
        }
      }, 100)
    }
  }

  return (
    <>
      <h3>Bootcamp Dates</h3>
      <Row className="dates">
        <Col md={4} xs={12}>
          <p className="title">
            Infusion Soft ID
            <Tooltip title={<span style={{ fontSize: '10px' }}>ID assigned to the Bootcamp in Infusion Soft.</span>}>
              <InfoCircleOutlined />
            </Tooltip>
          </p>
          {!edit && <p>{bootcampToShow.product_tag}</p>}
          {edit && (
            <FormItem
              name="product_tag"
              rules={[
                {
                  required: true,
                  message: 'InfusionSoft ID is required',
                },
              ]}
            >
              <Input type="number" />
            </FormItem>
          )}
        </Col>
        <Col md={4} xs={12}>
          <p className="title">
            Duration
            <Tooltip title={<span style={{ fontSize: '10px' }}>The length of weeks each Bootcamp will have.</span>}>
              <InfoCircleOutlined />
            </Tooltip>
          </p>
          {!edit && <p>{bootcampToShow.duration} weeks</p>}
          {edit && (
            <FormItem
              name="duration"
              rules={[
                {
                  required: true,
                  message: 'Duration is required',
                },
              ]}
            >
              <Input type="number" disabled />
            </FormItem>
          )}
        </Col>
        <Col md={4} xs={12}>
          <p className="title">
            Access date
            <Tooltip
              title={<span style={{ fontSize: '10px' }}>The date when the bootcampers will have access to the enrollment through the banner.</span>}
            >
              <InfoCircleOutlined />
            </Tooltip>
          </p>
          {!edit && <p>{moment(bootcampToShow.access_date).format('DD/MMM/YYYY')}</p>}
          {edit && (
            <FormItem
              name="access_date"
              rules={[
                {
                  required: true,
                  message: 'Access Date is required',
                },
                {
                  validator: (rule, value, callback) => {
                    if (!!!value) callback()
                    const startEndDate = form.getFieldValue('start_end_date')
                    if (!!startEndDate && !!startEndDate[0]) {
                      if (moment(value).isSameOrAfter(form.getFieldValue('start_end_date')[0])) callback(false)
                    }
                    callback()
                  },
                  message: 'The access date should be lower than start date',
                },
              ]}
            >
              <DatePicker onChange={() => calculateBannerDays()} showToday={false} />
            </FormItem>
          )}
        </Col>
        <Col md={8} xs={12}>
          <p className="title">
            Start - End date
            <Tooltip title={<span style={{ fontSize: '10px' }}>It indicates the period of duration of the bootcamp.</span>}>
              <InfoCircleOutlined />
            </Tooltip>
          </p>
          {!edit && (
            <p>
              {moment(bootcampToShow.start_date).format('DD/MMM/YYYY')} ~ {moment(bootcampToShow.end_date).format('DD/MMM/YYYY')}
            </p>
          )}
          {edit && (
            <FormItem
              name="start_end_date"
              rules={[
                { required: true, message: 'Please select the duration of bootcamp.' },
                {
                  validator: (rule, value, callback) => {
                    if (!!!value) return callback()
                    const start = value[0]
                    const end = value[1]
                    if (!!start && !!end) {
                      if (end.diff(start, 'day') <= 7) callback(false)
                    }
                    callback()
                  },
                  message: 'Please choose more than a one week.',
                },
              ]}
            >
              <RangePicker onChange={handleRangeSelected} />
            </FormItem>
          )}
        </Col>
        <Col md={4} xs={12}>
          <p className="title">
            Close date
            <Tooltip title={<span style={{ fontSize: '10px' }}>Date in which bootcampers will stop have access to the portal.</span>}>
              <InfoCircleOutlined />
            </Tooltip>
          </p>
          {!edit && <p>{moment(bootcampToShow.close_date).format('DD/MMM/YYYY')}</p>}
          {edit && (
            <FormItem
              name="close_date"
              rules={[
                {
                  required: true,
                  message: 'Close Date is required',
                },
                {
                  validator: (rule, value, callback) => {
                    if (!!!value) callback()
                    const startEndDate = form.getFieldValue('start_end_date')
                    if (!!startEndDate && !!startEndDate[1]) {
                      if (moment(value).isSameOrBefore(form.getFieldValue('start_end_date')[1])) callback(false)
                    }
                    callback()
                  },
                  message: 'The close date should be higher than end date',
                },
              ]}
            >
              <DatePicker />
            </FormItem>
          )}
        </Col>
      </Row>
      <hr />
    </>
  )
}

export default BootcampInformation
