import styled from '@emotion/styled'
import { Card } from 'antd'

export const Message = styled(Card)`
  position: relative;
  display: flex;
  align-items: center;
  padding: 7px 5px 10px 10px;
  min-height: 45px;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  border-left: solid 8px ${({ color }) => color};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin-bottom: 15px;
  .ant-card-body {
    padding: 0px;
    display: flex;
    align-items: center;
    .icon {
      margin-right: 10px;
      width: 35px;
    }
    p {
      font-size: 14px;
      line-height: 18px;
      margin-bottom: 0px;
    }
  }
`
