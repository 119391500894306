import { Form } from 'antd'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import iconPicture from 'images/picture.svg'

export default styled(Form)`
  && {
    margin: 0 auto;
    max-width: 400px;
    font-size: 10px;
    .ant-form-item {
      font-family: 'Montserrat', sans-serif;
    }
    .ant-form-item-label {
      line-height: inherit;
      padding-bottom: 0;
    }
    label {
      font-family: 'Montserrat', sans-serif;
      font-size: 10px;
      color: #4a4a4a;
    }
    .notation {
      font-size: 9px;
      color: #a6a6a6;
    }
    .description {
      color: #a6a6a6;
      line-height: 11px;
    }
    .ant-input {
      font-family: 'Montserrat', sans-serif;
      font-size: 9px;
    }
    .uploadPic {
      position: relative;
      .ant-btn {
        margin: 0 auto 9px;
        padding: 10px;
        text-align: center;
      }
    }
    .ant-upload.ant-upload-select-picture-card {
      width: 210px;
      height: 210px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 0;
      background-color: #fff;
      border: 1px solid #cfcfcf;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      background-image: url(${iconPicture});
      background-size: 150px;
      background-repeat: no-repeat;
      background-position: top center;

      ${({ background }) =>
        background &&
        `
        background-image: url(${background});
        background-size: auto;
        background-position: center center;
      `}
    }
    .ant-upload-list-picture-card {
      float: none;
    }
    .ant-upload-list-picture-card .ant-upload-list-item {
      float: none;
      width: 210px;
      height: 210px;
      margin: auto;
    }
    .ant-upload.ant-upload-select-picture-card > .ant-upload {
      all: unset;
    }
    .ant-form-explain,
    .ant-form-extra {
      padding-top: 8px;
      font-size: 9px;
    }
    .inline {
      .ant-form-item-children {
        align-items: center;
        display: flex;
        justify-content: center;
        .ant-btn {
          margin: 0;
        }
      }
    }
  }
  ${({ custom }) => {
    if (custom === 'short') {
      return css`
        && {
          padding-left: 40px;
          padding-right: 40px;
        }
      `
    }
  }}
`
