import React, { useState, useContext, useEffect, useRef } from 'react'
import { Row, Empty, Tabs, Button, Input } from 'antd'
import { StyledModal } from 'styled'
import ExerciseConfiguration from './ExerciseConfiguration'
import { ExerciseConfigImport } from './ExerciseConfigImport'
import { WorkoutStore } from '../../Uses/WorkoutStore'
import ExerciseList from './ExerciseList'
import ExerciseEmpty from 'assets/exercises/empty.png'
import { AddButton, ExerciseListCol, ConfigCol } from './ExerciseWeeks.styled'
import ExerciseSelector from '../ExerciseSelector'
import { ImportRoutineModal } from '../../ImportRoutine/ImportRoutine.styled'
import { formatExercise } from '../../../ExercisesCatalog/Uses'

const { TabPane } = Tabs
const { TextArea } = Input

const ExerciseNoSelected = () => {
  return <Empty image={ExerciseEmpty} description="Select an exercise to configure the sets and reps" />
}

const getDefaultSelectedExercise = (routine, week, selectedExercise = null) => {
  if (selectedExercise) {
    if (routine && routine.routine_weeks.length > week - 1 ? routine.routine_weeks[week - 1].week_exercises.length > 0 : false) {
      const searchExercise = routine.routine_weeks[week - 1].week_exercises.find((ex) => ex.exercise_id === selectedExercise)
      if (searchExercise) {
        if (searchExercise.week_sets.length > 0) return searchExercise.exercise_id
      }
    }
  } else if (routine && routine.routine_weeks.length > week - 1) {
    const weekExercises = routine.routine_weeks[week - 1].weekExercises
    if (weekExercises) {
      const firstExercise = weekExercises.find((e) => !e.parent)
      if (firstExercise) return firstExercise.exercise_id
    }
  }
  return null
}
export default function ExerciseWeeks({ routine, editMode, setRoutine, heightlistcard, heightconfig, editfour = undefined }) {
  const [note, setNote] = useState('')
  const [showNote, setShowNote] = useState({ status: false, id: null })
  const [showModalDeleteExercise, setShowModalDeleteExercise] = useState({ status: false, id: null })
  const [showModalAddExercise, setShowModalAddExercise] = useState({ status: false, id: null })
  const [showModalAltExercise, setShowModalAltExercise] = useState(null)
  const [selectedExercise, setSelectedExercise] = useState(null)
  const [actualWeek, setActualWeek] = useState(1)
  const [selectedExerciseAlternatives, setSelectedExerciseAlternatives] = useState([])
  const { exercises, muscles, gym_equipments } = useContext(WorkoutStore)
  const textareaRef = useRef()

  useEffect(() => {
    setSelectedExercise(getDefaultSelectedExercise(routine, actualWeek))
  }, [])
  useEffect(() => {
    if (showNote.status) {
      setTimeout(() => {
        textareaRef.current.focus({
          cursor: 'end',
        })
      }, 500)
    }
  }, [showNote])
  const setExerciseNote = (note, weekId, exerciseId) => {
    let week = routine.routine_weeks.find((rw) => rw.id === weekId)
    let weekExercise = week.week_exercises.find((we) => we.exercise_id === exerciseId && we.is_alternative === false)
    week.week_exercises.forEach((we) => {
      if (we.parent === exerciseId) {
        we.technique_notes = note
      }
    })

    weekExercise.technique_notes = note
  }

  const handleDeleteExercise = (weekId, exerciseId) => {
    let routineWeeks = routine.routine_weeks.map((rw) => {
      rw.week_exercises = rw.week_exercises.filter((we) => we.exercise_id !== exerciseId && we.parent !== exerciseId)
      return rw
    })
    const routineExercises = routine.routine_exercises.filter((e) => e.exercise_id !== exerciseId && e.parent !== exerciseId)
    setRoutine((prev) => ({ ...prev, routine_weeks: routineWeeks, routine_exercises: routineExercises }))
    setSelectedExercise(getDefaultSelectedExercise(routine, actualWeek))
  }

  const resetShowAltModal = () => {
    setShowModalAltExercise(null)
    setSelectedExerciseAlternatives(null)
    const selectedEx = selectedExercise
    reloadSelectedExercise()
    setTimeout(() => setSelectedExercise(selectedEx), 10)
  }

  const handleSetConfiguration = (week) => {
    const { routine_weeks } = routine
    const { routine_week_id, exercise_id, is_alternative, parent } = week
    let routineWeeks = routine_weeks.map((rw) => {
      let { id, week_exercises } = rw
      if (id === routine_week_id) {
        rw.week_exercises = week_exercises.map((we) => {
          if (we.exercise_id === exercise_id && we.is_alternative === is_alternative && we.parent === parent) {
            we = week
          }
          return we
        })
      }
      return rw
    })
    setRoutine((prev) => ({ ...prev, routine_weeks: routineWeeks }))
  }

  const getNote = (week, exerciseId, weekNumber) => {
    if (week && exerciseId && weekNumber === parseInt(actualWeek)) {
      let exercise = week.week_exercises.find((we) => we.exercise_id === exerciseId && we.is_alternative === false)
      if (exercise) {
        setNote(exercise.technique_notes)
      }
    }
  }

  const reloadSelectedExercise = () => {
    setSelectedExercise(null)
  }
  const handleShiftEnter = (event, week) => {
    if (event.keyCode === 13 && event.shiftKey) {
      setShowNote({ status: false, id: null })
      setExerciseNote(note, week.id, showNote.id)
    }
  }
  const handleSelectAlternative = (e, id) => {
    if (e.target.checked) {
      const exercise = formatExercise({ id, exercises, muscles, gym_equipments, is_alternative: true, parent: showModalAltExercise.id })
      setSelectedExerciseAlternatives([...selectedExerciseAlternatives, exercise])
    } else {
      setSelectedExerciseAlternatives(selectedExerciseAlternatives.filter((a) => a.id !== id))
    }
  }

  const getAlternativesFromExercise = (exerciseId, week) => {
    let alternatives = []
    const weekExercises = week.week_exercises
    const parent = weekExercises.find((we) => we.exercise_id === exerciseId && we.parent === null)
    if (parent) {
      alternatives = weekExercises.filter((we) => we.parent === exerciseId)
      alternatives = alternatives.map((e) =>
        formatExercise({ id: e.exercise_id, exercises, muscles, gym_equipments, is_alternative: true, parent: exerciseId }),
      )
    }
    return alternatives
  }

  const handleShowAltModal = (exerciseId, week) => {
    const exercise = formatExercise({ id: exerciseId, exercises, muscles, gym_equipments, is_alternative: false, parent: null })
    setShowModalAltExercise(exercise)
    setSelectedExerciseAlternatives(getAlternativesFromExercise(exerciseId, week))
  }
  const handleDeleteAlternative = (id) => setSelectedExerciseAlternatives(selectedExerciseAlternatives.filter((a) => a.id !== id))

  const saveAlternativeExercises = (week) => {
    let routineWeeks = routine.routine_weeks.map((rw) => {
      const parent = { ...rw.week_exercises.find((we) => we.exercise_id === showModalAltExercise.id && we.parent === null) }
      if (parent) {
        delete parent.id
        const actualAlternatives = rw.week_exercises.filter((we) => we.parent === parent.exercise_id)
        const newAlternatives = []
        let order = 1
        for (const exercise of selectedExerciseAlternatives) {
          const search = actualAlternatives.find(
            (e) => e.exercise_id === exercise.id && e.is_alternative === exercise.is_alternative && e.parent === exercise.parent,
          )
          if (search != null) {
            newAlternatives.push({ ...search, order })
          } else {
            newAlternatives.push({
              ...parent,
              exercise_id: exercise.id,
              is_alternative: exercise.is_alternative,
              parent: parent.exercise_id,
              order,
              week_sets: parent.week_sets.map((s) => {
                return (({ id, ...set }) => set)(s)
              }),
            })
          }
          order++
        }
        rw.week_exercises = rw.week_exercises.filter((we) => we.parent !== parent.exercise_id)
        rw.week_exercises = [...rw.week_exercises, ...newAlternatives]
      }
      return rw
    })
    let newRoutineExercises = [...routine.routine_exercises]
    const parent = { ...newRoutineExercises.find((e) => e.exercise_id === showModalAltExercise.id && e.parent === null) }
    if (parent) {
      newRoutineExercises = newRoutineExercises.filter((e) => e.parent !== parent.exercise_id)
      let order = 1
      for (const exercise of selectedExerciseAlternatives) {
        newRoutineExercises.push({
          parent: parent.exercise_id,
          routine_id: routine.id,
          order,
          is_alternative: true,
          exercise_id: exercise.id,
          user_notes: null,
          delta_repetitions: {},
          delta_set: {},
          delta_weight: {},
        })
        order++
      }
    }
    setRoutine((prev) => ({ ...prev, routine_weeks: routineWeeks, routine_exercises: newRoutineExercises }))
    resetShowAltModal()
  }
  return (
    <Tabs
      editfour={editfour}
      type="card"
      tabBarGutter={5}
      onChange={(weekNumber) => {
        setSelectedExercise(getDefaultSelectedExercise(routine, weekNumber, selectedExercise))
        setActualWeek(weekNumber)
      }}
      destroyInactiveTabPane={true}
    >
      {routine &&
        routine.routine_weeks.map((week, i) => {
          return (
            <TabPane tab={`Week ${i + 1}`} key={i + 1}>
              <Row>
                <ExerciseListCol xs={24} md={12} heightlistcard={heightlistcard}>
                  {editMode && (
                    <ExerciseConfigImport
                      weekActual={i + 1}
                      routine={routine}
                      handleSetConfiguration={handleSetConfiguration}
                      reloadSelectedExercise={reloadSelectedExercise}
                    />
                  )}
                  <div className="section-header">
                    <p className="section-title">Exercise Order</p>
                    {editMode && <AddButton onClick={() => setShowModalAddExercise({ status: true })}>+ Add Exercise</AddButton>}
                  </div>
                  <ExerciseList
                    week={week}
                    routineExercises={week.week_exercises}
                    editMode={editMode}
                    handleSelectExercise={(id) => {
                      setSelectedExercise(id)
                      setShowNote({ status: false, id: null })
                    }}
                    selectedExercise={selectedExercise}
                    showModalDelete={setShowModalDeleteExercise}
                    showModalTip={setShowNote}
                    getNote={getNote}
                    weekNumber={i + 1}
                    showModalAltExc={(exerciseId) => handleShowAltModal(exerciseId, week)}
                    handleSetConfiguration={handleSetConfiguration}
                  />
                </ExerciseListCol>
                <ConfigCol xs={24} md={12} heightconfig={heightconfig} className="show-scrollbars" tabIndex="0">
                  {(!selectedExercise || week.week_exercises.length === 0) && <ExerciseNoSelected />}
                  {!!selectedExercise && week.week_exercises.length > 0 && (
                    <ExerciseConfiguration
                      key={i}
                      editMode={editMode}
                      week={week.week_exercises}
                      actualWeek={actualWeek}
                      selectedExercise={selectedExercise}
                      handleSetConfiguration={handleSetConfiguration}
                    />
                  )}
                </ConfigCol>

                <StyledModal
                  title="Add a Tip to Perform this Exercise"
                  visible={showNote.status}
                  onCancel={() => setShowNote({ status: false, id: null })}
                  className="btn-centered"
                  footer={[
                    <Button key="cancel" onClick={() => setShowNote({ status: false, id: null })}>
                      Cancel
                    </Button>,
                    <Button
                      key="save"
                      type="primary"
                      onClick={() => {
                        setShowNote({ status: false, id: null })
                        setExerciseNote(note, week.id, showNote.id)
                      }}
                    >
                      Save
                    </Button>,
                  ]}
                >
                  <TextArea
                    ref={textareaRef}
                    onKeyDown={(e) => handleShiftEnter(e, week)}
                    value={note}
                    onChange={(e) => setNote(e.target.value)}
                    style={{ borderRadius: 4, border: '1px solid #CFCFCF', boxShadow: 'inset 2px 2px 5px rgba(0, 0, 0, 0.2)' }}
                    autoSize={{ minRows: 10 }}
                  />
                </StyledModal>

                <StyledModal
                  className="confirm"
                  title="Are you sure you want to delete this exercise?"
                  onCancel={() => setShowModalDeleteExercise({ status: false, id: null })}
                  visible={showModalDeleteExercise.status}
                  centered={true}
                  footer={[
                    <Button key="cancel" onClick={() => setShowModalDeleteExercise({ status: false, id: null })}>
                      Cancel
                    </Button>,
                    <Button
                      key="save"
                      type="primary"
                      onClick={() => {
                        handleDeleteExercise(week.id, showModalDeleteExercise.id)
                        setShowModalDeleteExercise({ status: false, id: null })
                      }}
                    >
                      Delete
                    </Button>,
                  ]}
                />
                {!!showModalAltExercise && (
                  <ImportRoutineModal visible={true} closable={true} footer={[]} destroyOnClose={true} onCancel={resetShowAltModal}>
                    <ExerciseSelector
                      maxExercises={15}
                      exercisesSelectedList={selectedExerciseAlternatives}
                      handleClickBack={resetShowAltModal}
                      isBtnBackLoading={false}
                      isBtnLoading={false}
                      handleClickNext={() => saveAlternativeExercises(week)}
                      canGoNext={true}
                      preloadExercises={[]}
                      handleSelectExercise={handleSelectAlternative}
                      level={0}
                      setExercisesSelectedList={setSelectedExerciseAlternatives}
                      handleDeleteExercise={handleDeleteAlternative}
                      isAlternativesSelector={true}
                      selectedExercise={showModalAltExercise}
                      setSelectedExercise={null}
                    />
                  </ImportRoutineModal>
                )}

                {!!showModalAddExercise.status && (
                  <StyledModal
                    title="Alternative Exercises for"
                    onCancel={() => setShowModalAddExercise(false)}
                    visible={showModalAddExercise.status}
                    centered={true}
                    footer={[
                      <Button key="cancel" onClick={() => setShowModalAddExercise(false)}>
                        Cancel
                      </Button>,
                      <Button key="save" type="primary" onClick={() => setShowModalAddExercise(false)}>
                        Save
                      </Button>,
                    ]}
                  />
                )}
              </Row>
            </TabPane>
          )
        })}
    </Tabs>
  )
}
