import { Input } from 'antd'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

export default styled(Input)`
  &.ant-input {
    font-family: 'Montserrat', sans-serif;
    height: 35px;
    font-size: 17px;
    line-height: 35px;
    color: #4a4a4a;
    &:hover {
      border-color: #5d5d5d;
    }
    &:focus {
      border-color: #5d5d5d;
      box-shadow: 0 0 3px 0 #5d5d5d;
    }
  }
  &.ant-input + span {
    display: inline-block;
    vertical-align: bottom;
    font-size: 9px;
  }
  &.ant-input + .ant-input-group-addon {
    border: none;
  }
  ${({ custom }) => {
    if (custom === 'short') {
      return css`
        &.ant-input {
          max-width: 130px;
          &:hover {
            border-color: #ff4613;
          }
          &:focus {
            border-color: #ff4613;
            box-shadow: 0 0 3px 0 #ff4613;
          }
        }
      `
    }
    if (custom === 'veryShort') {
      return css`
        &.ant-input {
          max-width: 76px;
        }
      `
    }
  }}
`
