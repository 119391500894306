import React, { useEffect, useState } from 'react'
import Layout from 'components/Layout'
import { Row, Col, Input, Form, Avatar, Checkbox, List, Button, notification, Popconfirm, Alert, Tag, Tooltip } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import gql from 'graphql-tag'
import apolloClient from 'apolloClient.js'
import { createContactMerge } from './query'
import { LOCAL_STORAGE } from 'constants.js'

const { Search } = Input
const FormItem = Form.Item

const getStudent = gql`
  query getStudent($contact_id: String!) {
    user(where: { contact_id: { _eq: $contact_id } }) {
      contact_id
      email
      first_name
      gender
      last_name
      legal_name
      picture
      units
      sent_messages_aggregate {
        aggregate {
          count
        }
      }
      trainee {
        id
        pictures_aggregate {
          aggregate {
            count
          }
        }
        macro_aggregate {
          aggregate {
            count
          }
        }
        measurement_aggregate {
          aggregate {
            count
          }
        }
        configuration
        current_goal
        selected(order_by: { program: { access_date: desc } }) {
          thread_id
          program {
            program_id
            description
          }
          is_active
        }
      }
    }
  }
`
const MergeContact = () => {
  const [form] = Form.useForm()
  let errorMerge = false
  let programMergeType

  const [studentDuplicate, setStudentDuplicate] = useState([])
  const [searchContadDuplicateId, setSearchContadDuplicateId] = useState()
  const [studentTarget, setStudentTarget] = useState([])
  const [searchContadTargetId, setSearchContadTargetId] = useState()
  const [error, setError] = useState()
  const [visible, setVisible] = useState()

  const [firstNameDuplicate, setFirstNameDuplicate] = useState(['', false])
  const [lastNameDuplicate, setLastNameDuplicate] = useState(['', false])
  const [emailDuplicate, setEmailDuplicate] = useState(['', false])
  const [pictureDuplicate, setPictureDuplicate] = useState(['', false])
  const [programsDuplicate, setProgramsDuplicate] = useState([])
  const [dayliesDuplicate, setDayliesDuplicate] = useState()
  const [weekliesDuplicate, setWeekliesDuplicate] = useState()
  const [picturesDuplicate, setPicturesDuplicate] = useState()
  const [messagesDuplicate, setMessagesDuplicate] = useState()

  const [firstNameOriginal, setFirstNameOriginal] = useState(['', false])
  const [lastNameOriginal, setLastNameOriginal] = useState(['', false])
  const [emailOriginal, setEmailOriginal] = useState(['', false])
  const [pictureOriginal, setPictureOriginal] = useState(['', false])
  const [programsOriginal, setProgramsOriginal] = useState([])
  const [dayliesOriginal, setDayliesOriginal] = useState()
  const [weekliesOriginal, setWeekliesOriginal] = useState()
  const [picturesOriginal, setPicturesOriginal] = useState()
  const [messagesOriginal, setMessagesOriginal] = useState()

  let ListPrograms = []
  let dayliesMerge
  let weekliesMerge
  let picturesMerge
  let messagesMerge

  useEffect(() => {
    updateContactDuplicateForm()
  }, [searchContadDuplicateId])

  useEffect(() => {
    updateContactTargetForm()
  }, [searchContadTargetId])

  const updateContactDuplicateForm = () => {
    if (!!searchContadDuplicateId) {
      if (searchContadDuplicateId === searchContadTargetId) {
        notification.error({
          message: `Enter a user other than ${searchContadDuplicateId}`,
        })
        cleanFormDuplicateContact()
        cleanFormMergeContact()
      } else {
        apolloClient
          .query({
            query: getStudent,
            variables: { contact_id: searchContadDuplicateId },
            fetchPolicy: 'network-only',
          })
          .then((response) => {
            if (response.data) {
              if (!response.data.user[0]) {
                notification.error({
                  message: `We could not find the Contact ID ${searchContadDuplicateId}`,
                })
              }
              validateContactIdMerge()
              cleanFormMergeContact()
              setStudentDuplicate(response.data.user)
            }
          })
          .catch((e) => {
            setError(`${e}`)
            notification.error({
              message: 'An error occurred while searching for the user',
              description: `${error}`,
            })
          })
      }
    }
  }

  const updateContactTargetForm = () => {
    if (!!searchContadTargetId) {
      if (searchContadTargetId === searchContadDuplicateId) {
        notification.error({
          message: `Enter a user other than ${searchContadTargetId}`,
        })
        cleanFormOriginalContact()
        cleanFormMergeContact()
      } else {
        apolloClient
          .query({
            query: getStudent,
            variables: { contact_id: searchContadTargetId },
            fetchPolicy: 'network-only',
          })
          .then((response) => {
            if (response.data) {
              if (!response.data.user[0]) {
                notification.error({
                  message: `We could not find the Contact ID ${searchContadTargetId}`,
                })
              }
              validateContactIdMerge()
              cleanFormMergeContact()
              setStudentTarget(response.data.user)
            }
          })
          .catch((e) => {
            setError(`${e}`)
          })
      }
    }
  }
  // Contact Duplicate
  function onChangefirstNameDuplicate(Value) {
    if (Value.target.checked === true && errorMerge !== true) {
      setFirstNameDuplicate([Value.target.value, true])
      setFirstNameOriginal(['', false])
      form.setFieldsValue({ firstNameMerge: Value.target.value })
    } else {
      setFirstNameDuplicate(['', false])
    }
  }
  function onChangeLastNameDuplicate(Value) {
    if (Value.target.checked === true && errorMerge !== true) {
      setLastNameDuplicate([Value.target.value, true])
      setLastNameOriginal(['', false])
      form.setFieldsValue({ lastNameMerge: Value.target.value })
    } else {
      setLastNameDuplicate(['', false])
    }
  }
  function onChangeEmailDuplicate(Value) {
    if (Value.target.checked === true && errorMerge !== true) {
      setEmailDuplicate([Value.target.value, true])
      setEmailOriginal(['', false])
      form.setFieldsValue({ emailMerge: Value.target.value })
    } else {
      setEmailDuplicate(['', false])
    }
  }
  function onChangePictureDuplicate(Value) {
    if (Value.target.checked === true && errorMerge !== true) {
      setPictureDuplicate([Value.target.value, true])
      setPictureOriginal(['', false])
      form.setFieldsValue({ pictureMerge: Value.target.value })
    } else {
      setPictureDuplicate(['', false])
    }
  }
  function onChangeProgramsDuplicate(Value) {
    if (Value.target.checked === true && errorMerge !== true) {
      setProgramsDuplicate([Value.target.value.programs, true])
      setDayliesDuplicate(Value.target.value.daylies)
      setPicturesDuplicate(Value.target.value.pictures)
      setWeekliesDuplicate(Value.target.value.weeklies)
      setMessagesDuplicate(Value.target.value.messages)
    } else {
      setProgramsDuplicate([])
      setDayliesDuplicate()
      setPicturesDuplicate()
      setWeekliesDuplicate()
      setMessagesDuplicate()
    }
  }

  //Contact Original
  function onChangefirstNameOriginal(Value) {
    if (Value.target.checked === true && errorMerge !== true) {
      setFirstNameOriginal([Value.target.value, true])
      setFirstNameDuplicate(['', false])
      form.setFieldsValue({ firstNameMerge: Value.target.value })
    } else {
      setFirstNameOriginal(['', false])
    }
  }
  function onChangeLastNameOriginal(Value) {
    if (Value.target.checked === true && errorMerge !== true) {
      setLastNameOriginal([Value.target.value, true])
      setLastNameDuplicate(['', false])
      form.setFieldsValue({ lastNameMerge: Value.target.value })
    } else {
      setLastNameOriginal(['', false])
    }
  }
  function onChangeEmailOriginal(Value) {
    if (Value.target.checked === true && errorMerge !== true) {
      setEmailOriginal([Value.target.value, true])
      setEmailDuplicate(['', false])
      form.setFieldsValue({ emailMerge: Value.target.value })
    } else {
      setEmailOriginal(['', false])
    }
  }
  function onChangePictureOriginal(Value) {
    if (Value.target.checked === true && errorMerge !== true) {
      setPictureOriginal([Value.target.value, true])
      setPictureDuplicate(['', false])
      form.setFieldsValue({ pictureMerge: Value.target.value })
    } else {
      setPictureOriginal(['', false])
    }
  }
  function onChangeProgramsOriginal(Value) {
    if (Value.target.checked === true && errorMerge !== true) {
      setProgramsOriginal([Value.target.value.programs, true])
      setDayliesOriginal(Value.target.value.daylies)
      setPicturesOriginal(Value.target.value.pictures)
      setWeekliesOriginal(Value.target.value.weeklies)
      setMessagesOriginal(Value.target.value.messages)
    } else {
      setProgramsOriginal([])
      setDayliesOriginal()
      setPicturesOriginal()
      setWeekliesOriginal()
      setMessagesOriginal()
    }
  }

  const cleanFormMergeContact = () => {
    setProgramsOriginal([])
    setFirstNameOriginal(['', false])
    setEmailOriginal(['', false])
    setLastNameOriginal(['', false])
    setPictureOriginal(['', false])
    setProgramsDuplicate([])
    setFirstNameDuplicate(['', false])
    setEmailDuplicate(['', false])
    setLastNameDuplicate(['', false])
    setPictureDuplicate(['', false])
    form.setFieldsValue({ pictureMerge: '' })
    form.setFieldsValue({ emailMerge: '' })
    form.setFieldsValue({ lastNameMerge: '' })
    form.setFieldsValue({ firstNameMerge: '' })
    setDayliesOriginal()
    setPicturesOriginal()
    setWeekliesOriginal()
    setMessagesOriginal()
    setDayliesDuplicate()
    setPicturesDuplicate()
    setWeekliesDuplicate()
    setMessagesDuplicate()
  }

  const cleanFormDuplicateContact = () => {
    setStudentDuplicate([])
    setSearchContadDuplicateId('')
    form.setFieldsValue({ contactIdMerge: '' })
  }
  const cleanFormOriginalContact = () => {
    setStudentTarget([])
    setSearchContadTargetId('')
    form.setFieldsValue({ contactIdMerge: '' })
  }

  const validateContactIdMerge = () => {
    let contact_id_merge = parseInt(searchContadDuplicateId) > parseInt(searchContadTargetId) ? searchContadTargetId : searchContadDuplicateId
    form.setFieldsValue({ contactIdMerge: contact_id_merge })
  }

  if (programsOriginal[0] === undefined && programsDuplicate[0] !== undefined && errorMerge !== true) {
    ListPrograms = programsDuplicate[0]
    dayliesMerge = dayliesDuplicate
    weekliesMerge = weekliesDuplicate
    picturesMerge = picturesDuplicate
    messagesMerge = messagesDuplicate
    programMergeType = 'Duplicate'
    form.setFieldsValue({ bootcampsMerge: ListPrograms })
  }
  if (programsOriginal[0] !== undefined && programsDuplicate[0] === undefined && errorMerge !== true) {
    ListPrograms = programsOriginal[0]
    dayliesMerge = dayliesOriginal
    weekliesMerge = weekliesOriginal
    picturesMerge = picturesOriginal
    messagesMerge = messagesOriginal
    programMergeType = 'Original'
    form.setFieldsValue({ bootcampsMerge: ListPrograms })
  }
  if (programsOriginal[0] !== undefined && programsDuplicate[0] !== undefined && errorMerge !== true) {
    ListPrograms = programsOriginal[0].concat(programsDuplicate[0])

    const duplicateIds = ListPrograms.map((e) => e.program.program_id)
      .map((e, i, final) => final.indexOf(e) !== i && i)
      .filter((obj) => ListPrograms[obj])
      .map((e) => ListPrograms[e].program.program_id)

    if (duplicateIds.length === 0) {
      programMergeType = 'OriginalAndDuplicate'
      form.setFieldsValue({ bootcampsMerge: ListPrograms })
      dayliesMerge = parseInt(dayliesDuplicate) + parseInt(dayliesOriginal)
      weekliesMerge = parseInt(weekliesDuplicate) + parseInt(weekliesOriginal)
      picturesMerge = parseInt(picturesDuplicate) + parseInt(picturesOriginal)
      messagesMerge = parseInt(messagesDuplicate) + parseInt(messagesOriginal)
    } else {
      errorMerge = true
      ListPrograms = []
    }
  }

  const FormMerge = () => {
    const ValidationFormMerge = (form) => {
      form
        .validateFields()
        .then((values) => {
          let programsMerge = values.bootcampsMerge
          let contactMerge

          if (values.contactIdMerge !== undefined) {
            contactMerge = values.contactIdMerge
          } else {
            if (searchContadTargetId < searchContadDuplicateId) {
              contactMerge = searchContadTargetId
            } else {
              contactMerge = searchContadDuplicateId
            }
          }
          let programsList = []

          programsMerge.forEach((p) => {
            programsList.push({
              program_id: p.program.program_id,
              description: p.program.description,
            })
          })

          if (programMergeType !== 'OriginalAndDuplicate') {
            if (parseInt(searchContadTargetId) > parseInt(searchContadDuplicateId)) {
              if (programMergeType === 'Original') {
                programMergeType = 'Duplicate'
              } else if (programMergeType === 'Duplicate') {
                programMergeType = 'Original'
              }
            }
          }

          apolloClient
            .mutate({
              mutation: createContactMerge,
              variables: {
                contactId: contactMerge,
                picture: values.pictureMerge,
                firstName: values.firstNameMerge,
                lastName: values.lastNameMerge,
                email: values.emailMerge,
                programs: programsList,
                programsMergeType: programMergeType,
                createBy: localStorage.getItem(LOCAL_STORAGE.USER_NICKNAME),
                createDate: 'now()',
                contactOriginal: parseInt(searchContadTargetId) < parseInt(searchContadDuplicateId) ? studentTarget[0] : studentDuplicate[0],
                contactDuplicate: parseInt(searchContadTargetId) < parseInt(searchContadDuplicateId) ? studentDuplicate[0] : studentTarget[0],
              },
            })
            .then((data) => {
              let MessageValidation = data.data.insert_merge_contact.returning[0]

              if (MessageValidation.status === 'FAILED') {
                notification.error({ message: 'An error occurred while saving the user.', description: `${MessageValidation.status_message}` })
              } else if (MessageValidation.status === 'SUCCESS') {
                notification.success({ message: 'The merger was successful' })
                cleanFormDuplicateContact()
                cleanFormOriginalContact()
                cleanFormMergeContact()
              }
            })
            .catch((error) => {
              console.warn(error)
              notification.error({ message: 'An error occurred while saving the user.', description: `${error}` })
            })
          setVisible(false)
        })
        .catch((e) => {
          console.log('error :>> ', e)
          let validations = e.errorFields.map((field) => field.errors.join('<br />')).join('<br />')
          notification.error({
            message: 'Please complete all the required fields.',
            description: <div dangerouslySetInnerHTML={{ __html: validations }}></div>,
          })
          setVisible(false)
        })
    }

    const SubmitMergeContact = () => {
      const showPopconfirm = () => {
        setVisible(true)
      }
      const handleCancel = () => {
        setVisible(false)
      }

      return (
        <Popconfirm
          overlayClassName={'Popconfirm-center-button'}
          title={
            <div>
              <p className="uppertext">Are you sure want to merge this student?</p>
              <small>
                <p className="bottomtext">This action is irreversible.</p>
              </small>
            </div>
          }
          visible={visible}
          onConfirm={() => ValidationFormMerge(form)}
          onCancel={handleCancel}
          okText="Confirm"
          cancelText="Cancel"
        >
          <Button onClick={showPopconfirm} type="primary">
            <span>Merge Contact</span>
          </Button>
        </Popconfirm>
      )
    }
    return (
      <Col span={8}>
        <Form autoComplete="off" form={form} onFinish={() => ValidationFormMerge(form)}>
          <span className="label">
            Merged
            <Tooltip title={<span style={{ fontSize: '10px' }}>We suggest keep the lower Contact ID, but you can edit anyway</span>}>
              <InfoCircleOutlined />
            </Tooltip>
          </span>

          <p className="label">Contact ID</p>
          <FormItem name="contactIdMerge">
            <Input type="number" />
          </FormItem>

          <p className="label" style={{ marginTop: -20 }}>
            Picture
          </p>
          <FormItem name="pictureMerge">
            <Avatar size="large" src={pictureOriginal[0] ? pictureOriginal[0] : pictureDuplicate[0]} />
          </FormItem>

          <p className="label" style={{ marginTop: -20 }}>
            First Name
          </p>
          <FormItem
            name="firstNameMerge"
            rules={[
              {
                required: true,
                message: 'The first name is required.',
              },
            ]}
          >
            <Input type="text" value={firstNameOriginal[0] ? firstNameOriginal[0] : firstNameDuplicate[0]} />
          </FormItem>

          <p className="label" style={{ marginTop: -20 }}>
            Last Name
          </p>
          <FormItem
            name="lastNameMerge"
            rules={[
              {
                required: true,
                message: 'The last name is required..',
              },
            ]}
          >
            <Input type="text" />
          </FormItem>

          <p className="label" style={{ marginTop: -20 }}>
            Email
          </p>
          <FormItem
            name="emailMerge"
            rules={[
              {
                required: true,
                message: 'The email is required.',
              },
            ]}
          >
            <Input type="text" disabled={true} />
          </FormItem>

          <div style={{ fontSize: 15 }}>
            <p className="label" style={{ marginTop: -20 }}>
              <b>Bootcamp data</b>
            </p>
            <p className="label">
              <b>{dayliesMerge != null ? dayliesMerge : 0}</b> Daylies
            </p>
            <p className="label">
              <b>{picturesMerge != null ? picturesMerge : 0}</b> Pictures
            </p>
            <p className="label">
              <b>{weekliesMerge != null ? weekliesMerge : 0}</b> Weeklies
            </p>
            <p className="label">
              <b>{messagesMerge != null ? messagesMerge : 0}</b> Messages
            </p>
          </div>

          <FormItem
            name="bootcampsMerge"
            rules={[
              {
                required: true,
                message: 'The bootcamps are required.',
              },
            ]}
          >
            <List
              size="small"
              header={<div>Bootcamps</div>}
              style={{ minHeight: '340px' }}
              bordered
              itemLayout="vertical"
              pagination={{
                pageSize: 6,
              }}
              dataSource={ListPrograms}
              renderItem={(item) => (
                <List.Item>
                  {item.program.description} - {item.is_active ? 'Active' : 'Blocked'}
                </List.Item>
              )}
            />
          </FormItem>
          {errorMerge === true && (
            <Alert
              message="We can not merge these students"
              description="There is a conflict with some bootcamps, exists on both student data. Please contact to the Developers team."
              type="warning"
              showIcon
            />
          )}
          {studentDuplicate[0] !== undefined && studentTarget[0] !== undefined && ListPrograms[0] !== undefined && errorMerge !== true && (
            <div className="actions">
              <SubmitMergeContact />
            </div>
          )}
        </Form>
      </Col>
    )
  }

  return (
    <Layout>
      <div className="MainContainer">
        <p className="title">Merge Contact</p>
        <p>This section allows you to merge the information between two student accounts</p>
        <p>
          <Tag color="warning">Note! After merge the contact, please make sure the status of the contact in gainspot.</Tag>
        </p>
        <hr />
        <Row gutter={24} className="row">
          <Col span={8}>
            <span className="label">Original</span>

            <div className="filters">
              <p className="label">Enter Contact ID</p>
              <Search placeholder="Contad id Target" type="number" allowClear={true} onSearch={(value) => setSearchContadTargetId(value)} />
            </div>

            {studentTarget.map((s) => {
              let programs = s.trainee.selected
              let daylies = s.trainee.macro_aggregate.aggregate.count
              let pictures = s.trainee.pictures_aggregate.aggregate.count
              let weeklies = s.trainee.measurement_aggregate.aggregate.count
              let messages = s.sent_messages_aggregate.aggregate.count
              return (
                <>
                  <p className="label">Picture</p>
                  <FormItem name="pictureOriginal">
                    <Row>
                      <Col lg={4}>
                        <Avatar size="large" src={s.picture} />
                      </Col>
                      <Col style={{ marginLeft: 2 }}>
                        <Checkbox value={s.picture} checked={pictureOriginal[1]} onChange={onChangePictureOriginal}></Checkbox>
                      </Col>
                    </Row>
                  </FormItem>

                  <p className="label" style={{ marginTop: -15 }}>
                    First name
                  </p>
                  <FormItem name="firstNameOriginal">
                    <Row>
                      <Col lg={20}>
                        <Input key={1} type="string" disabled={true} placeholder={'First Name'} value={s.first_name} defaultValue={s.first_name} />
                      </Col>
                      <Col span={2} style={{ marginLeft: 2 }}>
                        <Checkbox value={s.first_name} checked={firstNameOriginal[1]} onChange={onChangefirstNameOriginal}></Checkbox>
                      </Col>
                    </Row>
                  </FormItem>

                  <p className="label" style={{ marginTop: -20 }}>
                    Last name
                  </p>
                  <FormItem name="lastNameOriginal">
                    <Row>
                      <Col lg={20}>
                        <Input
                          key={2}
                          type="string"
                          disabled={true}
                          value={s.last_name}
                          defaultValue={s.last_name ? s.last_name : '---'}
                          placeholder={'Last Name'}
                        />
                      </Col>
                      <Col span={2} style={{ marginLeft: 2 }}>
                        <Checkbox value={s.last_name} checked={lastNameOriginal[1]} onChange={onChangeLastNameOriginal}></Checkbox>
                      </Col>
                    </Row>
                  </FormItem>

                  <p className="label" style={{ marginTop: -20 }}>
                    Email
                  </p>
                  <FormItem name="emailOriginal">
                    <Row>
                      <Col lg={20}>
                        <Input key={3} type="string" disabled={true} value={s.email} defaultValue={s.email ? s.email : '---'} placeholder={'Email'} />
                      </Col>
                      <Col span={2} style={{ marginLeft: 2 }}>
                        <Checkbox value={s.email} checked={emailOriginal[1]} onChange={onChangeEmailOriginal}></Checkbox>
                      </Col>
                    </Row>
                  </FormItem>
                  <div style={{ fontSize: 15 }}>
                    <p className="label" style={{ marginTop: -20 }}>
                      <b>Bootcamp data</b>{' '}
                      <Checkbox
                        value={{ programs, daylies, pictures, weeklies, messages }}
                        checked={programsOriginal[1]}
                        onChange={onChangeProgramsOriginal}
                      ></Checkbox>
                    </p>
                    <p className="label">
                      <b>{daylies}</b> Daylies
                    </p>
                    <p className="label">
                      <b>{pictures}</b> Pictures
                    </p>
                    <p className="label">
                      <b>{weeklies}</b> Weeklies
                    </p>
                    <p className="label">
                      <b>{messages}</b> Messages
                    </p>
                  </div>
                  <FormItem name="bootcampsOriginal">
                    <List
                      size="small"
                      header={
                        <Row>
                          <Col>
                            <div>Bootcamps</div>
                          </Col>
                        </Row>
                      }
                      style={{ minHeight: '340px' }}
                      bordered
                      dataSource={programs}
                      itemLayout="vertical"
                      pagination={{
                        pageSize: 6,
                      }}
                      renderItem={(item) => (
                        <List.Item>
                          {item.program.description} - {item.is_active ? 'Active' : 'Blocked'}
                        </List.Item>
                      )}
                    />
                  </FormItem>
                </>
              )
            })}
          </Col>
          <Col span={8}>
            <span className="label">Duplicate</span>

            <div className="filters">
              <p className="label">Enter Contact ID</p>
              <Search placeholder="Contact id Duplicate" type="number" allowClear={true} onSearch={(value) => setSearchContadDuplicateId(value)} />
            </div>

            {studentDuplicate.map((s) => {
              let programs = s.trainee.selected
              let daylies = s.trainee.macro_aggregate.aggregate.count
              let pictures = s.trainee.pictures_aggregate.aggregate.count
              let weeklies = s.trainee.measurement_aggregate.aggregate.count
              let messages = s.sent_messages_aggregate.aggregate.count
              return (
                <>
                  <p className="label">Picture</p>
                  <FormItem name="pictureDuplicate">
                    <Row>
                      <Col lg={4}>
                        <Avatar size="large" src={s.picture} />
                      </Col>
                      <Col style={{ marginLeft: 2 }}>
                        <Checkbox value={s.picture} checked={pictureDuplicate[1]} onChange={onChangePictureDuplicate}></Checkbox>
                      </Col>
                    </Row>
                  </FormItem>

                  <p className="label" style={{ marginTop: -15 }}>
                    First name
                  </p>
                  <FormItem name="firstNameDuplicate">
                    <Row>
                      <Col lg={20}>
                        <Input key={1} disabled={true} type="string" placeholder={'First Name'} value={s.first_name} defaultValue={s.first_name} />
                      </Col>
                      <Col span={2} style={{ marginLeft: 2 }}>
                        <Checkbox value={s.first_name} checked={firstNameDuplicate[1]} onChange={onChangefirstNameDuplicate}></Checkbox>
                      </Col>
                    </Row>
                  </FormItem>

                  <p className="label" style={{ marginTop: -20 }}>
                    Last name
                  </p>
                  <FormItem name="lastNameDuplicate">
                    <Row>
                      <Col lg={20}>
                        <Input
                          key={2}
                          disabled={true}
                          type="string"
                          value={s.last_name}
                          defaultValue={s.last_name ? s.last_name : '---'}
                          placeholder={'Last Name'}
                        />
                      </Col>
                      <Col span={2} style={{ marginLeft: 2 }}>
                        <Checkbox value={s.last_name} checked={lastNameDuplicate[1]} onChange={onChangeLastNameDuplicate}></Checkbox>
                      </Col>
                    </Row>
                  </FormItem>

                  <p className="label" style={{ marginTop: -20 }}>
                    Email
                  </p>
                  <FormItem name="emailDuplicate">
                    <Row>
                      <Col lg={20}>
                        <Input key={3} disabled={true} type="string" value={s.email} defaultValue={s.email ? s.email : '---'} placeholder={'Email'} />
                      </Col>
                      <Col span={2} style={{ marginLeft: 2 }}>
                        <Checkbox value={s.email} checked={emailDuplicate[1]} onChange={onChangeEmailDuplicate}></Checkbox>
                      </Col>
                    </Row>
                  </FormItem>
                  <div style={{ fontSize: 15 }}>
                    <p className="label" style={{ marginTop: -20 }}>
                      <b>Bootcamp data</b>{' '}
                      <Checkbox
                        value={{ programs, daylies, pictures, weeklies, messages }}
                        checked={programsDuplicate[1]}
                        onChange={onChangeProgramsDuplicate}
                      ></Checkbox>
                    </p>
                    <p className="label">
                      <b>{daylies}</b> Daylies
                    </p>
                    <p className="label">
                      <b>{pictures}</b> Pictures
                    </p>
                    <p className="label">
                      <b>{weeklies}</b> Weeklies
                    </p>
                    <p className="label">
                      <b>{messages}</b> Messages
                    </p>
                  </div>
                  <FormItem name="bootcampsDuplicate">
                    <List
                      size="small"
                      header={
                        <Row>
                          <Col>
                            <div>Bootcamps</div>
                          </Col>
                        </Row>
                      }
                      style={{ minHeight: '340px' }}
                      itemLayout="vertical"
                      pagination={{
                        pageSize: 6,
                      }}
                      bordered
                      dataSource={programs}
                      renderItem={(item) => (
                        <List.Item>
                          {item.program.description} - {item.is_active ? 'Active' : 'Blocked'}
                        </List.Item>
                      )}
                    />
                  </FormItem>
                </>
              )
            })}
          </Col>
          <FormMerge />
        </Row>
      </div>
    </Layout>
  )
}

export default MergeContact
