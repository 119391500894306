import styled from '@emotion/styled'

export const Step2Container = styled.div`
  margin: 0px 22px;
  height: inherit;
  .ant-row:nth-child(2) {
    height: inherit;
    .ant-col {
      height: inherit;
    }
  }
  p {
    color: #959595;
    font-weight: 500;
    font-size: 12px;
  }
  p {
    margin-bottom: 18px;
  }

  .site-drawer-render-in-current-wrapper {
    position: relative;
    overflow: hidden;
    p {
      margin-top: 10px;
    }
    .ant-drawer-content-wrapper {
      width: 350px;
      .filter-exercises {
        span {
          color: #5d5d5d;
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
        }
      }
    }
  }

  .ant-input-group-wrapper {
    .ant-input-affix-wrapper {
      border: 1px solid #b9b9b9;
      border-radius: 3px;
    }

    .ant-input-group-addon {
      background: transparent;
      border: none;
      padding-right: 0px;

      span {
        background: #ffffff;
        box-shadow: 0px 0px 3px rgba(176, 176, 176, 0.567035);
        border-radius: 2px;
        font-size: 20px;
        color: #ff6b00;
        width: 32px;
        height: 32px;
        text-align: center;
        padding: 6px;

        &:first-of-type {
          margin-right: 8px;
        }
      }
    }
  }

  .cards-wrapper {
    padding: 15px;
    border-left: 2px solid #f2f2f2;
    overflow-y: auto;
    height: 430px;
  }
  @media (min-height: 1000px) {
    .cards-wrapper {
      height: calc(100% - 88px);
    }
    .ant-row:nth-child(2) {
      .ant-col:nth-child(1) {
        .show-scrollbars {
          height: ${(props) => (props.exerciseNumber > 12 ? `calc(100% - 105px)` : null)};
        }
      }
    }
  }

  .step-action {
    position: absolute;
    bottom: 12px;
    width: 100%;
    text-align: right;
    right: 19px;

    .ant-btn {
      width: 148px;
      height: 35px;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      font-size: 20px;
      line-height: 24px;
      font-weight: 600;
    }
    .ant-btn.btn-cancel {
      margin-right: 22px;
    }
    hr {
      margin-left: 19px;
      margin-right: -19px;
    }
  }
`

export const ExerciseListContainer = styled.div`
  border-right: 2px solid #f2f2f2;
  height: 430px;
  overflow-y: scroll;

  .ant-list {
    .ant-list-items {
      padding-right: 10px;

      .ant-list-item {
        border: 0;
        padding: 0;

        .ant-collapse {
          width: 100%;
          border: none;
          background: #ffffff;

          .ant-collapse-item {
            border: none;
            filter: drop-shadow(2px 4px 6px rgba(0, 0, 0, 0.25));
          }

          .ant-collapse-header {
            display: flex;
            align-items: center;
            border-radius: 3px 3px 0px 0px;
            .e-name {
              margin-left: 20px;
            }
            .ant-collapse-arrow {
              top: 40%;
            }
          }
        }

        &:nth-of-type(odd) {
          .ant-collapse {
            background: #f4f2f2;
          }
        }
      }
    }
  }
`

export const PreviewContainer = styled.div`
  width: 100%;
  background: #ffffff;
  hr {
    border-color: #cfcfcf;
  }
  .header {
    display: flex;
    img {
      height: 15px;
    }

    img.picture {
      border-radius: 4px;
      width: 72px;
      height: 64px;
      margin-right: 15px;
    }

    .wrap-content {
      span {
        display: block;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #5d5d5d;
        margin-bottom: 10px;
      }
      .ctn-info {
        display: flex;
        align-items: center;
        margin-bottom: 5px;
        height: 18px;
        p {
          color: #4a4a4a;
          font-weight: 500;
          font-size: 12px;
          line-height: 10px;
          margin-left: 5px;
          margin-bottom: 0px;
        }
      }
    }
  }

  .video {
    background: linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%);
    min-height: 120px;

    img {
      width: 100%;
      border-radius: 2px 2px 0px 0px;
    }
  }

  .content {
    padding: 11px 9px 20px;

    .title {
      color: #4a4a4a;
      font-weight: bold;
      font-size: 21px;
    }

    .equipment-muscle {
      border-top: 1px solid #cfcfcf;
      border-bottom: 1px solid #cfcfcf;
      display: grid;
      grid-template-columns: 50% 50%;
      margin: 13px 0px 10px;
      padding: 11px 10px;

      div.item {
        display: grid;
        grid-template-columns: 27px 1fr;
        grid-gap: 5px;

        p {
          font-weight: 500;
          font-size: 11px;
          margin-bottom: 3px;
          line-height: 11px;
          color: #4a4a4a;

          b {
            font-weight: 600;
            font-size: 13px;
          }
        }
      }
    }
  }
  .description {
    max-height: 250px;
    overflow-y: auto;

    .title {
      font-weight: 600;
      line-height: 16px;
      font-size: 13px;
      color: #4a4a4a;
    }
    div {
      margin-bottom: 10px;
      ol li {
        font-weight: normal;
        font-size: 13px;
        line-height: 151.6%;
        color: #4a4a4a;
      }
    }
  }
`
