import React, { useEffect, useState, useMemo, useReducer } from 'react'
import Layout from 'components/Layout'
import { MainContainer, DrawerButtonsContainer } from './ExercisesCatalog.styled'
import { Button, Drawer, Input, notification, Progress, Modal } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { apiServices } from 'services/api'
import PreviewExercise from './PreviewExercise'
import ExerciseForm from './ExerciseForm'
import ExerciseTable from './ExerciseTable'
import { filterExercises } from './Uses'
import uploadVideo from 'services/vimeo'

const catalogsToGet = ['techniques', 'exercises', 'muscle', 'gym_equipment']
export const actions = { create: 'create', update: 'update', delete: 'delete' }
const exerciseCreateDefault = {
  alternatives: [],
  description: '',
  created_date: '',
  execution: '',
  gif_url: '',
  gym_equipments: [],
  muscles: [],
  name: '',
  preparation: '',
  video_url: '',
  thumbnails: { start: '', finish: '' },
}

const catalogReducer = (currentState, action) => {
  switch (action.type) {
    case 'FIRSTCHARGE':
      return {
        ...currentState,
        allExercises: action.allExercises,
        loading: false,
        musclesCatalog: action.musclesCatalog,
        gymEquipmentsCatalog: action.gymEquipmentsCatalog,
        exerciseFilter: { name: '', order: 'asc' },
      }
    case 'FINISHLOADING':
      return { ...currentState, loading: false }
    case 'FILTER':
      return { ...currentState, exerciseFilter: action.exerciseFilter }
    case 'ADDEXERCISE':
      return {
        ...currentState,
        allExercises: action.allExercises,
        exerciseFilter: action.exerciseFilter,
        selectedExercise: action.selectedExercise,
        previewExerciseVisible: true,
        newExerciseVisible: false,
      }
    case 'UPDATEEXERCISE':
      return {
        ...currentState,
        allExercises: action.allExercises,
        exerciseFilter: action.exerciseFilter,
        selectedExercise: action.selectedExercise,
        previewExerciseVisible: true,
        newExerciseVisible: false,
        editExerciseVisible: false,
      }
    case 'SETNEWEXERCISE':
      return { ...currentState, newExerciseVisible: action.newExerciseVisible, selectedExercise: action.selectedExercise }
    case 'SETEXERCISETOEDIT':
      return { ...currentState, editExerciseVisible: action.editExerciseVisible, selectedExercise: action.selectedExercise }
    case 'SETEXERCISETOPREVIEW':
      return { ...currentState, previewExerciseVisible: action.previewExerciseVisible, selectedExercise: action.selectedExercise }
    case 'SETSELECTEDEXERCISE':
      return { ...currentState, selectedExercise: action.selectedExercise }
    case 'ADDIMAGETODELETE':
      const currentImages = [...currentState.imagesToDeleteUploadCare]
      currentImages.push(action.image)
      if (!action.image) return { ...currentState }
      return { ...currentState, imagesToDeleteUploadCare: currentImages }
    default:
      break
  }
}
const showWarning = (message) => {
  notification.warning({ message: message })
  return false
}
const isExerciseCompleted = (exercise) => {
  if (!exercise.name) return showWarning('You have to enter a name for the exercise')
  if (!!!exercise.preparation || exercise.preparation.replace(/<(.|\n)*?>/g, '').trim().length === 0)
    return showWarning('You have to enter the preparation for the exercise')
  if (!!!exercise.execution || exercise.execution.replace(/<(.|\n)*?>/g, '').trim().length === 0)
    return showWarning('You have to enter the execution for the exercise')
  if (exercise.muscles.length === 0) return showWarning('You have to select the muscles for the exercise')
  if (exercise.gym_equipments.length === 0) return showWarning('You have to select the equipment for the exercise')
  if (!exercise.gif_url) return showWarning('You have to upload a gif for the exercise')
  return true
}
const getUuidFromUrlImage = (image) => {
  if (!image) return ''
  return image.replace('https://ucarecdn.com/', '').split('/')[0]
}
const getGifUrlWithExerciseName = (gifUrl, exerciseName) => {
  if (!gifUrl || !exerciseName) return ''
  const baseUrl = gifUrl.slice(0, gifUrl.indexOf('/', 21))
  const name = exerciseName.replaceAll(' ', '').replaceAll('/', '')
  return `${baseUrl}/${name}.gif`
}
const formatThumbnails = (thumbnails) => {
  const thumbnailsArr = []
  if (thumbnails.start) thumbnailsArr.push({ is_recomended: true, order: 1, size: 'SMALL', url: thumbnails.start })
  else if (thumbnails.finish) thumbnailsArr.push({ is_recomended: true, order: 1, size: 'SMALL', url: thumbnails.finish })
  if (thumbnails.start && thumbnails.finish) thumbnailsArr.push({ is_recomended: false, order: 2, size: 'SMALL', url: thumbnails.finish })
  return thumbnailsArr
}
const getMuscleIdByName = (name, musclesCatalog) => musclesCatalog.find((m) => m.name === name).id
const getGymEquipmentNameById = (id, gymEquipmentsCatalog) => gymEquipmentsCatalog.find((e) => e.id === id).name
const getMuscleNameById = (id, musclesCatalog) => musclesCatalog.find((m) => m.id === id).name
const formatMuscles = (muscles, musclesCatalog) => {
  const musclesArr = muscles.map((muscle) => {
    return { muscle_id: getMuscleIdByName(muscle, musclesCatalog), movement_classification: '' }
  })
  return { data: musclesArr }
}
const formatGymEquipments = (gymEquipments, equipmentCatalog) => {
  const equipmentArr = gymEquipments.map((eq) => {
    return { gym_equipment_id: equipmentCatalog.find((equipment) => equipment.name === eq.name).id, quantity: eq.quantity }
  })
  return { data: equipmentArr }
}
const formatAlternatives = (alternatives) => {
  //Needs to be implemented
  return { data: alternatives }
}
const formatExercise = (exercise, musclesCatalog, gymEquipmentsCatalog) => {
  const thumbnailStart = exercise.thumbnails.length > 0 ? exercise.thumbnails[0].url : ''
  const thumbnailFinish = exercise.thumbnails.length > 1 ? exercise.thumbnails[1].url : ''
  return {
    ...exercise,
    thumbnails: { start: thumbnailStart, finish: thumbnailFinish },
    muscles: exercise.muscles.map((muscleId) => {
      return musclesCatalog.find((m) => m.id === muscleId).name
    }),
    gym_equipments: exercise.gym_equipments.map((eq) => {
      return { name: gymEquipmentsCatalog.find((equipment) => equipment.id === eq.gym_equipment_id).name, quantity: eq.quantity }
    }),
  }
}
const getRelationalPropsUpdates = (actualE, newE, musclesCatalog, gymEquipmentsCatalog) => {
  let result = {}
  if (actualE.muscles.length === newE.muscles.length) {
    for (const muscle of actualE.muscles) {
      if (!newE.muscles.find((m) => m === getMuscleNameById(muscle, musclesCatalog))) {
        result = { ...result, muscles: formatMuscles(newE.muscles, musclesCatalog) }
        break
      }
    }
  } else result = { ...result, muscles: formatMuscles(newE.muscles, musclesCatalog) }

  if (actualE.gym_equipments.length === newE.gym_equipments.length) {
    for (const equipment of actualE.gym_equipments) {
      if (
        !newE.gym_equipments.find(
          (eq) => eq.name === getGymEquipmentNameById(equipment.gym_equipment_id, gymEquipmentsCatalog) && eq.quantity === equipment.quantity,
        )
      ) {
        result = { ...result, gym_equipments: formatGymEquipments(newE.gym_equipments, gymEquipmentsCatalog) }
        break
      }
    }
  } else result = { ...result, gym_equipments: formatGymEquipments(newE.gym_equipments, gymEquipmentsCatalog) }

  if (actualE.alternatives.length === newE.alternatives.length) {
    for (const alternative of actualE.alternatives) {
      if (!newE.alternatives.find((al) => al.exercise_alternative_id === alternative.exercise_alternative_id && al.level === alternative.level)) {
        result = { ...result, alternatives: formatAlternatives(newE.alternatives) }
        break
      }
    }
  } else result = { ...result, alternatives: formatAlternatives(newE.alternatives) }
  return result
}
const isExerciseEqual = (actualE, newE) => {
  if (
    actualE.name === newE.name &&
    actualE.description === newE.description &&
    actualE.execution === newE.execution &&
    actualE.preparation === newE.preparation &&
    actualE.gif_url === newE.gif_url &&
    actualE.video_url === newE.video_url &&
    actualE.thumbnails === newE.thumbnails
  )
    return true
  return false
}

export default (props) => {
  const [catalogService, dispatch] = useReducer(catalogReducer, {
    allExercises: [],
    loading: true,
    musclesCatalog: [],
    gymEquipmentsCatalog: [],
    exerciseFilter: { name: '', order: '' },
    previewExerciseVisible: false,
    editExerciseVisible: false,
    newExerciseVisible: false,
    selectedExercise: '',
    imagesToDeleteUploadCare: [],
  })
  const [exercises, setExercises] = useState([])
  const [video, setVideo] = useState({ progress: '', size: '', file: '' })
  const [isSaving, setIsSaving] = useState(false)

  useMemo(() => {
    setExercises(filterExercises({ allExercises: catalogService.allExercises, exerciseFilter: catalogService.exerciseFilter }))
  }, [catalogService.exerciseFilter, catalogService.allExercises])

  const setExercise = (exercise, method) => {
    if (method === 'SETEXERCISETOEDIT')
      dispatch({
        type: method,
        editExerciseVisible: true,
        selectedExercise: formatExercise(exercise, catalogService.musclesCatalog, catalogService.gymEquipmentsCatalog),
      })
    else if (method === 'SETEXERCISETOPREVIEW')
      dispatch({
        type: method,
        previewExerciseVisible: true,
        selectedExercise: formatExercise(exercise, catalogService.musclesCatalog, catalogService.gymEquipmentsCatalog),
      })
  }
  useEffect(async () => {
    const catalogs = await apiServices('GET', `catalogs?ids=${catalogsToGet.join(',')}`)
      .then((response) => response.json())
      .then((data) => data)
      .catch((error) => {
        return { hasError: true, message: `${error}` }
      })
    if (!catalogs.hasError) {
      let allExercises = []
      allExercises = catalogs.exercises.map((e) => {
        return { ...e, key: e.id }
      })
      dispatch({
        type: 'FIRSTCHARGE',
        allExercises,
        musclesCatalog: catalogs.muscles,
        gymEquipmentsCatalog: catalogs.gym_equipments,
      })
    } else dispatch({ type: 'FINISHLOADING' })
  }, [])

  const filterExercisesByName = (e) => dispatch({ type: 'FILTER', exerciseFilter: { ...catalogService.exerciseFilter, name: e.target.value } })
  const saveExercise = async (e) => {
    e.preventDefault()
    if (!isExerciseCompleted(catalogService.selectedExercise)) return
    setIsSaving(true)
    let videoUrl = video.file ? await uploadVideo(catalogService.selectedExercise.name, video.size, video.file, uploadVideoProgress) : ''
    if (videoUrl && videoUrl.hasError) {
      notification.error({
        message: `Error while trying to upload the video`,
        description: `Error from Vimeo ${videoUrl.message}`,
      })
      setIsSaving(false)
      return
    }
    const gif = getGifUrlWithExerciseName(catalogService.selectedExercise.gif_url, catalogService.selectedExercise.name)
    if (catalogService.newExerciseVisible) {
      //Create exercise
      const exercise = {
        name: catalogService.selectedExercise.name,
        description:
          !!!catalogService.selectedExercise.description || catalogService.selectedExercise.description.replace(/<(.|\n)*?>/g, '').trim().length === 0
            ? ''
            : catalogService.selectedExercise.description,
        execution: catalogService.selectedExercise.execution,
        preparation: catalogService.selectedExercise.preparation,
        gif_url: gif,
        video_url: videoUrl ? videoUrl : catalogService.selectedExercise.video_url,
        movement: '',
        movement_detail: '',
        utility: '',
        intensity: '',
        category: '',
        subcategory: '',
        external_url: '',
        body_part: '',
        thumbnails: formatThumbnails(catalogService.selectedExercise.thumbnails),
        muscles: formatMuscles(catalogService.selectedExercise.muscles, catalogService.musclesCatalog),
        gym_equipments: formatGymEquipments(catalogService.selectedExercise.gym_equipments, catalogService.gymEquipmentsCatalog),
        alternatives: formatAlternatives(catalogService.selectedExercise.alternatives),
        imagesToDeleteUploadCare: catalogService.imagesToDeleteUploadCare,
      }
      const id = await apiServices('POST', `exercises`, exercise)
        .then((response) => response.json())
        .then((data) => data)
        .catch((error) => {
          return { hasError: true, message: `${error}` }
        })
      if (!id.hasError) {
        setVideo({ progress: '', size: '', file: '' })
        notification.success({ message: `Exercise ${exercise.name} created successfully!` })
        const newExercise = {
          id: id.id,
          name: exercise.name,
          description: exercise.description,
          preparation: exercise.preparation,
          execution: exercise.execution,
          thumbnails: exercise.thumbnails,
          video_url: exercise.video_url,
          gif_url: exercise.gif_url,
          muscles: catalogService.selectedExercise.muscles.map((muscleName) => {
            return getMuscleIdByName(muscleName, catalogService.musclesCatalog)
          }),
          gym_equipments: exercise.gym_equipments.data,
          alternatives: exercise.alternatives.data,
        }
        const allExercises = [...catalogService.allExercises]
        allExercises.push(newExercise)
        dispatch({
          type: 'ADDEXERCISE',
          allExercises,
          exerciseFilter: catalogService.exerciseFilter,
          selectedExercise: formatExercise(newExercise, catalogService.musclesCatalog, catalogService.gymEquipmentsCatalog),
        })
      } else {
        if (videoUrl) {
          dispatch({ type: 'SETSELECTEDEXERCISE', selectedExercise: { ...catalogService.selectedExercise, video_url: videoUrl } })
          setVideo({ progress: '', size: '', file: '' })
        }
        notification.error({
          message: `Error while trying create the exercise`,
          description: `Error from DB ${id.message}`,
        })
        setIsSaving(false)
      }
    } else if (catalogService.editExerciseVisible) {
      //Update exercise
      const actualExercise = catalogService.allExercises.find((ex) => ex.id === catalogService.selectedExercise.id)
      if (!actualExercise) {
        setIsSaving(false)
        return
      }
      if (isExerciseEqual(actualExercise, catalogService.selectedExercise)) {
        setIsSaving(false)
        return
      }
      const addRelationalProps = getRelationalPropsUpdates(
        actualExercise,
        catalogService.selectedExercise,
        catalogService.musclesCatalog,
        catalogService.gymEquipmentsCatalog,
      )
      const exercise = {
        ...addRelationalProps,
        id: catalogService.selectedExercise.id,
        name: catalogService.selectedExercise.name,
        description:
          !!!catalogService.selectedExercise.description || catalogService.selectedExercise.description.replace(/<(.|\n)*?>/g, '').trim().length === 0
            ? ''
            : catalogService.selectedExercise.description,
        execution: catalogService.selectedExercise.execution,
        preparation: catalogService.selectedExercise.preparation,
        gif_url: gif,
        video_url: videoUrl ? videoUrl : catalogService.selectedExercise.video_url,
        movement: '',
        movement_detail: '',
        utility: '',
        intensity: '',
        category: '',
        subcategory: '',
        external_url: '',
        body_part: '',
        thumbnails: formatThumbnails(catalogService.selectedExercise.thumbnails),
        imagesToDeleteUploadCare: catalogService.imagesToDeleteUploadCare,
      }
      const result = await apiServices('PUT', `exercises`, exercise)
        .then((response) => response.json())
        .then((data) => data)
        .catch((error) => {
          return { hasError: true, message: `${error}` }
        })
      if (!result.hasError) {
        setVideo({ progress: '', size: '', file: '' })
        notification.success({ message: `Exercise ${exercise.name} updated successfully!` })
        delete exercise.imagesToDeleteUploadCare
        const updatedExercise = {
          ...exercise,
          muscles: catalogService.selectedExercise.muscles.map((muscleName) => {
            return getMuscleIdByName(muscleName, catalogService.musclesCatalog)
          }),
          gym_equipments: formatGymEquipments(catalogService.selectedExercise.gym_equipments, catalogService.gymEquipmentsCatalog).data,
          alternatives: formatAlternatives(catalogService.selectedExercise.alternatives).data,
        }
        const allExercises = [...catalogService.allExercises]
        const updateIndex = allExercises.findIndex((ex) => ex.id === updatedExercise.id)
        allExercises[updateIndex] = { ...updatedExercise }
        dispatch({
          type: 'UPDATEEXERCISE',
          allExercises: allExercises,
          exerciseFilter: catalogService.exerciseFilter,
          selectedExercise: formatExercise(updatedExercise, catalogService.musclesCatalog, catalogService.gymEquipmentsCatalog),
        })
      } else {
        if (videoUrl) {
          dispatch({ type: 'SETSELECTEDEXERCISE', selectedExercise: { ...catalogService.selectedExercise, video_url: videoUrl } })
          setVideo({ progress: '', size: '', file: '' })
        }
        notification.error({
          message: `Error while trying update the exercise`,
          description: `Error from DB ${result.message}`,
        })
        setIsSaving(false)
      }
    }
    setIsSaving(false)
  }

  const uploadVideoProgress = (bytesUploaded, bytesTotal) => {
    setVideo({ ...video, progress: ((bytesUploaded / bytesTotal) * 100).toFixed(2) })
  }

  return (
    <Layout {...{ ...props }}>
      <MainContainer>
        <header>
          <h1>Exercises Catalog</h1>
          <Button
            type="primary"
            disabled={catalogService.loading}
            onClick={() =>
              dispatch({
                type: 'SETNEWEXERCISE',
                newExerciseVisible: !catalogService.newExerciseVisible,
                selectedExercise: { ...exerciseCreateDefault, thumbnails: { start: '', finish: '' }, gym_equipments: [], alternatives: [] },
              })
            }
          >
            Add exercise
          </Button>
        </header>
        <Input style={{ width: '30%' }} placeholder="Search by name" onChange={filterExercisesByName} allowClear={true} prefix={<SearchOutlined />} />
        <ExerciseTable
          showAlternativesColumn
          allExercises={catalogService.allExercises}
          exercises={exercises}
          musclesCatalog={catalogService.musclesCatalog}
          gymEquipmentsCatalog={catalogService.gymEquipmentsCatalog}
          loading={catalogService.loading}
          setExerciseFilter={(filter) => dispatch({ type: 'FILTER', exerciseFilter: filter })}
          exerciseFilter={catalogService.exerciseFilter}
          setExercise={setExercise}
        />
      </MainContainer>
      <Drawer
        width="450px"
        title="Add exercise"
        placement="right"
        closable={false}
        visible={catalogService.newExerciseVisible}
        footer={
          <DrawerButtonsContainer>
            <Button
              disabled={isSaving}
              onClick={() =>
                dispatch({
                  type: 'SETNEWEXERCISE',
                  newExerciseVisible: !catalogService.newExerciseVisible,
                  selectedExercise: { ...exerciseCreateDefault, thumbnails: { start: '', finish: '' }, gym_equipments: [], alternatives: [] },
                })
              }
            >
              Cancel
            </Button>
            <Button disabled={isSaving} type="primary" onClick={saveExercise}>
              Save
            </Button>
          </DrawerButtonsContainer>
        }
      >
        {catalogService.newExerciseVisible && (
          <ExerciseForm
            video={video}
            setVideo={setVideo}
            allExercises={catalogService.allExercises}
            addImageToDeleteUploadCare={(imageUrl) => dispatch({ type: 'ADDIMAGETODELETE', image: getUuidFromUrlImage(imageUrl) })}
            selectedExercise={catalogService.selectedExercise}
            setSelectedExercise={(exercise) => dispatch({ type: 'SETSELECTEDEXERCISE', selectedExercise: exercise })}
            action={actions.create}
            musclesCatalog={catalogService.musclesCatalog}
            gymEquipmentsCatalog={catalogService.gymEquipmentsCatalog}
          />
        )}
      </Drawer>
      <Drawer
        width="450px"
        title="Edit Exercise"
        placement="right"
        closable={false}
        visible={catalogService.editExerciseVisible}
        footer={
          <DrawerButtonsContainer>
            <Button
              disabled={isSaving}
              onClick={() => {
                dispatch({
                  type: 'SETEXERCISETOEDIT',
                  editExerciseVisible: !catalogService.editExerciseVisible,
                  selectedExercise: { ...exerciseCreateDefault, thumbnails: { start: '', finish: '' }, gym_equipments: [], alternatives: [] },
                })
              }}
            >
              Cancel
            </Button>
            <Button disabled={isSaving} type="primary" onClick={saveExercise}>
              Save
            </Button>
          </DrawerButtonsContainer>
        }
      >
        {catalogService.editExerciseVisible && (
          <ExerciseForm
            video={video}
            setVideo={setVideo}
            allExercises={catalogService.allExercises}
            addImageToDeleteUploadCare={(imageUrl) => dispatch({ type: 'ADDIMAGETODELETE', image: getUuidFromUrlImage(imageUrl) })}
            selectedExercise={catalogService.selectedExercise}
            setSelectedExercise={(exercise) => dispatch({ type: 'SETSELECTEDEXERCISE', selectedExercise: exercise })}
            action={actions.update}
            musclesCatalog={catalogService.musclesCatalog}
            gymEquipmentsCatalog={catalogService.gymEquipmentsCatalog}
          />
        )}
      </Drawer>
      <Drawer
        width="450px"
        title="Preview Exercise"
        placement="right"
        closable={true}
        visible={catalogService.previewExerciseVisible}
        onClose={() => {
          dispatch({
            type: 'SETEXERCISETOPREVIEW',
            previewExerciseVisible: !catalogService.previewExerciseVisible,
            selectedExercise: { ...exerciseCreateDefault, thumbnails: { start: '', finish: '' }, gym_equipments: [], alternatives: [] },
          })
        }}
      >
        {catalogService.previewExerciseVisible && (
          <PreviewExercise allExercises={catalogService.allExercises} exercise={catalogService.selectedExercise} />
        )}
      </Drawer>
      {video && video.progress && (
        <Modal
          visible="true"
          title="Upload video progress"
          closable={false}
          footer={null}
          onCancel={(e) => {
            e.preventDefault()
            showWarning("Sorry, you can't cancel the upload, please wait")
          }}
        >
          <Progress percent={video.progress} />
        </Modal>
      )}
    </Layout>
  )
}
