import React, { useEffect, useState, useContext } from 'react'
import { Row, Col, Select, Input, Button, notification } from 'antd'
import { Container } from './Step1.styled'
import { apiServices } from 'services/api'
import { WorkoutStore } from '../../Uses/WorkoutStore'
import CloseModal from '../CloseModal'
import CustomSwitch from '../../Uses/CustomSwitch'
const { Option } = Select

export default ({ bootcampId, workout, category, onNext, showClose, onClose, setShowClose }) => {
  const { routine_types: routineTypes } = useContext(WorkoutStore)

  const [loading, setLoading] = useState(true)
  const [programType, setProgramType] = useState()
  const [programName, setProgramName] = useState()
  const [programEdition, setProgramEdition] = useState()
  const [programOrder, setProgramOrder] = useState()
  const [programIcon, setProgramIcon] = useState()
  const [isOptionalProgram, setIsOptionalProgram] = useState(false)
  const [canGoNext, setCanGoNext] = useState(false)
  const [isBtnLoading, setIsBtnLoading] = useState(false)
  const [routinesGym, setRoutinesGym] = useState([])
  const [routinesHome, setRoutinesHome] = useState([])
  const [bootcampID, setBootcampID] = useState()
  const [workoutID, setWorkoutID] = useState()
  const [showCloseModal, setShowCloseModal] = useState(showClose)
  const step = 'routine'
  let routineId = localStorage.getItem('ROUTINE_ID')

  useEffect(() => {
    setBootcampID(bootcampId)
    setWorkoutID(workout.id)
    setRoutinesGym(workout.routines.filter((r) => r.category === 'GYM'))
    setRoutinesHome(workout.routines.filter((r) => r.category === 'HOME'))
    if (!!Number(routineId)) {
      const fetchData = async () => {
        apiServices('GET', `routine?step=${step}&id=${routineId}`)
          .then((resp) => resp.json())
          .then((data) => {
            const { category, name, order, type, icon, is_optional } = data
            setProgramType(type)
            setProgramName(name)
            setProgramEdition(category)
            setProgramIcon(icon)
            setProgramOrder(order)
            setIsOptionalProgram(is_optional)
          })
          .catch(console.warn)
          .finally(() => setLoading(false))
      }
      fetchData()
    } else {
      setProgramEdition(category)
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    validate()
  }, [programType, programName, programEdition, isOptionalProgram])

  useEffect(() => setShowCloseModal(showClose), [showClose])

  const validate = () => {
    let isValid = !!programType && !!programName && !!programEdition && typeof isOptionalProgram === 'boolean'
    if (isValid) {
      setCanGoNext(true)
    }
  }

  const save = async (skipValidation) => {
    let routineOrder =
      programEdition === 'GYM'
        ? routinesGym.length > 0
          ? routinesGym[routinesGym.length - 1].order + 1
          : 1
        : routinesHome.length > 0
        ? routinesHome[routinesHome.length - 1].order + 1
        : 1

    const routine = {
      id: !!Number(routineId) ? routineId : null,
      program_id: bootcampID,
      workout_plan_id: workoutID,
      name: skipValidation && !programName ? `[routine-${programOrder || routineOrder}]` : programName,
      order: programOrder || routineOrder,
      type: programType,
      category: programEdition,
      icon: programIcon || 'https://ucarecdn.com/17602073-f889-4c5d-a85e-59b33e67a674/routinedefault.png',
      is_optional: isOptionalProgram,
    }

    return apiServices('POST', `routine?step=routine${skipValidation ? '&skip-validation=true' : ''}`, { ...routine }).then((result) => result.json())
  }

  const handleClickNext = () => {
    setIsBtnLoading(true)
    save()
      .then((data) => {
        if (!data.hasError) {
          localStorage.setItem('ROUTINE_ID', data.id)
          onNext()
        }
      })
      .catch((err) => {
        notification.error({
          message: `Error while trying to save the routine`,
          description: `Error from DB ${err.message}`,
        })
      })
      .finally(() => setIsBtnLoading(false))
  }

  const handleClose = async (withSave) => {
    return new Promise((resolve, reject) => {
      if (withSave) {
        if (!programType && !programName && !programEdition && typeof isOptionalProgram !== 'boolean') {
          onClose()
          resolve()
        } else {
          save(true)
            .then((data) => {
              if (!data.hasError) {
                notification.success({ message: 'Saved correctly!' })
                onClose()
                resolve()
              } else {
                throw new Error(data)
              }
            })
            .catch((err) => {
              notification.error({
                message: `Error while trying to save the routine`,
                description: `Error from DB ${err.message}`,
              })
              resolve()
            })
        }
      } else if (typeof onClose === 'function') {
        onClose()
        resolve()
      }
    })
  }

  return (
    <Container>
      {!loading && (
        <>
          <p className="sub-title">Assign name and day</p>
          <Row gutter={24} className="row">
            <Col span={9}>
              <span className="label">Program type</span>
              <Select defaultValue={programType} placeholder="Select an option" onChange={setProgramType}>
                {routineTypes.map((type) => {
                  return <Option key={type.key}>{type.label}</Option>
                })}
              </Select>
            </Col>
            <Col span={9} offset={6}>
              <span className="label">Program name</span>
              <Input
                type="text"
                defaultValue={programName}
                placeholder="Type a name to your program"
                maxLength="30"
                onChange={(e) => setProgramName(e.target.value)}
              />
            </Col>
          </Row>
          <Row gutter={24} className="row">
            <Col span={9}>
              <span className="label">Is Gym Edition?</span>
              <CustomSwitch checked={programEdition === 'GYM'} onChange={() => setProgramEdition(programEdition === 'GYM' ? 'HOME' : 'GYM')} />
            </Col>
            <Col span={9} offset={6}>
              <span className="label">Is this program optional?</span>
              <CustomSwitch checked={isOptionalProgram} onChange={() => setIsOptionalProgram(!isOptionalProgram)} />
            </Col>
          </Row>
          <div className="step-action">
            <hr />
            <Button className="btn-cancel" disabled={isBtnLoading} onClick={() => setShowClose(true)}>
              Cancel
            </Button>
            <Button type="primary" onClick={handleClickNext} disabled={!canGoNext} loading={isBtnLoading}>
              Next
            </Button>
          </div>
        </>
      )}
      <CloseModal visible={showCloseModal} onCancel={setShowClose} handleClose={handleClose} />
    </Container>
  )
}
