import React, { useEffect, useState } from 'react'
import { Popover, Button } from 'antd'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { ExerciseContainer } from './ExerciseList.styled'
import { StyledModal } from 'styled'
import ContextMenu from 'assets/icons/context-menu.png'
import ExerciseCard from '../../../Uses/ExerciseCard/ExerciseCard'
import { ReactComponent as IconTrash } from 'assets/icons/icon-trash.svg'
import { ReactComponent as IconRepeat } from 'assets/icons/icon-repeat.svg'

export default function ExerciseList({ exercisesArr, handleDragAndDrop, handleDeleteExercise, handleAlternativesExercises, showAlternatives }) {
  const [exercises, setExercises] = useState([])
  const [visible, setVisible] = useState()
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [idToDelete, setIdToDelete] = useState()

  useEffect(() => {
    setExercises(exercisesArr)
  }, [exercisesArr])

  function handleOnDragEnd(result) {
    if (!result.destination) return
    const items = Array.from(exercises)
    const [reorderItem] = items.splice(result.source.index, 1)
    items.splice(result.destination.index, 0, reorderItem)
    setExercises(items)
    handleDragAndDrop(items)
  }

  const handleVisibleChange = (isVisible, id) => {
    setVisible(isVisible ? id : null)
  }

  const handleModalDelete = (exerciseId) => {
    setIsModalVisible(true)
    setIdToDelete(exerciseId)
  }

  const handleDelete = () => {
    handleDeleteExercise(idToDelete)
    setIsModalVisible(false)
  }

  return (
    <>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="exercises">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {exercises.map((exercise, index) => (
                <Draggable key={exercise.id} draggableId={exercise.id} index={index}>
                  {(provided) => (
                    <ExerciseContainer {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                      <ExerciseCard exercise={exercise} className={true} />
                      <div className="context-menu">
                        <Popover
                          placement="bottomRight"
                          content={
                            <>
                              <p onClick={(e) => handleModalDelete(exercise.id)}>
                                <IconTrash /> <span>Delete</span>
                              </p>
                              {showAlternatives && (
                                <p
                                  onClick={(e) => {
                                    handleAlternativesExercises(exercise)
                                    handleVisibleChange(false)
                                  }}
                                >
                                  <IconRepeat />
                                  <span>Alternative</span>
                                </p>
                              )}
                            </>
                          }
                          visible={visible === exercise.id}
                          onVisibleChange={(isVisible) => handleVisibleChange(isVisible, exercise.id)}
                          trigger="click"
                          overlayClassName="context-menu-exercises"
                        >
                          <img src={ContextMenu} alt="..." />
                        </Popover>
                      </div>
                    </ExerciseContainer>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <StyledModal
        visible={isModalVisible}
        title="Are you sure you want to delete this exercise?"
        className="confirm"
        centered={true}
        onCancel={() => setIsModalVisible(false)}
        footer={[
          <Button key="import" onClick={() => setIsModalVisible(false)}>
            Cancel
          </Button>,
          <Button key="create" type="primary" onClick={handleDelete}>
            Delete
          </Button>,
        ]}
      />
    </>
  )
}
