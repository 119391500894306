import React, { Fragment } from 'react'
import ReactDOM from 'react-dom'
import * as serviceWorker from 'serviceWorker.js'
import { ApolloProvider } from 'react-apollo'
import apolloClient from 'apolloClient.js'
import Routes from 'routes.js'

const Root = () => (
  <ApolloProvider client={apolloClient}>
    <Fragment>
      <Routes />
    </Fragment>
  </ApolloProvider>
)

ReactDOM.render(<Root />, document.getElementById('root'))

serviceWorker.unregister()
