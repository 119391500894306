import gql from 'graphql-tag'

export const createContactMerge = gql`
  mutation(
    $contactId: String!
    $picture: String
    $firstName: String!
    $lastName: String!
    $email: String!
    $programs: jsonb!
    $programsMergeType: String!
    $createBy: String!
    $createDate: timestamp!
    $contactOriginal: jsonb!
    $contactDuplicate: jsonb!
  ) {
    insert_merge_contact(
      objects: [
        {
          contact_id: $contactId
          picture: $picture
          first_name: $firstName
          last_name: $lastName
          email: $email
          programs: $programs
          programs_merge_type: $programsMergeType
          created_by: $createBy
          create_date: $createDate
          contact_original: $contactOriginal
          contact_duplicate: $contactDuplicate
        }
      ]
    ) {
      returning {
        id
        status
        status_message
      }
    }
  }
`
