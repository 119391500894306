import React from 'react'
import gql from 'graphql-tag'
import { Query } from 'react-apollo'

const coach = (props) => {
  return (
    <Query query={getCoachesInfo} fetchPolicy="network-only">
      {props.children}
    </Query>
  )
}

const getCoachesInfo = gql`
  query getCoachesInfo {
    coach(where: { is_active: { _eq: true } }, order_by: { user: { first_name: asc } }) {
      id
      levels
      is_master
      is_meal_planner
      max_students
      assignation_order
      welcome_letter
      user {
        first_name
        last_name
        picture
        email
      }
    }
  }
`

export default coach
