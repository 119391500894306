import styled from '@emotion/styled'

export const LayoutContainer = styled.div`
  .ant-layout {
    min-height: 100vh;

    .main-header {
      height: 65px;
      background: #ffffff;
      box-shadow: 0px 2px 12px rgba(210, 210, 210, 0.5);
      display: grid;
      grid-template-columns: 145px auto 200px;

      .logo {
        float: left;
        width: 144px;
        height: 28px;
      }

      .ant-menu {
        text-align: center;

        .ant-menu-item-selected {
          font-weight: 600;
        }

        .ant-menu-overflowed-submenu {
          .anticon {
            margin-right: 0;
          }
        }
      }

      .profile {
        float: right;
        text-align: right;

        .nickname {
          margin-right: 4px;
        }
      }
    }

    .main-content {
      padding: 0px 50px;
      overflow-x: scroll;
      margin-top: 14px;
    }

    .loader {
      width: 100%;
      text-align: center;
      padding: 30px;

      .anticon-loading {
        font-size: 100px;
        color: #ff6b00;
      }
    }

    @media (max-width: 991.9px) {
      .main-header {
        padding: 0px 10px;
        grid-template-columns: 145px auto auto;

        .profile {
          .nickname {
            display: none;
          }
        }
      }

      .main-content {
        padding: 0px 10px;
      }
    }
  }
`
