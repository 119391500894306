import styled from '@emotion/styled'

export const LoginContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #471308;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: ${({ bgUrl }) => `url(${bgUrl})`};
  font-family: 'Montserrat', sans-serif;
  height: 100vh;
  padding: 20px;
`
export const Content = styled.div`
  border-radius: 2px;
  box-shadow: inset 0 0 2px 1px #d4d4d4;
  background: #fff;
  margin: 0 auto;
  min-width: 320px;
  max-width: 400px;
  padding: 25px;
`
export const Main = styled.div``
export const Header = styled.div`
  text-align: center;
  padding-bottom: 26px;
`
export const Version = styled.div`
  color: #777;
  text-align: right;
  font-size: 8px;
  margin: 0 -15px -15px 0;
`

export const H1 = styled.h1`
  font-size: 15px;
  font-weight: 600;
  text-align: center;
`
export const Footer = styled.div`
  padding-top: 15px;
`
export const StyledLink = styled.a`
  color: #fff;
  font-weight: thin;
  text-decoration: underline;
  &:hover {
    color: #fff;
  }
`
