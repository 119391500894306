export const getGymEquipments = ({ exerciseEquipments, gymEquipments }) => {
  let equipment = []
  exerciseEquipments.forEach((equip) => {
    const foundEquipment = gymEquipments.find((e) => equip.gym_equipment_id === e.id)
    if (foundEquipment) {
      equipment.push(`${foundEquipment.name} (${equip.quantity})`)
    }
  })
  return equipment.length > 0 ? equipment.join(' / ') : null
}

export const getMuscles = ({ exerciseMuscles, arrayMuscles }) => {
  let muscles = []
  exerciseMuscles.forEach((muscle) => {
    const foundMuscle = arrayMuscles.find((m) => m.id === muscle)
    if (foundMuscle) {
      muscles.push(`${foundMuscle.name}`)
    }
  })

  return muscles.length > 0 ? muscles.join(' / ') : null
}

export const isValidConfiguration = (routine) => {
  let weeks = routine.routine_weeks
  return weeks.every((w) => {
    if (w.week_exercises.length > 0) {
      return w.week_exercises.every((we) => {
        let hasSets = we.week_sets.length > 0
        let isValid
        switch (we.technique) {
          case '':
            isValid = we.week_sets.every(
              (ws) => (ws.min_repetitions > 0 && ws.max_repetitions > 0) || (ws.min_repetitions > 0 && ws.max_repetitions === null) || !!ws.is_amrap,
            )
            break
          case 'DELOAD':
            if (!we.deload_percent_min) we.deload_percent_min = 0
            isValid =
              ((we.deload_percent_min >= 0 && !we.deload_percent_max) ||
                (we.deload_percent_min > 0 && we.deload_percent_max && we.deload_percent_max > 0)) &&
              !!we.has_deload &&
              !!we.deload_week &&
              we.week_sets.every(
                (ws) =>
                  (ws.min_repetitions > 0 &&
                    ws.max_repetitions === null &&
                    ((ws.min_rir > 0 && ws.max_rir > 0) || (!!!ws.min_rir && !!!ws.max_rir))) ||
                  (ws.min_repetitions > 0 && ws.max_repetitions > 0 && ((ws.min_rir > 0 && ws.max_rir > 0) || (!!!ws.min_rir && !!!ws.max_rir))),
              )
            break
          case 'AMRAP':
            isValid = true
            break
          case 'BFR':
          case 'DROPSET':
          case '%1RM & X RM':
            isValid = we.week_sets.every(
              (ws) => !!ws.is_amrap || (ws.min_repetitions > 0 && ws.max_repetitions > 0) || (ws.min_repetitions > 0 && ws.max_repetitions === null),
            )
            break
          case 'RPT':
            isValid = we.week_sets.every(
              (ws) =>
                (ws.min_repetitions > 0 && ws.max_repetitions === null && ((ws.min_rir > 0 && ws.max_rir > 0) || (!!!ws.min_rir && !!!ws.max_rir))) ||
                !!ws.is_amrap ||
                (ws.min_repetitions > 0 && ws.max_repetitions > 0 && ((ws.min_rir > 0 && ws.max_rir > 0) || (!!!ws.min_rir && !!!ws.max_rir))),
            )
            break
          case 'APT':
            isValid = we.week_sets.every(
              (ws) => (ws.min_repetitions > 0 && ((ws.min_rir > 0 && ws.max_rir > 0) || (!!!ws.min_rir && !!!ws.max_rir))) || !!ws.is_amrap,
            )
            break
          case 'MYO REPS':
            isValid = we.week_sets.every((ws) => ws.min_repetitions > 0 && ws.max_repetitions > 0)
            break
          case 'EMOM':
            isValid = we.week_sets.every((ws) => ws.min_repetitions > 0 && ((ws.min_rir > 0 && ws.max_rir > 0) || (!!!ws.min_rir && !!!ws.max_rir)))
            break
          case "21's":
            isValid = we.week_sets.every((ws) => ws.min_repetitions > 0)
            break
          default:
            isValid = false
            break
        }
        return hasSets && isValid
      })
    } else {
      return false
    }
  })
}
