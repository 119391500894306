import React, { useState, useEffect, useRef } from 'react'
import { ContainerTextEditor } from '../../../styled/TextEditor.styled'
import ReactQuill, { Quill } from 'react-quill'
import quillEmoji from 'quill-emoji'
import 'react-quill/dist/quill.snow.css'
import 'quill-emoji/dist/quill-emoji.css'

Quill.register(
  {
    'formats/emoji': quillEmoji.EmojiBlot,
    'modules/emoji-toolbar': quillEmoji.ToolbarEmoji,
  },
  true,
)

const TextEditor = (props) => {
  const { letter } = props
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [editorHtml, setEditorHtml] = useState(letter)
  const quillRef = useRef(null)

  useEffect(() => {
    if (quillRef) {
      if (quillRef.current) {
        if (quillRef.current.editingArea) {
          let editorChild = quillRef.current.editingArea.getElementsByClassName('ql-editor')
          if (editorChild && editorChild.length > 0) {
            editorChild[0].removeAttribute('data-gramm')
          }
        }
      }
    }

    setLoading(false)
  }, [])

  const handleChange = (html) => {
    setEditorHtml(html)
    localStorage.setItem('COACH_WELCOME_LETTER', html)
    if (quillRef) {
      if (quillRef.current) {
        if (quillRef.current.editingArea) {
          let editorChild = quillRef.current.editingArea.getElementsByClassName('ql-editor')
          if (editorChild && editorChild.length > 0) {
            editorChild[0].removeAttribute('data-gramm')
          }
        }
      }
    }
  }

  return (
    <ContainerTextEditor>
      {error && <small>Please enter a welcome letter</small>}
      {!loading && (
        <>
          <div className="wrap-editor">
            <ReactQuill
              placeholder="Write your letter"
              onChange={handleChange}
              modules={TextEditor.modules}
              formats={TextEditor.formats}
              defaultValue={editorHtml}
              ref={quillRef}
              value={editorHtml}
              dangerouslySetInnerHTML={{ __html: editorHtml }}
            />
          </div>

          <div id="quill-toolbar" className="buttons-container">
            <select className="ql-color">
              <option value="#00000" />
              <option value="#E53D2F" />
              <option value="#6FCF97" />
              <option value="#2EA3F2" />
              <option value="#FF6B00" />
              <option value="#ED16D3" />
              <option value="#10EF16" />
            </select>
            <button className="ql-bold" />
            <button className="ql-italic" />
            <button className="ql-link" />
            <button className="ql-underline" />
            <button className="ql-emoji" />
          </div>
        </>
      )}
    </ContainerTextEditor>
  )
}

TextEditor.modules = {
  toolbar: {
    container: '#quill-toolbar',
  },
  'emoji-toolbar': true,
}

TextEditor.formats = ['header', 'bold', 'italic', 'underline', 'list', 'link', 'align', 'emoji', 'color', 'video']

export default TextEditor
