import React from 'react'
import { Avatar, Layout, Menu } from 'antd'
import { MenuOutlined } from '@ant-design/icons'
import { MenuItems } from './data'
import { UserOutlined } from '@ant-design/icons'
import Logo from 'assets/logo-full.svg'
import { LayoutContainer } from './Layout.styled'
import { navigate } from '@reach/router'
import { GlobalStyles } from 'styled/Global.styled'

const { Header, Content } = Layout
const { SubMenu } = Menu

export default (props) => {
  const { children } = props
  const userNickName = localStorage.getItem('USER_NICKNAME')
  const userPicture = localStorage.getItem('USER_PICTURE')
  return (
    <GlobalStyles>
      <LayoutContainer>
        <Layout>
          <Header theme="light" className="main-header">
            <div className="logo">
              <a href="/">
                <img src={Logo} alt="Ketogains" />
              </a>
            </div>
            <Menu theme="light" mode="horizontal" selectedKeys={[props.path]} overflowedIndicator={<MenuOutlined />}>
              {MenuItems.map((mi) => {
                if (mi.child && mi.child.length > 0) {
                  return (
                    <SubMenu key={`Submenu_${mi.name}`} title={mi.name}>
                      {mi.child.map((mic) => {
                        return (
                          <Menu.Item key={mic.url} onClick={() => navigate(mic.url)}>
                            {mic.name}
                          </Menu.Item>
                        )
                      })}
                    </SubMenu>
                  )
                }
                return (
                  <Menu.Item key={mi.url} onClick={() => navigate(mi.url)}>
                    {mi.name}
                  </Menu.Item>
                )
              })}
            </Menu>
            <div className="profile">
              <span className="nickname">{userNickName}</span>
              <Avatar icon={<UserOutlined />} src={userPicture} />
            </div>
          </Header>
          <Content className="main-content">{children}</Content>
        </Layout>
      </LayoutContainer>
    </GlobalStyles>
  )
}
