import styled from '@emotion/styled'

export const PreviewRoutineContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 3px 0px 0px;

    .title {
      font-size: 18px;
      font-weight: 600;
      line-height: 22px;
      color: #5d5d5d;

      .ant-divider {
        height: 2.5em;
        background-color: #9473c4;
        width: 3px;
      }
    }

    .edit-link {
      float: right;
      font-weight: 600;
      font-size: 14px;
      color: #ff6b00;
      cursor: pointer;
    }
  }

  .routine-details {
    padding: 5px 22px 0px;

    label {
      font-weight: 500;
      font-size: 11px;
      color: #959595;
      display: block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      span {
        font-weight: 500;
        font-size: 14px;
        color: #5d5d5d;
      }
    }

    p {
      font-weight: 500;
      font-size: 14px;
      color: #5d5d5d;

      b {
        font-size: 14px;
        font-weight: 600;
      }
    }

    .ant-row {
      margin-bottom: 0px;
    }
  }

  .routine-weeks {
    padding: 5px 22px;
    height: calc(100% - 135px);

    &.edit {
      height: calc(100% - 260px);
    }

    p.title {
      font-weight: 500;
      font-size: 12px;
      color: #959595;
    }

    .ant-tabs {
      height: calc(100% - 26px);

      @media (max-width: 990px) {
        height: unset;
        .ant-col-md-12 {
          max-height: 400px;
        }
      }
      .ant-tabs-content-holder {
        .ant-tabs-content {
          height: 100%;
          .ant-row {
            height: 100%;
            @media (max-width: 990px) {
              display: grid;
              grid-row-gap: 50px !important;
            }
          }
        }
      }
    }
  }

  div.section-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
  }

  .step-action {
    bottom: 0;
    width: 100%;
    text-align: right;
    left: 0;

    .ant-btn {
      width: 148px;
      height: 35px;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      font-size: 20px;
      line-height: 24px;
      font-weight: 600;
    }
    .ant-btn.btn-cancel {
      margin-right: 22px;
    }
  }
`
