import React, { useState } from 'react'
import { ContentDrawer } from './ExerciseFilter.styled'
import { Checkbox, Row, Col, Button } from 'antd'
import { FilterOutlined, CloseOutlined } from '@ant-design/icons'

export const ExerciseFilter = ({ isVisible, setIsVisible, handleFilter, muscles, equipments }) => {
  const [options, setOptions] = useState({
    filterMuscles: [],
    filterEquipments: [],
  })

  const handleSelectFilters = (type, checkedValues) => {
    const { filterMuscles, filterEquipments } = options
    setOptions({
      filterMuscles: type === 'muscles' ? checkedValues : filterMuscles,
      filterEquipments: type === 'equipments' ? checkedValues : filterEquipments,
    })
  }

  const handleApplyFilters = () => {
    handleFilter('options', options)
    setIsVisible(false)
  }

  const handleResetFilters = () => {
    const options = {
      filterMuscles: [],
      filterEquipments: [],
    }
    setOptions(options)
    handleFilter('options', options)
    setIsVisible(false)
  }

  return (
    <ContentDrawer
      title={
        <>
          {' '}
          <FilterOutlined /> Exercise Filter
        </>
      }
      className="filter-exercises"
      placement="right"
      closable={true}
      closeIcon={<CloseOutlined />}
      maskClosable={false}
      onClose={() => setIsVisible(false)}
      visible={isVisible}
      getContainer={false}
      style={{ position: 'absolute' }}
      width={400}
    >
      <p>Muscles.</p>
      <Checkbox.Group value={options.filterMuscles} style={{ width: '100%' }} onChange={(values) => handleSelectFilters('muscles', values)}>
        <Row>
          {muscles.map((muscle) => {
            return (
              <Col key={muscle.id} span={12}>
                <Checkbox value={muscle.id}>{muscle.name}</Checkbox>
              </Col>
            )
          })}
        </Row>
      </Checkbox.Group>
      <p>Equipment</p>
      <Checkbox.Group value={options.filterEquipments} style={{ width: '100%' }} onChange={(values) => handleSelectFilters('equipments', values)}>
        <Row>
          {equipments.map((equipment) => {
            return (
              <Col key={equipment.id} span={12}>
                <Checkbox value={equipment.id}>{equipment.name}</Checkbox>
              </Col>
            )
          })}
        </Row>
      </Checkbox.Group>
      <hr />
      <div className="step-action">
        <Button className="btn-reset" onClick={handleResetFilters}>
          Reset
        </Button>
        <Button type="primary" disabled={options.filterMuscles.length === 0 && options.filterEquipments.length === 0} onClick={handleApplyFilters}>
          Apply
        </Button>
      </div>
    </ContentDrawer>
  )
}
