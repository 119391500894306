import gql from 'graphql-tag'

export const getCoachesData = gql`
  query getCoachesData($forDate: String!, $programId: uuid!) {
    coach(where: { assigned: { program_id: { _eq: $programId } } }) {
      id
      user {
        first_name
        last_name
        picture
      }

      assigned(where: { program_id: { _eq: $programId } }) {
        trainee {
          completed: measurement_aggregate(
            where: { for_date: { _eq: $forDate }, _and: [{ status: { _eq: 1 } }, { trainee: { selected: { program_id: { _eq: $programId } } } }] }
          ) {
            aggregate {
              count
            }
          }

          ready_wait: measurement_aggregate(
            where: { for_date: { _eq: $forDate }, _and: [{ status: { _eq: 2 } }, { trainee: { selected: { program_id: { _eq: $programId } } } }] }
          ) {
            aggregate {
              count
            }
          }

          assistance: measurement_aggregate(
            where: { for_date: { _eq: $forDate }, _and: [{ status: { _eq: 5 } }, { trainee: { selected: { program_id: { _eq: $programId } } } }] }
          ) {
            aggregate {
              count
            }
          }
        }
      }
    }
  }
`
