import React from 'react'
import { Row, Col, Tag, Popover, Button, Checkbox } from 'antd'
import { StyledCheckboxCircle } from 'styled'
import { filterAlternativesToShow } from '../Uses'
import ExercisesTable from './ExerciseTable.styled'

const ExerciseTable = ({
  exercises,
  musclesCatalog,
  gymEquipmentsCatalog,
  setExercise,
  loading,
  setExerciseFilter,
  exerciseFilter,
  alternatives,
  changeAlternative,
  selector,
  popover,
  setPopover,
  setLevel,
  allExercises,
  showAlternativesColumn,
  showLevelsSelected,
  sorting = true,
}) => {
  const alternativeColumn = {
    title: 'Alternatives',
    dataIndex: 'alternatives',
    key: 'alternatives',
    render: (alts) => {
      return filterAlternativesToShow(alts, allExercises, 'table')
    },
  }
  const levelsColumn = {
    title: 'Levels',
    dataIndex: 'levels',
    key: 'levels',
    render: (levels, exercise) => {
      if (!levels) return
      return (
        <ul>
          {levels
            .sort()
            .join(',')
            .replace(1, 'L1')
            .replace(2, 'L2')
            .replace(3, 'L3')
            .split(',')
            .map((lev, i) => {
              return <li key={`level-${lev}-index-${i}-${exercise.id}`}>{lev}</li>
            })}
        </ul>
      )
    },
  }
  const actionsColumn = {
    title: 'Actions',
    dataIndex: '',
    key: 'actions',
    render: (exercise) => {
      return selector ? (
        <>
          <StyledCheckboxCircle checked={alternatives.find((e) => e.id === exercise.id)} onChange={(e) => changeAlternative(e, exercise)} />
          {popover && popover.id === exercise.id && (
            <Popover
              placement="top"
              content={
                <>
                  <Checkbox type="checkbox" checked={popover.levels.find((l) => l === 1)} onChange={setLevel} value={1}>
                    Level 1
                  </Checkbox>
                  <Checkbox type="checkbox" checked={popover.levels.find((l) => l === 2)} onChange={setLevel} value={2}>
                    Level 2
                  </Checkbox>
                  <Checkbox type="checkbox" checked={popover.levels.find((l) => l === 3)} onChange={setLevel} value={3}>
                    Level 3
                  </Checkbox>
                  <br />
                  <Button
                    type="primary"
                    style={{ margin: '5px auto 0px auto', display: 'block' }}
                    onClick={(e) => {
                      changeAlternative(e, popover)
                    }}
                  >
                    Select
                  </Button>
                </>
              }
              title={'Select levels'}
              trigger="click"
              visible={true}
              onVisibleChange={() => setPopover('')}
            ></Popover>
          )}
        </>
      ) : (
        <>
          <span className="actions-buttons" onClick={() => setExercise(exercise, 'SETEXERCISETOEDIT')}>
            Edit
          </span>{' '}
          <span
            className="actions-buttons"
            onClick={(e) => {
              e.preventDefault()
              setExercise(exercise, 'SETEXERCISETOPREVIEW')
            }}
          >
            Preview
          </span>{' '}
        </>
      )
    },
  }
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: sorting,
      sortDirections: ['descend'],
    },
    {
      title: 'Muscles',
      dataIndex: 'muscles',
      key: 'muscles',
      render: (muscles) => {
        return (
          <span>
            {muscles.map((m, ind) => {
              let muscle = musclesCatalog.find((mc) => mc.id === m)
              return (
                <Tag color="#FF6B00" key={`muscle-${ind}`}>
                  {muscle ? muscle.name : m}
                </Tag>
              )
            })}
          </span>
        )
      },
    },
    {
      title: 'Gym Equipment',
      dataIndex: 'gym_equipments',
      key: 'gym_equipments',
      render: (gym_equipments) => {
        return (
          <span>
            {gym_equipments.map((ge, ind) => {
              let gym_equipment = gymEquipmentsCatalog.find((gec) => gec.id === ge.gym_equipment_id)
              return (
                <Tag color="#FF6B00" key={`${ge.gym_equipment_id}-${ind}-equipment`}>
                  {gym_equipment ? `${gym_equipment.name}${ge.quantity && ge.quantity !== 1 ? ` (${ge.quantity})` : ``}` : ge.gym_equipment_id}
                </Tag>
              )
            })}
          </span>
        )
      },
    },
  ]

  if (showAlternativesColumn) columns.push(alternativeColumn)
  if (showLevelsSelected) columns.push(levelsColumn)
  columns.push(actionsColumn)
  const handleTableChange = (pagination, filters, sorter) => {
    if (sorter.columnKey === 'name')
      if (sorter.order === 'descend') setExerciseFilter({ ...exerciseFilter, order: 'des' })
      else setExerciseFilter({ ...exerciseFilter, order: 'asc' })
  }
  return (
    <ExercisesTable
      dataSource={exercises}
      columns={columns}
      loading={loading}
      scroll={{ x: true }}
      onChange={handleTableChange}
      expandable={{
        expandedRowRender: (record) => {
          return (
            <Row gutter={[16, 16]} key={`exercise-record-${record.id}`}>
              <Col xs={24} md={8}>
                {record.description && (
                  <>
                    <p>Description</p>
                    <p dangerouslySetInnerHTML={{ __html: record.description }} />
                  </>
                )}
                {record.preparation && (
                  <>
                    <p>Preparation</p>
                    <p dangerouslySetInnerHTML={{ __html: record.preparation }} />
                  </>
                )}
                {record.execution && (
                  <>
                    <p>Execution</p>
                    <p dangerouslySetInnerHTML={{ __html: record.execution }} />
                  </>
                )}
              </Col>
              <Col xs={24} md={8} className="thumbnails">
                {record.thumbnails.map((t, ind) => {
                  return <img key={`thumb-${ind}-${(<b>ALternatives</b>)}`} src={t.url} alt="gif" />
                })}
                <img src={record.gif_url} alt="gif" />
              </Col>
              <Col xs={24} md={8}>
                <iframe
                  title={record.name}
                  src={record.video_url}
                  frameBorder="0"
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </Col>
            </Row>
          )
        },
      }}
    />
  )
}

export default ExerciseTable
