import moment from 'moment'

/**
 * gets start of week standarized to avoid local diferences in start of week
 * Starting on Monday we add 5 days to get to Saturday and 17 hours to start after 5 PM
 *
 * @return {Object} - which has properties:
 * actualWeek {a moment object) - actual Week for filling,
 * isStartOfFilledWeek {Boolean} - allow to fill current week,
 * today {String} - current Day in special format for using in Queries,
 * actualWeekStart {String} - start of filled week in special format for using in Queries,
 * actualWeekEnd {String} - end of filled week in special format for using in Queries,
 * prevWeekStart {String} - start of previous from filled week in special format  for using in Queries,
 * prevWeekEnd {String} - end of previous from filled week in special format for using in Queries
 */
export const getDateReview = () => {
  const today = moment()
    .local()
    .format('YYYYMMDD')
  // const today = moment('2019-03-09 17:01:01')
  const endOfWeekDay = moment(today)
    .startOf('isoWeek')
    .add(5, 'd')
    .add(17, 'h')
  const isStartOfFilledWeek = moment(today).isAfter(endOfWeekDay, 'minute')
  const actualWeek = isStartOfFilledWeek ? moment(today) : moment(today).subtract(1, 'weeks')
  const actualWeekStart = moment(actualWeek.startOf('isoWeek')).format('YYYYMMDD')
  const actualWeekEnd = moment(actualWeek.endOf('isoWeek')).format('YYYYMMDD')
  const prevWeek = moment(actualWeek).subtract(1, 'weeks')
  const prevWeekStart = moment(prevWeek.startOf('isoWeek')).format('YYYYMMDD')
  const prevWeekEnd = moment(prevWeek.endOf('isoWeek')).format('YYYYMMDD')

  return { today, actualWeek, isStartOfFilledWeek, actualWeekStart, actualWeekEnd, prevWeekStart, prevWeekEnd }
}

export const suffix = (number) => (number > 1 ? 's' : '')
