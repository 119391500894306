import styled from '@emotion/styled'

export const MainContainer = styled.div`
  background: #ffffff;
  border: 0.5px solid #e6e6e6;
  box-sizing: border-box;
  box-shadow: 1px 1px 7px rgba(0, 0, 0, 0.35);
  border-radius: 3px;
  padding: 17px 10px 10px;

  header {
    margin-bottom: 10px;

    h1 {
      display: inline;
    }

    .ant-btn {
      float: right;
    }
  }
  .actions-buttons {
    cursor: pointer;
    color: rgb(255, 107, 0);
  }
`
export const DrawerButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  button {
    margin: 0px 5px;
  }
`
