import React, { useState } from 'react'
import { Switch, Select, Popover } from 'antd'
import { SetCard } from './TechniqueCards.styled'
import PopoverInput from './PopoverInput'

const onlyNumbers = (e) => {
  if (!/[0-9]/.test(e.key)) {
    e.preventDefault()
  }
}

export const TechniqueCards = ({
  editMode,
  exercise,
  config,
  setWarmup,
  technique,
  unitReps,
  setMinReps,
  setMaxReps,
  setMinRIR,
  setMaxRIR,
  keyDown,
  changeSet,
  exerciseTechnique,
}) => {
  const [popoverVisible, setPopoverVisible] = useState()
  const [initialSet, setInitialSet] = useState()
  const [showDropsetToggle, setShowDropsetToggle] = useState(config.is_drop_set)

  const addAmrapSet = () => {
    setPopoverVisible(false)
    if (config.is_amrap) return
    delete config.id
    const set = { ...config, is_amrap: true, is_drop_set: false, max_repetitions: null, max_rir: null, min_rir: null, min_repetitions: 0 }
    setInitialSet(config)
    changeSet(set)
  }

  const addNormalSet = () => {
    setPopoverVisible(false)
    if (!config.is_amrap) return
    delete config.id
    const emptySet = { ...config, is_amrap: false, is_drop_set: false, max_repetitions: null, max_rir: null, min_rir: null, min_repetitions: 0 }
    if (initialSet) initialSet.order = config.order
    changeSet(initialSet || emptySet)
  }
  const toggleDropsetAndAmrap = () => {
    if (config.order === 1 || exerciseTechnique !== 'DROPSET') return
    setShowDropsetToggle(!showDropsetToggle)
    changeSet({ ...config, is_drop_set: false, is_warmup: false, drop_set_percent_max: null, drop_set_percent_min: null })
  }
  const setDropset = (is_drop_set) => {
    const drop_set_percent_max = is_drop_set ? 25 : null
    const drop_set_percent_min = is_drop_set ? 20 : null
    changeSet({ ...config, is_drop_set, drop_set_percent_max, drop_set_percent_min })
  }
  const OrderAndWarmupColumn = () => {
    return editMode ? (
      <>
        <div className="set-id">{config.order}</div>
        <div className="set-type" onClick={toggleDropsetAndAmrap}>
          {showDropsetToggle ? 'Dropset' : 'Warmup'}
        </div>
        <div className="set-type-value">
          <Switch checked={showDropsetToggle ? config.is_drop_set : config.is_warmup} onChange={showDropsetToggle ? setDropset : setWarmup} />
        </div>
      </>
    ) : (
      <>
        <div className="set-id">{config.order}</div>
        <div className="set-type">Set Type</div>
        <div className="set-type-value">{config.is_warmup ? <p>Warmup</p> : config.is_drop_set ? <p>Dropset</p> : <p>Working</p>}</div>
      </>
    )
  }

  let cardType
  if (technique === 'AMRAP' || config.is_amrap) {
    return editMode ? (
      <SetCard>
        <OrderAndWarmupColumn />
        <div className="set-reps">{unitReps}</div>
        <div className="set-reps-value">
          <Popover
            trigger="focus"
            content={
              <>
                <p className="selected" onClick={addAmrapSet}>
                  AMRAP
                </p>
                <p onClick={addNormalSet}>{exerciseTechnique || 'NORMAL'}</p>
              </>
            }
            visible={technique === 'AMRAP' ? false : popoverVisible}
            onVisibleChange={technique === 'AMRAP' ? () => {} : setPopoverVisible}
            overlayClassName="context-menu-exercises"
          >
            <input value="AMRAP" className="custom-input" style={{ width: 80 }} readOnly={true} onKeyDown={keyDown} />
          </Popover>
        </div>
      </SetCard>
    ) : (
      <SetCard>
        <OrderAndWarmupColumn />
        <div className="set-reps">{unitReps}</div>
        <div className="set-reps-value">
          <p> {technique === 'AMRAP' || config.is_amrap ? 'AMRAP' : config.min_repetitions ? config.min_repetitions : '0'} </p>
        </div>
      </SetCard>
    )
  }
  switch (technique) {
    case '':
    case null:
    case 'NORMAL':
      cardType = editMode ? (
        <SetCard>
          <OrderAndWarmupColumn />
          <div className="set-reps">{unitReps}</div>
          <div className="set-reps-value">
            <PopoverInput
              config={config}
              keyDown={keyDown}
              onlyNumbers={onlyNumbers}
              setMinReps={setMinReps}
              setMaxReps={setMaxReps}
              exerciseTechnique={exerciseTechnique}
              addNormalSet={addNormalSet}
              addAmrapSet={addAmrapSet}
              popoverVisible={popoverVisible}
              setPopoverVisible={setPopoverVisible}
              singleInput
            />
          </div>
        </SetCard>
      ) : (
        <SetCard>
          <OrderAndWarmupColumn />
          <div className="set-reps">{unitReps}</div>
          <div className="set-reps-value">
            <p> {config.min_repetitions ? config.min_repetitions : '0'} </p>
          </div>
        </SetCard>
      )
      break
    case 'MYO REPS':
      cardType = editMode ? (
        <SetCard>
          <OrderAndWarmupColumn />
          <div className="set-reps">Activation Reps ({unitReps})</div>
          <div className="set-reps-value">
            <div className="multi-input-container">
              <input
                className="multi-input"
                maxLength="3"
                value={config.min_repetitions || ''}
                onKeyPress={(e) => onlyNumbers(e)}
                onChange={(e) => setMinReps(e.target.value)}
                onKeyDown={keyDown}
                autoFocus
              />
              <span> - </span>
              <input
                className="multi-input"
                maxLength="3"
                value={config.max_repetitions || ''}
                onKeyPress={(e) => onlyNumbers(e)}
                onChange={(e) => setMaxReps(e.target.value)}
              />
            </div>
          </div>
        </SetCard>
      ) : (
        <SetCard>
          <OrderAndWarmupColumn />
          <div className="set-reps">Activation Reps ({unitReps})</div>
          <div className="set-reps-value">
            {!!config.min_repetitions && !!config.max_repetitions ? (
              <p>
                {config.min_repetitions} - {config.max_repetitions}
              </p>
            ) : (
              <p>0-0</p>
            )}
          </div>
        </SetCard>
      )
      break
    case 'DELOAD':
      cardType = editMode ? (
        <SetCard threeColumns deload>
          <OrderAndWarmupColumn />
          <div className="set-reps">{unitReps}</div>
          <div className="set-reps-value">
            <input className="custom-input" maxLength="3" onKeyPress={(e) => onlyNumbers(e)} onKeyDown={keyDown} autoFocus />
          </div>
          <div className="set-range">Weight Cut</div>
          <div className="set-range-value">
            <input className="custom-input" maxLength="3" onKeyPress={(e) => onlyNumbers(e)} onKeyDown={keyDown} autoFocus />
            <span>%</span>
          </div>
          <div className="from">From</div>
          <div className="select">
            <Select type="text" placeholder="Week 5 top" onKeyPress={(e) => onlyNumbers(e)} />
          </div>
        </SetCard>
      ) : (
        <SetCard fourColumns>
          <OrderAndWarmupColumn />
          <span className="set-reps">{unitReps}</span>
          <div className="set-reps-value">
            <p>
              {!!config.min_repetitions ? config.min_repetitions : 0} {!!config.max_repetitions ? `- ${config.max_repetitions}` : ''}{' '}
            </p>
          </div>
          <span className="set-range">Weight Cut</span>
          <div className="set-range-value">
            <p>
              {exercise.deload_percent_min}
              {exercise.deload_percent_max > 0 ? ` - ${exercise.deload_percent_max}` : ''} %
            </p>
          </div>
          <span className="from">From</span>
          <div className="select">
            <p>Week {exercise.deload_week}</p>
          </div>
        </SetCard>
      )
      break
    case 'APT':
    case 'Fixed Reps / RIR':
      cardType = editMode ? (
        <SetCard threeColumns>
          <OrderAndWarmupColumn />
          <div className="set-reps">{unitReps}</div>
          <div className="set-reps-value">
            <PopoverInput
              config={config}
              keyDown={keyDown}
              onlyNumbers={onlyNumbers}
              setMinReps={setMinReps}
              setMaxReps={setMaxReps}
              exerciseTechnique={exerciseTechnique}
              addNormalSet={addNormalSet}
              addAmrapSet={addAmrapSet}
              popoverVisible={popoverVisible}
              setPopoverVisible={setPopoverVisible}
              singleInput
            />
          </div>
          <div className="set-range">RIR</div>
          <div className="set-range-value">
            <div className="multi-input-container">
              <input
                className="multi-input"
                maxLength="3"
                value={config.min_rir || ''}
                onKeyPress={(e) => onlyNumbers(e)}
                onChange={(e) => setMinRIR(e.target.value)}
                onKeyDown={keyDown}
                autoFocus
              />
              <span>-</span>
              <input
                className="multi-input"
                maxLength="3"
                value={config.max_rir || ''}
                onKeyPress={(e) => onlyNumbers(e)}
                onChange={(e) => setMaxRIR(e.target.value)}
              />
            </div>
          </div>
        </SetCard>
      ) : (
        <SetCard threeColumns>
          <OrderAndWarmupColumn />
          <div className="set-reps">{unitReps}</div>
          <div className="set-reps-value">{!!config.min_repetitions ? <p>{config.min_repetitions}</p> : <p>0</p>}</div>
          <div className="set-range">RIR</div>
          <div className="set-range-value">
            {!!config.min_rir && !!config.max_rir ? (
              <p>
                {config.min_rir} - {config.max_rir}
              </p>
            ) : (
              <p>0-0</p>
            )}
          </div>
        </SetCard>
      )
      break
    case 'Range Reps':
    case 'Mini Sets':
      cardType = editMode ? (
        <SetCard>
          <OrderAndWarmupColumn />
          <div className="set-reps"> {technique === 'Mini Sets' ? 'Mini Sets' : unitReps} </div>
          <div className="set-reps-value">
            <PopoverInput
              config={config}
              keyDown={keyDown}
              onlyNumbers={onlyNumbers}
              setMinReps={setMinReps}
              setMaxReps={setMaxReps}
              exerciseTechnique={exerciseTechnique}
              addNormalSet={addNormalSet}
              addAmrapSet={addAmrapSet}
              popoverVisible={popoverVisible}
              setPopoverVisible={setPopoverVisible}
            />
          </div>
        </SetCard>
      ) : (
        <SetCard>
          <OrderAndWarmupColumn />
          <div className="set-reps">{technique === 'Mini Sets' ? `Mini Sets (${unitReps})` : unitReps}</div>
          <div className="set-reps-value">
            {config.min_repetitions && config.max_repetitions ? (
              <p>
                {config.min_repetitions} - {config.max_repetitions}
              </p>
            ) : (
              <p>0</p>
            )}
          </div>
        </SetCard>
      )
      break
    case 'Range Reps / RIR':
      cardType = editMode ? (
        <SetCard threeColumns>
          <OrderAndWarmupColumn />
          <div className="set-reps"> {technique === 'Mini Sets' ? 'Mini Sets' : unitReps} </div>
          <div className="set-reps-value">
            <PopoverInput
              config={config}
              keyDown={keyDown}
              onlyNumbers={onlyNumbers}
              setMinReps={setMinReps}
              setMaxReps={setMaxReps}
              exerciseTechnique={exerciseTechnique}
              addNormalSet={addNormalSet}
              addAmrapSet={addAmrapSet}
              popoverVisible={popoverVisible}
              setPopoverVisible={setPopoverVisible}
            />
          </div>
          <div className="set-range">RIR</div>
          <div className="set-range-value">
            <div className="multi-input-container">
              <input
                className="multi-input"
                maxLength="3"
                value={config.min_rir || ''}
                onKeyPress={(e) => onlyNumbers(e)}
                onChange={(e) => setMinRIR(e.target.value)}
                onKeyDown={keyDown}
                autoFocus
              />
              <span>-</span>
              <input
                className="multi-input"
                maxLength="3"
                value={config.max_rir || ''}
                onKeyPress={(e) => onlyNumbers(e)}
                onChange={(e) => setMaxRIR(e.target.value)}
              />
            </div>
          </div>
        </SetCard>
      ) : (
        <SetCard threeColumns>
          <OrderAndWarmupColumn />
          <div className="set-reps">{unitReps}</div>
          <div className="set-reps-value">
            {config.min_repetitions && config.max_repetitions ? (
              <p>
                {config.min_repetitions} - {config.max_repetitions}
              </p>
            ) : (
              <p>0</p>
            )}
          </div>
          <div className="set-range">RIR</div>
          <div className="set-range-value">
            {!!config.min_rir && !!config.max_rir ? (
              <p>
                {config.min_rir} - {config.max_rir}
              </p>
            ) : (
              <p>0-0</p>
            )}
          </div>
        </SetCard>
      )
      break
    default:
      cardType = <> </>
      break
  }
  return cardType
}
