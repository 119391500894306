import React from 'react'
import { Popover } from 'antd'
const validAmrapSingleSetsTechniques = ['NORMAL', 'BFR', 'APT', 'RPT', '']
const PopoverInput = ({
  config,
  keyDown,
  onlyNumbers,
  setMinReps,
  setMaxReps,
  exerciseTechnique,
  addNormalSet,
  addAmrapSet,
  singleInput,
  popoverVisible,
  setPopoverVisible,
}) => {
  const inputs = singleInput ? (
    <input
      className="custom-input"
      maxLength="3"
      value={config.min_repetitions || ''}
      onKeyPress={(e) => onlyNumbers(e)}
      onChange={(e) => setMinReps(e.target.value)}
      onKeyDown={keyDown}
      autoFocus
    />
  ) : (
    <div className="multi-input-container">
      <input
        className="multi-input"
        maxLength="3"
        value={!!config.min_repetitions ? config.min_repetitions : ''}
        onKeyPress={(e) => onlyNumbers(e)}
        onChange={(e) => setMinReps(e.target.value)}
        onKeyDown={keyDown}
        autoFocus
      />
      <span>-</span>
      <input
        className="multi-input"
        maxLength="3"
        value={!!config.max_repetitions ? config.max_repetitions : ''}
        onKeyPress={(e) => onlyNumbers(e)}
        onChange={(e) => setMaxReps(e.target.value)}
        onKeyDown={keyDown}
      />
    </div>
  )
  const validateTechnique = exerciseTechnique ? exerciseTechnique.toUpperCase() : ''
  if (!validAmrapSingleSetsTechniques.includes(validateTechnique)) return inputs
  return (
    <Popover
      trigger="click"
      content={
        <>
          <p className="selected" onClick={addNormalSet}>
            {exerciseTechnique || 'NORMAL'}
          </p>
          <p onClick={addAmrapSet}>AMRAP</p>
        </>
      }
      visible={popoverVisible}
      onVisibleChange={setPopoverVisible}
      overlayClassName="context-menu-exercises"
    >
      {inputs}
    </Popover>
  )
}
export default PopoverInput
