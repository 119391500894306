import styled from '@emotion/styled'
import { Col } from 'antd'

export const ExerciseListCol = styled(Col)`
  height: 100%;
  margin: 0 auto;
  padding-right: 20px;

  ul {
    padding: 12px 0px;
  }
  .steps-content {
    height: 500px;
  }

  .container-overflow {
    height: calc(100% - ${(props) => `${props.heightlistcard}px`});
    padding-bottom: 27px;
    overflow-y: auto;
    @media (max-width: 990px) {
      max-height: 400px;
    }
  }
`

export const ConfigCol = styled(Col)`
  height: calc(100% - ${(props) => `${props.heightconfig}px`});
  display: flex;
  justify-content: center;

  .configuration {
    outline: none;
    width: 90%;
    margin: 0 auto;
    max-width: 350px;
  }

  div.section-deload {
    display: flex;
    justify-content: space-between;
    margin-top: 12px;
    > div {
      width: 47%;
      p {
        color: #959595;
        font-size: 12px;
      }
    }
    .multi-input-container {
      border-radius: 3px;
      border: 1px solid #d9d9d9;
      height: 32px;
      outline: none;
      display: flex;
      align-items: center;
      justify-content: center;
      .multi-input {
        width: 100%;
        border: none;
        outline: none;
        text-align: center;
        font-weight: 600;
        background-color: transparent;
      }
    }
  }

  border-left: 2px solid #f2f2f2;
  overflow-y: auto;

  p.section-title {
    color: #959595;
    font-size: 12px;
    margin: 5px 0px;
  }
`

export const WeeksContainer = styled.div`
  margin: 0px 22px;
  height: inherit;

  .program-details {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;
    margin-bottom: 20px;

    .edit-link {
      float: right;
      font-weight: 600;
      font-size: 14px;
      color: #ff6b00;
      cursor: pointer;
    }
  }

  .routine-details {
    label {
      font-weight: 500;
      font-size: 11px;
      color: #959595;
      display: block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      span {
        font-weight: 500;
        font-size: 14px;
        color: #5d5d5d;
      }
    }

    p {
      font-weight: 500;
      font-size: 14px;
      color: #5d5d5d;

      b {
        font-size: 14px;
        font-weight: 600;
      }
    }

    .ant-row {
      margin-bottom: 0px;
      //height: auto;
    }
  }

  .routine-weeks {
    p.title {
      font-weight: 500;
      font-size: 12px;
      color: #959595;
    }
    .ant-tabs {
      height: ${(props) => (props.editfour ? '370px' : '479px')};
      @media (min-height: 1000px) {
        height: 100vh;
      }
      @media (max-width: 990px) {
        height: unset;
        .ant-col-md-12 {
          max-height: 400px;
        }
      }
      .ant-tabs-content-holder {
        .ant-tabs-content {
          height: 100%;
          .ant-row {
            height: 100%;
            @media (max-width: 990px) {
              display: grid;
              grid-row-gap: 50px !important;
            }
          }
        }
      }
    }
  }

  div.section-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
  }

  p.section-title {
    color: #959595;
    font-size: 12px;
    margin: 5px 0px;
  }

  .super-set-config {
    display: flex;
    align-items: center;
    justify-content: start;
    margin-bottom: 8px;

    p {
      font-weight: 500;
      margin: 0 10px;
      :nth-of-type(1) {
        margin-left: 0;
      }
    }
  }

  .container-overflow {
    padding: 15px;
    border-left: 2px solid #f2f2f2;
    overflow-y: auto;
  }

  .empty-style {
    .ant-empty-description {
      margin-top: 20px !important;
    }
  }
  @media (min-height: 500px) {
    .routine-weeks {
      height: inherit;
      .ant-tabs {
        height: inherit;
        .ant-tabs-content-holder {
          height: inherit;

          .ant-tabs-tabpane {
            height: calc(100% - 50px);
            .show-scrollbars,
            .container-overflow {
              height: calc(100% - ${(props) => (props.editfour ? '160' : '80')}px);
            }
            .section-header + .container-overflow {
              height: calc(100% - ${(props) => (props.editfour ? '300' : '80')}px);
            }
          }
          .ant-tabs-content {
            @media (max-height: 770px) {
              height: calc(100% - 45px);
            }
          }
        }
      }
    }
  }
`

export const AddButton = styled.div`
  outline: none;
  border: none;
  color: #ff6b00;
  font-weight: 600;
  font-size: 16px;
  background: transparent;

  :hover {
    cursor: pointer;
  }
`

export const SetCardContainer = styled.div`
  display: grid;
  grid-template-columns: ${(props) => (props.editMode ? '90% 10%' : '100%')};
  column-gap: ${(props) => (props.editMode ? '5px' : null)};
  align-items: center;
  justify-content: center;

  .trash-button {
    border: 0;
    background-color: transparent;
  }
`
