import React, { Component } from 'react'
import { Query } from 'react-apollo'
import { get } from 'lodash'
import { getDateReview } from 'services'
import { getData } from 'queries/bootcamp'
import { getTotalAmount } from './query'
import { StyledTitle, StyledTable } from 'styled'
import {
  Root,
  ClientsData,
  ClientsCount,
  ClientsCountText,
  ClientsCountValue,
  ClientsDataWrap,
  ClientsCountWrap,
  TotalClients,
  StyledLink,
} from './ClientsInfo.styled'

const columns = [
  {
    title: '',
    dataIndex: 'level',
  },
  {
    title: '1st Time',
    dataIndex: 'first_time',
  },
  {
    title: 'Resubscribers',
    dataIndex: 'resubscribers',
  },
  {
    title: 'Total',
    dataIndex: 'total',
  },
]
const levelAmount = 3
const { actualWeekStart: forDate } = getDateReview()

class ClientsInfo extends Component {
  render() {
    return (
      <Query query={getData}>
        {({ error, data }) => {
          if (error) {
            console.error(error)
            return null
          }
          const {
            Bootcamp: { id: programId },
          } = data

          return (
            <Query query={getTotalAmount} fetchPolicy="cache-and-network" variables={{ forDate, programId }}>
              {({ loading, error, data }) => {
                if (error || loading) return null
                const totalAmount = get(data, 'total_amount.aggregate.count') || null
                const detailsAmounts = {}
                detailsAmounts.level_1 = get(data, 'level_1.aggregate.count') || null
                detailsAmounts.level_2 = get(data, 'level_2.aggregate.count') || null
                detailsAmounts.level_3 = get(data, 'level_3.aggregate.count') || null

                const trainees = {}
                trainees.level_1 = get(data, 'level_1.nodes') || []
                trainees.level_2 = get(data, 'level_2.nodes') || []
                trainees.level_3 = get(data, 'level_3.nodes') || []

                const dataClients = []
                for (let i = 1; i <= levelAmount; i++) {
                  const total = detailsAmounts[`level_${i}`]
                  const first_time = trainees[`level_${i}`].filter((item) => item.selected_aggregate.aggregate.count < 2).length
                  const resubscribers = total - first_time
                  dataClients.push({
                    key: i,
                    level: `L${i}`,
                    total,
                    first_time,
                    resubscribers,
                  })
                }
                const sentReview = get(data, 'sent_review.aggregate.count') || 0
                const percentageEngagement = Math.round((sentReview / totalAmount) * 100)
                const unassignedTrainees = get(data, 'unassigned.aggregate.count') || 0

                return (
                  <Root>
                    <ClientsData>
                      <StyledTitle>Clients data</StyledTitle>
                      <ClientsDataWrap>
                        <StyledTable
                          {...{
                            columns: columns,
                            dataSource: dataClients,
                            pagination: false,
                            bordered: false,
                          }}
                        />
                        <TotalClients>
                          Total Clients: <span>{totalAmount}</span>
                        </TotalClients>
                      </ClientsDataWrap>
                      <ClientsCountWrap>
                        <ClientsCount custom="engagement">
                          <ClientsCountText>
                            Client <span>Engagement</span>
                          </ClientsCountText>
                          <ClientsCountValue>
                            {percentageEngagement}
                            <span>%</span>
                          </ClientsCountValue>
                        </ClientsCount>
                        <StyledLink to="/clients-assigned">
                          <ClientsCount custom="assigned">
                            <ClientsCountText>
                              Clients to be <span>Assigned</span>
                            </ClientsCountText>
                            <ClientsCountValue custom="assigned">{unassignedTrainees}</ClientsCountValue>
                          </ClientsCount>
                        </StyledLink>
                      </ClientsCountWrap>
                    </ClientsData>
                  </Root>
                )
              }}
            </Query>
          )
        }}
      </Query>
    )
  }
}

export default ClientsInfo
