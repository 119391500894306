import { Table } from 'antd'
import styled from '@emotion/styled'

export default styled(Table)`
  font-family: 'Montserrat', sans-serif;
  width: 100%;
  .ant-table {
    font-size: 20px;
    table {
      text-align: center;
    }
  }
  .ant-table-thead {
    tr {
      th {
        font-weight: 600;
        font-size: 16px;
        text-align: center;
        border-bottom: 2px solid #f3f3f3;
        background-color: transparent;
      }
      td {
        border-bottom: 2px solid #f3f3f3;
      }
    }
  }
  .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
    background-color: #f9f9f9;
  }
  .ant-table-row {
    td:first-of-type {
      font-weight: 600;
    }
  }
`
