import ApolloClient from 'apollo-boost'
import { navigate } from '@reach/router'
import { getCookie } from 'tiny-cookie'
import { LOCAL_STORAGE, COOKIE, DB } from 'constants.js'

export default new ApolloClient({
  uri: DB,
  headers: {
    'Content-Type': 'application/json',
  },
  request: (operation) => {
    if (!getCookie(COOKIE.USER_TOKEN)) {
      navigate('/login')
    } else {
      const token = getCookie(COOKIE.USER_TOKEN)
      operation.setContext({
        headers: {
          Authorization: token,
        },
      })
    }
  },
  clientState: {
    defaults: {
      User: {
        id: localStorage.getItem(LOCAL_STORAGE.USER_ID) === 'null' ? null : localStorage.getItem(LOCAL_STORAGE.USER_ID),
        contact_id: localStorage.getItem(LOCAL_STORAGE.CONTACT_ID) === 'null' ? null : localStorage.getItem(LOCAL_STORAGE.CONTACT_ID),
        nickname: localStorage.getItem(LOCAL_STORAGE.USER_NICKNAME) === 'null' ? null : localStorage.getItem(LOCAL_STORAGE.USER_NICKNAME),
        picture: localStorage.getItem(LOCAL_STORAGE.USER_PICTURE) === 'null' ? null : localStorage.getItem(LOCAL_STORAGE.USER_PICTURE),
        roles: !!localStorage.getItem(LOCAL_STORAGE.USER_ROLES) ? localStorage.getItem(LOCAL_STORAGE.USER_ROLES).split(',') : [],
        __typename: 'User',
      },
      Bootcamp: {
        id: localStorage.getItem(LOCAL_STORAGE.BOOTCAMP_ID) === 'null' ? null : localStorage.getItem(LOCAL_STORAGE.BOOTCAMP_ID),
        start_date:
          localStorage.getItem(LOCAL_STORAGE.BOOTCAMP_START_DATE) === 'null' ? null : localStorage.getItem(LOCAL_STORAGE.BOOTCAMP_START_DATE),
        end_date: localStorage.getItem(LOCAL_STORAGE.BOOTCAMP_END_DATE) === 'null' ? null : localStorage.getItem(LOCAL_STORAGE.BOOTCAMP_END_DATE),
        description:
          localStorage.getItem(LOCAL_STORAGE.BOOTCAMP_DESCRIPTION) === 'null' ? null : localStorage.getItem(LOCAL_STORAGE.BOOTCAMP_DESCRIPTION),
        __typename: 'Bootcamp',
      },
      App: {
        loading: false,
        __typename: 'Application',
      },
    },
  },
})
