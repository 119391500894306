import React, { useEffect } from 'react'
import { Row, Col, Form, Tabs, Input, Icon, Button } from 'antd'
import { bootcampBanners } from './query'
import { BannerContainer } from './Bootcamps.styled'

import GreenBanner from 'images/banner-green.png'
import RedBanner from 'images/banner-red.png'

const FormItem = Form.Item
const { TabPane } = Tabs

const Banners = ({ banners, edit, form }) => {
  useEffect(() => {
    let fields = {}

    bootcampBanners.forEach((banner) => {
      let newBanner = banners.find((bdb) => bdb.phase_key === banner.phase_key)
      if (newBanner) {
        fields[`Banners.${banner.phase_key}.title`] = newBanner.title
        fields[`Banners.${banner.phase_key}.subtitle`] = newBanner.subtitle
        fields[`Banners.${banner.phase_key}.button`] = newBanner.button
        fields[`Banners.${banner.phase_key}.days_to_show`] = newBanner.days_to_show
      }
    })

    form.setFieldsValue(fields)
  }, [banners])

  return (
    <div className="content-shadow">
      <Tabs defaultActiveKey="on_time">
        {bootcampBanners.map((b) => {
          let banner = banners.find((bdb) => bdb.phase_key === b.phase_key) || b

          let card = RedBanner

          if (banner.phase_key === 'on_time') {
            card = GreenBanner
          }

          return (
            <TabPane tab={banner.phase_title} key={banner.phase_key} forceRender={true}>
              <Row>
                <Col md={12} sm={24} xs={24}>
                  <p>Preview Card</p>
                  <BannerContainer background={card} status={banner.phase_key}>
                    <div className="banner">
                      <h2>{form.getFieldValue(`Banners.${banner.phase_key}.title`) || banner.title}</h2>
                      <h3>{form.getFieldValue(`Banners.${banner.phase_key}.subtitle`) || banner.subtitle}</h3>
                      <hr />
                      <div className="actions">
                        <Button type="link" shape="round" size={'small'}>
                          {form.getFieldValue(`Banners.${banner.phase_key}.button`) || banner.button}
                          <Icon type="right" />
                        </Button>
                      </div>
                    </div>
                  </BannerContainer>
                </Col>
                <Col md={12} sm={24} xs={24}>
                  <p>Bootcamp Banners</p>
                  <p className="title">Title message</p>
                  {!edit && <p>{banner.title}</p>}
                  {edit && (
                    <FormItem
                      name={`Banners.${banner.phase_key}.title`}
                      rules={[
                        {
                          max: 65,
                          message: 'The max length is 65 characters',
                        },
                      ]}
                    >
                      <Input />
                    </FormItem>
                  )}
                  <p className="title">Subtitle message</p>
                  {!edit && <p>{banner.subtitle}</p>}
                  {edit && (
                    <FormItem
                      name={`Banners.${banner.phase_key}.subtitle`}
                      rules={[
                        {
                          max: 40,
                          message: 'The max length is 40 characters.',
                        },
                      ]}
                    >
                      <Input />
                    </FormItem>
                  )}
                  <Row>
                    <Col md={12} xs={24}>
                      <p className="title">Button message</p>
                      {!edit && <p>{banner.button}</p>}
                      {edit && (
                        <FormItem
                          name={`Banners.${banner.phase_key}.button`}
                          rules={[
                            {
                              max: 25,
                              message: 'The max length is 25 characters',
                            },
                          ]}
                        >
                          <Input />
                        </FormItem>
                      )}
                    </Col>
                    <Col md={12} xs={24}>
                      <p className="title">First warning date</p>
                      {!edit && (
                        <p>
                          {parseInt(banner.days_to_show)} days {banner.days_to_show <= 0 ? 'before' : 'after'}
                        </p>
                      )}
                      {edit && (
                        <FormItem
                          name={`Banners.${banner.phase_key}.days_to_show`}
                          rules={[
                            {
                              validator: (rule, value, callback) => {
                                if (!!!value) callback()
                                if (banner.phase_key === 'delayed') {
                                  if (value <= form.getFieldValue('Banners.on_time.days_to_show')) callback(false)
                                }
                                callback()
                              },
                              message: 'The value cannot be lower or equal than On-Time value.',
                            },
                            {
                              validator: (rule, value, callback) => {
                                if (!!!value) callback()
                                if (banner.phase_key === 'delayed') {
                                  if (value > -1) callback(false)
                                }
                                callback()
                              },
                              message: 'The value cannot be higher than -1.',
                            },
                          ]}
                        >
                          <Input disabled={banner.phase_key !== 'delayed'} />
                        </FormItem>
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </TabPane>
          )
        })}
      </Tabs>
    </div>
  )
}

export default Banners
