import React, { Fragment, useEffect } from 'react'
import { Row, Col, Form, Tabs, Input } from 'antd'
import { infusionSoftTags } from './query'

const FormItem = Form.Item
const { TabPane } = Tabs

const ExternalTools = ({ tags, urls, edit, form }) => {
  const learning = urls.find((u) => u.type === 'learning')
  const learningURL = !!learning && learning.url.length > 0 ? learning.url : null
  const foundation = urls.find((u) => u.type === 'foundation')
  const flex = urls.find((u) => u.type === 'flex')
  const foundationURL = !!foundation && foundation.url.length > 0 ? foundation.url : null
  const flexURL = !!flex && flex.url.length > 0 ? flex.url : null

  useEffect(() => {
    let fields = {}

    infusionSoftTags.forEach((ift) => {
      let tag = tags.find((t) => t.key === ift)
      fields[`tags[${ift}]`] = tag ? tag.value : null
    })

    const newLearning = urls.find((u) => u.type === 'learning')
    fields['Learning.Url'] = newLearning && newLearning.url && newLearning.url.length > 0 ? newLearning.url : null
    const newFlex = urls.find((u) => u.type === 'flex')
    fields['Flex.Url'] = newFlex && newFlex.url && newFlex.url.length > 0 ? newFlex.url : null
    const newFoundation = urls.find((u) => u.type === 'foundation')
    fields['Foundation.Url'] = newFoundation && newFoundation.url && newFoundation.url.length > 0 ? newFoundation.url : null

    form.setFieldsValue(fields)
  }, [tags, urls])

  return (
    <div className="content-shadow infusionsoft-tags">
      <Tabs defaultActiveKey="infusionsoft">
        <TabPane tab="InfusionSoft" key="infusionsoft" forceRender={true}>
          <p>We use this tags to sync the actions of the students in portal with InfusionSoft</p>
          <Row gutter={[2, 2]}>
            {infusionSoftTags.map((ift) => {
              const tag = tags.find((t) => t.key === ift) || {}
              return (
                <Fragment key={ift}>
                  <Col md={6} sm={6} xs={24}>
                    <p className="title">{ift}</p>
                    {!edit && <p>{!!tag.value ? tag.value : 'Not configured'}</p>}
                    {edit && (
                      <FormItem name={`tags[${ift}]`} value={tag.value || ''}>
                        <Input />
                      </FormItem>
                    )}
                  </Col>
                </Fragment>
              )
            })}
          </Row>
        </TabPane>
        <TabPane tab="Learning and Groups" key="gainspot" forceRender={true}>
          <p className="title">Learning Course Url</p>
          {!edit && <p>{!!learningURL ? learningURL : 'Not configured'}</p>}
          {edit && (
            <FormItem name="Learning.Url">
              <Input placeholder="https://drive.google.com/open?id=000000000…" />
            </FormItem>
          )}
          <p className="title">Foundations Group URL</p>
          {!edit && <p>{!!foundationURL ? foundationURL : 'Not configured'}</p>}
          {edit && (
            <FormItem name="Foundation.Url">
              <Input placeholder="https://drive.google.com/open?id=000000000…" />
            </FormItem>
          )}
          <p className="title">Flex Group URL</p>
          {!edit && <p>{!!flexURL ? flexURL : 'Not configured'}</p>}
          {edit && (
            <FormItem name="Flex.Url">
              <Input placeholder="https://drive.google.com/open?id=000000000…" />
            </FormItem>
          )}
        </TabPane>
      </Tabs>
    </div>
  )
}

export default ExternalTools
