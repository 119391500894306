import React, { useEffect, useState } from 'react'
import { pick } from 'lodash'
import { Button, Tabs, notification, Form, Input } from 'antd'
import { FileOutlined, ProfileOutlined, ExportOutlined } from '@ant-design/icons'
import { BootcampDetailsCard } from './Bootcamps.styled'
import { bootcampBanners, upsertBootcamp, createProgram } from './query'
import BootcampInformation from './BootcampInformation'
import Banners from './BootcampBanners'
import ExternalTools from './BootcampExternals'
import Timeline from './BootcampTimeline'
import Levels from './BootcampLevels'
import AutomatizationProcess from './BootcampAutomatizationProcess'
import { Mutation } from 'react-apollo'
import { ReactComponent as TimelineIcon } from '../../images/icon-timeline.svg'

const FormItem = Form.Item
const { TabPane } = Tabs

const BootcampDetails = ({ bootcamp, isNew, refetch }) => {
  const [edit, setEdit] = useState(false)
  const [bootcampToShow, setBootcampToShow] = useState(bootcamp)
  const [form] = Form.useForm()

  useEffect(() => {
    setEdit(isNew)
    setBootcampToShow(bootcamp)
  }, [isNew, bootcamp])

  const handleEdit = (isEdit, refetch) => {
    setEdit(isEdit)
    if (!isEdit) refetch()
  }

  const SubmitBootcamp = (mutation, refetch, levels, programId) => {
    form
      .validateFields()
      .then((data) => {
        const values = data

        let bootcampData = pick(values, ['description', 'product_tag', 'duration', 'access_date', 'close_date'])
        bootcampData.program_id = programId
        bootcampData.start_date = values.start_end_date[0]
        bootcampData.end_date = values.start_end_date[1]
        bootcampData.type = 'bootcamp'
        bootcampData.created_by = localStorage.getItem('USER_NICKNAME')
        bootcampData.configuration = { levels: [] }
        bootcampData.timeline = JSON.parse(localStorage.getItem('CurrentTimeline'))
        bootcampData.coach_letters_delivery_date = values.coach_letters_delivery_date !== null ? values.coach_letters_delivery_date.format() : null
        bootcampData.group_delivery_date = values.group_delivery_date !== null ? values.group_delivery_date.format() : null
        bootcampData.exercises_delivery_date = values.exercises_delivery_date !== null ? values.exercises_delivery_date.format() : null

        let tagKeys = Object.keys(values).filter((v) => v.indexOf('tags[') !== -1)
        let tags = []
        tagKeys.forEach((key) => {
          let keyFormat = key.replace('tags[', '').replace(']', '')
          tags.push({
            id: keyFormat,
            program_id: programId,
            value: values[key],
          })
        })

        let urls = []
        let levelsConfigured = []
        levels.forEach((level) => {
          let levelConfigured = { level: level.level, sublevels: [] }
          level.sublevels.forEach((sublevel) => {
            let sublevelConfigured = { id: sublevel.id }
            const sublevelUrls = `Levels.Level${level.level}.Sublevel${sublevel.id}`
            if (!!values[`${sublevelUrls}.excercise`]) {
              urls.push({
                program_id: programId,
                level: level.level,
                type: 'exercise',
                url: values[`${sublevelUrls}.excercise`],
                sub_level: sublevel.id,
              })
            }
            if (!!values[`${sublevelUrls}.home_exercise`]) {
              urls.push({
                program_id: programId,
                level: level.level,
                type: 'exercise_home',
                url: values[`${sublevelUrls}.home_exercise`],
                sub_level: sublevel.id,
              })
            }
            levelConfigured.sublevels.push(sublevelConfigured)
          })
          levelsConfigured.push(levelConfigured)
        })
        bootcampData.configuration.levels = levelsConfigured

        if (!!values[`Foundation.Url`]) {
          urls.push({
            program_id: programId,
            type: 'foundation',
            url: values[`Foundation.Url`],
            level: 0,
            sub_level: '',
          })
        }
        if (!!values[`Flex.Url`]) {
          urls.push({
            program_id: programId,
            type: 'flex',
            url: values[`Flex.Url`],
            level: 0,
            sub_level: '',
          })
        }

        if (!!values[`Learning.Url`]) {
          urls.push({
            program_id: programId,
            type: 'learning',
            url: values[`Learning.Url`],
            level: 0,
            sub_level: '',
          })
        }

        let banners = []
        bootcampBanners.forEach((banner) => {
          banners.push({
            program_id: programId,
            phase_key: banner.phase_key,
            phase_title: banner.phase_title,
            title: values[`Banners.${banner.phase_key}.title`],
            subtitle: values[`Banners.${banner.phase_key}.subtitle`],
            button: values[`Banners.${banner.phase_key}.button`],
            days_to_show: values[`Banners.${banner.phase_key}.days_to_show`],
          })
        })

        let variables = {}
        if (!!programId) {
          variables = {
            data: bootcampData,
            tags,
            urls,
            banners,
          }
        } else {
          bootcampData = {
            ...bootcampData,
            program_urls: { data: urls },
            banners: { data: banners },
            infusionsoft_tags: { data: tags },
          }
          variables = { data: bootcampData }
        }

        mutation({
          variables,
        })
          .then(() => {
            notification.success({
              message: 'Bootcamp Saved',
              description: 'The bootcamp was saved successfully.',
            })

            setEdit(false)
            refetch()
          })
          .catch((error) => {
            notification.error({
              message: 'Error while trying to save the Bootcamp',
              description: `Error from DB: ${error.message}.`,
            })
          })
      })
      .catch((error) => {
        console.log('error :>> ', error)
        let validations = error.errorFields.map((field) => field.errors.join('<br />')).join('<br />')
        notification.error({
          message: 'Please complete all the required fields.',
          description: <div dangerouslySetInnerHTML={{ __html: validations }}></div>,
        })
      })
  }

  const getDescription = (description) => {
    if (!edit) {
      return description
    } else {
      return (
        <FormItem
          name="description"
          rules={[
            {
              required: true,
              message: 'Please enter a name of the bootcamp.',
            },
          ]}
        >
          <Input className="bootcamp-name" />
        </FormItem>
      )
    }
  }

  let mutationToExecute = upsertBootcamp
  if (!!!bootcampToShow.program_id) mutationToExecute = createProgram

  return (
    <Mutation mutation={mutationToExecute}>
      {(mutation) => (
        <Form autoComplete="off" form={form} onFinish={() => SubmitBootcamp(mutation, refetch, bootcampToShow.levels, bootcampToShow.program_id)}>
          <BootcampDetailsCard title={getDescription(bootcampToShow.description)} bordered={false} className={bootcampToShow.type}>
            <BootcampInformation {...{ form, bootcamp: bootcampToShow, edit }} />
            <Tabs type="card">
              <TabPane
                tab={
                  <span>
                    <FileOutlined /> Levels
                  </span>
                }
                key="1"
                forceRender={true}
              >
                <Levels {...{ edit, levels: bootcampToShow.levels, form }} />
              </TabPane>
              <TabPane
                tab={
                  <span>
                    <ProfileOutlined /> Banners
                  </span>
                }
                key="2"
                forceRender={true}
              >
                <Banners {...{ banners: bootcampToShow.banners, edit, form }} />
              </TabPane>
              <TabPane
                tab={
                  <span>
                    <TimelineIcon /> Timeline
                  </span>
                }
                key="3"
                forceRender={true}
              >
                <Timeline {...{ timeline: bootcampToShow.timeline, edit, form }} />
              </TabPane>
              <TabPane
                tab={
                  <span>
                    <ExportOutlined />
                    External Tools
                  </span>
                }
                key="4"
                forceRender={true}
              >
                <ExternalTools {...{ tags: bootcampToShow.infusionsoft_tags, urls: bootcampToShow.program_urls, edit, form }} />
              </TabPane>
              <TabPane
                tab={
                  <span>
                    <ExportOutlined />
                    Automatization Process
                  </span>
                }
                key="5"
                forceRender={true}
              >
                <AutomatizationProcess {...{ form, bootcamp: bootcampToShow, edit }} />
              </TabPane>
            </Tabs>

            <hr />
            <div className="actions">
              {!edit && (
                <Button type="primary" onClick={() => handleEdit(true)}>
                  Edit Bootcamp
                </Button>
              )}
              {edit && (
                <>
                  <Button onClick={() => handleEdit(false, refetch)}>Cancel</Button>
                  <Button type="primary" onClick={() => SubmitBootcamp(mutation, refetch, bootcampToShow.levels, bootcampToShow.program_id)}>
                    Save {!isNew ? 'Changes' : ''}
                  </Button>
                </>
              )}
            </div>
          </BootcampDetailsCard>
        </Form>
      )}
    </Mutation>
  )
}

export default BootcampDetails
