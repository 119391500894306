import React, { useEffect, useState } from 'react'
import Layout from 'components/Layout'
import { MainContainer, CoachListItem } from './ClientsAssigned.styled'
import { Row, Col, Table, Button, Select, Input, Result, Popconfirm, notification } from 'antd'
import Loader from 'components/Loader'
import apolloClient from 'apolloClient.js'
import gql from 'graphql-tag'
import moment from 'moment'
import { apiServices } from 'services/api'

const { Option } = Select
const { Search } = Input

const getInitialData = gql`
  query getBootcamps($today: date!) {
    program(where: { _and: [{ type: { _eq: "bootcamp" } }, { end_date: { _gt: $today } }] }, order_by: { start_date: desc }, limit: 6) {
      program_id
      description
      access_date
      start_date
    }
    coach(where: { _and: [{ is_active: { _eq: true } }, { max_students: { _gt: 0 } }] }, order_by: { user: { first_name: asc } }) {
      id
      max_students
      levels
      user {
        first_name
        last_name
      }
    }
  }
`

const getStudents = gql`
  query getStudents($program_id: uuid!) {
    view_coach_assignation(where: { current_program_id: { _eq: $program_id } }) {
      trainee_id
      contact_id
      first_name
      last_name
      email
      level
      coach_id
      total_past_bootcamp
      last_bootcamp
      last_bootcamp_coach
    }
  }
`

const setCoachesForTrainee = gql`
  mutation($coachId: uuid!, $ids: [uuid]!, $programId: uuid!) {
    update_trainee_program_coach(where: { trainee_id: { _in: $ids }, _and: { program_id: { _eq: $programId } } }, _set: { coach_id: $coachId }) {
      returning {
        trainee_id
        trainee {
          user {
            first_name
            last_name
          }
        }
        coach_id
        coach {
          user {
            first_name
            last_name
          }
        }
        program_id
      }
    }
  }
`

export default () => {
  const [students, setStudents] = useState([])
  const [searchText, setSearchText] = useState()
  const [bootcamps, setBootcamps] = useState([])
  const [bootcampSelected, setBootcampSelected] = useState()
  const [coaches, setCoaches] = useState([])
  const [coachSelected, setCoachSelected] = useState('unassigned')
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState()
  const [loadingStudents, setLoadingStudents] = useState(true)
  const [studentsToAssign, setStudentsToAssign] = useState([])
  const [saveDisabled, setSaveDisabled] = useState(true)
  const [showConfirmSave, setShowConfirmSave] = useState(false)
  const [confirmSaveLoading, setConfirmSaveLoading] = useState(false)

  const updateStudentsToAssign = (trainee_id, coach_id, level) => {
    let studentsToAssignAux = JSON.parse(JSON.stringify(studentsToAssign))
    if (studentsToAssignAux.length > 0) {
      if (coach_id === 'unassigned') coach_id = null
      const originalStudent = students.find((s) => s.trainee_id === trainee_id)
      if (originalStudent && originalStudent.coach_id === coach_id) {
        studentsToAssignAux = studentsToAssign.filter((s) => s.trainee_id !== trainee_id)
      } else {
        const studentIndex = studentsToAssignAux.findIndex((s) => s.trainee_id === trainee_id)
        if (studentIndex > -1) studentsToAssignAux[studentIndex] = { trainee_id, coach_id, level }
        else studentsToAssignAux.push({ trainee_id, coach_id, level })
      }
    } else {
      studentsToAssignAux.push({ trainee_id, coach_id, level })
    }

    setStudentsToAssign(studentsToAssignAux)
    setSaveDisabled(studentsToAssignAux.length === 0)
  }

  const columns = [
    {
      title: 'Contact ID',
      dataIndex: 'contact_id',
      key: 'contact_id',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => {
        if (a.first_name < b.first_name) {
          return -1
        }
        if (a.first_name > b.first_name) {
          return 1
        }
        return 0
      },
      render: (name, record) => {
        return `${record.first_name} ${record.last_name}`
      },
    },
    {
      title: 'Level',
      dataIndex: 'level',
      key: 'level',
      sorter: (a, b) => {
        if (a.level < b.level) {
          return -1
        }
        if (a.level > b.level) {
          return 1
        }
        return 0
      },
    },
    {
      title: 'Last Bootcamp',
      dataIndex: 'last_bootcamp',
      key: 'last_bootcamp',
      render: (history, record) => {
        if (record.total_past_bootcamp <= 0 && !record.last_bootcamp) return ''
        return (
          <div>
            <p>{record.last_bootcamp}</p>
            <p key={1}>
              <small>Total Past Bootcamps: {record.total_past_bootcamp}</small>{' '}
            </p>
          </div>
        )
      },
    },
    {
      title: 'Last Bootcamp Coach',
      dataIndex: 'last_bootcamp_coach',
      key: 'last_bootcamp_coach',
    },
    {
      title: 'Coach Assigned',
      dataIndex: 'current_coach',
      key: 'current_coach',
      render: (current_coach, record) => {
        let coachAssigned = record.coach_id

        if (studentsToAssign.length > 0) {
          const student = studentsToAssign.find((s) => s.trainee_id === record.trainee_id)
          if (student) {
            coachAssigned = student.coach_id
          }
        }

        if (!coachAssigned) {
          coachAssigned = 'unassigned'
        }

        let coachesAux = coaches.filter((c) => c.levels.find((cl) => cl.level === record.level))
        coachesAux = coachesAux.concat(
          coaches.filter((c) => {
            return c.levels.find((cl) => cl.level !== record.level) && !coachesAux.find((ca) => ca.id === c.id)
          }),
        )

        return (
          <Select
            style={{ width: '220px' }}
            defaultValue={coachAssigned}
            onChange={(value) => updateStudentsToAssign(record.trainee_id, value, record.level)}
          >
            <Option value="unassigned" disabled={coachSelected !== 'unassigned'}>
              Unassigned
            </Option>
            {coachesAux.map((coach) => {
              let studentsAssigned = students.filter((s) => s.coach_id === coach.id)
              studentsAssigned = studentsAssigned.concat(studentsToAssign.filter((s) => s.coach_id === coach.id))
              const levelsAssigned = []

              let uniqueLevels = [...new Set(studentsAssigned.map((s) => s.level))]
              let uniqueLevelsConfigured = [...new Set(coach.levels.map((l) => l.level))]
              uniqueLevels = uniqueLevels.concat(uniqueLevelsConfigured)
              uniqueLevels = [...new Set(uniqueLevels)]
              uniqueLevels = uniqueLevels.sort()
              uniqueLevels.forEach((ul) => {
                levelsAssigned.push(`<b>L${ul}</b> (${studentsAssigned.filter((s) => s.level === ul).length})`)
              })

              return (
                <Option key={coach.id} value={coach.id}>
                  <CoachListItem>
                    <p>{`${coach.user.first_name} ${coach.user.last_name}`}</p>
                    <p>
                      <small>
                        {levelsAssigned.length > 0 && (
                          <>
                            <span dangerouslySetInnerHTML={{ __html: levelsAssigned.join(' ') }} />
                            {' - '}
                          </>
                        )}
                        <span>
                          <b>Total </b>
                          {`${studentsAssigned.length}/${coach.max_students}`}
                        </span>
                      </small>
                    </p>
                  </CoachListItem>
                </Option>
              )
            })}
          </Select>
        )
      },
    },
  ]

  useEffect(() => {
    const main = async () => {
      apolloClient
        .query({
          query: getInitialData,
          variables: { today: moment().local().format('YYYY-MM-DD') },
        })
        .then((response) => {
          if (response.data) {
            let upcomingBootcamp = response.data.program.find((bootcamp) =>
              moment().local().isBetween(moment(bootcamp.access_date).local(), moment(bootcamp.start_Date).local()),
            )

            if (!upcomingBootcamp) {
              upcomingBootcamp = response.data.program[0]
            }

            setBootcampSelected(upcomingBootcamp.program_id)
            setCoaches(response.data.coach)
            setBootcamps(response.data.program)
            setLoading(false)
          }
        })
        .catch((e) => {
          setLoading(false)
          setError(`${e}`)
        })
    }
    main()
  }, [])

  useEffect(() => {
    updateStudentsTable()
  }, [bootcampSelected])

  const updateStudentsTable = () => {
    setLoadingStudents(true)

    if (bootcampSelected) {
      apolloClient
        .query({
          query: getStudents,
          variables: { program_id: bootcampSelected },
          fetchPolicy: 'network-only',
        })
        .then((response) => {
          if (response.data) {
            setStudents(response.data.view_coach_assignation)
            setLoadingStudents(false)
          }
        })
        .catch((e) => {
          setLoadingStudents(false)
          setError(`${e}`)
        })
    }
  }

  const saveChanges = () => {
    setConfirmSaveLoading(true)

    const uniqueCoaches = [...new Set(studentsToAssign.map((s) => s.coach_id))]
    let savePromises = []
    for (const coach of uniqueCoaches) {
      savePromises.push(
        new Promise((resolve) => {
          const selectedTrainees = studentsToAssign.filter((s) => s.coach_id === coach).map((s) => s.trainee_id)

          apolloClient
            .mutate({
              mutation: setCoachesForTrainee,
              variables: {
                coachId: coach === 'unassigned' ? null : coach,
                ids: selectedTrainees,
                programId: bootcampSelected,
              },
            })
            .then(async ({ data }) => {
              let coachLabel = 'Coach'
              const coachObject = coaches.find((c) => c.id === coach)

              if (coachObject) {
                coachLabel = `${coachObject.user.first_name} ${coachObject.user.last_name}`
              }

              if (data.update_trainee_program_coach) {
                for (const traineeSelected of selectedTrainees) {
                  await apiServices('POST', `infusion-soft/update-coach-and-level`, { traineeId: traineeSelected, programId: bootcampSelected })
                    .then((response) => {
                      return response.json()
                    })
                    .then((resp) => {
                      if (resp.code !== 204) {
                        console.log('resp :>> ', resp)
                      }
                    })
                    .catch((error) => {
                      console.log(error)
                    })
                }
                resolve({ message: `Success for coach ${coachLabel}`, coach_id: coach })
              } else {
                resolve({ hasError: true, message: `Something failed saving assignation of coach ${coachLabel}` })
              }
            })
            .catch(({ error }) => {
              resolve({ hasError: true, error })
            })
        }),
      )
    }

    Promise.all(savePromises).then((results) => {
      let studentsToAssignAux = studentsToAssign
      for (const result of results) {
        if (result.hasError) {
          notification.error({ message: result.message })
        } else {
          studentsToAssignAux = studentsToAssignAux.filter((s) => s.coach_id !== result.coach_id)
          notification.success({ message: result.message })
        }
      }
      setShowConfirmSave(false)
      setConfirmSaveLoading(false)
      setStudentsToAssign(studentsToAssignAux)
      setSaveDisabled(studentsToAssignAux.length === 0)
      updateStudentsTable()
    })
  }

  return (
    <Layout>
      <MainContainer>
        <p className="title">Coach Assignation</p>
        <hr />
        {loading && (
          <Loader>
            <div className="rectangle" />
            <div className="rectangle" />
            <div className="rectangle" />
            <div className="rectangle" />
          </Loader>
        )}
        {!loading && error && <Result title="There was an error" subTitle={error} />}
        {!loading && !error && (
          <>
            <Row gutter={[16, 16]} className="card" align="bottom">
              <Col xs={24} md={24}>
                <small>SELECT CLIENTS TO BE ASSIGNED ON:</small>
                <Select value={bootcampSelected} onChange={(value) => setBootcampSelected(value)}>
                  {bootcamps.map((bootcamp) => {
                    return (
                      <Option key={bootcamp.program_id} value={bootcamp.program_id}>
                        {bootcamp.description}
                      </Option>
                    )
                  })}
                </Select>
              </Col>
              <Col xs={24} md={12}>
                <small>Search by:</small>
                <Search placeholder="email, CID, first name, or last name" allowClear onChange={(e) => setSearchText(e.target.value)} />
              </Col>
              <Col xs={24} md={8}>
                <small>Coach:</small>
                <Select value={coachSelected} onChange={(value) => setCoachSelected(value)}>
                  <Option value="unassigned">Unassigned</Option>
                  {coaches.map((coach) => {
                    return <Option key={coach.id} value={coach.id}>{`${coach.user.first_name} ${coach.user.last_name}`}</Option>
                  })}
                </Select>
              </Col>
              <Col xs={24} md={4}>
                <Popconfirm
                  title="Are you sure?"
                  visible={showConfirmSave}
                  onConfirm={saveChanges}
                  okButtonProps={{ loading: confirmSaveLoading }}
                  onCancel={() => setShowConfirmSave(false)}
                  disabled={saveDisabled}
                >
                  <Button type="primary" disabled={saveDisabled} onClick={() => setShowConfirmSave(true)} block>
                    Save changes
                  </Button>
                </Popconfirm>
              </Col>
            </Row>
            <Table
              size="middle"
              columns={columns}
              dataSource={students.filter((student) => {
                let coachFilter = true
                if (coachSelected === 'unassigned') {
                  coachFilter = student.coach_id === null
                } else {
                  coachFilter = student.coach_id === coachSelected
                }

                let textFilter = true

                if (searchText) {
                  textFilter =
                    student.first_name.toLowerCase().includes(searchText.toLowerCase()) ||
                    student.email.toLowerCase().includes(searchText.toLowerCase()) ||
                    student.contact_id.includes(searchText)
                }

                return coachFilter && textFilter
              })}
              rowKey="contact_id"
              pagination={{ position: ['none', 'none'], pageSize: 500 }}
              loading={loadingStudents}
              scroll={{ x: true }}
            />
          </>
        )}
      </MainContainer>
    </Layout>
  )
}
