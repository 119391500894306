import styled from '@emotion/styled'

export const SetCard = styled.div`
  border: 1px solid #c4c4c4;
  border-radius: 3px;
  box-shadow: 0px 2px 12px rgb(0 0 0 / 25%);
  background: #ffffff;
  min-height: 65px;
  margin-bottom: 8px;
  display: grid;
  grid-template-columns: ${(props) => (props.threeColumns ? `32px 1fr 1fr 1fr` : props.fourColumns ? `32px 1fr 1fr 1fr 1fr` : `32px 1fr 1fr`)};
  grid-template-rows: ${(props) => (props.deload ? '1fr 1fr 1fr 1fr' : '1fr 1fr')};
  grid-template-areas: ${(props) =>
    props.threeColumns
      ? `
    'set-id set-type reps range'
    'set-id set-type-value reps-value range-value'
  `
      : props.fourColumns
      ? `'set-id set-type reps range from'
    'set-id set-type-value reps-value range-value select'`
      : `
    'set-id set-type reps'
    'set-id set-type-value reps-value'
  `};

  grid-template-areas: ${(props) =>
    props.deload
      ? `
    'set-id set-type reps range'
    'set-id set-type-value reps-value range-value'
    'set-id from from from'
    'set-id select select select'
  `
      : ''};

  .set-id {
    grid-area: set-id;
    width: 32px;
    background-color: #5d5d5d;
    color: #ffffff;
    font-size: 18px;
    font-weight: 700;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .set-type {
    grid-area: set-type;
    color: #959595;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
  }

  .set-type-value {
    grid-area: set-type-value;
    color: #5d5d5d;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
  }

  .set-reps {
    grid-area: reps;
    color: #959595;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    text-align: center;
  }

  .set-reps-value {
    grid-area: reps-value;
    color: #5d5d5d;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
  }

  .set-range {
    grid-area: range;
    color: #959595;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    text-align: center;
  }

  .set-range-value {
    grid-area: range-value;
    color: #5d5d5d;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    span {
      margin-left: 6px;
    }
  }

  .from {
    grid-area: from;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    text-align: center;
  }

  .select {
    grid-area: select;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
  }

  .custom-input {
    border-radius: 3px;
    border: 2px solid #c4c4c4;
    box-shadow: -2px -2px 5px rgba(0, 0, 0, 0.25) inset;
    height: 30px;
    width: 50px;
    outline: none;
    text-align: center;
    font-weight: 600;
    text-overflow: ellipsis;
  }

  .multi-input-container {
    border-radius: 3px;
    border: 2px solid #c4c4c4;
    box-shadow: -2px -2px 5px rgba(0, 0, 0, 0.25) inset;
    height: 30px;
    width: 80px;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;

    .multi-input {
      width: 30px;
      border: none;
      outline: none;
      text-align: center;
      font-weight: 600;
      background-color: transparent;
    }
  }
`
