import React, { useState, useRef, useEffect } from 'react'
import Layout from 'components/Layout'
import { Row, Col, Select, Button, notification, Popconfirm, Result } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'
import TextEditor from './TextEditor/index'
import Messages from './TextEditor/Messages'
import apolloClient from 'apolloClient.js'
import gql from 'graphql-tag'
import moment from 'moment'
import { Query } from 'react-apollo'
import { getAttachmentsHTML } from './uses'

const { Option } = Select

const getCurrentBootcamp = gql`
  query getCurrentBootcamp($today: date) {
    program(where: { end_date: { _gte: $today }, start_date: { _lte: $today }, type: { _eq: "bootcamp" } }) {
      program_id
      description
    }
  }
`

const getStudents = gql`
  query getStudents($programId: uuid!, $levels: [Int!]!) {
    trainee_program_coach(
      where: { program_id: { _eq: $programId }, enrollment: { level: { _in: $levels } }, coach_id: { _is_null: false }, is_active: { _eq: true } }
    ) {
      thread_id
      trainee {
        user {
          email
        }
      }
      coach {
        user_id
      }
    }
  }
`

const sendMessages = gql`
  mutation sendMessages($data: [message_insert_input!]!) {
    insert_message(objects: $data) {
      returning {
        timestamp
        body
      }
    }
  }
`

const Communication = () => {
  const [levelsSelected, setLevelsSelected] = useState(['1', '2', '3'])
  const textEditorRef = useRef(null)
  const [message, setMessage] = useState('')

  useEffect(() => {
    let localMessage = localStorage.getItem('MESSAGE')
    if (localMessage) {
      const localAttachments = localStorage.getItem('ATTACHMENTS')
      if (localAttachments) {
        localMessage += getAttachmentsHTML(JSON.parse(localAttachments))
      }
      setMessage(localMessage)
    }
  }, [])

  const sendMessage = async (message, programId, callback) => {
    if (levelsSelected.length > 0) {
      await apolloClient
        .query({ query: getStudents, variables: { programId, levels: levelsSelected } })
        .then(async (response) => {
          let data = response.data.trainee_program_coach.map((st) => {
            return { thread_id: st.thread_id, body: message, sender_id: st.coach.user_id }
          })

          await apolloClient
            .mutate({
              mutation: sendMessages,
              variables: {
                data,
              },
            })
            .then((response) => {
              if (response.errors) {
                throw new Error('Connection Error')
              } else {
                notification.success({ message: 'Your message was successfully sent.' })
                setMessage('')
                callback()
              }
            })
            .catch((error) => {
              notification.error({ message: 'The global message could not be sent', description: `${error}` })
            })
            .catch(console.warn)
        })
        .catch((error) => {
          notification.error({ message: 'We could not get the student list.', description: `${error}` })
        })
    }
  }

  return (
    <Layout>
      <div className="MainContainer">
        <Query query={getCurrentBootcamp} variables={{ today: moment().local().format('YYYYMMDD') }}>
          {({ data, error, loading }) => {
            if (loading) return <p>loading...</p>
            if (error) return <Result status="error" title="Something happen while trying to get the active bootcamp." />

            let currentBootcamps = data.program

            if (currentBootcamps.length > 0) {
              const currentBootcamp = currentBootcamps[0]
              return (
                <>
                  <p className="title">Communication</p>
                  <p>
                    This message will be send to the students in the <b>{currentBootcamp.description}</b>
                  </p>
                  <hr />
                  <div>
                    <Row gutter={[32, 16]}>
                      <Col xs={24} md={12} lg={12}>
                        <div>
                          <Col span={24}>
                            <label>Guests</label>
                            <Select mode="multiple" placeholder="Please select" defaultValue={['1', '2', '3']} onChange={(e) => setLevelsSelected(e)}>
                              <Option value="1">Level 1</Option>
                              <Option value="2">Level 2</Option>
                              <Option value="3">Level 3</Option>
                            </Select>
                          </Col>
                          <Col span={24}>
                            <label>Message</label>
                            <TextEditor send={sendMessage} programId={currentBootcamp.program_id} ref={textEditorRef} onChange={setMessage} />
                          </Col>
                          <Col span={24}>
                            <div className="footer">
                              <Popconfirm
                                title="This message will be sent to all the students in the selected levels and can not undo. Do you want to continue?"
                                onConfirm={() => textEditorRef.current.handleSendMessage()}
                                icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                overlayStyle={{ width: '300px' }}
                              >
                                <Button size="small" type="primary">
                                  Send Message
                                </Button>
                              </Popconfirm>
                            </div>
                          </Col>
                        </div>
                      </Col>
                      <Col xs={24} md={8} lg={12}>
                        <p style={{ textAlign: 'center' }}>Preview</p>
                        <Messages message={message} />
                      </Col>
                    </Row>
                  </div>
                </>
              )
            } else {
              return <Result status="warning" title="No active bootcamp found." />
            }
          }}
        </Query>
      </div>
    </Layout>
  )
}

export default Communication
