import React, { useEffect, useState } from 'react'
import { Empty, notification, Popover, Button } from 'antd'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import RoutineCard from '../Uses/RoutineCard'
import { apiServices } from 'services/api'
import ExerciseEmpty from 'assets/exercises/empty.png'
import ContextMenu from 'assets/icons/context-menu.png'
import { ReactComponent as IconTrash } from 'assets/icons/icon-trash.svg'
import { StyledModal } from 'styled'

export default ({ workoutPlanId, routines, category, handleRoutineSelect, routineSelected, levelSelected, reload }) => {
  const [routinesToShow, setRoutinesToShow] = useState(routines.filter((r) => r.category === category) || [])
  const [deleteRoutineModal, setDeleteRoutineModal] = useState({ show: false, routineId: '' })
  useEffect(() => {
    setRoutinesToShow(routines.filter((r) => r.category === category) || [])
  }, [routines])

  if (routinesToShow && routinesToShow.length <= 0) {
    return <Empty image={ExerciseEmpty} description="No programs created yet" />
  }

  async function handleOnDragEnd(result) {
    if (!result.destination) return
    await apiServices('POST', 'routine-reorder', {
      workout_plan_id: workoutPlanId,
      routine_id: parseInt(result.draggableId.replace('routine-', '')),
      order: result.destination.index + 1,
    })
      .then((response) => response.json())
      .then((data) => {
        if (!data.hasError) {
          const items = Array.from(routinesToShow)
          const [reorderItem] = items.splice(result.source.index, 1)
          items.splice(result.destination.index, 0, reorderItem)

          setRoutinesToShow(
            routinesToShow.map((r) => {
              const routineUpdated = data.find((ru) => ru.id === r.id)
              if (routineUpdated) {
                r.order = routineUpdated.order
              }
              return r
            }),
          )

          notification.success({
            className: 'success',
            message: 'Reorder successfully',
          })
          setRoutinesToShow(items)
        } else {
          notification.error({
            className: 'error',
            message: data.message,
            description: data.detail,
          })
        }
      })
      .catch((error) => {
        notification.error({
          className: 'error',
          message: 'Some happens :(',
        })
      })
  }
  const changeDeleteRoutineModalVisible = (routineId) => setDeleteRoutineModal({ show: !deleteRoutineModal.show, routineId: routineId })

  const handleDeleteRoutine = (e, routineId) => {
    e.preventDefault()
    changeDeleteRoutineModalVisible(routineId)
  }

  const deleteRoutine = async () => {
    apiServices('DELETE', `routine?id=${deleteRoutineModal.routineId}`, {})
      .then((data) => {
        if (data.status === 200) {
          notification.success({
            message: 'Routine deleted correctly!',
          })
          reload()
        } else {
          notification.error({
            message: `Error while trying to delete the routine`,
          })
        }
      })
      .catch((err) => {
        notification.error({
          message: `Error while trying to delete the routine`,
          description: `Error from DB ${err.message}`,
        })
      })
      .finally(() => changeDeleteRoutineModalVisible())
  }

  return (
    <>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId={`routines-${levelSelected}-${category}`}>
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {routinesToShow.map((r, index) => (
                <Draggable key={r.id} draggableId={`routine-${r.id}`} index={index}>
                  {(provided) => (
                    <div {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                      <RoutineCard
                        key={r.id}
                        routine={r}
                        onClick={handleRoutineSelect}
                        className={`${r.id === routineSelected ? 'selected' : ''} level-${levelSelected}`}
                        addonAfter={
                          <Popover
                            placement="bottomRight"
                            content={
                              <>
                                <p onClick={(e) => handleDeleteRoutine(e, r.id)}>
                                  <IconTrash />
                                  <span>Delete</span>
                                </p>
                              </>
                            }
                            trigger="click"
                            overlayClassName="context-menu-exercises"
                          >
                            <img src={ContextMenu} alt="..." />
                          </Popover>
                        }
                      />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <StyledModal
        visible={deleteRoutineModal.show}
        title="Are you sure you want to delete this routine?"
        className="confirm"
        centered={true}
        onCancel={() => changeDeleteRoutineModalVisible()}
        footer={[
          <Button key="cancel" onClick={() => changeDeleteRoutineModalVisible()}>
            Cancel
          </Button>,
          <Button key="delete" type="primary" onClick={deleteRoutine}>
            Delete
          </Button>,
        ]}
      />
    </>
  )
}
