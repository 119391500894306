import React, { useState, useContext } from 'react'
import { Row, Col, Button, Select, Tabs, notification, Empty } from 'antd'
import { ImportRoutineModal } from './ImportRoutine.styled'
import { MainContainer } from '../Main/Main.styled'
import { WorkoutStore } from '../Uses/WorkoutStore'
import RoutineCard from '../Uses/RoutineCard'
import moment from 'moment'
import ExerciseEmpty from 'assets/exercises/empty.png'
import { StyledCheckboxCircle } from 'styled'
import PreviewRoutine from '../PreviewRoutine'
import { apiServices } from 'services/api'

const { Option } = Select
const { TabPane } = Tabs

export default ({ visible, onCancel, onSuccess, bootcampId }) => {
  const [bootcampSelected, setBootcampSelected] = useState()
  const [levelSelected, setLevelSelected] = useState(1)
  const [routineSelected, setRoutineSelected] = useState()
  const [routinesToImport, setRoutinesToImport] = useState([])
  const [buttonLoading, setButtonLoading] = useState(false)
  const [importLevel, setImportLevel] = useState()
  const { bootcamps, workouts } = useContext(WorkoutStore)
  const actualBootcamp = bootcamps.find((b) => b.program_id === bootcampId)
  const bootcampsToShow = bootcamps.filter((b) => {
    return moment(b.access_date).isSameOrBefore(actualBootcamp ? actualBootcamp.access_date : moment().local())
  })

  const bootcampDefault = bootcampsToShow[0].program_id
  const workoutsToShow = workouts.filter((w) => w.program_id === (bootcampSelected || bootcampDefault)).sort((a, b) => a.level - b.level)
  const workoutSelected = workoutsToShow.find((w) => w.level === levelSelected) || { routines: [] }

  const RoutineEmpty = () => {
    return (
      <div className="empty">
        <img src={ExerciseEmpty} alt="" />
        <p>No programs created yet</p>
      </div>
    )
  }

  const handleBootcampSelected = (id) => {
    setRoutineSelected(null)
    setRoutinesToImport([])
    setBootcampSelected(id)
  }

  const handleRoutineSelect = (routineId, checked) => {
    if (checked) {
      setRoutinesToImport((prev) => [...prev, routineId])
    } else {
      setRoutinesToImport(routinesToImport.filter((r) => r !== routineId))
    }
  }

  const handleRoutinePreview = (routineId) => {
    setRoutineSelected(routineId)
  }

  const handleImport = () => {
    setButtonLoading(true)
    apiServices('POST', 'routine/import', {
      program_target: bootcampId,
      routines: routinesToImport,
      level: importLevel,
    })
      .then((resp) => resp.json())
      .then((data) => {
        if (data.hasError) {
          notification.error({
            className: 'error',
            message: data.message,
            description: data.detail ? data.detail : null,
          })
        } else {
          notification.success({
            className: 'success',
            message: 'Your routines were successfully imported',
          })
          onSuccess()
        }
        setButtonLoading(false)
      })
      .catch(console.warn)
  }

  const resetModal = () => {
    setBootcampSelected(null)
    setRoutineSelected(null)
    setRoutinesToImport([])
  }

  return (
    <ImportRoutineModal visible={visible} closable={false} footer={[]} afterClose={resetModal} destroyOnClose={true}>
      <MainContainer>
        <p className="title">Importing Exercise Programs</p>
        <hr />
        <Row gutter={[16, 16]}>
          <Col xs={24} md={6} lg={6}>
            <div className="card" style={{ marginBottom: '5px' }}>
              <p>Select a Bootcamp to import from</p>
              <Select value={bootcampSelected || bootcampDefault} onChange={handleBootcampSelected}>
                {bootcampsToShow.map((b) => {
                  return <Option key={b.program_id}>{b.description}</Option>
                })}
              </Select>
              <hr />
              <div className="levels">
                {workoutsToShow.map((l) => {
                  return (
                    <div
                      className={`level-${l.level} ${l.level === levelSelected ? 'selected' : ''}`}
                      key={l.level}
                      onClick={() => setLevelSelected(l.level)}
                    >
                      Level {l.level}
                    </div>
                  )
                })}
              </div>
            </div>
            <div className="card" style={{ marginBottom: '5px' }}>
              <Tabs defaultActiveKey="gym" className="routines-tab">
                <TabPane tab="Gym" key="gym">
                  {workoutSelected.routines.filter((r) => r.category === 'GYM').length <= 0 && <RoutineEmpty />}
                  {workoutSelected.routines
                    .filter((r) => r.category === 'GYM')
                    .map((r) => (
                      <RoutineCard
                        key={r.id}
                        routine={r}
                        onClick={handleRoutinePreview}
                        className={`level-${levelSelected} ${r.id === routineSelected ? 'selected' : ''}`}
                        addonBefore={
                          <StyledCheckboxCircle
                            checked={routinesToImport.indexOf(r.id) !== -1}
                            onChange={(e) => handleRoutineSelect(r.id, e.target.checked)}
                          />
                        }
                      />
                    ))}
                </TabPane>
                <TabPane tab="Bodyweight" key="home">
                  {workoutSelected.routines.filter((r) => r.category === 'HOME').length <= 0 && <RoutineEmpty />}
                  {workoutSelected.routines
                    .filter((r) => r.category === 'HOME')
                    .map((r) => (
                      <RoutineCard
                        key={r.id}
                        routine={r}
                        onClick={handleRoutinePreview}
                        className={`level-${levelSelected} ${r.id === routineSelected ? 'selected' : ''}`}
                        addonBefore={
                          <StyledCheckboxCircle
                            checked={routinesToImport.indexOf(r.id) !== -1}
                            onChange={(e) => handleRoutineSelect(r.id, e.target.checked)}
                          />
                        }
                      />
                    ))}
                </TabPane>
              </Tabs>
            </div>
          </Col>
          <Col xs={24} md={18} lg={18}>
            <div className="card" style={{ height: '100%', position: 'relative' }}>
              {routineSelected && <PreviewRoutine id={routineSelected} bootcampId={bootcampSelected || bootcampDefault} className="preview" />}
              {!routineSelected && <Empty image={ExerciseEmpty} description="Select a Program to see details" />}
              <hr />
              <div
                className="step-action"
                style={{ display: 'flex', justifyContent: 'end', alignItems: 'center', position: 'absolute', bottom: '10px', right: '10px' }}
              >
                <p>Override: </p>
                <Select
                  value={importLevel}
                  placeholder="Select level"
                  style={{ width: '110px', margin: '0 10px' }}
                  onChange={(level) => setImportLevel(level)}
                >
                  <Option value={null}></Option>
                  <Option value={1}>Level 1</Option>
                  <Option value={2}>Level 2</Option>
                  <Option value={3}>Level 3</Option>
                </Select>
                <Button onClick={onCancel} disabled={buttonLoading}>
                  Cancel
                </Button>
                <Button type="primary" loading={buttonLoading} onClick={handleImport} disabled={routinesToImport.length <= 0}>
                  Import
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </MainContainer>
    </ImportRoutineModal>
  )
}
