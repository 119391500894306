import React, { useContext, useState, useEffect } from 'react'
import { Row, Col, Input, List, Button, Collapse, Empty } from 'antd'
import {
  FilterOutlined,
  SearchOutlined,
  LoadingOutlined,
  SortAscendingOutlined,
  SortDescendingOutlined,
  PlusOutlined,
  MinusOutlined,
} from '@ant-design/icons'
import InfiniteScroll from 'react-infinite-scroller'
import ExerciseList from '../../CreateRoutine/Step2/ExerciseList'
import Message from 'components/Message'
import { ExerciseFilter } from '../../CreateRoutine/Step2/ExerciseFilter/ExerciseFilter'
import { ExerciseListContainer, Step2Container } from '../../CreateRoutine/Step2/Step2.styled'
import { StyledCheckboxCircle } from 'styled'
import ExerciseEmpty from 'assets/exercises/empty.png'
import useSearchExercises from './useSearchExercises'
import { WorkoutStore } from '../WorkoutStore'
import PreviewExercise from './PreviewExercise'
const { Panel } = Collapse

const ExerciseSelector = ({
  exercisesSelectedList,
  maxExercises,
  handleClickBack,
  isBtnBackLoading,
  isBtnLoading,
  handleClickNext,
  canGoNext,
  preloadExercises,
  handleSelectExercise,
  level,
  setExercisesSelectedList,
  handleDeleteExercise,
  isAlternativesSelector,
  selectedExercise,
  setSelectedExercise,
}) => {
  const [sort, setSort] = useState('asc')
  const [showFilters, setShowFilters] = useState(false)
  const [loading, setLoading] = useState(false)
  const [hasMore, setHasMore] = useState(true)
  const [exerciseList, setExerciseList] = useState([])
  const { exercises, muscles, gym_equipments } = useContext(WorkoutStore)
  const { query, setQuery, filteredExercises } = useSearchExercises(exercises, selectedExercise)

  useEffect(() => {
    setExerciseList(filteredExercises.slice(0, 12))
    handleInfiniteOnLoad()
  }, [filteredExercises])

  const handleFilter = (type, value) => {
    let newQuery = JSON.parse(JSON.stringify(query))
    newQuery[type] = value
    if (type === 'sort') setSort(value)
    setQuery(newQuery)
  }

  const handleInfiniteOnLoad = (isSort) => {
    setLoading(true)
    if (!!isSort) {
      if (exerciseList.length >= filteredExercises.length) {
        setHasMore(false)
        setLoading(false)
        return
      }
      setExerciseList(exerciseList.concat(filteredExercises.slice(exerciseList.length, exerciseList.length + 12)))
    }
    setLoading(false)
  }

  return (
    <Step2Container exerciseNumber={exerciseList.length}>
      {isAlternativesSelector && (
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <h2 style={{ margin: '10px' }}>Select alternative exercises for {selectedExercise.name}</h2>
            <hr style={{ borderColor: '#cfcfcf' }} />
          </Col>
        </Row>
      )}
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <p style={{ marginBottom: '0px' }}>Browse exercises</p>
        </Col>
      </Row>
      <Row gutter={[16, 16]} style={{ paddingBottom: isAlternativesSelector ? '60px' : '0' }}>
        <Col xs={24} md={12} className="site-drawer-render-in-current-wrapper">
          <Input
            placeholder="Search by name"
            onChange={(e) => handleFilter('text', e.target.value)}
            allowClear={true}
            prefix={<SearchOutlined />}
            addonAfter={
              <>
                <FilterOutlined onClick={() => setShowFilters(!showFilters)} />
                {sort === 'asc' && <SortAscendingOutlined onClick={() => handleFilter('sort', 'desc')} />}
                {sort === 'desc' && <SortDescendingOutlined onClick={() => handleFilter('sort', 'asc')} />}
              </>
            }
          />
          <p>Explore exercises by name ({filteredExercises.length})</p>
          {((query.options && query.options.filterMuscles.length > 0) || (query.options && query.options.filterEquipments.length > 0)) && (
            <p>You have filters applied</p>
          )}
          {exercisesSelectedList.length > maxExercises && (
            <Message message={`You can only select up to ${maxExercises} exercises per program day`} type="warning" />
          )}
          <ExerciseListContainer tabIndex="0" className="show-scrollbars">
            <InfiniteScroll initialLoad={false} pageStart={0} loadMore={handleInfiniteOnLoad} hasMore={!loading && hasMore} useWindow={false}>
              <List
                dataSource={exerciseList}
                renderItem={(item) => (
                  <List.Item key={item.id}>
                    <Collapse
                      expandIcon={(e) => {
                        if (e.isActive) return <MinusOutlined />
                        return <PlusOutlined />
                      }}
                      expandIconPosition="right"
                    >
                      <Panel
                        header={
                          <>
                            <StyledCheckboxCircle
                              defaultChecked={preloadExercises.find((e) => e === item.id)}
                              checked={exercisesSelectedList.find((e) => e.id === item.id)}
                              onClick={(e) => e.stopPropagation()}
                              onChange={(e) => handleSelectExercise(e, item.id)}
                            />{' '}
                            <span className="e-name">{item.name}</span>
                          </>
                        }
                        key="1"
                      >
                        <PreviewExercise {...{ muscles, gym_equipments, exercises, exercise: item, level }} />
                      </Panel>
                    </Collapse>
                  </List.Item>
                )}
              >
                {loading && hasMore && (
                  <div className="demo-loading-container">
                    <LoadingOutlined />
                  </div>
                )}
              </List>
            </InfiniteScroll>
          </ExerciseListContainer>
          <ExerciseFilter
            isVisible={showFilters}
            setIsVisible={setShowFilters}
            muscles={muscles}
            equipments={gym_equipments}
            handleFilter={handleFilter}
          />
        </Col>

        <Col xs={24} md={12}>
          <p>Selected exercises</p>
          {exercisesSelectedList.length === 0 && (
            <Empty
              image={ExerciseEmpty}
              description={
                isAlternativesSelector
                  ? 'The exercises you select for this alternative will display here'
                  : 'The exercises you select for this program day will display here'
              }
            />
          )}
          {exercisesSelectedList.length > 0 && (
            <div tabIndex="0" className="cards-wrapper show-scrollbars">
              <ExerciseList
                exercisesArr={exercisesSelectedList}
                handleDragAndDrop={setExercisesSelectedList}
                handleDeleteExercise={handleDeleteExercise}
                handleAlternativesExercises={setSelectedExercise}
                showAlternatives={!isAlternativesSelector}
              />
            </div>
          )}
        </Col>
      </Row>
      <div className="step-action">
        <hr />
        <Button className="btn-cancel" onClick={handleClickBack} disabled={isBtnBackLoading || isBtnLoading} loading={isBtnBackLoading}>
          {isAlternativesSelector ? 'Close' : 'Back'}
        </Button>
        <Button type="primary" onClick={handleClickNext} disabled={!canGoNext || isBtnBackLoading} loading={isBtnLoading}>
          {isAlternativesSelector ? 'Save' : 'Next'}
        </Button>
      </div>
    </Step2Container>
  )
}
export default ExerciseSelector
