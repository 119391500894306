import { Switch } from 'antd'
import React from 'react'

const CustomSwitch = ({ checked, onChange }) => {
  return (
    <div className="set-type-value" style={{ display: 'flex' }}>
      <p>No</p>
      <Switch style={{ margin: '0 10px' }} checked={checked} onChange={onChange} />
      <p>Yes</p>
    </div>
  )
}
export default CustomSwitch
