import React, { Component, Fragment } from 'react'
import showdown from 'showdown'
import { Root, MessageList, Message, Answer } from './Messages.styled'

const converter = new showdown.Converter()

const createMarkup = (body) => {
  return { __html: body }
}

class Messages extends Component {
  render() {
    let { message } = this.props
    if (message.replace(/<(.|\n)*?>/g, '').trim().length === 0) {
      message = ''
    }
    return (
      <Root>
        <MessageList>
          <Fragment>
            <Message>
              <Answer
                dangerouslySetInnerHTML={createMarkup(converter.makeHtml(message ? message : 'Here will be displayed the preview of the message'))}
              />
            </Message>
          </Fragment>
        </MessageList>
      </Root>
    )
  }
}

export default Messages
