import * as tus from 'tus-js-client'
const authorization = process.env.REACT_APP_VIMEO_AUTORIZATION

const addVideoToFolder = (videoId) => {
  return new Promise((resolve) => {
    fetch(`https://api.vimeo.com/me/projects/2111161/videos/${videoId}`, {
      method: 'PUT',
      headers: {
        Authorization: authorization,
        'Content-Type': 'application/json',
        Accept: 'application/vnd.vimeo.*+json;version=3.4',
      },
    })
      .then((response) => {
        if (response.ok) {
          resolve(`Video ${videoId} added to exercises folder!`)
        }
        throw new Error(`Error adding ${videoId} to exercises folder => ${response.statusText}`)
      })
      .catch((error) => resolve({ hasError: true, message: `${error}` }))
  })
}

const createVideo = (video) => {
  return new Promise((resolve) => {
    const vimeoVideo = {
      name: video.name,
      upload: {
        approach: 'tus',
        size: video.size,
      },
      privacy: {
        view: 'unlisted',
        comments: 'nobody',
        download: false,
        add: false,
        embed: 'whitelist',
      },
      embed: {
        volume: false,
        color: 'FF6B00',
        buttons: {
          fullscreen: true,
          like: false,
          share: false,
          watchlater: false,
        },
        logos: {
          vimeo: false,
        },
      },
    }

    fetch(`https://api.vimeo.com/me/videos`, {
      method: 'POST',
      headers: {
        Authorization: authorization,
        'Content-Type': 'application/json',
        Accept: '	application/vnd.vimeo.*+json;version=3.4',
      },
      body: JSON.stringify(vimeoVideo),
    })
      .then((response) => response.json())
      .then((responseVimeo) => resolve(responseVimeo))
      .catch((error) => resolve({ hasError: true, message: `Error while trying to create the vimeo video ${video.name}:${error}` }))
  })
}

const getVideoId = (videoUrl) => {
  let videoId = ''
  if (!!videoUrl) {
    const videoUrlSplit = videoUrl.split('/')
    if (videoUrlSplit.length > 0) {
      videoId = videoUrlSplit[videoUrlSplit.length - 1]
    }
  }
  return videoId
}

const upload = (vimeoVideo, file, onProgress) => {
  return new Promise((resolve) => {
    if (vimeoVideo.upload && vimeoVideo.upload.upload_link) {
      const optionsUploadVimeo = {
        uploadUrl: vimeoVideo.upload.upload_link,
        uploadSize: vimeoVideo.upload.size,
        method: 'PATCH',
        onError: (error) => {
          resolve({ hasError: true, message: `Error uploading vimeo video: ${error}` })
        },
        onSuccess: () => {
          const newVimeoId = vimeoVideo.uri.replace('videos', 'video')
          resolve({ video_url: `https://player.vimeo.com${newVimeoId}` })
        },
        onProgress: onProgress,
      }
      var uploadVimeo = new tus.Upload(file, optionsUploadVimeo)
      uploadVimeo.start()
    } else {
      resolve({ hasError: true, message: `UploadLink not found ${JSON.stringify(vimeoVideo)}` })
    }
  })
}

const addDomainToWhitelist = (videoId, domain) => {
  return new Promise((resolve) => {
    fetch(`https://api.vimeo.com/videos/${videoId}/privacy/domains/${domain}`, {
      method: 'PUT',
      headers: {
        Authorization: authorization,
        'Content-Type': 'application/json',
        Accept: 'application/vnd.vimeo.*+json;version=3.4',
      },
    })
      .then((response) => {
        if (response.ok) {
          resolve(`Domain ${domain} added to white list!`)
        }
        throw new Error(`Error adding ${domain} to whitelist => ${response.statusText}`)
      })
      .catch((error) => resolve({ hasError: true, message: `${error}` }))
  })
}

const uploadVideo = async (fileName, fileSize, file, uploadVideoProgress) => {
  if (!file) return
  let log = `${fileName} - `
  const vimeoVideo = await createVideo({
    name: fileName,
    size: fileSize,
  })
  let uploadedVimeo = ''
  const vimeoVideoId = getVideoId(vimeoVideo.uri)
  if (!vimeoVideo.hasError) {
    uploadedVimeo = await upload(vimeoVideo, file, uploadVideoProgress)
    if (!uploadedVimeo.hasError) {
      log += uploadedVimeo.video_url + ','
      const responseFolder = await addVideoToFolder(vimeoVideoId)
      log += `Add video to folder: ${responseFolder.hasError ? 'FALSE' : 'TRUE'},`
      const responseDomainLocal = await addDomainToWhitelist(vimeoVideoId, 'localhost:3000')
      log += `Add localhost support: ${responseDomainLocal.hasError ? 'FALSE' : 'TRUE'},`
      const responseDomainPortal = await addDomainToWhitelist(vimeoVideoId, 'portal.ketogains.com')
      log += `Add portal support: ${responseDomainPortal.hasError ? 'FALSE' : 'TRUE'},`
      const responseDomainExercises = await addDomainToWhitelist(vimeoVideoId, 'exercises.portal.ketogains.com')
      log += `Add exercise portal support: ${responseDomainExercises.hasError ? 'FALSE' : 'TRUE'},`
      const responseDomainKetogains = await addDomainToWhitelist(vimeoVideoId, 'ketogains.com')
      log += `Add ketogains support: ${responseDomainKetogains.hasError ? 'FALSE' : 'TRUE'},`
      const responseDomainDevGainspot = await addDomainToWhitelist(vimeoVideoId, 'dev.gainspot.app')
      log += `Add Gainspot Dev: ${responseDomainDevGainspot.hasError ? 'FALSE' : 'TRUE'},`
      const responseDomainProdGainspot = await addDomainToWhitelist(vimeoVideoId, 'gainspot.app')
      log += `Add Gainspot: ${responseDomainProdGainspot.hasError ? 'FALSE' : 'TRUE'},`
    } else {
      log += uploadedVimeo.message
      return { hasError: true, message: uploadedVimeo.message }
    }
  } else {
    return { hasError: true, message: vimeoVideo.message }
  }

  console.log(log)

  let newVideoURL = vimeoVideo.link.replace('vimeo.com', 'player.vimeo.com/video')
  const lastSlashPosition = newVideoURL.lastIndexOf('/')
  newVideoURL = newVideoURL.substring(0, lastSlashPosition) + '?h=' + newVideoURL.substring(lastSlashPosition + 1)
  return newVideoURL
}

export default uploadVideo
