import React, { Component } from 'react'

import { logout } from 'auth'
import backgroundImage from 'images/sign-in-bg.png'
import { Root } from './Logout.styled'

class Logout extends Component {
  render() {
    logout()
    return <Root bgUrl={backgroundImage} />
  }
}

export default Logout
