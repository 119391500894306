import React from 'react'
import CardInfoExercise from '../CardInfoExercise'
import { PreviewExerciseContainer } from './PreviewExercise.styled'
import { filterAlternativesToShow } from '../Uses'
const PreviewExercise = ({ exercise, allExercises }) => {
  const thumbnail = exercise.thumbnails.start ? exercise.thumbnails.start : exercise.thumbnails.finish
  let vimeoURL = exercise.video_url
  const videoParameters = 'autoplay=1&loop=1&muted=1'

  if (vimeoURL.indexOf('?h=') !== -1) {
    vimeoURL += `&${videoParameters}`
  } else {
    vimeoURL += `?${videoParameters}`
  }

  return (
    <PreviewExerciseContainer>
      {exercise.video_url ? (
        <div className="video-container">
          <p>
            <b>Video</b>
          </p>
          <iframe
            controls
            src={vimeoURL}
            allow="autoplay; fullscreen"
            width="311px"
            height="233px"
            frameBorder="0"
            title={exercise.name}
            allowFullScreen={true}
            webkitallowfullscreen="true"
            mozallowfullscreen="true"
          />
        </div>
      ) : exercise.gif_url ? (
        <div className="thumbnail-container">
          <p>
            <b>Gif</b>
          </p>
          <img src={exercise.gif_url} alt={`${exercise.name}`} />
        </div>
      ) : (
        thumbnail && (
          <div className="thumbnail-container">
            <p>
              <b>Thumbnail</b>
            </p>
            <img src={thumbnail} alt={`${exercise.name}`} />
          </div>
        )
      )}
      {exercise.alternatives && exercise.alternatives.length > 0 && (
        <>
          <p>
            <b>Alternatives</b>
          </p>
          {filterAlternativesToShow(exercise.alternatives, allExercises, 'preview')}
        </>
      )}
      <CardInfoExercise
        name={exercise.name}
        description={exercise.description}
        preparation={exercise.preparation}
        execution={exercise.execution}
        gym_equipments={exercise.gym_equipments}
        muscles={exercise.muscles}
      />
    </PreviewExerciseContainer>
  )
}
export default PreviewExercise
