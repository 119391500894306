import React from 'react'
import { Router } from '@reach/router'
import PrivateRoute from 'components/PrivateRoute'
import Homepage from 'views/Homepage'
import Login from 'views/Security/Login'
import Logout from 'views/Security/Logout'
import ClientsAssigned from 'views/Clients/ClientsAssigned'
import Coaches from 'views/Coaches'
import Clients from 'views/Clients/Main'
import Communication from 'views/Clients/Communication'
import MergeContact from 'views/Clients/MergeContact'
import Bootcamps from 'views/Bootcamps'
import NotFound from 'views/NotFound'
import ExercisesMain from 'views/Exercises/Main'
import ExercisesCatalog from 'views/ExercisesCatalog'

const App = () => (
  <Router>
    <PrivateRoute path="/" component={Homepage} />
    <PrivateRoute path="/clients-assigned" component={ClientsAssigned} />
    <PrivateRoute path="/coaches" component={Coaches} />
    <PrivateRoute path="/clients" component={Clients} />
    <PrivateRoute path="/communication" component={Communication} />
    <PrivateRoute path="/mergecontact" component={MergeContact} />
    <PrivateRoute path="/bootcamps" component={Bootcamps} />
    <PrivateRoute path="/exercises" component={ExercisesMain} />
    <PrivateRoute path="/exercises-catalog" component={ExercisesCatalog} />
    <Login path="/login" />
    <Logout path="/logout" />
    <NotFound default />
  </Router>
)

export default App
