import { setCookie, getCookie, removeCookie } from 'tiny-cookie'
import { LOCAL_STORAGE, COOKIE } from 'constants.js'
import apolloClient from './apolloClient.js'
import { navigate } from '@reach/router'
import gql from 'graphql-tag'
import moment from 'moment'

const authServer = process.env.REACT_APP_AUTH_SERVER

const getUserData = gql`
  query GetUserData($id: String!, $today: date!) {
    program(where: { start_date: { _lte: $today } }, order_by: { start_date: desc }, limit: 1) {
      program_id
      start_date
      end_date
      access_date
      close_date
      duration
      description
    }
    user(where: { contact_id: { _eq: $id } }) {
      id
      contact_id
      picture
    }
  }
`

export const loginCredentials = ({ username, password }) => {
  return new Promise((resolve, reject) => {
    var urlencoded = new URLSearchParams()
    urlencoded.append('username', username)
    urlencoded.append('password', password)
    urlencoded.append('issueJWT', 'true')
    urlencoded.append('includeRoles', 'true')
    urlencoded.append('refreshableJWT', true)

    fetch(`${authServer}/wp-json/aam/v2/authenticate`, {
      method: 'POST',
      body: urlencoded,
    })
      .then((resp) => resp.json())
      .then((wpData) => {
        if (wpData.user && wpData.jwt) {
          setCookie(COOKIE.USER_TOKEN_EXPIRE_TIME, 'Refresh Token on 24 hours', {
            expires: '24h',
            domain: process.env.REACT_APP_BRANCH === '' ? 'ketogains.com' : '',
          })
          setCookie(COOKIE.USER_TOKEN, `Bearer ${wpData.jwt.token}`, {
            expires: moment.unix(wpData.jwt.token_expires).toDate(),
            domain: process.env.REACT_APP_BRANCH === '' ? 'ketogains.com' : '',
          })
          const user = wpData.user.data
          const today = moment().local().format('YYYYMMDD')

          if (!user.roles.includes('admin')) {
            reject("Sorry, you don't have access")
          } else {
            localStorage.setItem('USER_ROLES', user.roles)
            apolloClient
              .query({
                query: getUserData,
                variables: { id: `${user.contact_id}`, today },
              })
              .then(async ({ data }) => {
                const authData = {
                  id: user.ID,
                  contactId: `${user.contact_id}`,
                  nickname: user.display_name,
                  email: user.user_email,
                }
                loadInitialStorage(data.user[0], data.program[0], authData)
                resolve()
              })
          }
        } else {
          let message = wpData.message || wpData.reason || 'Something failed please try again'
          if (wpData.code) {
            message = 'Wrong email or password'
          }
          reject(message)
        }
      })
      .catch((error) => reject(`${error}`))
  })
}

const loadInitialStorage = async (user, program, authData) => {
  const { id, picture } = user
  const { nickname, contactId } = authData
  const { program_id, start_date, end_date, description } = program
  localStorage.setItem(LOCAL_STORAGE.USER_ID, id)
  localStorage.setItem(LOCAL_STORAGE.USER_PICTURE, picture)
  localStorage.setItem(LOCAL_STORAGE.USER_NICKNAME, nickname)
  localStorage.setItem(LOCAL_STORAGE.BOOTCAMP_END_DATE, end_date)
  localStorage.setItem(LOCAL_STORAGE.BOOTCAMP_START_DATE, start_date)
  localStorage.setItem(LOCAL_STORAGE.BOOTCAMP_DESCRIPTION, description)
  localStorage.setItem(LOCAL_STORAGE.BOOTCAMP_ID, program_id ? program_id : null)
  apolloClient.writeData({
    data: {
      User: { nickname, picture, id, contact_id: contactId, __typename: 'User' },
      Bootcamp: { id: program_id, start_date, end_date, description, __typename: 'Bootcamp' },
    },
  })
}

export const logout = async () => {
  let myHeaders = new Headers()
  myHeaders.append('Content-Type', 'application/json')
  const oldToken = getCookie(COOKIE.USER_TOKEN)
  if (oldToken) {
    await fetch(`${authServer}/wp-json/aam/v2/jwt/revoke`, {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify({ jwt: oldToken.replace('Bearer ', '') }),
    })
      .then((resp) => resp.json())
      .then((wpData) => {
        console.log('wpData :>> ', wpData)
      })
      .catch((error) => {
        console.log('error :>> ', error)
      })
  }

  localStorage.clear()
  removeCookie(COOKIE.API_TOKEN)
  removeCookie(COOKIE.USER_TOKEN)
  removeCookie(COOKIE.USER_TOKEN_EXPIRE_TIME)
  navigate('/login')
}
