import React, { useState, useEffect, useContext } from 'react'
import { Row, Col, Button, Select, Input, notification } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { WorkoutStore } from '../../Uses/WorkoutStore'
import ExerciseWeeks from '../../Uses/ExerciseWeeks/ExerciseWeeks'
import { apiServices } from 'services/api'
import { WeeksContainer } from '../../Uses/ExerciseWeeks/ExerciseWeeks.styled'
import EditIcon from 'assets/icons/edit.png'
import CloseModal from '../CloseModal'
import { isValidConfiguration } from '../../Uses/GlobalFunctions'
import CustomSwitch from '../../Uses/CustomSwitch'
const { Option } = Select

export default ({ onBack, showClose, onClose, setShowClose, onFinish }) => {
  const routineId = localStorage.getItem('ROUTINE_ID')
  const step = 'configuration'

  const { routine_types, routine_categories, refetch } = useContext(WorkoutStore)
  const [edit, setEdit] = useState(false)
  const [loading, setLoading] = useState(true)
  const [isBtnLoading, setIsBtnLoading] = useState(false)
  const [routine, setRoutine] = useState()
  const [programName, setProgramName] = useState()
  const [programType, setProgramType] = useState()
  const [programCategory, setProgramCategory] = useState()
  const [isOptionalProgram, setIsOptionalProgram] = useState()
  const [showCloseModal, setShowCloseModal] = useState(showClose)
  const [canPublishRoutine, setCanPublishRoutine] = useState(false)

  useEffect(() => {
    apiServices('GET', `routine?&id=${routineId}&step=${step}&withExercises=true&withWeeks=true&withUniqueExercises=true&withSets=true`)
      .then((resp) => resp.json())
      .then((data) => {
        setProgramName(data.name)
        setProgramType(data.type)
        setProgramCategory(data.category)
        setIsOptionalProgram(data.is_optional)
        setRoutine(data)
      })
      .catch(console.log)
      .finally(() => setLoading(false))
  }, [])

  useEffect(() => setShowCloseModal(showClose), [showClose])

  useEffect(() => {
    validate()
  }, [routine, programName, programType, programCategory, isOptionalProgram])

  const handleSaveOnEdit = () => {
    setIsBtnLoading(true)
    const routineObj = { ...routine, name: programName, type: programType, category: programCategory, is_optional: isOptionalProgram }
    save(routineObj)
      .then((data) => {
        if (data === 204) {
          notification.success({
            message: 'Routine save correctly!',
          })
          setEdit(!edit)
          setRoutine(routineObj)
        } else {
          notification.error({
            message: `Error while trying to save the routine`,
          })
        }
      })
      .catch((err) => {
        notification.error({
          message: `Error while trying to save the routine`,
          description: `Error from DB ${err.message}`,
        })
      })
      .finally(() => {
        setIsBtnLoading(false)
      })
  }

  const save = (routineObj) => {
    return apiServices('POST', `routine?id${routineId}&step=routine-summary`, {
      ...routineObj,
      routine_exercises: routineObj.routine_exercises.map((e) => {
        return {
          routine_id: routineId,
          exercise_id: e.exercise_id,
          is_alternative: e.is_alternative,
          parent: e.parent,
          user_notes: e.user_notes,
          order: e.order,
        }
      }),
    }).then((resp) => resp.json())
  }

  const handleFinishRoutine = () => {
    const data = {
      id: routineId,
    }
    setIsBtnLoading(true)
    apiServices('POST', `routine?id=${routineId}&step=publish-routine`, { ...data })
      .then((data) => {
        if (!data.hasError) {
          notification.success({
            message: `Your Program ${programName} Workout was successfully created!`,
          })
        }
      })
      .catch((err) => {
        notification.error({
          message: `Error while trying to save the routine`,
          description: `Error from DB ${err.message}`,
        })
      })
      .finally(() => {
        setIsBtnLoading(false)
        refetch()
        onFinish()
      })
  }

  const validate = () => {
    if (routine) {
      let isValid = isValidConfiguration(routine)
      let validRoutine = programType && !!programName && !!programCategory && typeof isOptionalProgram === 'boolean' && isValid
      if (validRoutine) {
        setCanPublishRoutine(true)
      } else {
        setCanPublishRoutine(false)
      }
    }
  }

  const handleClose = async (withSave) => {
    return new Promise((resolve, reject) => {
      if (withSave && edit) {
        const routineObj = { ...routine, name: programName, type: programType, category: programCategory, is_optional: isOptionalProgram }
        save(routineObj)
          .then((data) => {
            if (data === 204) {
              notification.success({ message: 'Saved correctly!' })
              onClose()
              resolve()
            } else {
              throw new Error('Error saving exercises!')
            }
          })
          .catch((err) => {
            notification.error({
              message: `Error while trying to save the routine`,
              description: `Error from DB ${err.message}`,
            })
            resolve()
          })
      } else if (typeof onClose === 'function') {
        onClose()
        resolve()
      }
    })
  }

  const getRoutineType = (type) => {
    const foundType = routine_types.find((rt) => rt.key === type)
    return foundType ? foundType.label : ''
  }

  const getRoutineCategory = (category) => {
    const foundCategory = routine_categories.find((rc) => rc.key === category)
    return foundCategory ? foundCategory.label : ''
  }

  return (
    <WeeksContainer editfour={edit ? 'true' : undefined}>
      <div className="routine-details">
        <div className="program-details">
          <p>
            <b>Program Details</b>
          </p>
          {!edit && (
            <div className="edit-link" onClick={() => setEdit(!edit)}>
              <img src={EditIcon} alt="" /> <span>Edit Program</span>
            </div>
          )}
        </div>
        {!edit && !loading && (
          <Row gutter={[16, 16]}>
            <Col xs={24} md={8}>
              <label>
                Program type <span>{getRoutineType(routine.type)}</span>
              </label>
            </Col>
            <Col xs={24} md={8}>
              <label>
                Program name <span>{routine.name}</span>
              </label>
            </Col>
            <Col xs={24} md={4}>
              <label>
                Program Edition <span>{getRoutineCategory(routine.category)}</span>
              </label>
            </Col>
            <Col xs={24} md={4}>
              <label>
                Optional day <span>{routine.is_optional ? 'Yes' : 'No'}</span>
              </label>
            </Col>
          </Row>
        )}
        {edit && (
          <Row gutter={[16, 16]}>
            <Col xs={24} md={12}>
              <label>Program type</label>
              <Select defaultValue={routine.type} placeholder="Select an option" onChange={setProgramType}>
                {routine_types.map((type) => {
                  return <Option key={type.key}>{type.label}</Option>
                })}
              </Select>
            </Col>
            <Col xs={24} md={12}>
              <label>Program name</label>
              <Input
                type="text"
                defaultValue={routine.name}
                placeholder="Type a name to your program"
                maxLength="30"
                onChange={(e) => setProgramName(e.target.value)}
              />
            </Col>
            <Col xs={24} md={12}>
              <label>Is Gym Edition?</label>
              <CustomSwitch checked={programCategory === 'GYM'} onChange={() => setProgramCategory(programCategory === 'GYM' ? 'HOME' : 'GYM')} />
            </Col>
            <Col xs={24} md={12}>
              <label>Optional day</label>
              <CustomSwitch checked={isOptionalProgram} onChange={() => setIsOptionalProgram(!isOptionalProgram)} />
            </Col>
          </Row>
        )}
      </div>
      <hr />
      <div className="routine-weeks">
        <p className="title">Program Overview</p>
        {loading && (
          <div className="loader">
            <LoadingOutlined />
          </div>
        )}
        {!loading && routine && (
          <ExerciseWeeks routine={routine} editMode={edit} setRoutine={setRoutine} heightlistcard={edit ? 140 : 57} heightconfig={edit ? 17 : 24} />
        )}
      </div>
      {edit && (
        <div className="step-action">
          <Button className="btn-cancel" disabled={isBtnLoading} onClick={() => setEdit(false)}>
            Cancel
          </Button>
          <Button type="primary" onClick={handleSaveOnEdit} loading={isBtnLoading} disabled={!canPublishRoutine}>
            Save
          </Button>
        </div>
      )}
      {!edit && (
        <div className="step-action">
          <Button className="btn-cancel" disabled={isBtnLoading} onClick={onBack}>
            Back
          </Button>
          <Button type="primary" onClick={handleFinishRoutine} loading={isBtnLoading} disabled={!canPublishRoutine}>
            Finish
          </Button>
        </div>
      )}
      <CloseModal visible={showCloseModal} onCancel={setShowClose} handleClose={handleClose} />
    </WeeksContainer>
  )
}
