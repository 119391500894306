import React from 'react'
import { getMuscles, getGymEquipments } from '../../../ExercisesCatalog/Uses'
import DumbbellIcon from 'assets/exercises/dumbbell.png'
import MuscleIcon from 'assets/exercises/muscle.png'
import { PreviewContainer } from '../../CreateRoutine/Step2/Step2.styled'

const PreviewExercise = ({ muscles, gym_equipments, exercises, exercise, level }) => {
  let alternatives = []
  let exercisesAlt = []
  let thumbnail = ''
  let musclesFormat = getMuscles(exercise.muscles, muscles)
  let gymEquipmentsFormat = getGymEquipments(exercise.gym_equipments, gym_equipments)

  if (exercise.thumbnails && exercise.thumbnails.length > 0) {
    thumbnail = exercise.thumbnails[0].url
  }

  if (exercise.alternatives && exercise.alternatives.length > 0) {
    exercisesAlt = exercise.alternatives.filter((ea) => ea.level === level)
    exercisesAlt.forEach((alt) => {
      const foundExercise = exercises.find((e) => e.id === alt.exercise_alternative_id)
      if (foundExercise) {
        alternatives.push(foundExercise.name)
      }
    })
  }

  return (
    <PreviewContainer>
      <div className="header">
        <img className="picture" src={thumbnail} alt={exercise.name} />
        <div className="wrap-content">
          <span>{exercise.name}</span>
          {musclesFormat && (
            <div className="ctn-info">
              <img src={MuscleIcon} alt="" /> <p>{musclesFormat}</p>
            </div>
          )}
          {gymEquipmentsFormat && (
            <div className="ctn-info">
              <img src={DumbbellIcon} alt="" /> <p>{gymEquipmentsFormat}</p>
            </div>
          )}
        </div>
      </div>
      <hr />
      <div className="description show-scrollbars" tabIndex="0">
        <p className="title">Technique</p>
        <div dangerouslySetInnerHTML={{ __html: exercise.preparation }} />
        <div dangerouslySetInnerHTML={{ __html: exercise.execution }} />
        <div dangerouslySetInnerHTML={{ __html: exercise.description }} />
        {alternatives.length > 0 && (
          <>
            <p className="title">Alternative exercises:</p>
            <p>{alternatives.join(' / ')}</p>
          </>
        )}
      </div>
    </PreviewContainer>
  )
}
export default PreviewExercise
