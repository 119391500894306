import styled from '@emotion/styled'
import { Checkbox } from 'antd'
import OrangeCheck from 'assets/icons/orange-check.svg'

export default styled(Checkbox)`
  .ant-checkbox {
    .ant-checkbox-inner {
      border: 1px solid #c4c4c4;
      border-radius: 50%;
      box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
      width: 30px;
      height: 30px;
      background: #ffffff;
      transition: none;
    }
    .ant-checkbox-inner::after {
      opacity: 0;
      transform: none;
      transition: 0s;
    }
  }

  .ant-checkbox-checked {
    .ant-checkbox-inner {
      background-image: url(${OrangeCheck});
      background-repeat: no-repeat;
      background-position: center;
    }
  }
  .ant-checkbox-checked::after {
    border: none;
  }
`
