import React, { Fragment, useEffect } from 'react'
import { Row, Col, Form, Tabs, Input } from 'antd'
import { SublevelCard } from './Bootcamps.styled'

import Logo from '../../images/ketogainslogo.png'
import ketogainsBanner from '../../images/ketogains_banner.svg'

const FormItem = Form.Item
const { TabPane } = Tabs

const Levels = ({ edit, levels, form }) => {
  useEffect(() => {
    let fields = {}

    levels.forEach((l) => {
      l.sublevels.forEach((s) => {
        fields[`Levels.Level${l.level}.Sublevel${s.id}.excercise`] = s.exerciseURL ? s.exerciseURL : null
        fields[`Levels.Level${l.level}.Sublevel${s.id}.home_exercise`] = s.exerciseHomeURL ? s.exerciseHomeURL : null
        fields[`Levels.Level${l.level}.Sublevel${s.id}.facebook`] = s.facebookURL ? s.facebookURL : null
      })
    })

    form.setFieldsValue(fields)
  }, [levels])

  return (
    <div className="content-shadow">
      <h3 className="section-title">Sublevel Configuration</h3>
      <Tabs defaultActiveKey="1" tabPosition="left">
        {levels.map((item) => {
          let sizeColumn = item.sublevels.length > 2 ? 8 : 12
          return (
            <TabPane tab={`Level ${item.level}`} key={item.level}>
              <Row gutter={[8, 8]}>
                {item.sublevels.map((sublevel) => {
                  return (
                    <Fragment key={sublevel.id}>
                      <Col span={sizeColumn}>
                        <SublevelCard>
                          <div className="cover" style={{ backgroundImage: 'url(' + ketogainsBanner + ')' }} />
                          <div className="content">
                            <div className="name-icon">
                              <img src={Logo} alt="" />
                              <h3>
                                Level {item.level}-{sublevel.id}
                              </h3>
                            </div>
                            <p className="title">URLs Configuration</p>
                            <p className="label">Gym Routines URL</p>
                            {!edit && <p>{sublevel.exerciseURL ? sublevel.exerciseURL : 'Not configured'}</p>}
                            {edit && (
                              <FormItem name={`Levels.Level${item.level}.Sublevel${sublevel.id}.excercise`}>
                                <Input />
                              </FormItem>
                            )}
                            <p className="label">Home Routines URL</p>
                            {!edit && <p>{sublevel.exerciseHomeURL ? sublevel.exerciseHomeURL : 'Not configured'}</p>}
                            {edit && (
                              <FormItem name={`Levels.Level${item.level}.Sublevel${sublevel.id}.home_exercise`}>
                                <Input />
                              </FormItem>
                            )}
                          </div>
                        </SublevelCard>
                      </Col>
                    </Fragment>
                  )
                })}
              </Row>
            </TabPane>
          )
        })}
      </Tabs>
    </div>
  )
}

export default Levels
