import React, { useEffect, useState, useContext } from 'react'
import { TableContainer } from './TablesSummary.styled'
import { apiServices } from 'services/api'
import TableSummary from './TableSummary'
import { LoadingOutlined } from '@ant-design/icons'
import { WorkoutStore } from '../Uses/WorkoutStore'

const getLabelSetConfiguration = ({ week_sets, technique, deload_week, deload_percent_min, deload_percent_max, has_deload }) => {
  const getRir = (sets) => {
    if (sets.length > 0) {
      const set = sets[0]
      if (set.max_rir) return ` (${set.min_rir}-${set.max_rir})`
      if (set.min_rir) return ` (${set.min_rir})`
    }
    return ''
  }
  const getDropsets = (sets) => {
    if (sets.length > 0)
      return ` + ${sets.length} x AMRAP (-${sets[0].drop_set_percent_min}${sets[0].drop_set_percent_max ? `-${sets[0].drop_set_percent_max}` : ''}%)`
    return ''
  }
  const hasAmrap = (sets) => {
    if (sets.length > 0) return !!sets.find((s) => s.is_amrap)
    return false
  }
  const allAreAmrap = (sets) => {
    if (sets.length > 0) return sets.every((s) => s.is_amrap)
    return false
  }
  const getDeload = () => {
    if (has_deload) {
      return `\nDELOAD(W${deload_week}${deload_percent_min && deload_percent_min > 0 ? `-${deload_percent_min}%` : ''}${
        deload_percent_min && deload_percent_min > 0 && deload_percent_max && deload_percent_max > 0 ? ` - ${deload_percent_max}%` : ''
      })`
    }
    return ''
  }
  const dropsets = week_sets.filter((s) => s.is_drop_set)
  let originalSets = week_sets.filter((s) => !s.is_drop_set)
  let lengthSets = originalSets.length
  let areEquals = true
  let arrayReps = []
  let label = []
  const rir = getRir(originalSets)

  if (technique === 'RPT') {
    return (
      `${lengthSets} x ${originalSets
        .map((s) =>
          !s.min_repetitions && s.is_amrap
            ? 'AMRAP'
            : s.min_repetitions && s.max_repetitions
            ? `${s.min_repetitions}-${s.max_repetitions}`
            : s.min_repetitions,
        )
        .join('/')}${rir}` +
      getDropsets(dropsets) +
      getDeload()
    )
  } else if (technique === 'APT') {
    return (
      `${lengthSets} x ${originalSets.map((s) => (!s.min_repetitions && s.is_amrap ? 'AMRAP' : s.min_repetitions)).join('/')}${rir}` +
      getDropsets(dropsets) +
      getDeload()
    )
  } else if (technique === 'MYO REPS') {
    const activationSet = originalSets[0]
    const miniset = originalSets[originalSets.length - 1]
    return (
      `1 x ${activationSet.min_repetitions} - ${activationSet.max_repetitions} + ${originalSets.length - 1} x ${miniset.min_repetitions} - ${
        miniset.max_repetitions
      }` +
      getDropsets(dropsets) +
      getDeload()
    )
  } else if (allAreAmrap(originalSets)) {
    return `${lengthSets} x AMRAP` + getDropsets(dropsets) + getDeload()
  } else if ((!technique || technique === 'BFR') && hasAmrap(originalSets)) {
    return (
      `${lengthSets} x ${originalSets
        .map((s) =>
          !s.min_repetitions && s.is_amrap ? 'AMRAP' : !!s.max_repetitions ? `${s.min_repetitions}-${s.max_repetitions}` : s.min_repetitions,
        )
        .join('/')}${rir}` +
      getDropsets(dropsets) +
      getDeload()
    )
  } else {
    for (const [key, set] of originalSets.entries()) {
      if (arrayReps.length > 0) {
        const index = arrayReps.length - 1
        if (arrayReps[index].min_repetitions !== set.min_repetitions || arrayReps[index].max_repetitions !== set.max_repetitions) {
          areEquals = false
        }
      }

      arrayReps.push(set)

      const minReps = set.is_amrap && set.min_repetitions === 0 ? 'AMRAP' : set.min_repetitions
      const str = `${key + 1} x ${minReps} ${set.max_repetitions ? '- ' + set.max_repetitions : ''} `
      label.push(str)
    }

    if (lengthSets === 0) {
      return ''
    } else if (areEquals) {
      return `${lengthSets} x ${originalSets[0].min_repetitions} ${
        originalSets[0].max_repetitions ? '- ' + originalSets[0].max_repetitions : ''
      }${rir}${getDropsets(dropsets)}${getDeload()}`
    } else {
      return label.join('/ ') + getDropsets(dropsets) + getDeload()
    }
  }
}

const TablesSummary = ({ programRoutines, programName }) => {
  const [loading, setLoading] = useState()
  const [routines, setRoutines] = useState()
  const { exercises } = useContext(WorkoutStore)

  useEffect(async () => {
    setLoading(true)
    if (!programRoutines) {
      setLoading(false)
      return
    }
    const promises = await programRoutines.map(async (routine) => {
      return await apiServices(
        'GET',
        `routine?&id=${routine.id}&step=configuration&withExercises=true&withWeeks=true&withUniqueExercises=true&withSets=true`,
      )
        .then((resp) => resp.json())
        .then((data) => data)
        .catch((error) => {
          return { hasError: true, message: error }
        })
    })
    const routinesData = await Promise.all(promises)
    routinesData.forEach((routine) => {
      if (routine.hasError) {
        console.log('ERROR', routine.message)
      }
    })
    const routineFormat = routinesData.map((routine) => {
      const exercisesArr = []
      routine.routine_weeks.forEach((routineWeek, weekIndex) => {
        routineWeek.week_exercises
          .filter((ex) => !ex.parent)
          .forEach((exercise) => {
            const week = getLabelSetConfiguration(exercise)
            const index = exercisesArr.findIndex((ex) => ex.id === exercise.exercise_id)
            if (index >= 0) {
              exercisesArr[index]['week' + (weekIndex + 1)] = week
            } else {
              const details = exercises.find((el) => el.id === exercise.exercise_id)
              const name = details ? details.name : ''
              let alternatives = routineWeek.week_exercises
                .filter((ex) => ex.parent === exercise.exercise_id)
                .map((alt) => {
                  const detailsAlternative = exercises.find((el) => el.id === alt.exercise_id)
                  return detailsAlternative ? detailsAlternative.name : ''
                })
              alternatives = alternatives.filter((e) => e !== '')
              exercisesArr.push({ exercise: name, alternatives: alternatives.join(' / '), week1: week, id: exercise.exercise_id })
            }
          })
      })
      return { id: routine.id, name: routine.name, exercises: exercisesArr, order: routine.order }
    })
    setRoutines(routineFormat)
    setLoading(false)
  }, [])

  return (
    <TableContainer>
      {loading && (
        <div className="loader">
          <LoadingOutlined />
        </div>
      )}
      {routines && (
        <>
          <div className="program-title">
            <h1>{programName}</h1>
          </div>
          {routines.map((routine) => {
            return <TableSummary key={`Routine-${routine.id}`} routine={routine} />
          })}
        </>
      )}
    </TableContainer>
  )
}
export default TablesSummary
