import styled from '@emotion/styled'
import { css } from '@emotion/react'

export const Table = styled.div`
  display: table;
  width: 100%;
  text-align: center;
  ${({ custom }) => {
    if (custom === 'left') {
      return css`
        && {
          text-align: left;
        }
      `
    }
  }}
`
export const TableRow = styled.div`
  display: table-row;
  ${({ custom }) => {
    if (custom === 'flex') {
      return css`
        && {
          display: flex;
          flex-wrap: wrap;
          div {
            margin-right: 10px;
          }
        }
      `
    }
  }}
`
export const TableHead = styled.div`
  display: table-cell;
  padding: 5px;
  color: #959595;
  font-size: 9px;
  font-weight: 500;
  text-transform: uppercase;
  ${({ custom }) => {
    if (custom === 'block') {
      return css`
        && {
          display: block;
        }
      `
    }
  }}
`
export const TableCell = styled.div`
  display: table-cell;
  padding: 5px;
  color: #383838;
  font-size: 11px;
  font-weight: 500;
  &::first-letter {
    text-transform: uppercase;
  }
  ${({ custom }) => {
    if (custom === 'zero') {
      return css`
        && {
          padding-left: 16px;
        }
      `
    }
    if (custom === 'progress') {
      return css`
        && {
          width: 25%;
          min-width: 80px;
        }
      `
    }
  }}
`
