import { Button } from 'antd'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

export default styled(Button)`
  &.ant-btn {
    background: #ff4613;
    border: 0;
    border-radius: 40px;
    color: #fff;
    display: block;
    max-width: 200px;
    font-family: 'Montserrat', sans-serif;
    height: auto;
    margin: 0 auto;
    outline: none;
    padding: 10px 25px;
    font-size: 12px;
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    &:hover,
    &:active,
    &:focus {
      background: #c62b00;
      box-shadow: 0 0 4px 2px #d4d4d4;
      cursor: pointer;
    }
    &:disabled,
    &[disabled]:hover {
      background-color: #9b9b9b;
      cursor: not-allowed;
    }
    span {
      color: #fff;
      font-size: 12px;
      line-height: 15px;
    }
  }
  ${({ custom }) => {
    if (custom === 'transparent') {
      return css`
        &.ant-btn {
          background: transparent;
          border: thin solid #ff4613;
          border-radius: 5px;
          margin-top: 40px;
          max-width: 240px;
          width: 100%;
          span {
            color: #000;
            font-size: 14px;
            line-height: inherit;
          }
          &:hover {
            background: transparent;
          }
          &:focus {
            span {
              color: #ffffff;
            }
            background-color: #ff4613;
          }
        }
      `
    }
    if (custom === 'notification') {
      return css`
        &.ant-btn {
          text-transform: none;
          padding-left: 40px;
          padding-right: 40px;
        }
      `
    }
  }}
`
