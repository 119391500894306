import React, { createContext, useEffect, useState } from 'react'
import { Result } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { apiServices } from 'services/api'
import moment from 'moment'

const catalogsToGet = [
  'routine_type',
  'set_repetition_unit',
  'set_weight_unit',
  'routine_category',
  'set_type',
  'techniques',
  'exercises',
  'muscle',
  'gym_equipment',
]

const initialCatalogs = {
  routine_types: [],
  set_repetition_units: [],
  set_weight_units: [],
  routine_categories: [],
  set_types: [],
  techniques: [],
  exercises: [],
  muscles: [],
  gym_equipments: [],
  workouts: [],
  bootcamps: [],
}

const WorkoutStore = createContext(initialCatalogs)
const { Provider, Consumer: WorkoutConsumer } = WorkoutStore

const WorkoutProvider = ({ children }) => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState()
  const [data, setData] = useState(initialCatalogs)

  const fetchData = async () => {
    setLoading(true)
    const catalogs = await apiServices('GET', `catalogs?ids=${catalogsToGet.join(',')}`)
      .then((response) => response.json())
      .then((data) => {
        if (data.hasError) throw new Error(`${data.message} ${data.code}`)
        return {
          routine_types: data.routine_type,
          set_repetition_units: data.set_repetition_unit,
          set_weight_units: data.set_weight_unit,
          routine_categories: data.routine_category,
          set_types: data.set_type,
          techniques: data.techniques,
          exercises: data.exercises,
          muscles: data.muscles.sort((a, b) => a.name.localeCompare(b.name)),
          gym_equipments: data.gym_equipments.sort((a, b) => a.name.localeCompare(b.name)),
        }
      })
      .catch((error) => {
        return { hasError: true, message: `${error}` }
      })

    if (!catalogs.hasError) {
      let workouts = []
      let bootcamps = []

      const workoutPlansResponse = await apiServices('GET', 'workout-plans')
        .then((response) => response.json())
        .then((data) => {
          return data
        })
        .catch((error) => {
          return { hasError: true, message: `${error}` }
        })

      if (!workoutPlansResponse.hasError) {
        workouts = workoutPlansResponse.data

        if (workouts.length > 0) {
          bootcamps = workouts
            .map((w) => w.program)
            .filter((value, index, self) => self.findIndex((s) => s.program_id === value.program_id) === index)
            .sort((a, b) => moment(a.access_date).diff(moment(b.access_date)))

          setData({ ...catalogs, workouts, bootcamps, refetch })
        } else {
          setError({ hasError: true, message: 'No workouts to show...' })
        }
      } else {
        setError(workoutPlansResponse)
      }
    } else {
      setError(catalogs)
    }
    setLoading(false)
  }

  useEffect(() => {
    fetchData()
  }, [])

  const refetch = () => {
    fetchData()
  }

  if (loading) {
    return (
      <div className="loader">
        <LoadingOutlined />
      </div>
    )
  }

  if (error) {
    return (
      <Result status="warning" title="There are some problems">
        <div className="desc">{error.message}</div>
      </Result>
    )
  }

  return <Provider value={data}>{children}</Provider>
}

export { WorkoutStore, WorkoutProvider, WorkoutConsumer }
