import React from 'react'
export const filterExercises = ({ allExercises, exerciseFilter }) => {
  let result = allExercises.filter((exercise) => {
    let isMatch = true
    if (exerciseFilter.name) isMatch = exercise.name.toLowerCase().includes(exerciseFilter.name.toLowerCase())
    return isMatch
  })
  return result.sort((a, b) => {
    var exerciseA = (exerciseFilter.order === 'asc' ? a : b).name.trim().toUpperCase()
    var exerciseB = (exerciseFilter.order === 'asc' ? b : a).name.trim().toUpperCase()
    if (exerciseA < exerciseB) {
      return -1
    }
    if (exerciseA > exerciseB) {
      return 1
    }

    return 0
  })
}
export const filterAlternativesToShow = (altExercises, allExercises, key = 'popover') => {
  const result = []
  altExercises.forEach((ex) => {
    if (!result.find((e) => e.exercise_alternative_id === ex.exercise_alternative_id)) result.push(ex)
  })
  const alternatives = result.map((ex) => {
    return {
      levels: altExercises
        .filter((e) => e.exercise_alternative_id === ex.exercise_alternative_id)
        .map((e) => {
          return e.level
        })
        .sort()
        .join(',')
        .replace(1, 'L1')
        .replace(2, 'L2')
        .replace(3, 'L3'),
      name: allExercises.find((e) => e.id === ex.exercise_alternative_id).name,
      exercise_alternative_id: ex.exercise_alternative_id,
    }
  })
  if (alternatives.length === 0) return
  return (
    <ul>
      {alternatives.map((alt, ind) => {
        return <li key={`alternative-${key}-${alt.exercise_alternative_id}`}>{`${alt.name}(${alt.levels})`}</li>
      })}
    </ul>
  )
}

export const getGymEquipments = (exerciseEquipments, arrayEquipments) => {
  let equipment = []
  exerciseEquipments.forEach((equip) => {
    const foundEquipment = arrayEquipments.find((e) => equip.gym_equipment_id === e.id)
    if (foundEquipment) {
      equipment.push(`${foundEquipment.name} (${equip.quantity})`)
    }
  })
  return equipment.length > 0 ? equipment.join(' / ') : null
}

export const getMuscles = (exerciseMuscles, arrayMuscles) => {
  let muscles = []
  exerciseMuscles.forEach((muscle) => {
    const foundMuscle = arrayMuscles.find((m) => m.id === muscle)
    if (foundMuscle) {
      muscles.push(`${foundMuscle.name}`)
    }
  })

  return muscles.length > 0 ? muscles.join(' / ') : null
}

export const formatExercise = ({ id, exercises, muscles, gym_equipments, is_alternative = false, parent = null }) => {
  let details = exercises.find((el) => el.id === id)
  let muscle = getMuscles(details.muscles, muscles)
  let equipment = getGymEquipments(details.gym_equipments, gym_equipments)
  return {
    id: id,
    name: details.name,
    gif_url: details.gif_url,
    thumbnail: details.thumbnails.length > 0 ? details.thumbnails[0].url : '',
    muscles: muscle,
    gym_equipment: equipment,
    is_alternative,
    parent,
  }
}
