import React, { useState } from 'react'
import { Button } from 'antd'
import { StyledModal } from 'styled'

export default ({ visible, handleClose, onCancel }) => {
  const [isBtnLoading, setIsBtnLoading] = useState(false)

  const handleOnClick = async (withSave) => {
    setIsBtnLoading(true)
    if (typeof handleClose === 'function') {
      await handleClose(withSave)
    }
    setIsBtnLoading(false)
  }

  return (
    <StyledModal
      visible={visible}
      title="Do you want to save your progress to finish later?"
      className="confirm"
      centered={true}
      closable={!isBtnLoading}
      onCancel={() => onCancel(false)}
      footer={[
        <Button key="save" loading={isBtnLoading} onClick={() => handleOnClick(true)} style={{ marginBottom: '20px' }}>
          Yes, save as draft
        </Button>,
        <Button key="close" loading={isBtnLoading} type="primary" onClick={() => handleOnClick(false)}>
          No, don’t save my progress
        </Button>,
      ]}
    />
  )
}
