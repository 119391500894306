export const MenuItems = [
  {
    name: 'Coaches',
    url: '/coaches',
    disabled: false,
  },
  {
    name: 'Clients',
    disabled: true,
    child: [
      {
        name: 'Dashboard',
        url: '/clients',
        disabled: false,
      },
      {
        name: 'Communication',
        url: '/communication',
        disabled: false,
      },
      {
        name: 'Merge Contact',
        url: '/mergecontact',
        disabled: false,
      },
    ],
  },
  {
    name: 'Assign',
    url: '/clients-assigned',
    disabled: false,
  },
  {
    name: 'Bootcamps',
    url: '/bootcamps',
    disabled: false,
  },
  {
    name: 'Exercises',
    disabled: true,
    child: [
      {
        name: 'Programs',
        url: 'exercises',
        disabled: false,
      },
      {
        name: 'Catalog',
        url: 'exercises-catalog',
        disabled: false,
      },
    ],
  },
  {
    name: 'Logout',
    url: '/logout',
    disabled: false,
  },
]
