import gql from 'graphql-tag'

export const getData = gql`
  query GetProgram {
    Bootcamp @client {
      id
      start_date
      end_date
      description
    }
  }
`
