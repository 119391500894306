import styled from '@emotion/styled'
export const TableContainer = styled.div`
  max-width: 100%;
  overflow: auto;
  max-height: 100%;
  .program-title {
    background-color: #ff6b00;
    min-width: calc(100vw + 61px);
    display: inline-block;
    h1 {
      color: white;
      padding: 5px 20px;
      margin: 0px;
    }
  }

  .ant-table-wrapper {
    margin-bottom: 20px;
  }
  .ant-table-wrapper:last-of-type {
    margin-bottom: 0px;
  }
  .ant-table-cell {
    min-width: 150px;
    text-align: center;
    padding: 6px 16px;
  }
  @media (max-height: 1000px) {
    max-height: 685px;
  }
`
