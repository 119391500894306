import React, { useState, useMemo } from 'react'
import { Input, notification, Row, Col } from 'antd'
import ExerciseTable from '../ExerciseTable'
import { SearchOutlined } from '@ant-design/icons'
import { filterExercises } from '../Uses'

const AlternativesSelector = ({ allExercises, musclesCatalog, gymEquipmentsCatalog, alternatives, setAlternatives, selectedExercise }) => {
  const [filter, setFilter] = useState({ name: '', order: '' })
  const [exercises, setExercises] = useState([...allExercises])
  const [popover, setPopover] = useState('')
  useMemo(() => {
    setExercises(filterExercises({ allExercises: allExercises, exerciseFilter: filter }))
  }, [filter, allExercises])

  const changeAlternative = (event, exercise) => {
    event.preventDefault()
    let newAlts = [...alternatives]
    if (alternatives.find((ex) => ex.id === exercise.id)) newAlts = newAlts.filter((ex) => ex.id !== exercise.id)
    else if (popover) {
      if (popover.levels.length === 0) {
        notification.warning({ message: 'You have to select at least one level' })
        return
      }
      exercise.levels = popover.levels
      newAlts.push({ ...exercise, levels: popover.levels })
      setPopover('')
    } else setPopover({ ...exercise, levels: [] })
    setAlternatives(newAlts)
  }
  const setLevel = (e) => {
    const levels = [...popover.levels]
    if (popover.levels.find((l) => l === e.target.value)) levels.filter((l) => l !== e.target.value)
    else levels.push(e.target.value)
    setPopover({ ...popover, levels })
  }
  const filterExercisesByName = (e) => setFilter({ ...filter, name: e.target.value })
  const filteredExercises = []
  exercises.forEach((ex) => {
    if (!alternatives.find((exercise) => exercise.id === ex.id) && ex.id !== selectedExercise.id) filteredExercises.push(ex)
  })
  return (
    <>
      <Row gutter={[8, 0]}>
        <Col span={13}>
          <Input
            style={{ width: '30%' }}
            placeholder="Search by name"
            onChange={filterExercisesByName}
            allowClear={true}
            prefix={<SearchOutlined />}
          />
          <br />
          <b>Select alternative exercises</b>
          <ExerciseTable
            allExercises={allExercises}
            exercises={filteredExercises}
            musclesCatalog={musclesCatalog}
            gymEquipmentsCatalog={gymEquipmentsCatalog}
            loading={false}
            setExerciseFilter={(exerciseFilter) => setFilter(exerciseFilter)}
            exerciseFilter={filter}
            alternatives={alternatives}
            changeAlternative={changeAlternative}
            selector={true}
            popover={popover}
            setPopover={setPopover}
            setLevel={setLevel}
          />
        </Col>
        <Col span={11} style={{ marginTop: '32px' }}>
          <b>Your alternatives</b>
          <ExerciseTable
            showLevelsSelected
            allExercises={allExercises}
            exercises={alternatives}
            musclesCatalog={musclesCatalog}
            gymEquipmentsCatalog={gymEquipmentsCatalog}
            loading={false}
            setExerciseFilter={(exerciseFilter) => setFilter(exerciseFilter)}
            exerciseFilter={filter}
            alternatives={alternatives}
            changeAlternative={changeAlternative}
            selector={true}
            sorting={false}
          />
        </Col>
      </Row>
    </>
  )
}

export default AlternativesSelector
