import styled from '@emotion/styled'

export const ExerciseCard = styled.div`
  .exercise-card {
    background: #ffffff;
    border: 1px solid #c4c4c4;
    box-sizing: border-box;
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    display: grid;
    grid-template-columns: 75px 1fr;
    grid-gap: 14px;
    border-left: 10px solid #5d5d5d;
    padding: 10px 10px 9px 13px;
    opacity: ${(props) => (!!props.selected ? '1' : '0.5')};
    cursor: pointer;

    .exercise-image {
      img {
        max-width: 100%;
        max-height: 100%;
      }
      height: 75px;
      width: 75px;
      border-radius: 4px;
    }

    .exercise-info {
      .muscles,
      .equipments {
        display: flex;
        align-items: baseline;

        svg {
          height: 15px;
          width: 15px;
          margin-right: 7px;
        }

        p {
          font-size: 12px;
          color: #4a4a4a;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }

    div {
      overflow: hidden;

      p {
        margin-bottom: 3px;
        font-weight: 500;
        font-size: 14px;
        overflow: hidden;

        &.title {
          font-size: 16px;
          font-weight: 600;
        }
      }
    }
  }

  &.selected {
    .exercise-card {
      opacity: 1;
    }
  }
`
