import { useState } from 'react'
import { getCookie } from 'tiny-cookie'
import { COOKIE } from 'constants.js'

const useCoach = (notification) => {
  const [welcomeLetter, setWelcomeLetter] = useState(false)
  const [welcomeLetterVisible, setWelcomeLetterVisible] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const handleImpersonate = (coachData) => {
    notification.info({ message: `Please wait..` })
    setIsLoading(true)

    const urlencoded = new URLSearchParams()
    urlencoded.append('email', coachData.email)

    fetch(`${process.env.REACT_APP_AUTH_SERVER}/wp-json/ketogains/impersonate`, {
      method: 'POST',
      headers: {
        Authorization: getCookie(COOKIE.USER_TOKEN),
      },
      body: urlencoded,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code) {
          throw new Error(`We could not impersonate you. ${data.message ? data.message : 'Error from server.'}`)
        } else {
          setIsLoading(false)
          window.open(`${process.env.REACT_APP_COACH_PORTAL}/sso?jwt=${data.jwt}&admin=true&key=${coachData.key}`)
        }
      })
      .catch((error) => {
        setIsLoading(false)
        notification.error({ message: `${error}` })
      })
  }

  return {
    welcomeLetter,
    setWelcomeLetter,
    welcomeLetterVisible,
    setWelcomeLetterVisible,
    isLoading,
    handleImpersonate,
  }
}

export default useCoach
