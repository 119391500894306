import { Modal } from 'antd'
import styled from '@emotion/styled'

export default styled(Modal)`
  .ant-modal-content {
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 6px;

    .ant-modal-close {
      .ant-modal-close-x {
        .anticon {
          color: #ff6b00;
        }
      }
    }

    .ant-modal-body {
      padding: 24px 45px;
    }

    .ant-modal-header {
      border: none;
      padding-top: 50px;

      .ant-modal-title {
        text-align: center;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        color: #5d5d5d;
      }
    }

    .ant-modal-footer {
      border: none;

      .ant-btn {
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 12.5px;
        border: 1px solid #ff6b00;
        font-weight: 600;
        font-size: 20px;
        line-height: 20px;
        color: #ff6b00;

        &.ant-btn-primary {
          color: #ffffff;
        }
      }
    }
  }

  &.btn-centered {
    max-width: 440px;
    .ant-modal-footer {
      text-align: center;
      padding-bottom: 35px;
      .ant-btn-primary {
        width: 102.06px;
      }
    }
  }

  &.confirm {
    max-width: 440px;

    .ant-modal-content {
      .ant-modal-body {
        display: none;
      }

      .ant-modal-footer {
        text-align: center;
        padding-bottom: 35px;
      }
    }
  }
`
