import styled from '@emotion/styled'
import { keyframes } from '@emotion/react'

const loadingFrame = keyframes`
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
`

export const Loader = styled.div`
  width: 100%;
  padding: 14px;

  .rectangle {
    width: 100%;
    height: 80px;
    margin-bottom: 4px;
    background: linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%);
    background-size: 400% 100%;
    animation: ${loadingFrame} 1.4s ease infinite;
    border-radius: 7px;
  }

  .button {
    width: 100%;
    height: 43px;
    margin-bottom: 4px;
    background: linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%);
    background-size: 400% 100%;
    animation: ${loadingFrame} 1.4s ease infinite;
    border-radius: 7px;
  }
`
