import React from 'react'
import { Global, css } from '@emotion/react'
import SuccessIcon from 'assets/icons/success-circle.png'

export const GlobalStyles = (props) => {
  const { children } = props

  const global = `
    * {
      font-family: 'Montserrat', sans-serif !important;
    }

    p{
      margin-bottom: 3px;
    }

    html {
      --scrollbarBG: transparent;
      --thumbBG: rgb(255, 107, 0, 0.3);
      --trackBG: rgb(255, 107, 0);
    }
    *::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }
    * {
      scrollbar-width: thin;
      scrollbar-color: var(--thumbBG) var(--scrollbarBG);
    }
    *::-webkit-scrollbar-track {
      border-color: var(--scrollbarBG);
        border-style: solid;
        border-width: 0px 0px 0px 10px;
      background: var(--scrollbarBG);
    }
    *::-webkit-scrollbar-thumb {
      background-color: var(--thumbBG);
        border-radius: 3px;
        :hover{
          background-color: var(--trackBG);
        }
    }
    .context-menu-exercises.ant-popover-placement-bottomRight > 
    .ant-popover-content > .ant-popover-arrow {
      right: 3px;
    }
    .show-scrollbars{
      outline: none !important;
      ::-webkit-scrollbar {
        width: 8px;
      }
      ::-webkit-scrollbar-track {
        border-color: var(--scrollbarBG);
        border-style: solid;
        border-width: 0px 0px 0px 10px;
        background: var(--scrollbarBG);
      }
      ::-webkit-scrollbar-thumb {
        background-color: var(--thumbBG);
        border-radius: 3px;
        :hover{
          background-color: var(--trackBG);
        }
      }
    }
    .context-menu-exercises {
      .ant-popover-arrow {
        border-top-color: #000000 !important;
        border-right-color: transparent;
        border-bottom-color: transparent;
        border-left-color: #000000 !important;
        right: 3px;
      }
      .ant-popover-inner-content{
        background-color: #000000;
        opacity: 0.85;
        border-radius: 3px;
        display: flex;
        flex-direction: column;
        
        p {
          cursor: pointer;
          margin-bottom: 2px;
          color: #fff;
          
          span {
            vertical-align: text-bottom;
            margin-left: 10px;
            font-weight: 500;
            font-size: 16px;
            line-height: 23px;
          }
        }
        p.selected{
          color: #ff8929;
        }
      }
    }
  `

  const antButton = `
    .ant-btn {
      border-radius: 12.5px;

      &.ant-btn-primary {
        &:disabled {
          opacity: 0.5;
          background: #ff6b00;
          border-color: #ff6b00;
          color: #fff;
        }
      }
    }
  `

  const antSelect = `
    .ant-select {
      width: 100%;

      .ant-select-selector {
        border-radius: 4px;

        .ant-select-selection-item {
          font-weight: 600;
          font-size: 16px;
          color: #383838;
        }
      }

      .ant-select-arrow {
        color: #fe6b00;
      }
    }
  `

  const antNotification = `
    .ant-notification{
      top: 80px !important; 
      right: 25px !important;

      .ant-notification-notice{
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 4px;
        padding: 9px 0px 12px 19px;
        border: 1px solid #C4C4C4;

        .ant-notification-notice-content{
          .ant-notification-notice-with-icon{
            .anticon{
              margin: 0;
            }

            .ant-notification-notice-message{
              font-size: 15px;
              margin-bottom: 0px;
              margin-left: 30px;
            }

            .ant-notification-notice-description{
              margin-bottom: 0px;
              margin-left: 30px;
            }
          }
        }

        .ant-notification-notice-close{
          display: none;
        }

        &.success{
          border-left: 8px solid #6FCF97;

          .ant-notification-notice-content{
            .ant-notification-notice-with-icon{
              .anticon{
                width: 24px;
                height: 24px;
                background: url(${SuccessIcon});

                svg{
                  display: none;
                }
              }
            }
          }
        }

        &.error{
          border-left: 8px solid #E53D2F;
        }
      }
    }
  `

  const antEmpty = `
    .ant-empty{
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .ant-empty-image{
        width: 70%;
        margin: 0 auto;
        
        img{
          width: 100%;
          max-width: 255px;
          height: auto;
        }
      }

      .ant-empty-description{
        margin-top: 40px;
        font-weight: 600;
        font-size: 17px;
        color: #C4C4C4;
      }

      &.no-space {
        .ant-empty-description {
          margin-top: 0px;
        }
      }
    }
  `

  return (
    <>
      <Global
        styles={css`
          ${global}
          ${antSelect}
          ${antButton}
          ${antNotification}
          ${antEmpty}
        `}
      />
      {children}
    </>
  )
}
