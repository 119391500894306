import React from 'react'
import { Tag } from 'antd'
import { RoutineCardContainer } from './RoutineCard.styled.js'
import moment from 'moment'

export default ({ routine, onClick, className = '', addonBefore, addonAfter }) => {
  if (addonBefore) className += ' with-addon-before'
  if (addonAfter) className += ' with-addon-after'
  if (routine.status !== 'Approved') className += ' draft'

  let lastEditedDate = moment(routine.created_date).format('MM/DD/YY')
  if (routine.edited_date) lastEditedDate = moment(routine.edited_date).format('MM/DD/YY')

  return (
    <RoutineCardContainer {...{ className }}>
      {addonBefore && <div className="addon-before">{addonBefore}</div>}
      <div className="routine-card" onClick={() => onClick(routine.id)}>
        <img src={routine.icon} alt={routine.name} />
        <div>
          <p className="title" title={routine.name}>
            {routine.name}
          </p>
          <p>
            {routine.status === 'Approved' && <span>Day {routine.order}</span>}
            {routine.status !== 'Approved' && <span title={`Saved ${lastEditedDate}`}>Saved {lastEditedDate}</span>}
            {routine.status === 'Approved' && routine.is_optional && <Tag color="#6FCF97">Optional</Tag>}
            {routine.status !== 'Approved' && <Tag>{routine.status === 'Imported' ? 'Imported' : 'Draft'}</Tag>}
          </p>
        </div>
      </div>
      {addonAfter && <div className="addon-after">{addonAfter}</div>}
    </RoutineCardContainer>
  )
}
