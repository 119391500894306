import styled from '@emotion/styled'

export const MainContainer = styled.div`
  background: #ffffff;
  border: 0.5px solid #e6e6e6;
  box-sizing: border-box;
  box-shadow: 1px 1px 7px rgba(0, 0, 0, 0.35);
  border-radius: 3px;
  padding: 17px 10px 10px;

  header {
    margin-bottom: 10px;

    h1 {
      display: inline;
    }

    .ant-btn {
      float: right;
    }
  }

  .actions-buttons {
    cursor: pointer;
    color: rgb(255, 107, 0);
    font-size: 22px;
    margin-left: 10px;
  }
`

export const WelcomeLetterPreview = styled.div`
  padding: 10px;
  text-align: left;
  background-color: rgb(243, 243, 243);
  border-radius: 7px;
  font-family: Montserrat, sans-serif;
  font-size: 11px;

  p {
    margin-bottom: 1em;
    font-size: 11px;
  }

  li {
    font-size: 11px;
  }

  .ql-align-center {
    text-align: center;
  }

  .ql-align-right {
    text-align: right;
  }
`
