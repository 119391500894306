import React from 'react'
import Layout from 'components/Layout'
import ClientsInfo from './ClientsInfo'
import CoachesInfo from './CoachesInfo'

const Homepage = (props) => {
  return (
    <Layout {...{ ...props }}>
      <ClientsInfo />
      <CoachesInfo />
    </Layout>
  )
}

export default Homepage
