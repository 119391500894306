import styled from '@emotion/styled'
import { Steps } from 'antd'

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  .header-stepper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 3px 0px 0px;
    .title {
      font-size: 18px;
      font-weight: 600;
      line-height: 22px;
      color: #5d5d5d;
      .ant-divider {
        height: 2.5em;
        background-color: #9473c4;
        width: 3px;
      }
    }

    .close {
      cursor: pointer;
    }
  }

  .steps-content {
    min-height: 500px;
    height: 100%;
    .label {
      display: block;
      font-weight: 500;
      font-size: 11px;
      line-height: 13px;
      color: #959595;
      margin-bottom: 10px;
    }
    .ant-tabs {
      height: calc(100% - 26px);
      @media (max-width: 990px) {
        height: unset;
        .ant-col-md-12 {
          max-height: 400px;
        }
      }
      .ant-tabs-content-holder {
        .ant-tabs-content {
          height: 100%;
          .ant-row {
            height: 100%;
            @media (max-width: 990px) {
              display: grid;
              grid-row-gap: 50px !important;
            }
          }
          @media (max-height: 770px) {
            height: calc(100% - 45px);
          }
        }
      }
    }
  }

  .steps-action {
    width: 100%;
    text-align: right;
    .ant-btn {
      width: 148px;
      height: 35px;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      font-size: 20px;
      line-height: 24px;
      font-weight: 600;
    }
    .ant-btn.btn-cancel {
      margin-right: 22px;
    }
  }

  .close {
    padding: 0px 20px;
  }
  .step-action {
    position: absolute;
    bottom: 12px;
    width: 100%;
    text-align: right;
    right: 19px;

    .ant-btn {
      width: 148px;
      height: 35px;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      font-size: 20px;
      line-height: 24px;
      font-weight: 600;
    }
    .ant-btn.btn-cancel {
      margin-right: 22px;
    }
  }
  max-height: calc(100% - 40px);
  height: 100%;
`

export const StyledSteps = styled(Steps)`
  width: 340px;
  .ant-steps-item {
    padding-left: 0px !important;
  }

  .ant-steps-item-icon {
    height: 25px;
    width: 25px;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    margin: 0px;
  }

  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
    background: #ffffff;
    border: 1px solid #fe6b00;
    .ant-steps-icon {
      color: #fe6b00;
    }
  }

  .ant-steps-item-wait .ant-steps-item-icon,
  .ant-steps-item-finish .ant-steps-item-icon {
    background: #c4c4c4;
    border: none;
    .ant-steps-icon {
      display: none;
    }

    .ant-steps-item-title::after {
      border-color: #ff6b00;
    }
  }

  .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon,
  .ant-steps-item-finish .ant-steps-item-icon {
    color: transparent;
    border: none;
    background-color: #fe6b00;
  }

  .ant-steps-item-title::after {
    left: auto !important;
    top: 10px !important;
    border: 2px solid #c4c4c4;
  }

  .ant-steps-item-finish {
    .ant-steps-item-title::after {
      border-color: #ff6b00;
    }
  }
`
