import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { Link } from '@reach/router'

export const Root = styled.div`
  background: #f9f9f9;
`
export const ClientsData = styled.div`
  margin: 0 auto 0;
  max-width: 1024px;
  padding: 50px;
`
export const ClientsDataWrap = styled.div`
  margin: 0 auto;
  margin-bottom: 20px;
  max-width: 780px;
  padding: 20px 80px;
  border: 1px solid #e5e5e5;
  border-radius: 3px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(146, 146, 146, 0.5);
`
export const ClientsCountWrap = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 10px auto;
  max-width: 780px;
`
export const ClientsCount = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 30px;
  padding: 30px;
  border-radius: 3px;
  color: #fff;
  text-align: right;
  width: 48%;
  box-shadow: 0 2px 4px 0 rgba(146, 146, 146, 0.5);
  &:last-of-type {
    margin-right: 0;
  }
  ${({ custom }) => {
    if (custom === 'engagement') {
      return css`
        && {
          background-color: #70ca85;
        }
      `
    }
    if (custom === 'assigned') {
      return css`
        && {
          width: 100%;
          background-color: #e95757;
        }
      `
    }
  }}
`
export const ClientsCountText = styled.div`
  min-width: 170px;
  text-align: right;
  font-size: 20px;
  line-height: 28px;
  span {
    display: block;
    font-size: 29px;
    font-weight: 600;
  }
`
export const ClientsCountValue = styled.div`
  margin-left: 20px;
  font-size: 60px;
  font-weight: 600;
  span {
    font-size: 30px;
    font-weight: 400;
  }
  ${({ custom }) => {
    if (custom === 'assigned') {
      return css`
        && {
          margin-right: 40px;
        }
      `
    }
  }}
`
export const TotalClients = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  background-color: #f3f3f3;
  border: thin solid #ff6b00;
  padding: 10px 30px;
  font-size: 19px;
  span {
    font-weight: 600;
    font-size: 25px;
  }
`
export const StyledLink = styled(Link)`
  display: block;
  width: 48%;
`
