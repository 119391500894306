import styled from '@emotion/styled'

export const RoutineCardContainer = styled.div`
  display: grid;
  grid-gap: 10px;
  align-items: center;
  margin-bottom: 8px;

  &.with-addon-after {
    grid-template-columns: auto 20px;
  }

  &.with-addon-before {
    grid-template-columns: 30px auto;
  }

  .routine-card {
    background: #ffffff;
    border: 1px solid #c4c4c4;
    box-sizing: border-box;
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    cursor: pointer;
    display: grid;
    grid-template-columns: 52px 1fr;
    grid-gap: 14px;
    border-left: 10px solid #4bc4bc;
    padding: 10px 10px 9px 13px;
    opacity: 0.5;

    img {
      width: 100%;
      max-width: 52px;
    }

    div {
      overflow: hidden;

      p {
        margin-bottom: 3px;
        font-weight: 500;
        font-size: 14px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        span:not(.ant-tag) {
          display: inline-block;
          width: calc(100% - 49px);
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        &.title {
          font-size: 16px;
        }
      }
    }

    .ant-tag {
      font-size: 8px;
      line-height: 12px;
      padding: 0px 4px;
      float: right;
      margin-top: 4px;
      margin-right: 0px;
    }
  }

  .addon-after {
    text-align: center;

    img {
      cursor: pointer;
    }
  }

  &.selected {
    .routine-card {
      opacity: 1;
    }
  }

  &.level-1 {
    .routine-card {
      border-left: 10px solid #4bc4bc;
    }
  }

  &.level-2 {
    .routine-card {
      border-left: 10px solid #ec5784;
    }
  }

  &.level-3 {
    .routine-card {
      border-left: 10px solid #9987e2;
    }
  }

  &.draft {
    .routine-card {
      border-left: 10px solid #c4c4c4;
    }
  }
`
