import React from 'react'
import { Table } from 'antd'

const TableSummary = ({ routine }) => {
  const columns = [
    {
      title: `Day ${routine.order}: ${routine.name}`,
      dataIndex: 'exercise',
      key: 'exercise',
    },
    {
      title: 'Alternatives',
      dataIndex: 'alternatives',
      key: 'alternatives',
    },
  ]
  for (let index = 1; index < 9; index++) {
    columns.push({
      title: `Week ${index}`,
      dataIndex: `week${index}`,
      key: `week${index}`,
    })
  }

  return <Table bordered pagination={false} dataSource={routine.exercises} columns={columns} />
}

export default TableSummary
