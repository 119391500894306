import gql from 'graphql-tag'

export const getBootcamps = gql`
  query getBootcamps($today: date!, $configurations: [String!]!) {
    program(where: { _and: [{ end_date: { _gte: $today } }, { type: { _eq: "bootcamp" } }] }, order_by: { start_date: asc }) {
      program_id
      description
      product_tag
      duration
      access_date
      start_date
      end_date
      close_date
      configuration
      timeline
      coach_letters_delivery_date
      group_delivery_date
      exercises_delivery_date
      program_urls {
        level
        sub_level
        type
        url
      }
      banners {
        phase_key
        phase_title
        title
        subtitle
        button
        days_to_show
      }
    }
    configuration(where: { id: { _in: $configurations } }) {
      id
      value
    }
    program_infusionsoft_tag {
      key: id
      program_id
      value
    }
  }
`

export const upsertBootcamp = gql`
  mutation upsertBootcamp(
    $data: program_insert_input!
    $tags: [program_infusionsoft_tag_insert_input!]!
    $urls: [program_url_insert_input!]!
    $banners: [program_enrollment_banner_insert_input!]!
  ) {
    insert_program(
      objects: [$data]
      on_conflict: {
        constraint: program_pkey
        update_columns: [
          description
          product_tag
          duration
          access_date
          start_date
          end_date
          close_date
          last_change
          created_by
          configuration
          timeline
          coach_letters_delivery_date
          group_delivery_date
          exercises_delivery_date
        ]
      }
    ) {
      returning {
        program_id
      }
    }
    insert_program_infusionsoft_tag(objects: $tags, on_conflict: { constraint: program_infusionsoft_tag_pkey, update_columns: [value] }) {
      returning {
        id
      }
    }
    insert_program_url(objects: $urls, on_conflict: { constraint: program_url_program_id_type_level_sub_level_key, update_columns: [url] }) {
      returning {
        id
      }
    }
    insert_program_enrollment_banner(
      objects: $banners
      on_conflict: { constraint: program_enrollment_banner_pkey, update_columns: [phase_title, title, subtitle, button, days_to_show] }
    ) {
      returning {
        program_id
        phase_key
      }
    }
  }
`

export const createProgram = gql`
  mutation createProgram($data: program_insert_input!) {
    insert_program(objects: [$data]) {
      returning {
        program_id
      }
    }
  }
`

export const bootcampBanners = [
  {
    phase_key: 'on_time',
    phase_title: 'On Time',
    title: 'The next bootcamp is starting soon.',
    subtitle: 'Enter your info now.',
    button: 'Let’s begin!',
    days_to_show: -21,
  },
  {
    phase_key: 'delayed',
    phase_title: 'Delayed',
    title: 'Enter your info now.',
    subtitle: 'Ensure that you get your macros on-time!',
    button: 'Don’t get left behind!',
    days_to_show: -8,
  },
  {
    phase_key: 'overdue',
    phase_title: 'Overdue',
    title: 'We already started this bootcamp and we don’t have your info.',
    subtitle: 'Hurry up so we can send your macros.',
    button: 'This is your last chance!',
    days_to_show: 30,
  },
]

export const infusionSoftTags = [
  'ContactDuplicate',
  'BootcampProcessSuccess',
  'BootcampAssigned',
  'StartedEnrollment',
  'SignedForm',
  'MacrosInProcess',
  'MacrosSent',
  'WeeklyCompleted',
  'WeeklyFeedback',
  'Weekly1',
  'Weekly2',
  'Weekly3',
  'Weekly4',
  'Weekly5',
  'Weekly6',
  'Weekly7',
  'Weekly8',
]
