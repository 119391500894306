import React, { useEffect, useState } from 'react'
import Coach from 'queries/coach'
import Layout from 'components/Layout'
import { Avatar, Drawer, Table, Tag, notification, Button } from 'antd'
import { UserOutlined, UserSwitchOutlined, MailOutlined } from '@ant-design/icons'
import showdown from 'showdown'
import { MainContainer, WelcomeLetterPreview } from './Coaches.styled.js'
import useCoach from 'hooks/useCoach'
import TextEditor from './TextEditor/index'
import apolloClient from 'apolloClient'
import { updateWelcomeLetter } from './data'

const converter = new showdown.Converter()

export default ({ props, isNew, refetch }) => {
  const { welcomeLetter, setWelcomeLetter, welcomeLetterVisible, setWelcomeLetterVisible, isLoading, handleImpersonate } = useCoach(notification)

  const [editWelcomeLetter, setEditWelcomeLetter] = useState(false)
  const [letter, setLetter] = useState()
  const [idcoach, setIDcoach] = useState()

  useEffect(() => {
    setEditWelcomeLetter(isNew)
  }, [isNew])

  const handleEdit = (isEdit) => {
    setEditWelcomeLetter(isEdit)
  }

  const saveChanges = async (refetch, IDcoach) => {
    const welcomeLetter = localStorage.getItem('COACH_WELCOME_LETTER')
    if (!welcomeLetter || welcomeLetter.replace(/<(.|\n)*?>/g, '').trim().length === 0) {
      notification.warning({ message: 'Please enter the coach welcome letter.' })
    } else {
      const { errors } = await apolloClient.mutate({
        mutation: updateWelcomeLetter,
        variables: {
          IDcoach,
          welcomeLetter,
        },
        update: () => refetch(),
      })
      if (!errors) {
        notification.success({ message: 'Updated successfully!' })
        handleEdit(false)
        setLetter(welcomeLetter)
        setWelcomeLetter(welcomeLetter)
        refetch()
      } else notification.error({ message: 'An error has occurred!' })
    }
  }

  const onCloseWelcomeLetter = () => {
    localStorage.removeItem('COACH_WELCOME_LETTER')
    setWelcomeLetterVisible(false)
    setEditWelcomeLetter(false)
  }

  return (
    <Coach>
      {({ loading, error, data, refetch }) => {
        if (error || loading) return null
        const coaches = data.coach.map((coach) => {
          let coachFormatted = {
            key: coach.id,
            name: `${coach.user.first_name} ${coach.user.last_name}`,
            picture: coach.user.picture,
            roles: ['Coach'],
            levels: [],
            capacity: coach.max_students,
            welcome_letter: coach.welcome_letter,
            email: coach.user.email,
          }

          if (coach.is_master) coachFormatted.roles.push('Master')
          if (coach.is_meal_planner) coachFormatted.roles.push('Meal Planner')
          if (coach.levels && coach.levels.length > 0) {
            coach.levels.forEach((level) => {
              level.sublevels.forEach((sublevel) => {
                coachFormatted.levels.push(`Level ${level.level}-${sublevel}`)
              })
            })
          }

          return coachFormatted
        })
        const columns = [
          {
            title: '',
            dataIndex: 'picture',
            key: 'picture',
            render: (picture) => <Avatar src={picture} icon={<UserOutlined />} />,
          },
          {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
          },
          {
            title: 'Roles',
            dataIndex: 'roles',
            key: 'roles',
            render: (roles) => roles.map((role) => <Tag key={role}>{role}</Tag>),
          },
          {
            title: 'Levels',
            dataIndex: 'levels',
            key: 'levels',
            render: (levels) => levels.map((level) => <Tag key={level}>{level}</Tag>),
          },
          {
            title: (
              <p>
                Capacity <br />
                <small>Students</small>
              </p>
            ),
            dataIndex: 'capacity',
            key: 'capacity',
          },
          {
            title: '',
            dataIndex: '',
            key: 'actions',
            render: (coach) => {
              return (
                <div key={coach.key}>
                  <UserSwitchOutlined
                    className="actions-buttons"
                    title={!isLoading ? 'Impersonate' : 'Loading....'}
                    onClick={() => (!isLoading ? handleImpersonate(coach) : null)}
                  />{' '}
                  <MailOutlined
                    className="actions-buttons"
                    title="Welcome letter preview"
                    onClick={() => {
                      setWelcomeLetter(coach.welcome_letter)
                      setLetter(coach.welcome_letter)
                      setIDcoach(coach.key)
                      setWelcomeLetterVisible(true)
                    }}
                  />
                </div>
              )
            },
          },
        ]
        return (
          <Layout {...{ ...props }}>
            <MainContainer>
              <header>
                <h1>Coaches</h1>
                <Table columns={columns} dataSource={coaches} scroll={{ x: true }} pagination={{ pageSize: 50, position: ['none', 'none'] }} />
                <Drawer
                  title="Welcome letter preview"
                  visible={welcomeLetterVisible}
                  onClose={onCloseWelcomeLetter}
                  destroyOnClose={true}
                  width="400"
                >
                  <p style={{ marginBottom: '10px' }}>
                    <div style={{ textAlign: 'center', alignItems: 'center' }}>
                      {!editWelcomeLetter && (
                        <Button type="primary" onClick={() => handleEdit(true)}>
                          Edit Welcome letter
                        </Button>
                      )}
                      {editWelcomeLetter && (
                        <>
                          <Button onClick={() => handleEdit(false)}>Cancel</Button>
                          <Button type="primary" onClick={() => saveChanges(refetch, idcoach)}>
                            Save {!isNew ? 'Changes' : ''}
                          </Button>
                        </>
                      )}
                    </div>
                  </p>
                  <WelcomeLetterPreview>
                    {!editWelcomeLetter && <div dangerouslySetInnerHTML={{ __html: converter.makeHtml(welcomeLetter) }} />}
                    {editWelcomeLetter && <TextEditor letter={letter} />}
                  </WelcomeLetterPreview>
                </Drawer>
              </header>
            </MainContainer>
          </Layout>
        )
      }}
    </Coach>
  )
}
