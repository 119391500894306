import React from 'react'
import { Message } from './Message.styled'
import { ReactComponent as IconWarning } from 'assets/icons/icon-warning.svg'

export default ({ message, type }) => {
  let color
  let IconToShow

  switch (type) {
    case 'warning':
      color = '#FFA401'
      IconToShow = IconWarning
      break
    default:
      break
  }
  return (
    <Message color={color}>
      {!!IconToShow && <IconToShow className="icon" />}
      <p> {message} </p>
    </Message>
  )
}
