import styled from '@emotion/styled'

export const MainContainer = styled.div`
  background: #ffffff;
  border: 0.5px solid #e6e6e6;
  box-sizing: border-box;
  box-shadow: 1px 1px 7px rgba(0, 0, 0, 0.35);
  border-radius: 3px;
  padding: 17px 10px 10px;

  @media (max-width: 990px) {
    .ant-col-lg-18,
    .ant-col-lg-6,
    .ant-col-md-12 {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
  .ant-col-lg-18 {
    height: calc(100vh - 160px);
    .card {
      overflow: hidden;
      height: 100%;
    }
  }

  .ant-col-lg-6 {
    height: calc(100vh - 160px);
    .card:nth-child(2) {
      height: calc(100% - 170px);
      & > .ant-tabs {
        max-height: 100%;
      }
    }
  }
  @media (max-width: 1222px) {
    .ant-col-lg-6 {
      height: calc(100vh - 195px);
    }
  }
  .title {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #5d5d5d;
    margin-bottom: 4px;
  }

  hr {
    border: none;
    border-top: 1px solid #f6f6f6;
  }

  .card {
    background: #ffffff;
    border: 0.5px solid #e6e6e6;
    box-sizing: border-box;
    box-shadow: 1px 1px 7px rgba(0, 0, 0, 0.35);
    border-radius: 2px;
    padding: 10px;
  }

  .levels {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 5px;

    div {
      border-radius: 12.5px;
      padding: 4px 10px;
      text-align: center;
      font-size: 16px;
      cursor: pointer;

      &.selected {
        color: #ffffff;

        &.level-1 {
          background: #4bc4bc;
        }

        &.level-2 {
          background: #ec5784;
        }

        &.level-3 {
          background: #9987e2;
        }
      }
    }
  }

  .routines-tab {
    .ant-tabs-nav {
      .ant-tabs-nav-list {
        width: 100%;

        .ant-tabs-tab {
          width: 47%;
          text-align: center;

          .ant-tabs-tab-btn {
            width: 100%;
            text-align: center;
            position: relative;
            span > span {
              position: absolute;
              top: 5px;
              right: -7px;
            }
          }
        }
      }
    }
    .ant-tabs-content-holder {
      max-height: 100%;
      overflow-y: auto;
      padding-right: 6px;
      .ant-tabs-content {
        height: 100%;
      }
    }
  }

  div.disabled {
    opacity: 0.5;
    touch-action: none;
    pointer-events: none;
  }

  .empty {
    padding-top: 50px;
    text-align: center;

    img {
      width: 100%;
      max-width: 250px;
    }

    p {
      font-weight: 600;
      font-size: 17px;
      text-align: center;
      color: #c4c4c4;
      margin-top: 10px;
    }
  }
`
