import styled from '@emotion/styled'

export const MainContainer = styled.div`
  background: #ffffff;
  border: 0.5px solid #e6e6e6;
  box-sizing: border-box;
  box-shadow: 1px 1px 7px rgba(0, 0, 0, 0.35);
  border-radius: 3px;
  padding: 17px 10px 10px;

  .title {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #5d5d5d;
    margin-bottom: 4px;
  }

  hr {
    border: none;
    border-top: 1px solid #f6f6f6;
  }

  .card {
    background: #ffffff;
    border: 0.5px solid #e6e6e6;
    box-sizing: border-box;
    box-shadow: 1px 1px 7px rgba(0, 0, 0, 0.35);
    border-radius: 2px;
    padding: 10px;
    margin: 10px 0px !important;
  }
`

export const CoachListItem = styled.div`
  p {
    margin: 0;

    span {
      b {
        font-weight: 500;
      }
    }
  }
`
