import React, { Component, Fragment } from 'react'
import Layout from 'components/Layout'
import { get } from 'lodash'
import { Button, Tag, Row, Col, Tooltip } from 'antd'
import { Root, BootcampCard } from './Bootcamps.styled'
import BootcampDetails from './BootcampDetails'
import { getBootcamps, infusionSoftTags } from './query'
import { Query } from 'react-apollo'
import moment from 'moment'

const BootcampCardItem = (props) => {
  const { bootcamp, onClick, isActive } = props
  return (
    <BootcampCard onClick={() => onClick(bootcamp.program_id)} className={`${isActive ? 'active' : ''}`}>
      <div className={`content ${bootcamp.type}`}>
        <h2>{bootcamp.description}</h2>
        <hr />
        <p>{`${moment(bootcamp.start_date).local().format('DD/MMM/YYYY')} - ${moment(bootcamp.end_date).local().format('DD/MMM/YYYY')}`}</p>
        <p>Infusion Soft ID: {bootcamp.product_tag}</p>
        <div className="tags">
          {bootcamp.tags.map((tag) => {
            return (
              <Fragment key={tag.label}>
                <Tooltip
                  title={
                    tag.tooltip ? (
                      <div style={{ fontSize: '10px' }}>
                        {tag.tooltip.map((t) => (
                          <p key={t}>{t}</p>
                        ))}
                      </div>
                    ) : null
                  }
                >
                  <Tag key={tag.label} color={tag.color}>
                    {tag.label}
                  </Tag>
                </Tooltip>
              </Fragment>
            )
          })}
        </div>
      </div>
    </BootcampCard>
  )
}

class Bootcamps extends Component {
  state = {
    bootcampSelected: null,
    prevBootcampSelected: null,
    newBootcamp: false,
  }

  IsBootcampComplete = (bootcamp, levels) => {
    let validate = { isValid: true, warnings: [] }

    if (bootcamp.exercises_delivery_date === null) {
      validate.isValid = false
      validate.warnings.push('Exercises delivery date is missing')
    }
    if (bootcamp.group_delivery_date === null) {
      validate.isValid = false
      validate.warnings.push('Group delivery date is missing')
    }
    if (bootcamp.coach_letters_delivery_date === null) {
      validate.isValid = false
      validate.warnings.push('Coach letters delivery date is missing')
    }

    if (!!!bootcamp.product_tag || bootcamp.product_tag.length <= 0) {
      validate.isValid = false
      validate.warnings.push('The InfusionSoft ID is missing.')
    }

    let tagsMissing = []
    infusionSoftTags.forEach((ift) => {
      if (!!!bootcamp.infusionsoft_tags.find((t) => t.key === ift && t.value != null)) {
        validate.isValid = false
        tagsMissing.push(ift)
      }
    })
    if (tagsMissing.length > 0) validate.warnings.push(`The InfusionSoft tag(s) ${tagsMissing.join(', ')} are missing.`)

    levels.forEach((l) => {
      l.sublevels.forEach((sublevel) => {
        if (!!!bootcamp.program_urls.find((pu) => pu.type === 'exercise' && pu.level === l.level && pu.sub_level === sublevel && pu.url.length > 0)) {
          validate.isValid = false
          validate.warnings.push(`The Exercise URL on Level ${l.level}-${sublevel} is missing`)
        }
      })
    })

    return validate
  }

  formatBootcamp = (bootcamp, levels, ifTags) => {
    let bootcampFormat = {
      ...bootcamp,
      infusionsoft_tags: ifTags.filter((ift) => ift.program_id === bootcamp.program_id),
      tags: [],
      isCurrent: moment().local().isBetween(moment(bootcamp.start_date), moment(bootcamp.end_date)),
      isUpcoming: moment().local().isBetween(moment(bootcamp.access_date), moment(bootcamp.start_date)),
      type: '',
    }

    if (bootcampFormat.isCurrent) {
      bootcampFormat.tags.push({ label: 'CURRENT', color: '#FF6B00' })
      bootcampFormat.type = 'current'
    } else if (bootcampFormat.isUpcoming) {
      bootcampFormat.tags.push({ label: 'UPCOMING', color: '#2BA918' })
      bootcampFormat.type = 'upcoming'
    } else {
      bootcampFormat.tags.push({ label: 'STAND BY', color: '#4BC4BC' })
      bootcampFormat.type = 'stand-by'
    }
    const { isValid, warnings } = this.IsBootcampComplete(bootcampFormat, levels)
    if (!isValid) {
      bootcampFormat.tags.push({ label: 'INCOMPLETE', color: '#F45151', tooltip: warnings })
    }

    if (!!!bootcampFormat.configuration.levels) {
      bootcampFormat.levels = levels.map((level) => {
        return {
          level: level.level,
          sublevels: level.sublevels.map((sublevel) => {
            return {
              id: sublevel,
            }
          }),
        }
      })
    } else {
      bootcampFormat.levels = bootcampFormat.configuration.levels
    }

    bootcampFormat.levels.forEach((level) => {
      level.sublevels.forEach((sublevel) => {
        let exercise = bootcampFormat.program_urls.find((u) => u.level === level.level && u.sub_level === sublevel.id && u.type === 'exercise') || ''
        let exercise_home =
          bootcampFormat.program_urls.find((u) => u.level === level.level && u.sub_level === sublevel.id && u.type === 'exercise_home') || ''
        let facebook = bootcampFormat.program_urls.find((u) => u.level === level.level && u.sub_level === sublevel.id && u.type === 'facebook') || ''
        sublevel.exerciseURL = exercise ? exercise.url : ''
        sublevel.facebookURL = facebook ? facebook.url : ''
        sublevel.exerciseHomeURL = exercise_home ? exercise_home.url : ''
      })
    })

    return bootcampFormat
  }

  selectBootcamp = (id) => {
    this.setState({
      bootcampSelected: id,
      prevBootcampSelected: id,
      newBootcamp: false,
    })
  }

  handleNewBootcamp = () => {
    this.setState({
      bootcampSelected: null,
      newBootcamp: true,
    })
  }

  onRefetch = (refetch) => {
    this.setState(
      {
        bootcampSelected: this.state.prevBootcampSelected,
        newBootcamp: false,
      },
      () => refetch(),
    )
  }

  render() {
    const today = moment().local().format('YYYYMMDD')
    const configurations = ['Levels']
    return (
      <Layout {...{ ...this.props }}>
        <Root>
          <h1>Our Bootcamps</h1>
          <hr />
          <Query query={getBootcamps} variables={{ today, configurations }} fetchPolicy="network-only">
            {({ loading, error, data, refetch }) => {
              if (loading || error) return null

              const programs = get(data, 'program') || []
              const infusionsoft_tags = get(data, 'program_infusionsoft_tag') || []
              const configurationList = get(data, 'configuration') || []
              const levelsConfiguration = configurationList.find((c) => c.id === 'Levels') || {}
              const levels = JSON.parse(levelsConfiguration.value)
              const bootcampList = programs.map((bootcamp) => this.formatBootcamp(bootcamp, levels, infusionsoft_tags))

              const lastBootcamp = programs.slice().sort((a, b) => moment(b.access_date).format('X') - moment(a.access_date).format('X'))
              const lastTimeline = lastBootcamp[0].timeline

              let bootcampSelected
              if (this.state.bootcampSelected) {
                bootcampSelected = bootcampList.find((bootcamp) => bootcamp.program_id === this.state.bootcampSelected)
              } else if (this.state.newBootcamp) {
                bootcampSelected = {
                  infusionsoft_tags: [],
                  program_urls: [],
                  levels: levels.map((level) => {
                    return {
                      level: level.level,
                      sublevels: level.sublevels.map((sublevel) => {
                        return { id: sublevel, group: {} }
                      }),
                    }
                  }),
                  banners: [],
                  timeline: lastTimeline,
                }
              } else {
                bootcampSelected = bootcampList.find((bootcamp) => bootcamp.isCurrent) || {
                  infusionsoft_tags: [],
                  program_urls: [],
                  levels: levels.map((level) => {
                    return {
                      level: level.level,
                      sublevels: level.sublevels.map((sublevel) => {
                        return { id: sublevel, group: {} }
                      }),
                    }
                  }),
                  banners: [],
                  timeline: [],
                }
              }

              return (
                <main>
                  <Row gutter={2}>
                    <Col lg={6} xs={24}>
                      <div className="list">
                        {bootcampList.map((bootcamp) => {
                          const isActive = bootcamp.program_id === bootcampSelected.program_id
                          return (
                            <Fragment key={bootcamp.program_id}>
                              <BootcampCardItem onClick={this.selectBootcamp} {...{ bootcamp, isActive }} />
                            </Fragment>
                          )
                        })}
                      </div>
                      <hr />
                      <Button className="create-new" onClick={() => this.handleNewBootcamp()}>
                        CREATE NEW BOOTCAMP
                      </Button>
                    </Col>
                    <Col lg={18} xs={24}>
                      <BootcampDetails bootcamp={bootcampSelected} isNew={this.state.newBootcamp} refetch={() => this.onRefetch(refetch)} />
                    </Col>
                  </Row>
                </main>
              )
            }}
          </Query>
        </Root>
      </Layout>
    )
  }
}

export default Bootcamps
