import React, { useState, useEffect } from 'react'
import { Input, Select, Popover, Button } from 'antd'
import Uploader from '../Uploader'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { ExerciseFormContainer, AntModalExercisesSelector } from './ExerciseForm.styled'
import AlternativesSelector from '../AlternativesSelector'
import { filterAlternativesToShow } from '../Uses'
const ImageTypes = {
  gif: 'gif',
  thumbnails: 'thumbnails',
  start: 'start',
  finish: 'finish',
}
const maxEquipmentQuantity = 10
const equipmentQuantity = []
for (let index = 1; index < maxEquipmentQuantity; index++) equipmentQuantity.push(index)
const ExerciseForm = ({
  selectedExercise,
  setSelectedExercise,
  musclesCatalog,
  gymEquipmentsCatalog,
  addImageToDeleteUploadCare,
  allExercises,
  video,
  setVideo,
}) => {
  const [equipmentSelector, setEquipmentSelector] = useState(false)
  const [showSelectorAlternatives, setShowSelectorAlternatives] = useState()
  const [alternatives, setAlternatives] = useState([])
  useEffect(() => {
    if (selectedExercise.alternatives.length === 0) return []
    const filteredAlt = []
    selectedExercise.alternatives.forEach((ex) => {
      if (!filteredAlt.find((e) => e.exercise_alternative_id === ex.exercise_alternative_id)) filteredAlt.push(ex)
    })
    const alts = filteredAlt.map((ex) => {
      return {
        ...allExercises.find((e) => e.id === ex.exercise_alternative_id),
        levels: selectedExercise.alternatives
          .filter((e) => e.exercise_alternative_id === ex.exercise_alternative_id)
          .map((e) => {
            return e.level
          }),
      }
    })
    setAlternatives(alts)
  }, [])
  const uploadCareFileSelected = (file, type, thumbnailtype) => {
    if (type === ImageTypes.thumbnails) {
      const thumbnails = selectedExercise.thumbnails
      thumbnails[thumbnailtype] = file.cdnUrl
      setSelectedExercise({ ...selectedExercise, thumbnails })
    } else if (type === ImageTypes.gif) setSelectedExercise({ ...selectedExercise, gif_url: file.cdnUrl })
  }
  const removeImage = (e, type, thumbnailtype) => {
    e.preventDefault()
    if (type === ImageTypes.thumbnails) {
      addImageToDeleteUploadCare(selectedExercise.thumbnails[thumbnailtype])
      const thumbnails = selectedExercise.thumbnails
      thumbnails[thumbnailtype] = ''
      setSelectedExercise({ ...selectedExercise, thumbnails })
    } else if (type === ImageTypes.gif) {
      addImageToDeleteUploadCare(selectedExercise.gif_url)
      setSelectedExercise({ ...selectedExercise, gif_url: '' })
    }
  }
  const setSelectedEquipment = (newSelectedEquipment) => {
    if (newSelectedEquipment.length > selectedExercise.gym_equipments.length)
      setEquipmentSelector(newSelectedEquipment[newSelectedEquipment.length - 1])
    else {
      const newEquipment = []
      selectedExercise.gym_equipments.forEach((eq) => {
        if (newSelectedEquipment.find((equipment) => equipment === eq.name)) newEquipment.push(eq)
      })
      setSelectedExercise({ ...selectedExercise, gym_equipments: newEquipment })
    }
  }

  const addEquipment = (quantity) => {
    const gym_equipments = selectedExercise.gym_equipments
    gym_equipments.push({ name: equipmentSelector, quantity })
    setSelectedExercise({ ...selectedExercise, gym_equipments })
    setEquipmentSelector('')
  }
  const equipmentArr = selectedExercise.gym_equipments.map((eq) => {
    return eq.name
  })
  const videoUpload = async (event) => {
    const file = event.target.files[0]
    setVideo({ ...video, size: file.size.toString(), file })
  }
  const formatSetAlternatives = (exercicesWithLevels) => {
    setAlternatives(exercicesWithLevels)
    const alterstoAdd = []
    exercicesWithLevels.forEach((ex) => {
      ex.levels.forEach((level) => {
        alterstoAdd.push({ exercise_alternative_id: ex.id, level })
      })
    })
    setSelectedExercise({ ...selectedExercise, alternatives: [...alterstoAdd] })
  }

  let vimeoURL = selectedExercise.video_url
  const videoParameters = 'autoplay=1&loop=1&muted=1'

  if (vimeoURL.indexOf('?h=') !== -1) {
    vimeoURL += `&${videoParameters}`
  } else {
    vimeoURL += `?${videoParameters}`
  }

  return (
    <>
      <ExerciseFormContainer>
        <p>
          Inputs with <span className="required">*</span> are required!
        </p>
        <div>
          <label htmlFor="name">
            Name <span className="required">*</span>
          </label>
          <Input
            placeholder="Enter the exercise name"
            value={selectedExercise.name}
            id="name"
            autoFocus
            onChange={(e) => setSelectedExercise({ ...selectedExercise, name: e.target.value })}
          />
        </div>
        <div>
          <label htmlFor="description">Description</label>
          <ReactQuill
            placeholder="Enter the exercise description"
            id="description"
            theme="snow"
            value={selectedExercise.description}
            onChange={(value) => setSelectedExercise({ ...selectedExercise, description: value })}
          />
        </div>
        <div>
          <label htmlFor="preparation">
            Preparation <span className="required">*</span>
          </label>
          <ReactQuill
            placeholder="Enter the exercise preparation"
            id="preparation"
            theme="snow"
            value={selectedExercise.preparation}
            onChange={(value) => setSelectedExercise({ ...selectedExercise, preparation: value })}
          />
        </div>
        <div>
          <label htmlFor="execution">
            Execution <span className="required">*</span>
          </label>
          <ReactQuill
            placeholder="Enter the exercise execution"
            id="execution"
            theme="snow"
            value={selectedExercise.execution}
            onChange={(value) => setSelectedExercise({ ...selectedExercise, execution: value })}
          />
        </div>
        <div>
          <label htmlFor="muscles">
            Muscles <span className="required">*</span>
          </label>
          <Select
            placeholder="Select the exercise muscles"
            id="muscles"
            mode="multiple"
            value={selectedExercise.muscles}
            onChange={(newSelectedMuscles) => setSelectedExercise({ ...selectedExercise, muscles: newSelectedMuscles })}
          >
            {musclesCatalog.map((muscle) => {
              if (selectedExercise.muscles.find((m) => m === muscle.name)) return null
              return (
                <Select.Option key={`muscle-${muscle.name}`} value={muscle.name}>
                  {muscle.name}
                </Select.Option>
              )
            })}
          </Select>
        </div>
        <div>
          <label htmlFor="equipment">
            Gym Equipment <span className="required">*</span>
          </label>
          {selectedExercise.gym_equipments.length > 0 && (
            <p>
              {selectedExercise.gym_equipments
                .map((eq) => {
                  return `${eq.name}${eq.quantity !== 1 ? ` (${eq.quantity})` : ``}`
                })
                .join(' ')}
            </p>
          )}
          {equipmentSelector && (
            <Popover
              placement="top"
              content={
                <Select autoFocus placeholder="Select quantity" showSearch onChange={(quantity) => addEquipment(quantity)}>
                  {equipmentQuantity.map((quantity) => {
                    return (
                      <Select.Option key={`quantity-${quantity}`} value={quantity}>
                        {quantity}
                      </Select.Option>
                    )
                  })}
                </Select>
              }
              title={equipmentSelector}
              trigger="click"
              visible={equipmentSelector}
              onVisibleChange={() => setEquipmentSelector('')}
            ></Popover>
          )}
          <Select id="equipment" mode="multiple" placeholder="Select the exercise equipment" value={equipmentArr} onChange={setSelectedEquipment}>
            {gymEquipmentsCatalog.map((equipment) => {
              if (selectedExercise.gym_equipments.find((m) => m.name === equipment.name)) return null
              return (
                <Select.Option key={`equipment-${equipment.name}`} value={equipment.name}>
                  {equipment.name}
                </Select.Option>
              )
            })}
          </Select>
        </div>
        <div>
          <label>Alternatives</label>
          {selectedExercise.alternatives &&
            selectedExercise.alternatives.length > 0 &&
            filterAlternativesToShow(selectedExercise.alternatives, allExercises, 'form')}
          <Button
            onClick={(e) => {
              e.preventDefault()
              setShowSelectorAlternatives(true)
            }}
          >
            Select alternatives
          </Button>
        </div>
        <div>
          <label>
            Gif <span className="required">*</span>
          </label>
          {selectedExercise.gif_url ? (
            <div className="image-container">
              <img alt="Gif" src={selectedExercise.gif_url} />
              <Button onClick={(e) => removeImage(e, ImageTypes.gif)}>Remove gif</Button>
            </div>
          ) : (
            <Uploader effects={false} onUploadComplete={(file) => uploadCareFileSelected(file, ImageTypes.gif)} />
          )}
        </div>
        <div>
          <label>Thumbnail Start</label>
          {selectedExercise.thumbnails.start ? (
            <div className="image-container">
              <img alt="Thumbnail start" src={selectedExercise.thumbnails.start} />
              <Button onClick={(e) => removeImage(e, ImageTypes.thumbnails, ImageTypes.start)}>Remove image</Button>
            </div>
          ) : (
            <Uploader effects onUploadComplete={(file) => uploadCareFileSelected(file, ImageTypes.thumbnails, ImageTypes.start)} />
          )}
        </div>
        <div>
          <label>Thumbnail Finish</label>
          {selectedExercise.thumbnails.finish ? (
            <div className="image-container">
              <img alt="Thumbnail finish" src={selectedExercise.thumbnails.finish} />
              <Button onClick={(e) => removeImage(e, ImageTypes.thumbnails, ImageTypes.finish)}>Remove image</Button>
            </div>
          ) : (
            <Uploader effects onUploadComplete={(file) => uploadCareFileSelected(file, ImageTypes.thumbnails, ImageTypes.finish)} />
          )}
        </div>
        <div>
          <label htmlFor="video">Video</label>
          <Input
            disabled={true}
            id="video"
            value={selectedExercise.video_url}
            onChange={(e) => setSelectedExercise({ ...selectedExercise, video_url: e.target.value })}
          />
          <br />
          <Input onChange={videoUpload} accept="video/*" type="file" placeholder="Upload video" />
          {video.file && <p>Video ready to be saved!</p>}
          {selectedExercise.video_url && (
            <div className="video-container">
              <iframe
                style={{ margin: '10px auto', width: '300px', display: 'block' }}
                controls
                src={vimeoURL}
                allow="autoplay; fullscreen"
                width="311px"
                height="233px"
                frameBorder="0"
                title={selectedExercise.name}
                allowFullScreen={true}
                webkitallowfullscreen="true"
                mozallowfullscreen="true"
              />
            </div>
          )}
        </div>
      </ExerciseFormContainer>
      {showSelectorAlternatives && allExercises.length > 0 && (
        <AntModalExercisesSelector
          title={selectedExercise && selectedExercise.name ? `Alternative exercises for ${selectedExercise.name}` : `Alternative exercises selector`}
          visible={true}
          width={'95%'}
          okText="Save"
          onOk={(e) => {
            e.preventDefault()
            setShowSelectorAlternatives(false)
          }}
          onCancel={(e) => {
            e.preventDefault()
            setShowSelectorAlternatives(false)
          }}
        >
          <AlternativesSelector
            selectedExercise={selectedExercise}
            allExercises={allExercises}
            musclesCatalog={musclesCatalog}
            alternatives={alternatives}
            setAlternatives={formatSetAlternatives}
            gymEquipmentsCatalog={gymEquipmentsCatalog}
          />
        </AntModalExercisesSelector>
      )}
    </>
  )
}

export default ExerciseForm
